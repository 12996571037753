import React, { useEffect, useState } from "react";
import whitedelete from "../../../../../img/white-delete.svg";
import whiteedit from "../../../../../img/white-edit.svg";

import whiteview from "../../../../../img/white-eye.svg";

import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  designationDelete,
  designationUpdate,
  categoryList,
  categoryDelete,
  categoryUpdate,
  branchrole,
  restaurantList,
  Profilelist,
  categorySequenceUpdate,
} from "../../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "./list.css";
import swal from "sweetalert";

const CategoryList = ({
  setIShowSecondPart,
  updateAPI,
  setUpdateAPI,
  setCategoryId,
  setCanCreateCategory,
  setIsPerfectUserAdd,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roletype = localStorage.getItem("roletype");

  const [categories, setCategories] = useState([]);

  const [branches, setBranches] = useState([]);
  const [restaurants, setRestaurants] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [totalCounts, setTotalCounts] = useState("");

  const [restaurant, setRestaurant] = useState("");
  const [branch, setBranch] = useState("");
  const [status, setStatus] = useState("");
  const handleBlankFilter = () => {
    setRestaurant("");
    setBranch("");
    setStatus("");
    getCategoryList();
  };

  const [Profiledata, setProfiledata] = useState("");
  const [isperfectuser, setIsPerfectUser] = useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
          setIsPerfectUserAdd(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  let canView = false;
  let canEdit = false;
  let canDelete = false;

  if (roletype !== "2") {
    canView = true;
    canEdit = true;
    canDelete = true;
    setCanCreateCategory(true);

    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const HardAllotPermission = userpermissions?.find(
      (permission) => permission.name === "Categories"
    );

    if (HardAllotPermission) {
      canView = HardAllotPermission.View || false;
      setCanCreateCategory(HardAllotPermission.Create || false);
      canEdit = HardAllotPermission.Edit || false;
      canDelete = HardAllotPermission.Delete || false;
    }
  }
  const isVisibleViewIcon = () => {
    if (roletype == "2" && canView == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canView == true) {
      return true;
    } else {
      return true;
    }
  };
  const isVisibleEditIcon = () => {
    if (roletype == "2" && canEdit == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canEdit == true) {
      return true;
    } else {
      return true;
    }
  };
  const isVisibleDeleteIcon = () => {
    if (roletype == "2" && canDelete == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canDelete == true) {
      return true;
    } else {
      return true;
    }
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurants(res?.payload?.payload);
        } else {
          setRestaurants([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const getCategoryList = () => {
    setIsLoading(true);
    const requestOption = {
      page: page,
      search: search,
      limit: limit,
      restaurant: restaurant,
      branch: branch,
      status: status,
    };
    dispatch(categoryList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setCategories(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
          setUpdateAPI("");
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getCategoryList();
  }, [page, limit, search, updateAPI]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  // const handleDeleteDesignation = (id) => {
  //   try {
  //     dispatch(categoryDelete(id)).then((res) => {
  //       if (res?.payload?.status === true) {
  //         toast.success(res?.payload?.message);
  //         setCategories(categories.filter((branch) => branch._id !== id));
  //       } else {
  //         toast.error(res?.payload?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeleteDesignation = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(categoryDelete(id)).then((res) => {
          if (res?.payload?.status == true) {
            toast.success(res?.payload?.message);
            setCategories(categories.filter((branch) => branch._id !== id));
          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const handleStatusUpdate = (status, id) => {
    const formData = new FormData();
    formData.append("isActive", !status);

    // const requestOption = {
    //   id: id,
    //   data: {
    //     isActive: !status,
    //   },
    // };

    const requestOptions = {
      id: id,
      head: {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      },
    };
    try {
      dispatch(categoryUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          getCategoryList();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalCounts / limit);

  const handleSequenceChange = (id, newSequenceNo) => {
    // If the input is cleared, allow it
    if (newSequenceNo === "") {
      const updatedData = categories.map((item) => {
        if (item._id === id) {
          return { ...item, sequence: null }; // Set sequence to null or remove it
        }
        return item;
      });

      setCategories(updatedData);
      return;
    }

    // Convert newSequenceNo to a number
    const newSequence = Number(newSequenceNo);

    // Check if the new sequence number is within the valid range
    if (newSequence < 1 || newSequence > 10) {
      // alert('Sequence number must be between 1 and 10');
      toast.warning("Sequence number must be between 1 and 10");
      return;
    }

    // Check if the new sequence number already exists in other items
    const isDuplicate = categories.some(
      (item) => item.sequence === newSequence && item._id !== id
    );
    if (isDuplicate) {
      toast.warning("Sequence number must be unique");

      return;
    }

    // Update the sequence number for the selected item
    const updatedData = categories.map((item) => {
      if (item._id === id) {
        return { ...item, sequence: newSequence };
      }
      return item;
    });

    setCategories(updatedData);
  };

  // Function to handle form submission
  const handleSubmit = () => {
    const formattedData = categories.map((item) => ({
      categoryId: item._id,
      sequence: item.sequence,
      restaurantId: item?.restaurantId,
      branchIds: item?.branchIds,
    }));

    try {
      dispatch(categorySequenceUpdate(formattedData)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          getCategoryList();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }

    // console.log('Sending data to API:', formattedData);
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%", overflowX: "auto" }}
      >
        {/* <section className="Restaurants Dashbored allsection"> */}

        {/* <div className="container-fluid"> */}

        <div className="custom-table">
          <div className="card  card-custom_box mb-4 ">
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="dataTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div className=" pe-2 ">
                    <h6 class="text-navy-blue mb-4 ">Categories</h6>
                    <hr />
                    <div className="sorting_filter_container mb-4">
                      <select
                        name=""
                        id=""
                        value={restaurant}
                        onChange={(e) => {
                          setRestaurant(e.target.value);
                          getBraches(e.target.value);
                        }}
                      >
                        <option value="">Restaurant</option>
                        {restaurants.map((res, i) => (
                          <option key={i} value={res?._id}>
                            {res?.name}
                          </option>
                        ))}
                      </select>
                      <select
                        name=""
                        id=""
                        value={branch}
                        onChange={(e) => setBranch(e.target.value)}
                      >
                        <option value="">Branch</option>
                        {branches.map((res, i) => (
                          <option key={i} value={res?._id}>
                            {res?.name}
                          </option>
                        ))}
                      </select>
                      <select
                        name=""
                        id=""
                        value={status}
                        onChange={(e) => setStatus(e.target.value === "true")}
                      >
                        <option value="">Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                      <button
                        className="search_btn"
                        onClick={() => getCategoryList()}
                      >
                        Search
                      </button>
                      <button
                        className="reset_btn"
                        onClick={() => handleBlankFilter()}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-sm-12 col-md-4">
                        <div
                          className="dataTables_length my-0"
                          id="dataTable_length"
                        >
                          <label>
                            Show{" "}
                            <select
                              name="dataTable_length"
                              aria-controls="dataTable"
                              value={limit}
                              onChange={handleLimitChange}
                              className="custom-select custom-select-sm form-control form-control-sm"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              {/* <option value="100">100</option> */}
                            </select>{" "}
                            Entries
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-8">
                        <div
                          id="dataTable_filter"
                          className="dataTables_filter my-5 categorySearch"
                          style={{ position: "relative", right: "100px" }}
                        >
                          <label>
                            Search:
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="dataTable"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        className="table dataTable no-footer"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                        role="grid"
                        aria-describedby="dataTable_info"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-sort="ascending"
                              aria-label="Restaurant Name: activate to sort column descending"
                              style={{ width: "300px" }}
                            >
                              Title
                            </th>
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-label="Mobile Number: activate to sort column ascending"
                              style={{ width: "300px" }}
                            >
                              Sequence
                            </th>
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-label="Email: activate to sort column ascending"
                              style={{ width: "300px" }}
                            >
                              Status
                            </th>
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-label="Location: activate to sort column ascending"
                              style={{ width: "250px", whiteSpace: "pre" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {categories.length == 0 ? (
                            // Render a loader while data is being fetched
                            <tr>
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                <h3>Empty List</h3>
                              </td>
                            </tr>
                          ) : (
                            categories.map((item, index) => (
                              <>
                                <tr className="odd" key={item.index}>
                                  <td> {item.title}</td>
                                  {/* <td> <select>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select></td> */}
                                  <td>
                                    <input
                                      type="number"
                                      value={item?.sequence || ""}
                                      min="1"
                                      max="10"
                                      onChange={(e) =>
                                        handleSequenceChange(
                                          item._id,
                                          e.target.value
                                        )
                                      }
                                      className="category_sequence_field"
                                    />
                                  </td>

                                  <td
                                    className={
                                      item.isActive
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                    onClick={() =>
                                      handleStatusUpdate(
                                        item.isActive,
                                        item._id
                                      )
                                    }
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Click to change status"
                                    >
                                      {" "}
                                      {item.isActive
                                        ? "Active"
                                        : "Inactive"}{" "}
                                    </span>
                                  </td>

                                  <td>
                                    <ul className="action-btns d-flex gap-2">
                                      {isVisibleViewIcon() && (
                                        <li>
                                          <button
                                            className="bg-green delete_btn"
                                            onClick={() => {
                                              setIShowSecondPart("view");
                                              setCategoryId(item._id);
                                            }}
                                          >
                                            <img src={whiteview} />
                                          </button>
                                        </li>
                                      )}

                                      {isVisibleEditIcon() && (
                                        <li>
                                          <button
                                            className="bg-blue delete_btn"
                                            onClick={() => {
                                              setIShowSecondPart("edit");
                                              setCategoryId(item._id);
                                            }}
                                          >
                                            <img src={whiteedit} />
                                          </button>
                                        </li>
                                      )}

                                      {isVisibleDeleteIcon() && (
                                        <li>
                                          <button
                                            className="bg-orange delete_btn"
                                            onClick={() =>
                                              handleDeleteDesignation(item?._id)
                                            }
                                          >
                                            <img src={whitedelete} />
                                          </button>
                                        </li>
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                      <button
                        onClick={() => handleSubmit()}
                        className="set_sequence_btn"
                      >
                        Set Sequence
                      </button>
                    </div>
                  </div>
                  <div className="row  align-items-center justify-content-between">
                    <div className="col-sm-12 col-md-12">
                      <div
                        className="dataTables_info"
                        id="dataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to {totalCounts} of{" "}
                        {categories.length ? categories.length : "0"} Entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 d-flex justify-content-center">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={limit}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </section> */}
      </div>
    </div>
  );
};

export default CategoryList;
