import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../../TopHeader/topheader";
import Firedark from "../../../../../../img/Fire-dark.svg";
import userimg from "../../../../../../img/branch 1.svg";
import whitedelete from "../../../../../../img/white-delete.svg";
import whiteeye from "../../../../../../img/white-eye.svg";
import whiteedit from "../../../../../../img/white-edit.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  branchDelete,
  branchEdit,
  branchList,
  Profilelist,
} from "../../../../../../Helper/Redux/API";
import swal from "sweetalert";
import "./list.css";
import ReactPaginate from "react-paginate";
import AddBrachModal from "../add/add";
import EditBrachModal from "../edit/edit";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Branches = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const location = useLocation();

  const restaurantId = location.pathname.split("/")[3];
  const pathParts = location.search.split("/");
  const MaxBranchId = pathParts[1];
  console.log("MaxBranchId", MaxBranchId);

  const [brachid, setBrachId] = useState("");
  const [brachList, setBrachList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [updateAPI, setUpdateAPI] = useState(1);

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [totalCounts, setTotalCounts] = useState("");
  const [isperfectuser, setIsPerfectUser] = useState("");
  const [Profiledata, setProfiledata] = useState();
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  let canEditbranch = false;
  let canDeletebranch = false;
  let canCreactbranch = false;
  let canViewBranch = false;

  if (roletype !== "2") {
    canEditbranch = true;
    canDeletebranch = true;
    canCreactbranch = true;
    canViewBranch = true;
    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const restaurantbranch = userpermissions?.find(
      (permission) => permission.name === "Branch"
    );

    if (restaurantbranch) {
      canViewBranch = restaurantbranch.View || false;
      canEditbranch = restaurantbranch.Edit || false;
      canDeletebranch = restaurantbranch.Delete || false;
      canCreactbranch = restaurantbranch.Create || false;
    }
  }

  const getBraches = () => {
    setIsLoading(true);
    const requestOptions = {
      limit: limit,
      page: page,
      search: search,
      restaurantId: restaurantId,
    };

    try {
      dispatch(branchList(requestOptions)).then((res) => {
        setIsLoading(false);

        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);
          // console.log("totalCounts", res?.payload?.payload);
          setTotalCounts(res?.payload?.total);

          console.log(res?.payload?.restaurantsss.maxBranches);
          setBrachId(res?.payload?.restaurantsss.maxBranches);
          setUpdateAPI(1);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  useEffect(() => {
    getBraches();
  }, [page, limit, search, updateAPI]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  // const handleDeleteBranch = (id) => {
  //   try {
  //     dispatch(branchDelete(id)).then((res) => {
  //       if (res?.payload?.status === true) {
  //         toast.success(res?.payload?.message);
  //         setBrachList(brachList.filter((branch) => branch._id !== id));
  //         getBraches();
  //       } else {
  //         toast.error(res?.payload?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleUpdateStatus = (status, id) => {
    const formData = new FormData();
    formData.append("isActive", !status);
    formData.append("branch", 1);

    const requestOptions = {
      id: id,
      requestOption: {
        method: "PUT",
        body: formData,
        Headers: {
          authorization: localStorage.getItem("token"),
        },
      },
    };
    try {
      dispatch(branchEdit(requestOptions)).then((res) => {
        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          getBraches();
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBranch = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(branchDelete(id)).then((res) => {
          if (res?.payload?.status === true) {
            toast.success(res?.payload?.message);
            setBrachList(brachList.filter((branch) => branch._id !== id));
            getBraches();
          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalCounts / limit);
  const showButton = brachid == totalCounts;

  console.log("showButton", showButton, totalCounts);
  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Restaurant Branches" img_icon={Firedark} />
          <div className="container-fluid">
            <div className="mb-3">
              <Link to="/Restaurants" className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </Link>
            </div>

            {/* <!-- Content Row --> */}
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                <div className="card shadow-sm card-custom_box h-100 py-3">
                  <div className="card-body">
                    <div className="row card-custom_box_row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h5 mb-0 ">Total Restaurant Branch</div>
                      </div>
                      <div className="col-auto">
                        <h4 className="count-text">
                          <img className="img-fluid" src={userimg} />
                          <span>{totalCounts}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {roletype === "2" ? (
                <>
                  {" "}
                  {canCreactbranch ? (
                    <>
                      {showButton !== true ? (
                        <div className="col-lg-3 col-md-12 mb-4">
                          <AddBrachModal setUpdateAPI={setUpdateAPI} />
                        </div>
                      ) : (
                        <div className="col-lg-6 col-md-12 mb-4">
                          <div className="card shadow-sm card-custom_box h-100 py-3">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mr-2">
                                  <div className="h5 mb-0 ">
                                    Maximum Branch{" "}
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <h4 className="count-text">
                                    {brachid ? brachid : "0"}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        // <></>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {showButton !== true ? (
                    <div className="col-lg-3 col-md-12 mb-4">
                      <AddBrachModal setUpdateAPI={setUpdateAPI} />
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card shadow-sm card-custom_box h-100 py-3">
                        <div className="card-body">
                          <div className="row card-custom_box_row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="h5 mb-0 ">Maximum Branch </div>
                            </div>
                            <div className="col-auto">
                              <h4 className="count-text">
                                {brachid ? brachid : "0"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    // <></>
                  )}
                </>
              )}
            </div>

            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="pageName">Restaurant Branch</div>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            // style={{ width: "100%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "283px;" }}
                                >
                                  Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "180px" }}
                                >
                                  Address
                                </th>
                                {/* <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  // style="width: 204px;"
                                  style={{ width: "180px" }}
                                >
                                  Mobil Number
                                </th> */}
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "130px", whiteSpace: "pre" }}
                                >
                                  Post Code
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "70px" }}
                                >
                                  Location
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "132px" }}
                                >
                                  Status
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action: activate to sort column ascending"
                                  // style="width: 132px;"
                                  style={{ width: "100px" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {brachList.length == 0 ? (
                                // Render a loader while data is being fetched
                                <tr>
                                  <td
                                    colSpan="5"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3>Empty List</h3>
                                  </td>
                                </tr>
                              ) : (
                                brachList.map((item, index) => (
                                  <>
                                    <tr className="odd" key={item._id}>
                                      <td className="sorting_1">{item.name}</td>
                                      <td> {item.addressLineOne}</td>

                                      <td> {item.postCode}</td>
                                      {/* <td> {item.whatsapp}</td> */}

                                      <td> {item.country}</td>
                                      {roletype === "2" ? (
                                        <>
                                          {" "}
                                          {isperfectuser === true &&
                                          canEditbranch ? (
                                            <td
                                              className={
                                                item.isActive
                                                  ? "text-success"
                                                  : "text-danger"
                                              }
                                              data-toggle="tooltip"
                                              title="Change status"
                                              onClick={() =>
                                                handleUpdateStatus(
                                                  item.isActive,
                                                  item._id
                                                )
                                              }
                                            >
                                              {item.isActive
                                                ? "Active"
                                                : "Inactive"}
                                            </td>
                                          ) : (
                                            <td
                                              className={
                                                item.isActive
                                                  ? "text-success"
                                                  : "text-danger"
                                              }
                                              data-toggle="tooltip"
                                              title="Change status"
                                            >
                                              {item.isActive
                                                ? "Active"
                                                : "Inactive"}
                                            </td>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            className={
                                              item.isActive
                                                ? "text-success"
                                                : "text-danger"
                                            }
                                            data-toggle="tooltip"
                                            title="Change status"
                                            onClick={() =>
                                              handleUpdateStatus(
                                                item.isActive,
                                                item._id
                                              )
                                            }
                                          >
                                            {item.isActive
                                              ? "Active"
                                              : "Inactive"}
                                          </td>
                                        </>
                                      )}

                                      <td>
                                        <ul className="action-btns">
                                          {roletype === "2" ? (
                                            <>
                                              {" "}
                                              {canEditbranch && (
                                                <li>
                                                  <EditBrachModal
                                                    setUpdateAPI={setUpdateAPI}
                                                    id={item._id}
                                                    parentId={item.parentId}
                                                  />
                                                </li>
                                              )}{" "}
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <li>
                                                <EditBrachModal
                                                  setUpdateAPI={setUpdateAPI}
                                                  id={item._id}
                                                  parentId={item.parentId}
                                                />
                                              </li>
                                            </>
                                          )}

                                          {roletype === "2" ? (
                                            <>
                                              {" "}
                                              {canDeletebranch && (
                                                <li>
                                                  <button
                                                    className="bg-orange delete_btn"
                                                    onClick={() =>
                                                      handleDeleteBranch(
                                                        item?._id
                                                      )
                                                    }
                                                  >
                                                    <img src={whitedelete} />
                                                  </button>
                                                </li>
                                              )}{" "}
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <li>
                                                <button
                                                  className="bg-orange delete_btn"
                                                  onClick={() =>
                                                    handleDeleteBranch(
                                                      item?._id
                                                    )
                                                  }
                                                >
                                                  <img src={whitedelete} />
                                                </button>
                                              </li>
                                            </>
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  </>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  align-items-center justify-content-between">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to {totalCounts} of{" "}
                            {brachList.length ? brachList.length : "0"} entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Branches;
