import React, { useEffect, useState } from "react";
import Topheader from "../Topcart/topcart";
import burger from "../../../../../../img/no.jpg";
import burgernew from "../../../../../../img/Order/category/burger.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import { useDispatch } from "react-redux";
import {
  mycart,
  mycartremove,
  Addtocart,
  allOrder,
} from "../../../../../../Helper/Redux/API";
import { useLocation, useNavigate } from "react-router-dom";
import "./mycart.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import gift from "../../../../../../img/clen.svg";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: 5,
  overflow: "auto",
  height: "60%", // Added height constraint
};
export const Mycart = () => {
  const roletype = localStorage.getItem("id");
  const [isChecked, setIsChecked] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mycartdata, setMyCartData] = useState([]);
  const [totalprice, setTotalPrice] = useState("");
  const [specialinstruction, setSpecialInstruction] = useState("");
  const [itemcount, setItemCount] = useState("");
  const [comorder, setComOrder] = useState("");

  // const getmycartListData = () => {
  //   // setIsLoading(true);

  //   dispatch(mycart())
  //     .then((res) => {
  //       if (res?.payload?.status === true) {
  //         setMyCartData(res?.payload?.payload);
  //         setItemCount(res?.payload?.totalCount);

  //         const valuesArray = res?.payload?.payload?.map(
  //           (option) => option?.totalPrice
  //         );

  //         const totalPrice = valuesArray.reduce((total, currentValue) => {
  //           return total + Number(currentValue);
  //         }, 0);

  //         console.log("valuesArray", totalPrice);
  //         setTotalPrice(totalPrice);

  //         const dataall = res.payload.payload;

  //         dataall.forEach((dataItem) => {
  //           if (dataItem && Array.isArray(dataItem.bundle)) {
  //             const processedBundles = dataItem.bundle.map((bundle) => {
  //               if (bundle && Array.isArray(bundle.products)) {
  //                 // Process each bundle and format items
  //                 const bundlecustomization = bundle.products.flatMap(
  //                   (product) =>
  //                     product.items.map((item) => ({
  //                       id: item._id,
  //                       name: item.name,
  //                       price: item.price,
  //                     }))
  //                 );
  //                 // Remove the products array
  //                 const { products, ...rest } = bundle;
  //                 return {
  //                   ...rest,
  //                   bundlecustomization,
  //                 };
  //               }
  //               return bundle;
  //             });

  //             console.log("Processed Bundles for item:", processedBundles);
  //           } else {
  //             console.error(
  //               "Error fetching test data: dataItem.bundle is not defined or not an array"
  //             );
  //             // Handle the case where dataItem or dataItem.bundle is not defined or not an array
  //             // Possibly set a default or handle in another appropriate manner
  //           }
  //         });

  //           const allCustomizationItems = mycartdata.flatMap((order) =>
  //     order.customization.flatMap((custom) =>
  //       custom.items.map((item) => ({
  //         id: item._id,
  //         name: item.name,
  //         price: item.price,
  //       }))
  //     )
  //   );
  //   setMyCartData(res?.payload?.payload);

  //         console.log("res?.payload?.payload", dataall);
  //       } else {
  //         // toast.error(res?.payload?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching test data:", error);
  //       // Handle error if needed
  //     })
  //     .finally(() => {
  //       // setIsLoading(false); // Set loading to false after data fetching completes
  //     });
  // };

  const getmycartListData = () => {
    // setIsLoading(true);

    dispatch(mycart())
      .then((res) => {
        if (res?.payload?.status === true) {
          setMyCartData(res?.payload?.payload);
          setItemCount(res?.payload?.totalCount);

          const valuesArray = res?.payload?.payload?.map(
            (option) => option?.totalPrice
          );

          const totalPrice = valuesArray.reduce((total, currentValue) => {
            return total + Number(currentValue);
          }, 0);

          // console.log("valuesArray", totalPrice);
          setTotalPrice(totalPrice);

          const dataMeal = res.payload.payload.map((dataItem) => {
            if (dataItem && Array.isArray(dataItem.mealbundle)) {
              const processedBundles = dataItem.mealbundle.map((bundle) => {
                if (bundle && Array.isArray(bundle.products)) {
                  // Process each bundle and format items
                  const mealcustomization = bundle.products.flatMap((product) =>
                    product.items.map((item) => ({
                      id: item._id,
                      name: item.name,
                      price: item.price,
                    }))
                  );
                  // Remove the products array
                  const { products, ...rest } = bundle;
                  return {
                    ...rest,
                    mealcustomization,
                  };
                }
                return bundle;
              });

              return {
                ...dataItem,
                mealbundle: processedBundles,
              };
            } else {
              console.error(
                "Error fetching test data: dataItem.mealbundle is not defined or not an array"
              );
              return dataItem;
            }
          });

          const dataall = res.payload.payload.map((dataItem) => {
            if (dataItem && Array.isArray(dataItem.bundle)) {
              const processedBundles = dataItem.bundle.map((bundle) => {
                if (bundle && Array.isArray(bundle.products)) {
                  // Process each bundle and format items
                  const bundlecustomization = bundle.products.flatMap(
                    (product) =>
                      product.items.map((item) => ({
                        id: item._id,
                        name: item.name,
                        price: item.price,
                      }))
                  );
                  // Remove the products array
                  const { products, ...rest } = bundle;
                  return {
                    ...rest,
                    bundlecustomization,
                  };
                }
                return bundle;
              });

              // console.log("Processed Bundles for item:", processedBundles);
              return {
                ...dataItem,
                bundle: processedBundles,
              };
            } else {
              console.error(
                "Error fetching test data: dataItem.bundle is not defined or not an array"
              );
              // Handle the case where dataItem or dataItem.bundle is not defined or not an array
              // Possibly set a default or handle in another appropriate manner
              return dataItem;
            }
          });

          const allCustomizationItems = res?.payload?.payload.map((order) =>
            order.customization.flatMap((custom) =>
              custom.items.map((item) => ({
                id: item._id,
                name: item.name,
                price: item.price,
              }))
            )
          );

          // Combine dataall and allCustomizationItems
          // Combine dataall and allCustomizationItems
          const combinedData = dataall.map((dataItem, index) => {
            const { customization, ...rest } = dataItem; // Remove customization array
            return {
              ...rest,
              customization: allCustomizationItems[index] || [],
            };
          });

          // Combine combinedData with dataMeal
          const finalCombinedData = combinedData.map((dataItem, index) => {
            const mealDataItem = dataMeal[index] || {};
            return {
              ...dataItem,
              mealbundle: mealDataItem.mealbundle || [],
            };
          });

          // setMyCartData(combinedData);
          setComOrder(finalCombinedData);
          // console.log("json", JSON.stringify(combinedData));
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getmycartListData();
  }, []);

  // console.log("mycart", mycartdata);

  // const handleDuplicate = (id) => {
  //   // Find the item with the given ID
  //   const itemToDuplicate = mycartdata.find((item) => item._id === id);

  //   if (itemToDuplicate) {
  //     // Clone the item
  //     const duplicatedItem = { ...itemToDuplicate };

  //     // Optionally, you can modify properties of the duplicated item if needed
  //     // For example, you can reset the ID or append 'Copy' to the name
  //     duplicatedItem._id = generateUniqueId(); // Replace generateUniqueId with your unique ID generation logic
  //     duplicatedItem.item_name += " Copy";

  //     // Add the duplicated item to the cart
  //     setMyCartData((prevState) => [...prevState, duplicatedItem]);
  //   }
  // };

  const handleDuplicate = (item) => {
    console.log("item", item);
    const bundleData = item.bundle.map((selectedItem) => ({
      parentProduct_Id: selectedItem.parentProduct_Id,
      price: selectedItem.price,
      products: selectedItem.products.map((product) => ({
        items: product.items.map((item) => ({
          _id: item._id,
          name: item.name,
          price: item.price,
          childcategory: item.name,
        })),
      })),
    }));

    const mealbundleData = item.mealbundle.map((selectedItem) => ({
      parentProduct_Id: selectedItem.parentProduct_Id,
      price: selectedItem.price,
      products: selectedItem.products.map((product) => ({
        items: product.items.map((item) => ({
          _id: item._id,
          name: item.name,
          price: item.price,
          childcategory: item.name,
        })),
      })),
    }));

    // console.log("item", item);

    const requestOptions = {
      bundle: item.bundle,
      customization: item?.customization,
      itemId: item?.itemId,
      item_desc: item?.item_desc,
      item_image: item?.item_image,
      item_name: item?.item_name,
      restaurantId: localStorage.getItem("id"),
      totalPrice: item.totalPrice,
      mealbundle: mealbundleData,
    };

    // console.log("data", requestOptions);

    try {
      dispatch(Addtocart(requestOptions)).then((res) => {
        // setDisableSubmitBtn(false);

        if (res.payload.status === true) {
          // toast.success(res?.payload?.message);
          getmycartListData();
          // navigate("/ordermanagement/mycart");
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const generateUniqueId = () => {
    return crypto.randomUUID().replace(/-/g, "");
  };
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [removeid, setRemoveid] = useState("");
  const handleRemove = (id) => {
    setOpen(true);
    // removecartitem(id);
    setRemoveid(id);
  };

  // console.log("removeid", removeid);
  const removecartitem = () => {
    setMyCartData((prevState) =>
      prevState.filter((item) => item._id !== removeid)
    );
    try {
      dispatch(mycartremove(removeid)).then((res) => {
        if (res?.payload?.status === true) {
          getmycartListData();
          setOpen(false);
        } else {
          //   setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const NextPage = () => {
  //   navigate("/ordermanagement/myorder", {
  //     state: { mycartdata, specialinstruction, totalprice },
  //   });
  // };

  const backbtn = () => {
    navigate("/ordermanagement/category");
  };

  // console.log("comorder", comorder);
  const NextPage = () => {
    // console.log("item");

    // const allCustomizationItems = mycartdata.flatMap((order) =>
    //   order.customization.flatMap((custom) =>
    //     custom.items.map((item) => ({
    //       id: item._id,
    //       name: item.name,
    //       price: item.price,
    //     }))
    //   )
    // );

    const requestOptions = {
      OrdertotalPrice: totalprice,
      specialinstruction: specialinstruction,
      Data: comorder,
      // customerId: "",
      type: "web",
      restaurantId: localStorage.getItem("id"),
    };
    // navigate("/ordermanagement/myorder");
    // console.log("cccccc", JSON.stringify(requestOptions));
    // navigate("/ordermanagement/myorder", { state: { requestOptions } });

    try {
      dispatch(allOrder(requestOptions)).then((res) => {
        // setDisableSubmitBtn(false);

        if (res.payload.status === true) {
          // toast.success(res?.payload?.message);
          getmycartListData();
          navigate("/ordermanagement/myorder", { state: { isChecked } });
        } else {
          // toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const handleCustmise = (itemDataCustom) => {
    // console.log("item", item);
    navigate("/ordermanagement/CustomizationCart", {
      state: { itemDataCustom },
    });
  };

  console.log("itemcount", itemcount);
  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection mycart">
          <Topheader heding_text="MyCard" count={itemcount} />

          <div className="container-fluid">
            <div className="mb-3">
              <a className="Back_brn" onClick={backbtn}>
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </a>
            </div>

            <div className="custom-control pl-0 custom-switch text-end mt-4 mb-4">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                checked={isChecked == true}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label
                className={`custom-control-label ${
                  isChecked === true ? "eat-in" : "take-away"
                }`}
                for="customSwitch1"
              ></label>
              <span
                className={
                  isChecked == true
                    ? "custom_input_label_left"
                    : " custom_input_label_right"
                }
              >
                {" "}
                {isChecked == true ? "Take A" : " Eat In"}
              </span>
            </div>
            <div className="ContinueOrder">
              <div className="Totalamout">
                Total Amount :
                <span>
                  £{" "}
                  {parseFloat(totalprice).toFixed(2)
                    ? parseFloat(totalprice).toFixed(2)
                    : "0"}
                </span>
              </div>

              {itemcount === 0 ? (
                ""
              ) : (
                <button className="btn" onClick={NextPage}>
                  Continue Order
                </button>
              )}
            </div>
            <div className="row">
              <div className="col-md-8">
                {mycartdata?.map((res, i) => (
                  <>
                    <div className="Cart_item" key={res?._id}>
                      {/* <div className="cartdetails">{res?.item_name}</div> */}
                      <div className="Card_img">
                        <div className="imgcart">
                          {" "}
                          <img
                            src={res?.item_image ? res?.item_image : burger}
                            alt="No Img"
                          />
                        </div>
                        <div className="cart_text">
                          <h5>{res?.item_name}</h5>
                          <p>{res?.item_desc}</p>

                          {/* {res?.bundle?.map((bundlelist, i) => (
                            <div
                              className="allbundata"
                              key={bundlelist.parentProduct_Id}
                            >
                              <div className="bundlist">
                                {bundlelist?.products?.map((bundleall, i) => (
                                  <div className="all_list" key={bundleall.i}>
                                    <>
                                      {bundleall?.items.map((item, i) => (
                                        <div
                                          className="cart_pergrap"
                                          key={item?.i}
                                        >
                                          {item?.name}
                                        </div>
                                      ))}
                                    </>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))} */}

                          {/* {res?.customization?.map((customizitem, i) => (
                            <div className="allcus" key={i}>
                              <div className="all_liscus">
                                {customizitem?.items?.map((custemall, i) => (
                                  <div className="all_list" key={i}>
                                    <>
                                      <div className="cart_pergrap" key={i}>
                                        {custemall?.name}
                                      </div>
                                    </>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))} */}
                        </div>
                      </div>
                      <div className="price-text">
                        Price :{" "}
                        <span>£{parseFloat(res?.totalPrice).toFixed(2)}</span>
                      </div>

                      <div className="all_cart_btn">
                        <button
                          className="btn cartcustm"
                          onClick={() => handleCustmise(res)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_1265_5212)">
                              <path
                                d="M25.9185 13.5306C26.3357 13.5306 26.73 13.3375 26.9852 13.0081C27.2404 12.6786 27.3295 12.2493 27.2269 11.8455C26.8069 10.2023 25.9509 8.70086 24.7519 7.50185L20.0463 2.79629C18.2613 1.01129 15.8876 0.0283203 13.3627 0.0283203H7.26641C3.54517 0.0283203 0.516602 3.05689 0.516602 6.77948V25.6827C0.516602 29.4053 3.54517 32.4339 7.26776 32.4339H11.3184C12.0638 32.4339 12.6687 31.829 12.6687 31.0836C12.6687 30.3383 12.0638 29.7334 11.3184 29.7334H7.26776C5.03447 29.7334 3.21706 27.916 3.21706 25.6827V6.77948C3.21706 4.54619 5.03447 2.72878 7.26776 2.72878H13.3641C13.5841 2.72878 13.8029 2.73958 14.0189 2.75984V9.47994C14.0189 11.7132 15.8363 13.5306 18.0696 13.5306H25.9185ZM16.7194 9.47994V3.61858C17.2311 3.91294 17.7077 4.2775 18.1371 4.70687L22.8427 9.41243C23.2666 9.8364 23.6299 10.3144 23.9269 10.8302H18.0696C17.3256 10.8302 16.7194 10.2239 16.7194 9.47994ZM31.7366 16.0677C30.2055 14.5366 27.5374 14.5366 26.0076 16.0677L16.9516 25.1237C15.9322 26.1431 15.3691 27.5001 15.3691 28.9435V31.085C15.3691 31.8303 15.974 32.4352 16.7194 32.4352H18.8608C20.3042 32.4352 21.6599 31.8722 22.6793 30.8527L31.7353 21.7967C32.5009 21.0312 32.9221 20.0144 32.9221 18.9316C32.9221 17.8487 32.5009 16.8319 31.7366 16.0677ZM29.8261 19.8862L20.7687 28.9422C20.2597 29.4526 19.5819 29.7334 18.8595 29.7334H18.0683V28.9422C18.0683 28.2211 18.3491 27.5433 18.8595 27.0329L27.9168 17.9769C28.4259 17.4666 29.3157 17.4666 29.8261 17.9769C30.0813 18.2308 30.2217 18.5697 30.2217 18.9316C30.2217 19.2934 30.0813 19.631 29.8261 19.8862Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1265_5212">
                                <rect
                                  width="32.4055"
                                  height="32.4055"
                                  fill="white"
                                  transform="translate(0.516602 0.0283203)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Customization
                        </button>
                        <button
                          className="btn cartcustm"
                          onClick={() => handleRemove(res?._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_1265_5217)">
                              <path
                                d="M28.9063 5.50346H24.7206C24.4072 3.97961 23.5781 2.61039 22.3729 1.62657C21.1677 0.642753 19.6602 0.104503 18.1045 0.102539L15.404 0.102539C13.8483 0.104503 12.3408 0.642753 11.1356 1.62657C9.93044 2.61039 9.10128 3.97961 8.7879 5.50346H4.60218C4.24408 5.50346 3.90064 5.64572 3.64743 5.89894C3.39421 6.15215 3.25195 6.49559 3.25195 6.85369C3.25195 7.2118 3.39421 7.55523 3.64743 7.80845C3.90064 8.06167 4.24408 8.20393 4.60218 8.20393H5.95242V25.7569C5.95456 27.5468 6.66653 29.2627 7.93215 30.5284C9.19777 31.794 10.9137 32.5059 12.7036 32.5081H20.805C22.5948 32.5059 24.3108 31.794 25.5764 30.5284C26.842 29.2627 27.554 27.5468 27.5561 25.7569V8.20393H28.9063C29.2644 8.20393 29.6079 8.06167 29.8611 7.80845C30.1143 7.55523 30.2566 7.2118 30.2566 6.85369C30.2566 6.49559 30.1143 6.15215 29.8611 5.89894C29.6079 5.64572 29.2644 5.50346 28.9063 5.50346ZM15.404 2.803H18.1045C18.942 2.80402 19.7587 3.0641 20.4426 3.54758C21.1264 4.03105 21.644 4.71424 21.9243 5.50346H11.5842C11.8645 4.71424 12.3821 4.03105 13.066 3.54758C13.7498 3.0641 14.5665 2.80402 15.404 2.803ZM24.8557 25.7569C24.8557 26.8312 24.4289 27.8615 23.6692 28.6212C22.9096 29.3809 21.8793 29.8076 20.805 29.8076H12.7036C11.6293 29.8076 10.599 29.3809 9.8393 28.6212C9.07965 27.8615 8.65288 26.8312 8.65288 25.7569V8.20393H24.8557V25.7569Z"
                                fill="black"
                              />
                              <path
                                d="M14.0534 24.4073C14.4115 24.4073 14.7549 24.2651 15.0081 24.0118C15.2613 23.7586 15.4036 23.4152 15.4036 23.0571V14.9557C15.4036 14.5976 15.2613 14.2542 15.0081 14.0009C14.7549 13.7477 14.4115 13.6055 14.0534 13.6055C13.6953 13.6055 13.3518 13.7477 13.0986 14.0009C12.8454 14.2542 12.7031 14.5976 12.7031 14.9557V23.0571C12.7031 23.4152 12.8454 23.7586 13.0986 24.0118C13.3518 24.2651 13.6953 24.4073 14.0534 24.4073Z"
                                fill="black"
                              />
                              <path
                                d="M19.4537 24.4073C19.8119 24.4073 20.1553 24.2651 20.4085 24.0118C20.6617 23.7586 20.804 23.4152 20.804 23.0571V14.9557C20.804 14.5976 20.6617 14.2542 20.4085 14.0009C20.1553 13.7477 19.8119 13.6055 19.4537 13.6055C19.0956 13.6055 18.7522 13.7477 18.499 14.0009C18.2458 14.2542 18.1035 14.5976 18.1035 14.9557V23.0571C18.1035 23.4152 18.2458 23.7586 18.499 24.0118C18.7522 24.2651 19.0956 24.4073 19.4537 24.4073Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1265_5217">
                                <rect
                                  width="32.4055"
                                  height="32.4055"
                                  fill="white"
                                  transform="translate(0.550781 0.102539)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Remove
                        </button>
                        <button
                          className="btn cartcustm"
                          onClick={() => handleDuplicate(res)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_1265_5227)">
                              <path
                                d="M28.8754 4.55565L25.7915 1.37046C25.4127 0.981543 24.9601 0.672171 24.4602 0.460483C23.9604 0.248796 23.4232 0.139054 22.8804 0.137695L16.514 0.137695C14.9583 0.139659 13.4508 0.677909 12.2456 1.66173C11.0404 2.64554 10.2113 4.01477 9.8979 5.53862H9.76287C7.97301 5.54076 6.25708 6.25273 4.99145 7.51836C3.72583 8.78398 3.01386 10.4999 3.01172 12.2898V25.7921C3.01386 27.5819 3.72583 29.2979 4.99145 30.5635C6.25708 31.8291 7.97301 32.5411 9.76287 32.5432H17.8643C19.6541 32.5411 21.3701 31.8291 22.6357 30.5635C23.9013 29.2979 24.6133 27.5819 24.6154 25.7921V25.6571C26.1393 25.3437 27.5085 24.5145 28.4923 23.3093C29.4761 22.1042 30.0144 20.5967 30.0163 19.0409V7.37493C30.0184 6.32232 29.6089 5.31059 28.8754 4.55565ZM17.8643 29.8428H9.76287C8.68856 29.8428 7.65825 29.416 6.8986 28.6564C6.13895 27.8967 5.71218 26.8664 5.71218 25.7921V12.2898C5.71218 11.2155 6.13895 10.1852 6.8986 9.4255C7.65825 8.66585 8.68856 8.23908 9.76287 8.23908V19.0409C9.76502 20.8308 10.477 22.5467 11.7426 23.8124C13.0082 25.078 14.7242 25.7899 16.514 25.7921H21.915C21.915 26.8664 21.4882 27.8967 20.7285 28.6564C19.9689 29.416 18.9386 29.8428 17.8643 29.8428ZM23.2652 23.0916H16.514C15.4397 23.0916 14.4094 22.6649 13.6498 21.9052C12.8901 21.1456 12.4633 20.1152 12.4633 19.0409V6.88885C12.4633 5.81454 12.8901 4.78423 13.6498 4.02458C14.4094 3.26493 15.4397 2.83816 16.514 2.83816H21.915V5.53862C21.915 6.25483 22.1995 6.9417 22.7059 7.44813C23.2123 7.95457 23.8992 8.23908 24.6154 8.23908H27.3159V19.0409C27.3159 20.1152 26.8891 21.1456 26.1295 21.9052C25.3698 22.6649 24.3395 23.0916 23.2652 23.0916Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1265_5227">
                                <rect
                                  width="32.4055"
                                  height="32.4055"
                                  fill="white"
                                  transform="translate(0.310547 0.137695)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Duplicate
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="col-md-4">
                <div className="specialinstruction">
                  <h6>Add special instruction </h6>
                  {/* <></> */}

                  <textarea
                    as="textarea"
                    Placeholder="Add special instruction"
                    id="highlights"
                    name="highlights"
                    className="text_ins"
                    onChange={(e) => setSpecialInstruction(e.target.value)}
                    rows={20}
                  />
                </div>
              </div>
            </div>

            <div className="ContinueOrder">
              <div className="Totalamout">
                Total Amount :
                <span>
                  £
                  {parseFloat(totalprice).toFixed(2)
                    ? parseFloat(totalprice).toFixed(2)
                    : "0"}{" "}
                </span>
              </div>
              {itemcount === 0 ? (
                ""
              ) : (
                <button className="btn" onClick={NextPage}>
                  Continue Order
                </button>
              )}
            </div>
          </div>
        </section>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container removeoder">
              <div className="bg_img">
                <img src={gift} alt="man" className="img-fluid" />
              </div>
              <div className="modal_heading">
                {" "}
                <h5>
                  Are you sure want to <br /> Remove your order?
                </h5>
              </div>
              <div className="modal_form_container">
                <div className="close_btn">
                  <button onClick={removecartitem}>Yes</button>
                  <button onClick={handleClose}>No, Resume</button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
