// SidebarContext.js
import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };
  const hideCollapsed = () => {
    setIsSubMenuOpen(true);
  };

  return (
    <SidebarContext.Provider
      value={{ collapsed, toggleCollapsed, isSubMenuOpen, hideCollapsed }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
