import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import "./add.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  branchAdd,
  restaurantList,
  branchrole,
  ProductList,
  Hardwarecreact,
  setingEdit,
  Rolebased,
  Profilelist,
} from "../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import s1 from "../../../../../img/n1.jpg";
import s2 from "../../../../../img/n2.jpg";
import s3 from "../../../../../img/n3.jpg";
import bg from "../../../../../img/bg.jpg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "90vh", // Added height constraint
};

export default function AddHardwareModal({ setUpdateAPI }) {
  const roletype = localStorage.getItem("roletype");
  const [open, setOpen] = React.useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = React.useState(null);
  const [selectedOptionsBranch, setSelectedOptionsBranch] = React.useState([]);
  const [selectedOptionsProduct, setSelectedOptionsProduct] = React.useState(
    []
  );
  const [binaryImage, setBinaryImage] = React.useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [allrestaurant, setAllRestaurant] = React.useState([]);
  const [filddisble, setFilddisble] = React.useState(false);
  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];
  const [brachList, setBrachList] = React.useState([]);
  const [branchid, setBranchID] = React.useState([]);
  const [proid, setProId] = React.useState([]);

  const handleClose = () => setOpen(false);
  const [allproduct, setAllProduct] = React.useState("");

  const [Profiledata, setProfiledata] = React.useState("");
  const [isperfectuser, setIsPerfectUser] = React.useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  React.useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  const initialValues = {
    RestaurantName: "",
    // BranchName: "",
    HardwareType: "",
    SerialNo: "",
    password: "",
    SerialNo: "",
    confirmpassword: "",
  };
  const validationSchema = yup.object({
    RestaurantName: yup.object().required("Restaurant name is Required"),
    // email: yup
    //   .string()
    //   .required("Email is required")
    //   .email("Invalid email address"),

    // BranchName: yup.string().required("Branch name is required"),
    HardwareType: yup.object().required("Hardware type is Required"),
    SerialNo: yup.string().required("Serial no is Required"),
    password: yup
      .string()
      .required("Password is Required")
      .min(6, "Password should be at least 6 characters."),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is Required"),
  });

  const spimg = [s1];

  const handleSubmit = (values, { resetForm }) => {
    setDisableSubmitBtn(true);

    const requestOptions = {
      restaurantId: selectedRestaurant.value,
      branchId: selectedOptionsBranch.value
        ? selectedOptionsBranch.value
        : null,
      productId: selectedOptionsProduct.value,
      password: values.password,
      serialNo: values.SerialNo,
    };

    console.log("requestOptions", requestOptions);
    try {
      dispatch(Hardwarecreact(requestOptions)).then((res) => {
        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          setProId(res?.payload?.payload?._id);

          setUpdateAPI("addrange");
          setSelectedOptionsBranch([
            { value: "", label: "Select Branch Name" },
          ]);
          setSelectedRestaurant([
            { value: "", label: "Select Restaurant Name" },
          ]);
          setSelectedOptionsProduct([
            { value: "", label: "Select Product Name" },
          ]);

          if (
            res?.payload?.payload?._id != "" &&
            res?.payload?.payload?._id != ""
          ) {
            console.log(
              "res?.payload?.payload?._id",
              res?.payload?.payload?._id
            );
          }

          // console.log("res?.payload?.payload?._id", res?.payload?.payload?._id);

          setTimeout(() => {
            sendFormData(res?.payload?.payload?._id);
            resetForm();
            setUpdateAPI("addrange");
            handleClose();
          }, 2000);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("spimg", spimg);
  const onregister = () => {};

  const sendFormData = () => {
    const data = new FormData();
    // Append form values
    data.append("buttonColor", "#ffffff");
    data.append("productId", selectedOptionsProduct.value);
    data.append("restaurantId", selectedRestaurant.value);
    data.append("headingTextColor", "#174B7C");
    data.append("subheadingTextColor", "#454545");
    data.append("backgroundColor", "#FFFFF");
    data.append("buttonTextColor", "#F27A21");
    data.append("subHeading1", "Kiose");
    data.append("subHeading2", "Kiose");

    // Array to store all fetch promises

    const fetchPromises = [];

    spimg.forEach((image, index) => {
      // Push each fetch promise to the array
      fetchPromises.push(
        fetch(image)
          .then((response) => response.blob())
          .then((blob) => {
            data.append(`splashImage`, blob); // Append Blob with unique key
          })
          .catch((error) => {
            console.error("Error converting splash image to binary:", error);
          })
      );
    });

    // Fetch the background image
    fetchPromises.push(
      fetch(bg)
        .then((response) => response.blob()) // Convert image to Blob
        .then((blob) => {
          data.append("backgroundImage", blob); // Append Blob to FormData
        })
        .catch((error) => {
          console.error("Error converting background image to binary:", error);
        })
    );

    // Wait for all fetch operations to complete
    Promise.all(fetchPromises)
      .then(() => {
        let requestOptions = {
          id: selectedOptionsProduct.value,
          requestOption: {
            method: "post",
            body: data,
            headers: {
              Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
            },
          },
        };
        console.log("requestOptions", requestOptions);
        dispatch(setingEdit(requestOptions, selectedOptionsProduct.valu)).then(
          (res) => {
            if (res?.payload?.status == true) {
              toast.success(res?.payload?.message);
              // navigate("/Restaurants");
              // setProId("");
              // handleClose();
              console.log("res?.payload?", res?.payload);
            } else {
              toast.error(res?.payload?.message);
            }
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);

          // setAllProduct(res?.payload?.payload?.products);

          console.log("res?.payload?.payload?.products", res?.payload?.payload);

          if (roletype === "2" && !isperfectuser) {
            // setSelectedOptions({
            //   value: localStorage.getItem("id"),
            //   label: localStorage.getItem("RoleName"),
            // });
            setSelectedRestaurant({
              value: res?.payload?.payload[0]?._id,
              label: res?.payload?.payload[0]?.name,
            });

            setBranchID(res?.payload?.payload[0]?._id);
            getProductData(res?.payload?.payload[0]?._id);

            setFilddisble(true);
            getBraches(res?.payload?.payload[0]?._id);
          }
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const handleChange = (selectedOptions) => {
    setSelectedOptionsBranch([{ value: "", label: "Select Branch Name" }]);
    setSelectedRestaurant(selectedOptions);
    console.log("selectedOptions", selectedOptions.value);
    // selectedOptionsBranch([]);
    setBranchID(selectedOptions.value);
    // console.log("selectedOptions", selectedOptions);
    const valuesArray = selectedOptions.value;
    // console.log("valuesArray", valuesArray);
    // setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    getProductData(selectedOptions.value);

    setSelectedOptionsProduct([{ value: "", label: "Select Product Name" }]);
    getBraches(valuesArray);
    getRestaurantListData();
    console.log("selectedOptions", selectedOptions);
  };

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);

          // console.log("totalCounts", res?.payload?.payload);
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getProductData = (id) => {
    // const requestOption = {
    //   page: "",
    //   search: "",
    //   limit: "",
    // };
    dispatch(Rolebased(id))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllProduct(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allproductlist = Array.isArray(allproduct)
    ? allproduct.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    setSelectedOptionsBranch(selectedOptions);
    setBranchID(selectedOptions.value);
  };

  const handleChangeProduct = (selectedOptions) => {
    setSelectedOptionsProduct(selectedOptions);
  };

  const handleOpen = () => {
    setOpen(true);
    getRestaurantListData();
    // getProductData();
  };

  // React.useEffect(() => {}, [proid]);

  return (
    <div>
      <div
        className="card card-custom_box h-100 py-3 bg-blue"
        onClick={handleOpen}
      >
        <div className="card-body">
          <div className="row card-custom_box_row no-gutters align-items-center">
            <div className="col mt-2">
              <div className="h5 mb-0 text-center">
                <Button className="add_brach_btn">
                  {" "}
                  <h5>Allot Hardware </h5>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Allotment Details</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <Form>
                      <div className="field_cont  custom_form">
                        <div className="row">
                          <div className="col-md-12 d-flex flex-column mb-3">
                            <label htmlFor="RestaurantName">
                              Restaurant Name{" "}
                              <span className="text-danger">*</span>
                            </label>

                            {roletype === "2" ? (
                              <>
                                {" "}
                                {roletype === "2" && isperfectuser ? (
                                  <Select
                                    id="RestaurantName"
                                    name="RestaurantName"
                                    // value={selectedRestaurant}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      formik.setFieldValue("RestaurantName", e);
                                      handleChange(e);
                                    }}
                                    options={allrestaurantlist}
                                    placeholder="Select Restaurant Name"
                                    isSearchable
                                  />
                                ) : (
                                  <Select
                                    id="RestaurantName"
                                    name="RestaurantName"
                                    // value={selectedRestaurant}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      formik.setFieldValue("RestaurantName", e);
                                      handleChange(e);
                                    }}
                                    options={allrestaurantlist}
                                    placeholder="Select Restaurant Name"
                                    isSearchable
                                    isDisabled={filddisble}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {" "}
                                <Select
                                  id="RestaurantName"
                                  name="RestaurantName"
                                  // value={selectedRestaurant}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    formik.setFieldValue("RestaurantName", e);
                                    handleChange(e);
                                  }}
                                  options={allrestaurantlist}
                                  placeholder="Select Restaurant Name"
                                  isSearchable
                                />
                                {/* <Field
                                  as="select"
                                  placeholder="Enter Restaurant Name "
                                  id="Restaurant"
                                  name="RestaurantName"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "RestaurantName",
                                      e.target.value
                                    );
                                    setBranchID(e.target.value);
                                    getBraches(e.target.value);
                                    getProductData(e.target.value);
                                  }}
                                >
                                  <option value="">Select Restaurant</option>
                                  {restaurant.map((res, i) => (
                                    <option value={res?._id} key={i}>
                                      {res?.name}
                                    </option>
                                  ))}
                                </Field> */}
                              </>
                            )}

                            <ErrorMessage
                              component="p"
                              name="RestaurantName"
                              className="text-danger"
                            />
                          </div>

                          <div className="col-md-12 d-flex flex-column mb-3">
                            <label htmlFor="BranchName">
                              Branch Name
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Select
                              id="BranchName"
                              name="BranchName"
                              value={selectedOptionsBranch}
                              onChange={handleChangeBranch}
                              options={allbrachList}
                              placeholder="Select Branch Name"
                              isSearchable
                            />
                            {/* <ErrorMessage
                            component="p"
                            name="BranchName"
                            className="text-danger"
                          /> */}
                          </div>
                          <div className="col-md-12 d-flex flex-column mb-3">
                            <label htmlFor="HardwareType">
                              Product Type
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              id="HardwareType"
                              name="HardwareType"
                              // value={selectedOptionsProduct}
                              onChange={(e) => {
                                formik.setFieldValue("HardwareType", e);
                                handleChangeProduct(e);
                              }}
                              // onChange={handleChangeProduct}
                              options={allproductlist}
                              placeholder="Select Product Type"
                              isSearchable
                            />
                            <ErrorMessage
                              component="p"
                              name="HardwareType"
                              className="text-danger"
                            />
                          </div>

                          <div className="col-md-12 d-flex flex-column mb-3">
                            <label htmlFor="SerialNo">
                              Serial No. <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="text"
                              placeholder="Enter Serial No"
                              id="SerialNo"
                              name="SerialNo"
                            />
                            <ErrorMessage
                              component="p"
                              name="SerialNo"
                              className="text-danger"
                            />
                          </div>

                          <div className="col-md-12 d-flex flex-column mb-3 branchdisabled">
                            <label htmlFor="BranchId">
                              Branch Id
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              type="text"
                              placeholder="BranchId"
                              id="BranchId"
                              name="BranchId"
                              disabled
                              value={branchid}
                            />
                          </div>
                          <div className="col-md-6  mb-3">
                            <label htmlFor="password">
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="eys_input">
                              <Field
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                id="password"
                                name="password"
                                style={{ width: "100%" }}
                              />

                              <span
                                className="password-toggle-icon"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEyeSlash : faEye}
                                />
                              </span>
                            </div>
                            <ErrorMessage
                              component="p"
                              name="password"
                              className="text-danger"
                            />
                          </div>

                          <div className="col-md-6 d-flex flex-column mb-3">
                            <label htmlFor="confirmpassword">
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {/* <Field
                            type="password"
                            Placeholder="Enter Confirm No "
                            id="confirmpassword"
                            name="confirmpassword"
                          /> */}

                            <div className="eys_input">
                              <Field
                                type={showPassword2 ? "text" : "password"}
                                placeholder="Enter Confirm No "
                                id="confirmpassword"
                                name="confirmpassword"
                                style={{ width: "100%" }}
                              />

                              <span
                                className="password-toggle-icon"
                                onClick={() => setShowPassword2(!showPassword2)}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword2 ? faEyeSlash : faEye}
                                />
                              </span>
                            </div>
                            <ErrorMessage
                              component="p"
                              name="confirmpassword"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal_btn_container">
                        <div
                          className="modal_cancel_btn"
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </div>
                        <button className="modal_cancel_btn">Submit</button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
