import React, { useEffect, useContext, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
  Icon,
} from "react-pro-sidebar";
import "./sidebarpage.css";
import logo from "../../img/newlog.png";
import mobilelogo from "../../img/logsv.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Profilelist } from "../../Helper/Redux/API";

import { Link, useLocation, useNavigate } from "react-router-dom";
import sidebarimg from "../../img/sidebar-bottom-bg.png";
import logout_img from "../../img/logout-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import { useSidebar } from "../Sidebarpage/SideboxContext";
const Sidebarpage = () => {
  const roletype = localStorage.getItem("roletype");
  const [Profiledata, setProfiledata] = useState([]);
  const dispatch = useDispatch();
  // const userpermissions = useSelector(
  //   (state) => state?.user?.permissions?.permission
  // );

  const userpermissions = Profiledata ? Profiledata : [];
  const [isperfect, setIsPerfect] = useState("");
  const getProfilelogin = () => {
    // setIsLoading(true);
    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfect(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  let hasRestaurantPermission = false; // Initialize as true
  let hasHardwarePermission = false;
  let hasHardwareAllotment = false;
  let hasRoleManagement = false;
  let hasAddDesignations = false;
  let hasUserManagement = false;
  let hasMenuCategories = false;
  let hasMenuItems = false;
  let hasMenuBundlecreation = false;
  let hasMenuMelacreation = false;
  let OrderPlacement = false;

  if (roletype !== "2") {
    hasRestaurantPermission = true;
    hasHardwarePermission = true;
    hasHardwareAllotment = true;
    hasRoleManagement = true;
    hasAddDesignations = true;
    hasUserManagement = true;
    hasMenuCategories = true;
    hasMenuItems = true;
    hasMenuBundlecreation = true;
    hasMenuMelacreation = true;
    OrderPlacement = true;
    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const restaurantPermission = userpermissions?.find(
      (permission) => permission.name === "Restaurant"
    );

    const HardwareInformation = userpermissions?.find(
      (permission) => permission.name === "Hardware Information"
    );

    const branchPermission = userpermissions?.find(
      (permission) => permission.name === "Branch"
    );

    const Hardwareallotment = userpermissions?.find(
      (permission) => permission.name === "Hardware Allotment"
    );

    const RoleManagement = userpermissions?.find(
      (permission) => permission.name === "Role Management"
    );

    const Designations = userpermissions?.find(
      (permission) => permission.name === "Add Designations"
    );

    const UserManagement = userpermissions?.find(
      (permission) => permission.name === "User Management"
    );

    const UserCategories = userpermissions?.find(
      (permission) => permission.name === "Categories"
    );

    const UserItems = userpermissions?.find(
      (permission) => permission.name === "Items"
    );

    const UserBundlecreation = userpermissions?.find(
      (permission) => permission.name === "Bundle creation"
    );

    const UserMealcreation = userpermissions?.find(
      (permission) => permission.name === "Meal creation"
    );

    const OrderPlace = userpermissions?.find(
      (permission) => permission.name === "Order Placement"
    );

    if (
      restaurantPermission &&
      (restaurantPermission?.View ||
        restaurantPermission?.Create ||
        restaurantPermission?.Edit ||
        restaurantPermission?.Delete)
    ) {
      hasRestaurantPermission = true; // Set to true if permissions are valid
    }

    if (
      HardwareInformation &&
      (HardwareInformation?.View ||
        HardwareInformation?.Create ||
        HardwareInformation?.Edit ||
        HardwareInformation?.Delete)
    ) {
      hasHardwarePermission = true; // Set to true if Branch permissions are valid
    }

    if (
      Hardwareallotment &&
      (Hardwareallotment?.View ||
        Hardwareallotment?.Create ||
        Hardwareallotment?.Edit ||
        Hardwareallotment?.Delete)
    ) {
      hasHardwareAllotment = true; // Set to true if permissions are valid
    }

    if (
      RoleManagement &&
      (RoleManagement?.View ||
        RoleManagement?.Create ||
        RoleManagement?.Edit ||
        RoleManagement?.Delete)
    ) {
      hasRoleManagement = true; // Set to true if permissions are valid
    }

    if (
      Designations &&
      (Designations?.View ||
        Designations?.Create ||
        Designations?.Edit ||
        Designations?.Delete)
    ) {
      hasAddDesignations = true; // Set to true if permissions are valid
    }

    if (
      UserManagement &&
      (UserManagement?.View ||
        UserManagement?.Create ||
        UserManagement?.Edit ||
        UserManagement?.Delete)
    ) {
      hasUserManagement = true; // Set to true if permissions are valid
    }

    if (
      UserCategories &&
      (UserCategories?.View ||
        UserCategories?.Create ||
        UserCategories?.Edit ||
        UserCategories?.Delete)
    ) {
      hasMenuCategories = true; // Set to true if permissions are valid
    }

    if (
      UserItems &&
      (UserItems?.View ||
        UserItems?.Create ||
        UserItems?.Edit ||
        UserItems?.Delete)
    ) {
      hasMenuItems = true; // Set to true if permissions are valid
    }

    if (
      UserBundlecreation &&
      (UserBundlecreation?.View ||
        UserBundlecreation?.Create ||
        UserBundlecreation?.Edit ||
        UserBundlecreation?.Delete)
    ) {
      hasMenuBundlecreation = true; // Set to true if permissions are valid
    }

    if (
      UserMealcreation &&
      (UserMealcreation?.View ||
        UserMealcreation?.Create ||
        UserMealcreation?.Edit ||
        UserMealcreation?.Delete)
    ) {
      hasMenuMelacreation = true; // Set to true if permissions are valid
    }

    if (
      OrderPlace &&
      (OrderPlace?.View ||
        OrderPlace?.Create ||
        OrderPlace?.Edit ||
        OrderPlace?.Delete)
    ) {
      OrderPlacement = true; // Set to true if permissions are valid
    }
  }

  const history = useNavigate();
  const userName = localStorage.getItem("RoleName");
  // const roletype = localStorage.getItem("roletype");

  const { pathname } = useLocation();
  // useEffect(() => {
  //   // Close the submenu when the pathname changes
  //   setIsSubMenuOpen(false);
  // }, [pathname]);
  const logout = () => {
    if (roletype === "1" || roletype === "2") {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id");
      localStorage.removeItem("RoleName");
      localStorage.removeItem("roletype");
      localStorage.removeItem("restaurantType");
      localStorage.removeItem("isperfectmember");
      history("/restaurantslogin");
    } else {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id");
      localStorage.removeItem("RoleName");
      localStorage.removeItem("roletype");
      history("/");
    }
  };

  const RestaurantsIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_222_1292)">
        <path
          d="M0.136698 3.92081C0.773365 1.32665 4.34253 -0.0458542 6.53337 1.35081C7.47987 0.48413 8.71668 0.00341797 10 0.00341797C11.2834 0.00341797 12.5202 0.48413 13.4667 1.35081C18.83 -1.38169 22.9867 6.44498 17.2284 8.92748C17.0725 8.97243 16.9346 9.06494 16.8339 9.19208C16.7332 9.31923 16.6748 9.47466 16.6667 9.63665V14.1666H3.33336V9.63665C3.3253 9.47466 3.26684 9.31923 3.16614 9.19208C3.06544 9.06494 2.92754 8.97243 2.7717 8.92748C1.78501 8.57494 0.966662 7.86477 0.478676 6.93756C-0.0093097 6.01036 -0.131357 4.93372 0.136698 3.92081ZM3.33336 15.8333C3.28234 16.3581 3.34112 16.8878 3.50596 17.3886C3.67079 17.8895 3.93808 18.3505 4.2908 18.7425C4.64353 19.1344 5.07398 19.4486 5.55475 19.6651C6.03553 19.8816 6.55611 19.9956 7.08337 20H12.9167C13.444 19.9956 13.9645 19.8816 14.4453 19.6651C14.9261 19.4486 15.3565 19.1344 15.7093 18.7425C16.062 18.3505 16.3293 17.8895 16.4941 17.3886C16.6589 16.8878 16.7177 16.3581 16.6667 15.8333H3.33336Z"
          fill="#93ACC3"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_1292">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  // console.log("Profiledata", userpermissions, "dddd", Profiledata);
  const [isOpen, setIsOpen] = useState(false);

  const toggleallSidebar = () => {
    setIsOpen(!isOpen);
  };

  const Dashboard = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M14.1755 14.9969V20H17.5109C18.8925 20 20.0125 18.88 20.0125 17.4984V9.89288C20.0127 9.45968 19.8443 9.04341 19.543 8.73216L12.4569 1.07153C11.2066 -0.281261 9.09641 -0.364321 7.74361 0.885989C7.67935 0.945401 7.61744 1.00728 7.55806 1.07153L0.484481 8.72966C0.174054 9.0422 -0.000117201 9.46488 5.91708e-08 9.90539V17.4984C5.91708e-08 18.88 1.11999 20 2.50156 20H5.83695V14.9969C5.85255 12.7231 7.68834 10.8663 9.90484 10.8129C12.1955 10.7576 14.158 12.646 14.1755 14.9969Z"
        fill="#93ACC3"
      />
      <path
        d="M10.0064 11.4954C8.62488 11.4954 7.50488 12.6154 7.50488 13.9969V19H12.508V13.9969C12.508 12.6154 11.388 11.4954 10.0064 11.4954Z"
        fill="#93ACC3"
      />
    </svg>
  );

  const Management = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        d="M21.8092 20.0678C21.8092 20.3089 21.7135 20.54 21.5431 20.7104C21.3726 20.8808 21.1415 20.9766 20.9005 20.9766H0.908717C0.66771 20.9766 0.436575 20.8808 0.266157 20.7104C0.0957396 20.54 0 20.3089 0 20.0678C0 19.8268 0.0957396 19.5957 0.266157 19.4253C0.436575 19.2549 0.66771 19.1591 0.908717 19.1591H20.9005C21.1415 19.1591 21.3726 19.2549 21.5431 19.4253C21.7135 19.5957 21.8092 19.8268 21.8092 20.0678ZM1.81743 17.3417H19.9918C20.2328 17.3417 20.4639 17.246 20.6343 17.0755C20.8048 16.9051 20.9005 16.674 20.9005 16.433V15.5243C20.8972 13.0319 19.9638 10.6306 18.283 8.79032C16.6022 6.95004 14.2951 5.80335 11.8133 5.57472V4.36794C12.1598 4.1679 12.4306 3.85912 12.5837 3.4895C12.7368 3.11987 12.7637 2.71006 12.6601 2.32361C12.5566 1.93716 12.3284 1.59568 12.011 1.35213C11.6936 1.10858 11.3047 0.976563 10.9046 0.976562C10.5045 0.976563 10.1156 1.10858 9.79822 1.35213C9.48082 1.59568 9.25265 1.93716 9.1491 2.32361C9.04555 2.71006 9.07241 3.11987 9.22551 3.4895C9.37862 3.85912 9.64941 4.1679 9.99589 4.36794V5.57472C7.51408 5.80335 5.20696 6.95004 3.52619 8.79032C1.84542 10.6306 0.912017 13.0319 0.908717 15.5243V16.433C0.908717 16.674 1.00446 16.9051 1.17487 17.0755C1.34529 17.246 1.57643 17.3417 1.81743 17.3417Z"
        fill="#93ACC3"
      />
    </svg>
  );

  const MenuManagement = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M15 10.9844C12.2383 10.9844 10 13.2227 10 15.9844C10 18.746 12.2383 20.9844 15 20.9844C17.7617 20.9844 20 18.746 20 15.9844C20 13.2227 17.7617 10.9844 15 10.9844ZM17.66 16.1527L15.8158 17.9227C15.4392 18.2944 14.9392 18.4819 14.4383 18.4819C13.9375 18.4819 13.4358 18.2944 13.0525 17.9202L12.1092 16.996C11.78 16.6735 11.7758 16.146 12.0975 15.8177C12.4192 15.4877 12.9467 15.4835 13.2758 15.806L14.2183 16.7294C14.3383 16.8477 14.5342 16.8452 14.6533 16.7277L16.5058 14.9494C16.8367 14.631 17.365 14.6402 17.6842 14.9735C18.0025 15.3052 17.9917 15.8335 17.66 16.1527ZM8.33333 15.9844C8.33333 13.9927 9.20667 12.206 10.5908 10.9844H4.16667C3.70583 10.9844 3.33333 10.6119 3.33333 10.151C3.33333 9.69021 3.70583 9.31771 4.16667 9.31771H10.8333C11.2942 9.31771 11.6667 9.69021 11.6667 10.151C11.6667 10.171 11.6658 10.191 11.6642 10.211C12.645 9.64271 13.7842 9.31771 15 9.31771V5.15104C15 2.85354 13.1308 0.984375 10.8333 0.984375H4.16667C1.86917 0.984375 0 2.85354 0 5.15104V16.8177C0 19.1152 1.86917 20.9844 4.16667 20.9844H10.5908C9.20667 19.7627 8.33333 17.976 8.33333 15.9844ZM4.16667 5.15104H10.8333C11.2942 5.15104 11.6667 5.52354 11.6667 5.98438C11.6667 6.44521 11.2942 6.81771 10.8333 6.81771H4.16667C3.70583 6.81771 3.33333 6.44521 3.33333 5.98438C3.33333 5.52354 3.70583 5.15104 4.16667 5.15104ZM5.83333 15.151H4.16667C3.70583 15.151 3.33333 14.7785 3.33333 14.3177C3.33333 13.8569 3.70583 13.4844 4.16667 13.4844H5.83333C6.29417 13.4844 6.66667 13.8569 6.66667 14.3177C6.66667 14.7785 6.29417 15.151 5.83333 15.151Z"
        fill="#93ACC3"
      />
    </svg>
  );

  const TippingManagement = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M14.6899 13.9805V19.3223C14.6899 19.6912 14.3911 19.99 14.0222 19.99C13.6533 19.99 13.3545 19.6912 13.3545 19.3223V13.9805C13.3545 13.6116 13.6533 13.3127 14.0222 13.3127C14.3911 13.3127 14.6899 13.6116 14.6899 13.9805ZM11.9356 13.3127H8.17962C7.8107 13.3127 7.51189 13.6116 7.51189 13.9805C7.51189 14.3494 7.8107 14.6482 8.17962 14.6482H9.34813V19.3323C9.34813 19.7004 9.64694 20 10.0159 20C10.3848 20 10.6836 19.7012 10.6836 19.3323V14.6482H11.9356C12.3045 14.6482 12.6033 14.3494 12.6033 13.9805C12.6033 13.6116 12.3045 13.3127 11.9356 13.3127ZM20.0317 15.3994C20.0317 16.552 19.0977 17.486 17.9451 17.486H17.1939V19.3223C17.1939 19.6912 16.8951 19.99 16.5262 19.99C16.1572 19.99 15.8584 19.6912 15.8584 19.3223V13.9805C15.8584 13.6116 16.1572 13.3127 16.5262 13.3127H17.9451C19.0977 13.3127 20.0317 14.2467 20.0317 15.3994ZM18.6963 15.3994C18.6963 14.9846 18.3599 14.6482 17.9451 14.6482H17.1939V16.1514H17.9451C18.3599 16.1514 18.6963 15.8142 18.6963 15.3994ZM10.8213 9.97413H1.69518C0.672732 10.7353 0 11.9431 0 13.3127V16.6514C0 18.4951 1.49487 19.99 3.33862 19.99H7.67882V16.2599C6.49862 16.0012 5.64894 14.851 5.88098 13.5523C6.08213 12.4238 7.1338 11.6426 8.27978 11.6426H11.2436C11.6818 11.6426 11.9205 11.1376 11.6535 10.7913C11.4156 10.4824 11.1351 10.2078 10.8221 9.97329L10.8213 9.97413ZM10.8497 7.05283C10.8497 6.36174 10.2888 5.80085 9.5977 5.80085H9.18037C9.18037 4.41783 8.05943 3.29689 6.6764 3.29689C5.29338 3.29689 4.17244 4.41783 4.17244 5.80085H2.92046C2.22936 5.80085 1.66848 6.36174 1.66848 7.05283V8.30482H10.8497V7.05283ZM12.5407 0C11.1694 0 10.0576 1.11176 10.0576 2.4831C10.0576 3.85444 11.1694 4.9662 12.5407 4.9662C13.912 4.9662 15.0238 3.85444 15.0238 2.4831C15.0238 1.11176 13.912 0 12.5407 0Z"
        fill="white"
      />
    </svg>
  );

  const Tipping = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M14.6899 14.0312V19.373C14.6899 19.742 14.3911 20.0408 14.0222 20.0408C13.6533 20.0408 13.3545 19.742 13.3545 19.373V14.0312C13.3545 13.6623 13.6533 13.3635 14.0222 13.3635C14.3911 13.3635 14.6899 13.6623 14.6899 14.0312ZM11.9356 13.3635H8.17962C7.8107 13.3635 7.51189 13.6623 7.51189 14.0312C7.51189 14.4002 7.8107 14.699 8.17962 14.699H9.34813V19.3831C9.34813 19.7511 9.64694 20.0508 10.0159 20.0508C10.3848 20.0508 10.6836 19.752 10.6836 19.3831V14.699H11.9356C12.3045 14.699 12.6033 14.4002 12.6033 14.0312C12.6033 13.6623 12.3045 13.3635 11.9356 13.3635ZM20.0317 15.4502C20.0317 16.6028 19.0977 17.5368 17.9451 17.5368H17.1939V19.373C17.1939 19.742 16.8951 20.0408 16.5262 20.0408C16.1572 20.0408 15.8584 19.742 15.8584 19.373V14.0312C15.8584 13.6623 16.1572 13.3635 16.5262 13.3635H17.9451C19.0977 13.3635 20.0317 14.2975 20.0317 15.4502ZM18.6963 15.4502C18.6963 15.0353 18.3599 14.699 17.9451 14.699H17.1939V16.2022H17.9451C18.3599 16.2022 18.6963 15.865 18.6963 15.4502ZM10.8213 10.0249H1.69518C0.672732 10.7861 0 11.9939 0 13.3635V16.7021C0 18.5459 1.49487 20.0408 3.33862 20.0408H7.67882V16.3107C6.49862 16.052 5.64894 14.9018 5.88098 13.6031C6.08213 12.4746 7.1338 11.6934 8.27978 11.6934H11.2436C11.6818 11.6934 11.9205 11.1884 11.6535 10.842C11.4156 10.5332 11.1351 10.2586 10.8221 10.0241L10.8213 10.0249ZM10.8497 7.10361C10.8497 6.41252 10.2888 5.85163 9.5977 5.85163H9.18037C9.18037 4.46861 8.05943 3.34767 6.6764 3.34767C5.29338 3.34767 4.17244 4.46861 4.17244 5.85163H2.92046C2.22936 5.85163 1.66848 6.41252 1.66848 7.10361V8.3556H10.8497V7.10361ZM12.5407 0.0507812C11.1694 0.0507812 10.0576 1.16254 10.0576 2.53388C10.0576 3.90522 11.1694 5.01698 12.5407 5.01698C13.912 5.01698 15.0238 3.90522 15.0238 2.53388C15.0238 1.16254 13.912 0.0507812 12.5407 0.0507812Z"
        fill="#93ACC3"
      />
    </svg>
  );

  const TeamManagement = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M4.79424 9.63586C6.4849 9.63586 7.86019 8.26056 7.86019 6.5699C7.86019 4.87925 6.4849 3.50395 4.79424 3.50395C3.10359 3.50395 1.72829 4.87925 1.72829 6.5699C1.72829 8.26056 3.10359 9.63586 4.79424 9.63586ZM13.5541 6.13191C15.2448 6.13191 16.6201 4.75661 16.6201 3.06595C16.6201 1.3753 15.2448 0 13.5541 0C11.8635 0 10.4882 1.3753 10.4882 3.06595C10.4882 4.75661 11.8635 6.13191 13.5541 6.13191ZM15.7441 10.5118C12.8411 10.5118 10.4882 12.8647 10.4882 15.7678C10.4882 18.6708 12.8411 21.0237 15.7441 21.0237C18.6471 21.0237 21 18.6708 21 15.7678C21 12.8647 18.6471 10.5118 15.7441 10.5118ZM18.9703 15.5155L16.6025 17.8053C16.2066 18.196 15.681 18.3931 15.1545 18.3931C14.6281 18.3931 14.1007 18.196 13.6978 17.8027L12.5117 16.6359C12.1657 16.2969 12.1613 15.7424 12.4994 15.3972C12.8376 15.0512 13.3921 15.046 13.7381 15.385L14.9242 16.5509C15.0503 16.6753 15.257 16.6727 15.3814 16.5491L17.758 14.2506C18.1057 13.9168 18.6611 13.9264 18.9966 14.276C19.3312 14.6246 19.3199 15.1791 18.9712 15.5146L18.9703 15.5155ZM9.17593 13.3203C8.89211 14.0824 8.73618 14.9067 8.73618 15.7678C8.7283 15.7678 8.72041 15.7678 8.71253 15.7678H0.87595C0.62717 15.7678 0.388901 15.6618 0.22334 15.4761C0.0577783 15.2904 -0.0219365 15.0433 0.00521909 14.7954C0.275899 12.354 2.33447 10.5118 4.79424 10.5118C6.72667 10.5118 8.41119 11.6489 9.17593 13.3203ZM10.1605 11.5332C9.82241 11.0558 9.42296 10.6257 8.97357 10.2561C9.62794 8.34728 11.4333 7.0079 13.5305 7.0079C15.1107 7.0079 16.5246 7.76825 17.4067 8.95872C16.8741 8.82907 16.317 8.75987 15.745 8.75987C13.4656 8.75987 11.4404 9.84785 10.1605 11.5332Z"
        fill="white"
      />
    </svg>
  );

  // const hasRestaurantPermission = userpermissions.find(
  //   (permission) =>
  //     permission.name === "Restaurant" &&
  //     (permission.View ||
  //       permission.Create ||
  //       permission.Edit ||
  //       permission.Delete)
  // );

  // console.log(hasRestaurantPermission, "hasRestaurantPermission");
  const TeamManagementPermissin =
    hasRoleManagement || hasAddDesignations || hasUserManagement;
  const RestaurantPermissin =
    hasRestaurantPermission || hasHardwarePermission || hasHardwareAllotment;

  const MenuManagementper =
    hasMenuCategories ||
    hasMenuItems ||
    hasMenuBundlecreation ||
    hasMenuMelacreation;

  const [toggled, setToggled] = useState(false);
  const { collapsed, toggleCollapsed, isSubMenuOpen, hideCollapsed } =
    useSidebar();
  // const handleCollapsedChange = () => {
  //   setCollapsed(true);
  // };

  // const handleCollapseMobile = () => {
  //   setCollapsed(false);
  // };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const location = useLocation();
  const handleCollapsedOpan = () => {
    console.log("dddd");
    // setCollapsed(false);
  };
  const path = location.pathname;

  // useEffect(() => {
  //   // Check if the current path matches any of the paths to collapse
  //   if (path) {
  //     setCollapsed(!collapsed);
  //   } else {
  //     setCollapsed(false); // Reset to non-collapsed state if not in the list
  //   }
  // }, [location.pathname]);

  // console.log("handleCollapseMobile", collapsed);
  // const SidebarContext = createContext();
  // console.log(isSubMenuOpen, "isSubMenuOpen");
  return (
    <div className={`sidebar_div ${isOpen ? "open" : ""}`}>
      <Sidebar
        backgroundColor="#174B7C"
        width="320px"
        className={`app ${toggled ? "toggled" : ""}`}
        // style={{ height: "100%", position: "absolute" }}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        // handleCollapsedChange={handleCollapsedChange}
      >
        <main>
          {collapsed ? (
            <div className="Mobile_menu">
              <Link className="sidebar-brand" to="/">
                {" "}
                <img src={mobilelogo} alt="" className="img-fluid" />
              </Link>
              <FontAwesomeIcon
                className="icon_div"
                onClick={toggleCollapsed}
                icon={faBars}
              />
            </div>
          ) : (
            <>
              <div className="sidebar-brand-logo">
                <Link className="sidebar-brand" to="/">
                  {" "}
                  <img src={logo} alt="" className="img-fluid" />{" "}
                </Link>
                <FontAwesomeIcon
                  className="icon_div"
                  onClick={toggleCollapsed}
                  icon={faBars}
                />
              </div>
              <div className="user-name">
                <p>{userName ? userName : ""}</p>
              </div>
            </>
          )}

          <div className="menu_scolling">
            <Menu>
              {/* <Link
                className={pathname === "/dashboard" ? "active" : ""}
                to="/dashboard"
              > */}{" "}
              {/* <Tooltip title="Dashboard" arrow> */}
              <MenuItem
                className={pathname === "/dashboard" ? "active" : ""}
                icon={<Dashboard />}
                component={
                  <Link
                    className={pathname === "/dashboard" ? "active" : ""}
                    to="/dashboard"
                  />
                }
                data-toggle="tooltip"
                title="Dashboard"
              >
                Dashboard
              </MenuItem>
              {/* </Tooltip> */}
              {/* </Link> */}
              <Link
              // className={
              //   pathname === "/restaurants" ||
              //   pathname === "/create-restaurant" ||
              //   pathname === "/restaurants/edit-restaurant" ||
              //   pathname === "/restaurants/productinformation" ||
              //   pathname === "/restaurants/restaurant-hardware"
              //     ? "newadd"
              //     : ""
              // }
              >
                {RestaurantPermissin && (
                  <>
                    {" "}
                    {/* <Tooltip title="Restaurants Menu" arrow> */}{" "}
                    <SubMenu
                      label="Restaurants"
                      className="sub_item"
                      icon={<RestaurantsIcon />}
                      data-tip="Restaurants"
                      data-toggle="tooltip"
                      title="Restaurants Menu"
                      // defaultOpen={isSubMenuOpen}
                    >
                      {hasRestaurantPermission ? (
                        <MenuItem
                          data-tip="Restaurants"
                          data-toggle="tooltip"
                          title="Restaurants List"
                          // onClick={hideCollapsed}
                          component={
                            <Link
                              // className={
                              //   pathname === "/restaurants" ? "active" : ""
                              // }
                              to="/restaurants"
                            />
                          }
                        >
                          Restaurants List
                        </MenuItem>
                      ) : null}

                      {roletype === "1" && roletype === "2" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          {/* <Link
                            className={
                              pathname === "/restaurants/productinformation"
                                ? "active"
                                : ""
                            }
                            to="/restaurants/productinformation"
                          > */}
                          {isperfect === true && hasHardwarePermission ? (
                            <MenuItem
                              icon={<RestaurantsIcon />}
                              data-toggle="tooltip"
                              title="Hardware Information"
                              // onClick={hideCollapsed}
                              component={
                                <Link
                                  // className={
                                  //   pathname ===
                                  //   "/restaurants/productinformation"
                                  //     ? "active"
                                  //     : ""
                                  // }
                                  to="/restaurants/productinformation"
                                />
                              }
                            >
                              {" "}
                              Hardware Information
                            </MenuItem>
                          ) : null}
                          {roletype === "0" ? (
                            <MenuItem
                              data-toggle="tooltip"
                              title="Hardware Information"
                              // onClick={hideCollapsed}
                              component={
                                <Link
                                  // className={
                                  //   pathname ===
                                  //   "/restaurants/productinformation"
                                  //     ? "active"
                                  //     : ""
                                  // }
                                  to="/restaurants/productinformation"
                                />
                              }
                            >
                              {" "}
                              Hardware Information
                            </MenuItem>
                          ) : null}
                          {/* </Link> */}
                        </>
                      )}

                      {/* <Link
                        className={
                          // pathname === "/restaurants" ||
                          // pathname === "/create-restaurant" ||
                          pathname === "/restaurants/restaurant-hardware"
                            ? "active"
                            : ""
                        }
                        to="/restaurants/restaurant-hardware"
                      > */}
                      {hasHardwareAllotment ? (
                        <MenuItem
                          data-toggle="tooltip"
                          title="Hardware Allotment"
                          component={
                            <Link
                              // className={
                              //   pathname === "/restaurants" ||
                              //   pathname === "/create-restaurant" ||
                              //   pathname === "/restaurants/restaurant-hardware"
                              //     ? "active"
                              //     : ""
                              // }
                              to="/restaurants/restaurant-hardware"
                            />
                          }
                        >
                          {" "}
                          Hardware Allotment
                        </MenuItem>
                      ) : null}
                      {/* </Link> */}
                    </SubMenu>
                    {/* </Tooltip> */}
                  </>
                )}
              </Link>
              <Link
              // className={
              //   pathname == "/team-management" ||
              //   pathname === "/team-management/designations" ||
              //   pathname === "/team-management/team-members"
              //     ? "newadd"
              //     : ""
              // }
              >
                {TeamManagementPermissin && (
                  <>
                    {" "}
                    {/* <Tooltip title="Team Management" arrow> */}{" "}
                    <SubMenu
                      isOpen={isSubMenuOpen}
                      label="Team Management"
                      icon={<TeamManagement />}
                      className="sub_item"
                      data-toggle="tooltip"
                      title="Team Management"
                    >
                      {/* <Link
                        to="/team-management"
                        className={
                          // pathname === "/team-management/designations" ||
                          pathname === "/team-management" ? "active" : ""
                        }
                      > */}
                      {hasRoleManagement ? (
                        <MenuItem
                          data-toggle="tooltip"
                          title="Role Management"
                          component={
                            <Link
                              to="/team-management"
                              className={
                                // pathname === "/team-management/designations" ||
                                pathname === "/team-management" ? "active" : ""
                              }
                            />
                          }
                        >
                          {" "}
                          Role Management
                        </MenuItem>
                      ) : null}
                      {/* </Link> */}
                      {/* <Link
                        to="/team-management/designations"
                        className={
                          pathname === "/team-management/designations"
                            ? // pathname === "/team-management/team-members"
                              "active"
                            : ""
                        }
                      > */}
                      {hasAddDesignations ? (
                        <MenuItem
                          data-toggle="tooltip"
                          title="Add Designations"
                          component={
                            <Link
                              to="/team-management/designations"
                              className={
                                pathname === "/team-management/designations"
                                  ? // pathname === "/team-management/team-members"
                                    "active"
                                  : ""
                              }
                            />
                          }
                        >
                          Add Designations
                        </MenuItem>
                      ) : null}
                      {/* </Link> */}
                      {/* 
                      <Link
                        to="/team-management/team-members"
                        className={
                          pathname === "/team-management/team-members"
                            ? // pathname === "/team-management/designations"
                              "active"
                            : ""
                        }
                      > */}{" "}
                      {hasUserManagement ? (
                        <MenuItem
                          data-toggle="tooltip"
                          title=" User Management"
                          component={
                            <Link
                              to="/team-management/team-members"
                              className={
                                pathname === "/team-management/team-members"
                                  ? // pathname === "/team-management/designations"
                                    "active"
                                  : ""
                              }
                            />
                          }
                        >
                          {" "}
                          User Management
                        </MenuItem>
                      ) : null}
                      {/* </Link> */}
                      {/* <MenuItem> User Role Creation</MenuItem> */}
                    </SubMenu>
                    {/* </Tooltip>{" "} */}
                  </>
                )}
              </Link>
              {/* <SubMenu
              isOpen={isSubMenuOpen}
              label="Order Management"
              icon={<Management />}
            >
              <Link to="/ordermanagement/Orderplacement">
                {hasMenuCategories ? (
                  <MenuItem>Order Placement</MenuItem>
                ) : null}
              </Link>
            </SubMenu> */}
              {OrderPlacement && (
                <>
                  {" "}
                  {/* <Tooltip title="Order Management" arrow> */}
                  <SubMenu
                    isOpen={isSubMenuOpen}
                    label="Order Management"
                    icon={<Management />}
                    data-toggle="tooltip"
                    title="Order Management"
                    className="sub_item"
                    // onClick={handleCollapsedOpan}
                  >
                    {roletype === "1" ? (
                      <>
                        {/* <Link to="/ordermanagement/orderadd"> */}
                        {/* {hasMenuCategories ? ( */}
                        <MenuItem
                          data-toggle="tooltip"
                          title="Order Placement"
                          component={
                            <Link
                              // className={
                              //   pathname === "/restaurants" ? "active" : ""
                              // }
                              to="/ordermanagement/orderadd"
                            />
                          }
                        >
                          Order Placement
                        </MenuItem>
                        {/* ) : null} */}
                        {/* </Link> */}
                      </>
                    ) : (
                      ""
                    )}

                    {/* <Link to="/ordermanagement/Orderplacement"> */}
                    {hasMenuCategories ? (
                      <MenuItem
                        data-toggle="tooltip"
                        title="Order Tracking"
                        component={
                          <Link
                            // className={
                            //   pathname === "/restaurants" ? "active" : ""
                            // }
                            to="/ordermanagement/Orderplacement"
                          />
                        }
                      >
                        Order Tracking
                      </MenuItem>
                    ) : null}
                    {/* </Link> */}
                  </SubMenu>
                  {/* </Tooltip>{" "} */}
                </>
              )}
              {MenuManagementper && (
                <>
                  {" "}
                  {/* <Tooltip title="Menu Management" arrow> */}{" "}
                  <SubMenu
                    label="Menu Management "
                    icon={<MenuManagement />}
                    onClick={handleCollapsedOpan}
                    className="sub_item"
                    data-toggle="tooltip"
                    title="Menu Management"
                  >
                    {/* <Link to="/menu-management/category"> */}
                    {hasMenuCategories ? (
                      <MenuItem
                        data-toggle="tooltip"
                        title="Categories"
                        component={
                          <Link
                            // className={
                            //   pathname === "/restaurants" ? "active" : ""
                            // }
                            to="/menu-management/category"
                          />
                        }
                      >
                        Categories
                      </MenuItem>
                    ) : null}
                    {/* </Link> */}
                    {/* <Link to="/menu-management/items"> */}
                    {hasMenuItems ? (
                      <MenuItem
                        data-toggle="tooltip"
                        title="Items"
                        component={
                          <Link
                            className={
                              pathname === "/restaurants" ? "active" : ""
                            }
                            to="/menu-management/items"
                          />
                        }
                      >
                        Items
                      </MenuItem>
                    ) : null}
                    {/* </Link> */}
                    {/* <Link to="/menu-management/bundles"> */}
                    {hasMenuBundlecreation ? (
                      <MenuItem
                        data-toggle="tooltip"
                        title="Bundle creation"
                        component={
                          <Link
                            // className={
                            //   pathname === "/restaurants" ? "active" : ""
                            // }
                            to="/menu-management/bundles"
                          />
                        }
                      >
                        Bundle creation
                      </MenuItem>
                    ) : null}
                    {/* </Link> */}
                    {/* <Link to="/menu-management/meals"> */}
                    {hasMenuMelacreation ? (
                      <MenuItem
                        data-toggle="tooltip"
                        title="Meal creation"
                        component={
                          <Link
                            // className={
                            //   pathname === "/restaurants" ? "active" : ""
                            // }
                            to="/menu-management/meals"
                          />
                        }
                      >
                        Meal creation
                      </MenuItem>
                    ) : null}
                    {/* </Link> */}
                  </SubMenu>
                  {/* </Tooltip>{" "} */}
                </>
              )}
              {/* <SubMenu label="Tipping Management" icon={<Tipping />}> */}
              {/* <MenuItem> Pie charts</MenuItem> */}
              {/* <MenuItem> Line charts</MenuItem> */}
              {/* <MenuItem> Bar charts</MenuItem> */}
              {/* </SubMenu>  */}
              {/* <SubMenu
            label="Restaurants"
            icon={<FontAwesomeIcon icon={faChartPie} />}
          >
            <MenuItem> Pie charts</MenuItem>
            <MenuItem> Line charts</MenuItem>
            <MenuItem> Bar charts</MenuItem>
          </SubMenu> */}
              {/* <SubMenu
                label="Tipping Management"
                icon={<TippingManagement />}
                className="sub_item"
                data-toggle="tooltip"
                title="Tipping Management"
              >
                <MenuItem
                  data-toggle="tooltip"
                  title="Tipping Setting"
                  component={<Link to="/TippingManagement/tippingsetting" />}
                >
                  Tipping Setting
                </MenuItem>

                <MenuItem
                  data-toggle="tooltip"
                  title="Tipping Details"
                  component={<Link to="/TippingManagement/tippingDetails" />}
                >
                  Tipping Details
                </MenuItem>

                <MenuItem
                  data-toggle="tooltip"
                  title="Settlement"
                  component={<Link to="/menu-management/bundles" />}
                >
                  Settlement
                </MenuItem>
              </SubMenu> */}
            </Menu>

            {/* {roletype === 1 (_"ddd") :    } */}
            {/* <div className="sidebar-bottom">
            <img src={sidebarimg} alt="" className="img-fluid" width="100%" />
            <a onClick={logout} className="logout-btn">
              <span>LOG OUT</span>{" "}
              <img src={logout_img} alt="" className="img-fluid" />
            </a>
          </div> */}
          </div>
          {/* <button className="toggle-button" onClick={toggleallSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button> */}
        </main>
      </Sidebar>
    </div>
  );
};

export default Sidebarpage;
