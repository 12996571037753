import React, { useState, useEffect } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import menu_icon from "../../../../../img/Vector.svg";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "react-multi-select-component";
import preview_generic_image from "../../../../../img/AddModal/Group 839.png";
import { useDispatch } from "react-redux";
import {
  branchrole,
  customizationList,
  itemsList,
  mealView,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import upload_generic_image from "../../../../../img/AddModal/Group 82553.png";
import { useLocation, useNavigate } from "react-router-dom";

const ViewMeal = () => {
  const location = useLocation();
  const mealID = location.pathname.split("/")[4];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);
  const [items, setItems] = useState([]);
  const [customizationLists, setCustomizationList] = useState([]);

  const [itemImagePreview, setItemImagePreview] = useState("");
  const [itemImageUpload, setItemImageUpload] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setItemImageUpload(file);
    setItemImagePreview(URL.createObjectURL(file));
  };

  const [initialValue, setInitialValue] = useState({
    restaurant_name:
      localStorage.getItem("roletype") == "1" ? localStorage.getItem("id") : "",

    branch_name: [],
    mealName: "",
    mealDescription: "",
    category: [
      {
        categoryName: "",
        isMultiSelector: false,
        maximumNumber: "",
        minimumNumber: "",
        items: [
          {
            customizeItem: false,
            item: "",
            actual_price: "",
            discount_price: "",
            image: "",
          },
        ],
      },
    ],
    meal_actual_prise: "",
    meal_original_price: "",

    mealCustomization: [
      {
        title: "",
        items: [
          {
            item: "",
            actual_price: "",
            discount_price: "",
            image: "",
            isSelect: false,
            imagePreview: "",
          },
        ],
      },
    ],
  });

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };

  const getItems = (id) => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
      restaurant: "",
      branch: "",
      status: "",
      categoryid: "",
    };

    try {
      dispatch(itemsList(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setItems(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };
  useEffect(() => {
    getRestaurantListData();
    getItems();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allBrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const handleChangeItemsValue = (categoryIndex, itemIndex, id) => {
    const selectedItem = items.find((data) => data?._id === id);
    console.log(selectedItem, "selectedItem");
    setInitialValue((prevState) => {
      const updatedCategory = [...prevState.category];
      updatedCategory[categoryIndex] = {
        ...updatedCategory[categoryIndex],
        items: updatedCategory[categoryIndex].items.map((item, index) => {
          if (index === itemIndex) {
            return {
              ...item,
              actual_price: selectedItem.originalPrice,
            };
          }
          return item;
        }),
      };
      return {
        ...prevState,
        category: updatedCategory,
      };
    });
  };
  const [loadingCustomization, setLoadingCustomization] = useState(true);
  const getCustomisationList = (itemObject, newCategoryIndex, newItemIndex) => {
    const convertIntoParse = JSON.parse(itemObject);

    // setCategoeyIndex(newCategoryIndex);
    // setItemIndex(newItemIndex);
    setLoadingCustomization(false); // Set loading to true when the request starts

    const requestOption = {
      page: "",
      search: "",
      limit: "",
      status: "",
      itemId: convertIntoParse.value,
    };

    dispatch(customizationList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          const fetchedCustomizations = res?.payload?.payload?.map((res) => ({
            title: res?.customization?.title,
            items: res?.customization?.items?.map((item) => ({
              item: item?.name,
              actual_price: item?.price,
              discount_price: item?.discount_price || "", // Default to empty if not provided
              image: item?.image,
              isSelect: false, // Default to false
            })),
            isNew: false,
          }));

          setInitialValue((prevState) => {
            const updatedCategory = [...prevState.category];
            const currentCustomizations =
              updatedCategory[newCategoryIndex].items[newItemIndex]
                .mealCustomization || [];

            // Combine fetched customizations with current customizations
            const mergedCustomizations = fetchedCustomizations.map(
              (fetchedCustomization) => {
                // Try to find a matching customization by title
                const currentCustomization = currentCustomizations.find(
                  (c) => c.title === fetchedCustomization.title
                );

                if (currentCustomization) {
                  fetchedCustomization.items = fetchedCustomization.items.map(
                    (fetchedItem) => {
                      const currentItem = currentCustomization.items.find(
                        (i) => i.item === fetchedItem.item
                      );
                      return {
                        ...fetchedItem,
                        isSelect: currentItem
                          ? currentItem.isSelect
                          : fetchedItem.isSelect, // Mark as selected if it exists in current items
                        discount_price: currentItem
                          ? currentItem.discount_price
                          : fetchedItem.discount_price,
                      };
                    }
                  );
                } else {
                  // If no matching customization is found, just use the fetched one
                  return fetchedCustomization;
                }

                return fetchedCustomization;
              }
            );

            // Include current customizations that were not matched with fetched customizations
            currentCustomizations.forEach((currentCustomization) => {
              if (
                !mergedCustomizations.some(
                  (merged) => merged.title === currentCustomization.title
                )
              ) {
                mergedCustomizations.push(currentCustomization);
              }
            });

            updatedCategory[newCategoryIndex].items[
              newItemIndex
            ].mealCustomization = mergedCustomizations;

            return {
              ...prevState,
              category: updatedCategory,
            };
          });
        }
        setLoadingCustomization(true); // Set loading to false when the request completes
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };

  // Ensure your state is initialized properly

  const handleSelectCustomization = (customizationIndex, itemIndex, e) => {
    // Remove the customization from the array
    const { checked } = e.target;
    const newState = { ...initialValue }; // Create a copy of the state
    newState.mealCustomization[customizationIndex].items[itemIndex].isSelect =
      checked; // Update only the discount_price field
    setInitialValue(newState); // Set the updated state
  };

  const handleChangeItemActualPrice = (categoryIndex, itemIndex, e) => {
    // Remove the customization from the array
    const { value } = e.target;
    const newState = { ...initialValue }; // Create a copy of the state
    newState.category[categoryIndex].items[itemIndex].actual_price = value; // Update only the discount_price field
    setInitialValue(newState); // Set the updated state
  };
  const [pageLoading, setPageLoading] = useState(false);
  const viewMealDetails = () => {
    setPageLoading(true);
    try {
      dispatch(mealView(mealID)).then((res) => {
        console.log(res, "meal view");
        setPageLoading(false);

        if (res?.payload?.status === true) {
          setItemImagePreview(res?.payload?.payload[0]?.image);

          setInitialValue({
            restaurant_name: res?.payload?.payload[0]?.restaurantId,

            branch_name: res?.payload?.payload[0]?.branchInfo?.map(
              (branch) => ({
                value: branch?._id,
                label: branch?.name,
              })
            ),
            mealName: res?.payload?.payload[0]?.mealName,
            mealDescription: res?.payload?.payload[0]?.description,
            category: res?.payload?.payload[0]?.mealCategory?.map(
              (category) => ({
                categoryName: category?.categoryName,
                isMultiSelector: category?.isMultiselect,
                maximumNumber: category?.maximumNumber,
                minimumNumber: category?.minimumNumber,
                items: category?.items.map((item) => ({
                  customizeItem: false,
                  item: item?.isCustomizeItem
                    ? item?.itemName
                    : JSON.stringify({
                        value: item?.itemId,
                        label: item?.itemName,
                      }),
                  actual_price: item?.actualPrice,
                  discount_price: item?.discountPrice,
                  image: item?.image,
                  customizeItem: item?.isCustomizeItem,
                  imagePreview: item?.image,
                })),
              })
            ),

            meal_actual_prise: res?.payload?.payload[0]?.actualMealPrice,
            meal_original_price: res?.payload?.payload[0]?.discountMealPrice,

            mealCustomization: [
              {
                title: "",
                items: [
                  {
                    item: "",
                    actual_price: "",
                    discount_price: "",
                    image: "",
                    isSelect: false,
                  },
                ],
              },
            ],
          });
        }
      });
    } catch (Error) {
      console.log(Error);
    }
  };

  useEffect(() => {
    viewMealDetails();
  }, []);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Menu Management" img_icon={menu_icon} />
          <div className="container-fluid">
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  {!pageLoading && (
                    <Formik initialValues={initialValue}>
                      {(formik) => (
                        <Form className="form-container">
                          <div className="row">
                            <div className="col-md-7">
                              <section className="add_category ">
                                <div className="add_category_inner">
                                  <h6 className="heading_name">View Meal</h6>
                                  <hr />
                                  <div className="form_container">
                                    <div className="field-container">
                                      <div className="row">
                                        <div className="col-lg-6 d-flex flex-column">
                                          <label htmlFor="Restaurant">
                                            Restaurant{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            as="select"
                                            id="Restaurant"
                                            name="restaurant_name"
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                "restaurant_name",
                                                e.target.value
                                              );
                                              getBraches(e.target.value);
                                            }}
                                            disabled
                                          >
                                            <option value="">
                                              Select Restaurant Name
                                            </option>
                                            {restaurant.map((res, i) => (
                                              <option value={res?._id} key={i}>
                                                {res?.name}
                                              </option>
                                            ))}
                                          </Field>
                                          <ErrorMessage
                                            component="p"
                                            name="restaurant_name"
                                            className="text-danger"
                                          />
                                        </div>
                                        <div className="col-lg-6 d-flex flex-column">
                                          <label htmlFor="branch_name">
                                            Branch{" "}
                                          </label>
                                          <MultiSelect
                                            options={allBrachList}
                                            name="branch_name"
                                            labelledBy="Select"
                                            value={formik.values.branch_name}
                                            onChange={(e) =>
                                              formik.setFieldValue(
                                                "branch_name",
                                                e
                                              )
                                            }
                                            disabled
                                          />
                                          <ErrorMessage
                                            component="p"
                                            name="branch_name"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <label htmlFor="mealName">
                                          Meal Name{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Enter Meal Name"
                                          id="mealName"
                                          name="mealName"
                                          disabled
                                        />
                                        <ErrorMessage
                                          component="p"
                                          name="mealName"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <label htmlFor="mealDescription">
                                          Description{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          as="textarea"
                                          placeholder="Write Meal Description here"
                                          id="mealDescription"
                                          name="mealDescription"
                                          rows={4}
                                          cols={4}
                                          disabled
                                        />
                                        <ErrorMessage
                                          component="p"
                                          name="mealDescription"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className=" ">
                                        <label htmlFor="item_image">
                                          Upload Image
                                        </label>
                                        <div className="item_image_container">
                                          {/* <div className="item_image_upload_btn">
                                          <button>Upload Image</button>
                                          <input
                                            type="file"
                                            name=""
                                            id=""
                                            onChange={(e) =>
                                              handleImageChange(e)
                                            }
                                          />
                                        </div> */}
                                          <div className="uploaded_img_container">
                                            <img
                                              src={
                                                itemImagePreview
                                                  ? itemImagePreview
                                                  : preview_generic_image
                                              }
                                              alt=""
                                              className="item_uploaded_img"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <FieldArray name="category">
                                        {({ push, remove }) => (
                                          <>
                                            {formik.values.category.map(
                                              (category, categoryIndex) => (
                                                <>
                                                  <hr />
                                                  <div
                                                    key={categoryIndex}
                                                    className="category-section"
                                                  >
                                                    <div className="d-flex flex-column">
                                                      <label
                                                        htmlFor={`category.${categoryIndex}.categoryName`}
                                                      >
                                                        Category{" "}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                      <Field
                                                        type="text"
                                                        placeholder="Choose Category"
                                                        id={`category.${categoryIndex}.categoryName`}
                                                        name={`category.${categoryIndex}.categoryName`}
                                                        disabled
                                                      />
                                                      <ErrorMessage
                                                        component="p"
                                                        name={`category.${categoryIndex}.categoryName`}
                                                        className="text-danger"
                                                      />
                                                    </div>

                                                    <FieldArray
                                                      name={`category.${categoryIndex}.items`}
                                                    >
                                                      {({
                                                        push: itemPush,
                                                        remove: itemRemove,
                                                      }) => (
                                                        <>
                                                          <div className="d-flex align-items-center justify-content-between my-3">
                                                            <p className="m-0 font-weight-bold">
                                                              Items{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </p>
                                                            {/* <p
                                                            className="add_more_field_btn"
                                                            onClick={() =>
                                                              itemPush({
                                                                item: "",
                                                                actual_price:
                                                                  "",
                                                                discount_price:
                                                                  "",
                                                                image: "",
                                                              })
                                                            }
                                                          >
                                                            Add More Item
                                                          </p> */}
                                                          </div>
                                                          {category.items.map(
                                                            (
                                                              item,
                                                              itemIndex
                                                            ) => (
                                                              <div
                                                                key={itemIndex}
                                                                className="item-section"
                                                              >
                                                                <div className="row">
                                                                  <div className="col-lg-8 d-flex flex-column">
                                                                    <div
                                                                      className="d-flex"
                                                                      style={{
                                                                        gap: "10px",
                                                                      }}
                                                                    >
                                                                      <Field
                                                                        type="checkbox"
                                                                        className="input_check_box"
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.customizeItem`}
                                                                        disabled
                                                                      />
                                                                      <label
                                                                        htmlFor={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      >
                                                                        {" "}
                                                                        Customize
                                                                        Item or
                                                                        new Item{" "}
                                                                        <span className="text-danger">
                                                                          *
                                                                        </span>
                                                                      </label>
                                                                    </div>
                                                                    <Field
                                                                      disabled
                                                                      as="select"
                                                                      placeholder="Enter Item Name"
                                                                      id={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      name={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        formik.setFieldValue(
                                                                          `category.${categoryIndex}.items.${itemIndex}.item`,
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                        handleChangeItemsValue(
                                                                          categoryIndex,
                                                                          itemIndex,
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                      }}
                                                                    >
                                                                      <option value="">
                                                                        Select
                                                                        Item
                                                                      </option>
                                                                      {items?.map(
                                                                        (
                                                                          res,
                                                                          i
                                                                        ) => (
                                                                          <option
                                                                            value={JSON.stringify(
                                                                              {
                                                                                value:
                                                                                  res?._id,
                                                                                label:
                                                                                  res?.name,
                                                                              }
                                                                            )}
                                                                            key={
                                                                              i
                                                                            }
                                                                          >
                                                                            {
                                                                              res?.name
                                                                            }
                                                                          </option>
                                                                        )
                                                                      )}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                      component="p"
                                                                      name={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      className="text-danger"
                                                                    />
                                                                  </div>
                                                                  <div className="col-md-4">
                                                                    {/* <div
                                                                    className="choose_customize"
                                                                    disabled={
                                                                      formik
                                                                        .values
                                                                        ?.category[
                                                                        categoryIndex
                                                                      ]?.items[
                                                                        itemIndex
                                                                      ]
                                                                        .customizeItem ==
                                                                      true
                                                                    }
                                                                    onClick={() =>
                                                                      getCustomisationList(
                                                                        formik
                                                                          .values
                                                                          .category[
                                                                          categoryIndex
                                                                        ].items[
                                                                          itemIndex
                                                                        ].item
                                                                      )
                                                                    }
                                                                  >
                                                                    Choose
                                                                    Customize
                                                                  </div> */}
                                                                  </div>
                                                                </div>
                                                                {formik.values
                                                                  ?.category[
                                                                  categoryIndex
                                                                ]?.items[
                                                                  itemIndex
                                                                ]
                                                                  .customizeItem && (
                                                                  <div className="row my-2">
                                                                    <div className="col-lg-7 d-flex flex-column">
                                                                      <div
                                                                        className="d-flex"
                                                                        style={{
                                                                          gap: "10px",
                                                                        }}
                                                                      ></div>
                                                                      <Field
                                                                        type="text"
                                                                        placeholder="Enter Item Name"
                                                                        id={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                        disabled
                                                                      />
                                                                      <ErrorMessage
                                                                        component="p"
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                        className="text-danger"
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      className=" col-5 p-0 d-flex align-items-center "
                                                                      style={{
                                                                        gap: "10px",
                                                                      }}
                                                                    >
                                                                      <img
                                                                        src={
                                                                          formik
                                                                            .values
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ]
                                                                            .imagePreview
                                                                            ? formik
                                                                                .values
                                                                                .category[
                                                                                categoryIndex
                                                                              ]
                                                                                .items[
                                                                                itemIndex
                                                                              ]
                                                                                .imagePreview
                                                                            : preview_generic_image
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "55px",
                                                                          height:
                                                                            "45px",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                )}
                                                                <div className="row">
                                                                  <div className="col-md-5 d-flex flex-column  my-2 ">
                                                                    {" "}
                                                                    <div
                                                                      className="d-flex align-items-center "
                                                                      style={{
                                                                        gap: "20px",
                                                                      }}
                                                                    >
                                                                      <label
                                                                        htmlFor={`category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                        className="m-0"
                                                                      >
                                                                        Actual
                                                                        Price
                                                                        (£){" "}
                                                                        <span className="text-danger">
                                                                          *
                                                                        </span>
                                                                      </label>

                                                                      <Field
                                                                        type="text"
                                                                        placeholder="Actual Price"
                                                                        id={`category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                        name={`formik.values.category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                        className="meal_item_price_inputs"
                                                                        value={
                                                                          initialValue
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            ?.items[
                                                                            itemIndex
                                                                          ]
                                                                            .actual_price
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeItemActualPrice(
                                                                            categoryIndex,
                                                                            itemIndex,
                                                                            e
                                                                          )
                                                                        }
                                                                        disabled
                                                                        // value={
                                                                        //   formik
                                                                        //     .values
                                                                        //     .category[
                                                                        //     categoryIndex
                                                                        //   ].items[
                                                                        //     itemIndex
                                                                        //   ]
                                                                        //     .actual_price
                                                                        // }
                                                                        // onChange={(
                                                                        //   e
                                                                        // ) =>
                                                                        //   formik.setFieldValue(
                                                                        //     `category.${categoryIndex}.items.${itemIndex}.actual_price`,
                                                                        //     e
                                                                        //       .target
                                                                        //       .value
                                                                        //   )
                                                                        // }
                                                                      />
                                                                    </div>
                                                                    {/* <ErrorMessage
                                                                    component="p"
                                                                    name={`category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                    className="text-danger"
                                                                  /> */}
                                                                  </div>
                                                                  <div className="col-md-6 d-flex flex-column my-2 ">
                                                                    <div
                                                                      className="d-flex align-items-center "
                                                                      style={{
                                                                        gap: "20px",
                                                                      }}
                                                                    >
                                                                      <label
                                                                        htmlFor={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                      >
                                                                        Discounted
                                                                        Price
                                                                        (£){" "}
                                                                        <span className="text-danger">
                                                                          *
                                                                        </span>
                                                                      </label>

                                                                      <Field
                                                                        type="text"
                                                                        placeholder="Discounted Price"
                                                                        id={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                        className="meal_item_price_inputs"
                                                                        disabled
                                                                      />
                                                                    </div>
                                                                    <ErrorMessage
                                                                      component="p"
                                                                      name={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                      className="text-danger"
                                                                    />
                                                                  </div>
                                                                </div>
                                                                {/* <div className="col-lg-6 d-flex flex-column">
                                    <button type="button" onClick={() => itemRemove(itemIndex)}>Remove Item</button>
                                  </div> */}
                                                              </div>
                                                            )
                                                          )}
                                                        </>
                                                      )}
                                                    </FieldArray>
                                                    <div
                                                      className="d-flex justify-content-between align-items-center my-2 flex-wrap "
                                                      style={{ gap: "10px" }}
                                                    >
                                                      <div
                                                        className="d-flex  align-items-center"
                                                        style={{ gap: "10px" }}
                                                      >
                                                        <Field
                                                          type="checkbox"
                                                          id={`category.${categoryIndex}.isMultiSelector`}
                                                          name={`category.${categoryIndex}.isMultiSelector`}
                                                          disabled
                                                        />
                                                        <label
                                                          htmlFor={`category.${categoryIndex}.isMultiSelector`}
                                                          className="m-0"
                                                        >
                                                          Is Multiselect?
                                                        </label>
                                                      </div>
                                                      {formik.values.category[
                                                        categoryIndex
                                                      ].isMultiSelector && (
                                                        <>
                                                          <div
                                                            className="d-flex  align-items-center"
                                                            style={{
                                                              gap: "10px",
                                                            }}
                                                          >
                                                            <label
                                                              className="m-0"
                                                              htmlFor={`category.${categoryIndex}.minimumNumber`}
                                                            >
                                                              Enter Minimum
                                                              Number
                                                            </label>
                                                            <Field
                                                              type="number"
                                                              id={`category.${categoryIndex}.minimumNumber`}
                                                              name={`category.${categoryIndex}.minimumNumber`}
                                                              className="maximum_number_field"
                                                              disabled
                                                            />
                                                          </div>
                                                          <div
                                                            className=" d-flex align-items-center"
                                                            style={{
                                                              gap: "10px",
                                                            }}
                                                          >
                                                            <label
                                                              className="m-0"
                                                              htmlFor={`category.${categoryIndex}.maximumNumber`}
                                                            >
                                                              Enter Maximum
                                                              Number
                                                            </label>
                                                            <Field
                                                              type="number"
                                                              id={`category.${categoryIndex}.maximumNumber`}
                                                              name={`category.${categoryIndex}.maximumNumber`}
                                                              className="maximum_number_field"
                                                              disabled
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                    {/* <button type="button" onClick={() => remove(categoryIndex)}  >Remove Category</button> */}
                                                  </div>
                                                </>
                                              )
                                            )}
                                            <div className="d-flex justify-content-end my-2">
                                              {/* <p
                                              type="button"
                                              onClick={() =>
                                                push({
                                                  categoryName: "",
                                                  isMultiSelector: false,
                                                  maximumNumber: "",
                                                  minimumNumber: "",
                                                  items: [
                                                    {
                                                      item: "",
                                                      actual_price: "",
                                                      discount_price: "",
                                                      image: "",
                                                    },
                                                  ],
                                                })
                                              }
                                              className="add_more_field_btn "
                                            >
                                              Add More Category
                                            </p> */}
                                            </div>
                                          </>
                                        )}
                                      </FieldArray>
                                      <div className="row mt-2">
                                        <div className="col-md-6">
                                          <div className="d-flex flex-column">
                                            <label htmlFor="meal_actual_prise">
                                              Meal Actual Price (£)
                                            </label>
                                            <Field
                                              type="number"
                                              id="meal_actual_prise"
                                              name="meal_actual_prise"
                                              placeholder="200"
                                              disabled
                                            />
                                            <ErrorMessage
                                              component="p"
                                              name="meal_actual_prise"
                                              className="text-danger"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex flex-column">
                                            <label htmlFor="meal_original_price">
                                              Meal Discounted Price (£)
                                            </label>
                                            <Field
                                              type="number"
                                              id="meal_original_price"
                                              name="meal_original_price"
                                              placeholder="150"
                                              disabled
                                            />
                                            <ErrorMessage
                                              component="p"
                                              name="meal_original_price"
                                              className="text-danger"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mt-4">
                                        <p
                                          type="button"
                                          className="category_cancel m-0"
                                          onClick={() => navigate(-1)}
                                        >
                                          Cancel
                                        </p>
                                        {/* <button
                                        type="submit"
                                        className="category_save"
                                      >
                                        Save
                                      </button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="col-md-5">
                              <section
                                className="add_category "
                                style={{ height: "100%" }}
                              >
                                <div className="add_category_inner">
                                  <h6 className="heading_name">
                                    Choose Customization
                                  </h6>
                                  <hr />
                                  {loadingCustomization && (
                                    <div className="form_container">
                                      {customizationLists.length > 0 && (
                                        <div className="field-container">
                                          {initialValue.mealCustomization.map(
                                            (res, customizationIndex) => (
                                              <div key={customizationIndex}>
                                                <div className="d-flex flex-column">
                                                  <label
                                                    htmlFor={`mealCustomization.${customizationIndex}.title`}
                                                  >
                                                    Title
                                                    <span className="text-danger">
                                                      {" "}
                                                      *
                                                    </span>
                                                  </label>
                                                  <Field
                                                    type="text"
                                                    placeholder="Size"
                                                    value={
                                                      initialValue
                                                        ?.mealCustomization[
                                                        customizationIndex
                                                      ].title
                                                    }
                                                    disabled
                                                  />
                                                </div>

                                                <div className="row align-items-center my-2">
                                                  <div className="col-3">
                                                    Name
                                                  </div>
                                                  <div className="col-3">
                                                    Actual Price (£) *
                                                  </div>
                                                  <div className="col-3">
                                                    Discount Price (£) *
                                                  </div>
                                                  <div className="col-3"></div>
                                                </div>

                                                <FieldArray
                                                  name={`mealCustomization.${customizationIndex}.item`}
                                                >
                                                  {() =>
                                                    res?.items?.map(
                                                      (item, itemIndex) => (
                                                        <div
                                                          className="row align-items-center"
                                                          key={itemIndex}
                                                        >
                                                          <div className="col-3">
                                                            <Field
                                                              type="text"
                                                              placeholder="Name"
                                                              value={
                                                                initialValue
                                                                  ?.mealCustomization[
                                                                  customizationIndex
                                                                ].items[
                                                                  itemIndex
                                                                ].item
                                                              }
                                                              className="meal_customization_field"
                                                            />
                                                          </div>
                                                          <div className="col-3">
                                                            <Field
                                                              type="text"
                                                              placeholder="Actual Price"
                                                              value={
                                                                initialValue
                                                                  ?.mealCustomization[
                                                                  customizationIndex
                                                                ].items[
                                                                  itemIndex
                                                                ].actual_price
                                                              }
                                                              disabled
                                                              className="meal_customization_field"
                                                            />
                                                          </div>
                                                          <div className="col-3">
                                                            <input
                                                              type="text"
                                                              placeholder="Discount Price"
                                                              value={
                                                                initialValue
                                                                  ?.mealCustomization[
                                                                  customizationIndex
                                                                ].items[
                                                                  itemIndex
                                                                ].discount_price
                                                              }
                                                              disabled
                                                              className="meal_customization_field"
                                                            />
                                                          </div>
                                                          <div className="col-3">
                                                            <div
                                                              className="d-flex align-items-center"
                                                              style={{
                                                                gap: "5px",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  item?.image
                                                                    ? item?.image
                                                                    : preview_generic_image
                                                                }
                                                                alt=""
                                                                className="item_uploaded_img"
                                                              />
                                                              <input
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                  handleSelectCustomization(
                                                                    customizationIndex,
                                                                    itemIndex,
                                                                    e
                                                                  )
                                                                }
                                                                className="input_check_box"
                                                                disabled
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )
                                                  }
                                                </FieldArray>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </section>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewMeal;
