import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import "../add/add.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ProductView, Editproduct } from "../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import whiteedit from "../../../../../img/white-edit.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "50vh", // Added height constraint
};

export default function EditAdd({ setUpdateAPI, id }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [initialValues, setInitialValues] = React.useState({
    ProductName: "",
    // password: "",
  });
  const validationSchema = yup.object({
    ProductName: yup.string().required("Product name is required"),
    // countryCode: yup.string().required("Country Code is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const requestOptions = {
      name: values.ProductName,
    };

    const data = {
      id: id,
      requestOption: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(requestOptions),
      },
    };

    console.log("data", data);

    try {
      dispatch(Editproduct(data)).then((res) => {
        console.log("res", res);
        // setDisableSubmitBtn(false);
        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          handleClose();
          setUpdateAPI("addrange");
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const handleViewBranch = () => {
    try {
      dispatch(ProductView(id)).then((res) => {
        if (res?.payload?.status === true) {
          setInitialValues({
            ProductName: res?.payload?.payload?.name,
          });

          handleOpen();
        } else {
          setInitialValues(initialValues);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          handleViewBranch();
        }}
        className="edit_brach_btn bg-blue"
      >
        <img src={whiteedit} />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container Team_modal">
              <div className="modal_heading">
                <h1>Hardware Information</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="field_cont ">
                      <div className="row">
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="ProductName">
                            Product Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Product Name"
                            id="ProductName"
                            name="ProductName"
                          />
                          <ErrorMessage
                            component="p"
                            name="ProductName"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal_btn_container">
                      <div
                        className="modal_cancel_btn"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </div>
                      <button
                        className="modal_cancel_btn"
                        type="submit"
                        // disabled={disableSubmitBtn == true}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
