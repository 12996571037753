import React from "react";
import PrivateRoute from "./ProtectedRoute/ProtectedRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "../Component/Login/login";
import Dashboard from "../Component/SuperAdmin/Dashboard /dashboard";
import { ToastContainer, toast } from "react-toastify";
import Restaurants from "../Component/SuperAdmin/Restaurants/restaurants";
import Addrestaurant from "../Component/SuperAdmin/Restaurants/Addrestaurant/addrestaurant";
import Editrestaurant from "../Component/SuperAdmin/Restaurants/Editestaurant/editrestaurant";
import Hardware from "../Component/SuperAdmin/Restaurants/Hardware/hardware";
import Branches from "../Component/SuperAdmin/Restaurants/Restro-Branch/Branch/List/list";
import Rolelist from "../Component/SuperAdmin/Team Management/Rolemanagement/List/Rolelist";
import Roleadd from "../Component/SuperAdmin/Team Management/Rolemanagement/Add/Add";
import Roleedit from "../Component/SuperAdmin/Team Management/Rolemanagement/Edit/Edit";
import Designationlist from "../Component/SuperAdmin/Team Management/Designation/list/list";
import DesignationAdd from "../Component/SuperAdmin/Team Management/Designation/add/add";
import DesignationEdit from "../Component/SuperAdmin/Team Management/Designation/edit/edit";
import Teammembers from "../Component/SuperAdmin/Team Management/Team Members/List/list";
import ProductInformation from "../Component/SuperAdmin/Restaurants/Product Information/list/list";
import ProductAdd from "../Component/SuperAdmin/Restaurants/Product Information/add/add";
import Restaurantslogin from "../Component/restaurantslogin/restaurantslogin";
import Category from "../Component/SuperAdmin/MenuManagement/Categories/page";
import Itemslist from "../Component/SuperAdmin/MenuManagement/Items/list/list";
import AddItems from "../Component/SuperAdmin/MenuManagement/Items/add/add";
import EditItems from "../Component/SuperAdmin/MenuManagement/Items/edit/edit";
import Customization from "../Component/SuperAdmin/MenuManagement/Customizations/page";
import BundleList from "../Component/SuperAdmin/MenuManagement/BundleCreation/list/list";
import BundleAdd from "../Component/SuperAdmin/MenuManagement/BundleCreation/add/add";
import ViewItems from "../Component/SuperAdmin/MenuManagement/Items/view/view";
import BundleEdit from "../Component/SuperAdmin/MenuManagement/BundleCreation/edit/edit";
import BundleView from "../Component/SuperAdmin/MenuManagement/BundleCreation/view/view";
import MealList from "../Component/SuperAdmin/MenuManagement/Meal Creation/list/list";
import AddMeal from "../Component/SuperAdmin/MenuManagement/Meal Creation/add/add";
import Forget from "../Component/Login/ForgetPassword/forgetpassword";
import Confirmpassword from "../Component/Login/confirmpassword/confirmpassword";
import Forgetpassw from "../Component/restaurantslogin/ForgetPassword/forgetpassword";
import Orderplacement from "../Component/SuperAdmin/OrderManagement/OrderPlacement/list/list";
import OrderADD from "../Component/SuperAdmin/OrderManagement/OrderPlacement/add/add";
import BoatHouse from "../Component/SuperAdmin/OrderManagement/OrderPlacement/BoatHouse/BoatHouse";
import OrderCategory from "../Component/SuperAdmin/OrderManagement/OrderPlacement/Add/Category/category";
import OrderItems from "../Component/SuperAdmin/OrderManagement/OrderPlacement/Add/items/items";
import EditMeal from "../Component/SuperAdmin/MenuManagement/Meal Creation/edit/edit";
import ViewMeal from "../Component/SuperAdmin/MenuManagement/Meal Creation/View/view";
import Myorder from "../Component/SuperAdmin/OrderManagement/OrderPlacement/Add/MyOrder/myorder";
import Receipt from "../Component/SuperAdmin/OrderManagement/OrderPlacement/Add/Receipt/receipt";
import ChoosePayMethod from "../Component/SuperAdmin/OrderManagement/OrderPlacement/PaymentModule/First/page";
import { Mycart } from "../Component/SuperAdmin/OrderManagement/OrderPlacement/Add/Mycart/mycart";
import CustomizationCart from "../Component/SuperAdmin/OrderManagement/OrderPlacement/Add/customization/customization";
import EditMealNew from "../Component/SuperAdmin/MenuManagement/Meal Creation/newEditMeal/mealedit";
import OrderList from "../Component/SuperAdmin/OrderManagement/OrderPlacement/list/OrderList";
import Tippingseting from "../Component/SuperAdmin/TippingManagement/TippingSetting/Tippingsetting";
import TippingDetails from "../Component/SuperAdmin/TippingManagement/TippingDetails/TippingDetails";
import TippingView from "../Component/SuperAdmin/TippingManagement/TippingDetails/TippingView/TippingView";
function Routepage() {
  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/forgetpassword" element={<Forget />} />
          <Route path="/reset-password" element={<Confirmpassword />} />
          <Route path="/Repassword" element={<Forgetpassw />} />
        </Route>

        <Route path="/" element={<PublicRoute />}>
          <Route path="/restaurantslogin" element={<Restaurantslogin />} />
        </Route>

        {/* restaurantsManagement route */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            {/* <Route path="*" element={<Page404 home={true} />} /> */}
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="restaurants" element={<Restaurants />} />
            {/* <Route path="add-new-restaurant" element={<Addrestaurant />} /> */}
            {/* </Route> */}

            <Route
              path="restaurants/create-restaurant"
              element={<Addrestaurant />}
            />
            <Route
              path="restaurants/edit-restaurant"
              element={<Editrestaurant />}
            />
            <Route
              path="restaurants/restaurant-hardware"
              element={<Hardware />}
            />
            <Route path="restaurants/branches/:id" element={<Branches />} />

            <Route
              path="/restaurants/productinformation"
              element={<ProductInformation />}
            />
          </Route>
        </Route>

        {/* TeamManagement route */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="team-management" element={<Rolelist />} />
            <Route path="team-management/roleadd" element={<Roleadd />} />
            <Route path="team-management/roleedit" element={<Roleedit />} />

            <Route
              path="team-management/designations"
              element={<Designationlist />}
            />
            <Route
              path="team-management/designation/create"
              element={<DesignationAdd />}
            />
            <Route
              path="team-management/designation/edit/:id"
              element={<DesignationEdit />}
            />

            {/* Team Members route */}

            <Route
              path="team-management/team-members"
              element={<Teammembers />}
            />
          </Route>
        </Route>

        {/* MENUMENAGEMENT route */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="menu-management/category" element={<Category />} />
            <Route path="menu-management/items" element={<Itemslist />} />
            <Route path="menu-management/items/create" element={<AddItems />} />
            <Route
              path="menu-management/items/edit/:id"
              element={<EditItems />}
            />
            <Route
              path="menu-management/items/view/:id"
              element={<ViewItems />}
            />

            <Route
              path="menu-management/customization/:id"
              element={<Customization />}
            />

            <Route path="menu-management/bundles" element={<BundleList />} />

            <Route
              path="menu-management/bundles/create"
              element={<BundleAdd />}
            />
            <Route
              path="menu-management/bundles/edit/:id"
              element={<BundleEdit />}
            />
            <Route
              path="menu-management/bundles/view/:id"
              element={<BundleView />}
            />

            <Route path="menu-management/meals" element={<MealList />} />
            <Route path="menu-management/meal/create" element={<AddMeal />} />
            {/* <Route
              path="menu-management/meal/edit/:id"
              element={<EditMeal />}
            /> */}
            <Route
              path="menu-management/meal/edit/:id"
              element={<EditMealNew />}
            />
            <Route
              path="menu-management/meal/view/:id"
              element={<ViewMeal />}
            />
          </Route>
        </Route>

        {/* MENUMENAGEMENT route */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route
              path="ordermanagement/orderplacement"
              element={<Orderplacement />}
            />
            <Route
              path="ordermanagement/orderplacement/orderlist"
              element={<OrderList />}
            />

            <Route path="ordermanagement/orderadd" element={<OrderADD />} />
            <Route
              path="ordermanagement/orderadd/Favorites"
              element={<BoatHouse />}
            />
            <Route
              path="ordermanagement/category"
              element={<OrderCategory />}
            />
            <Route path="ordermanagement/item/:id" element={<OrderItems />} />
            <Route path="ordermanagement/myorder" element={<Myorder />} />
            <Route path="ordermanagement/receipt" element={<Receipt />} />

            <Route path="ordermanagement/pay" element={<ChoosePayMethod />} />
            <Route path="ordermanagement/mycart" element={<Mycart />} />
            <Route
              path="ordermanagement/CustomizationCart"
              element={<CustomizationCart />}
            />
          </Route>
        </Route>

        {/* Tipping Management route */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route
              path="TippingManagement/tippingsetting"
              element={<Tippingseting />}
            />
            <Route
              path="TippingManagement/tippingDetails"
              element={<TippingDetails />}
            />
            <Route
              path="TippingManagement/tippingview"
              element={<TippingView />}
            />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default Routepage;
