import React, { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { Profilelist } from "../../../../../Helper/Redux/API";

const CheckboxGroup = ({ label, options, ...props }) => {
  const dispatch = useDispatch();
  const roletype = localStorage.getItem("roletype");
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [filddisble, setFilddisble] = React.useState(false);
  const handleChange = (event) => {
    const { value, checked } = event.target;
    const currentValues = field.value || [];
    if (checked) {
      setFieldValue(field.name, [...currentValues, value]);
    } else {
      setFieldValue(
        field.name,
        currentValues.filter((val) => val !== value)
      );
    }
  };

  const [isPerfectUser, setIsPerfectUser] = useState("");

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  const fieldValues = field.value || []; // Ensure field.value is always an array

  useEffect(() => {
    if (
      localStorage.getItem("roletype") === "1" ||
      localStorage.getItem("roletype") === "2"
    ) {
      if (localStorage.getItem("roletype") === "2" && isPerfectUser == true) {
        setFilddisble(false);
      } else {
        setFilddisble(true);
      }
    } else {
      setFilddisble(false);
    }
  }, [localStorage.getItem("roletype"), isPerfectUser]);

  console.log(options, "options");

  return (
    <div className="form-group product">
      {/* <label>{label}</label> */}
      {options.map((option) => {
        if (option.isActive) {
          return (
            <div
              key={option.value}
              className="form-check"
              onClick={(e) => {
                if (e.target.tagName !== "INPUT") {
                  // Prevents the event from firing twice if the input is clicked directly
                  handleChange({
                    target: {
                      name: field.name,
                      value: option._id,
                      checked: !fieldValues.includes(option._id),
                    },
                  });
                }
              }}
            >
              <label
                htmlFor={`${field.name}_${option._id}`}
                className="form-check-label"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the label click from triggering the div click
                  handleChange({
                    target: {
                      name: field.name,
                      value: option._id,
                      checked: !fieldValues.includes(option._id),
                    },
                  });
                }}
              >
                {option.name}
              </label>
              <input
                type="checkbox"
                name={field.name}
                disabled={filddisble}
                value={option._id}
                checked={fieldValues.includes(option._id)}
                onChange={handleChange}
                className="form-check-input"
                id={`${field.name}_${option._id}`}
              />
            </div>
          );
        }
      })}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CheckboxGroup;
