import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/team.svg";
import userimg from "../../../../../img/users-icon.svg";
import whitedelete from "../../../../../img/white-delete.svg";
import whiteeye from "../../../../../img/white-eye.svg";
import whiteedit from "../../../../../img/white-edit.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  restaurantList,
  branchrole,
  RoleAtive,
  Profilelist,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
// import AddBrachModal from "../add/add";
// import EditBrachModal from "../edit/edit";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Edit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const roletype = localStorage.getItem("roletype");
  const { item } = location?.state || {};
  const [allrestaurant, setAllRestaurant] = useState("");
  const [brachList, setBrachList] = useState([]);
  const [filddisble, setFilddisble] = useState(false);
  const [sidebarmenulist, setSidebarmenulist] = useState(
    item?.permission ? item?.permission : []
  );

  const [selectedOptions, setSelectedOptions] = useState(
    item?.restaurantLabel ? item?.restaurantLabel : []
  );
  const [Profiledata, setProfiledata] = useState();
  const [isperfect, setIsPerfect] = useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfect(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  console.log("item", item);
  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  console.log(allrestaurantlist, "allrestaurantlist");
  useEffect(() => {
    getRestaurantListData();
    getBraches(item?.restaurantId ? item?.restaurantId : "");

    if (roletype === "1" || roletype === "2") {
      setFilddisble(true);
    }
  }, []);

  const [selectedOptionsBranch, setSelectedOptionsBranch] = useState(
    item?.branchLabel ? item?.branchLabel : []
  );
  const [selectedvaluesArray, setSelectedvaluesArray] = useState(
    item?.restaurantId ? item?.restaurantId : []
  );
  const [selectedBrancArray, setSelectedBrancArray] = useState(
    item?.branchIds ? item?.branchIds : []
  );
  const [isPerfectUser, setIsPerfectUser] = useState(
    item?.is_perfect_user ? item?.is_perfect_user : false
  );

  const [rolename, setRoleName] = useState(
    item?.roleName ? item?.roleName : ""
  );
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    getBraches(valuesArray);
  };

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);

          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    setSelectedOptionsBranch(selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    setSelectedBrancArray(valuesArray);
  };

  const handleCheckboxClick = (index, field) => {
    setSidebarmenulist((prevState) => {
      const updatedList = prevState.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: !item[field] };
        }
        return item;
      });
      return updatedList;
    });
  };

  const handlePerfectUserChange = (event) => {
    const isChecked = event.target.checked;
    setIsPerfectUser(isChecked);

    if (isChecked) {
      setSelectedOptions(allrestaurantlist);
      setSelectedOptionsBranch(allbrachList);

      const valuesArray = allrestaurantlist.map((option) => option.value);

      setSelectedvaluesArray(valuesArray);

      setTimeout(() => {
        const valuesArray2 = allbrachList.map((option) => option.value);
        getBraches(valuesArray);
        setSelectedBrancArray(valuesArray2);
      }, 2000);
    } else {
      setSelectedOptions([]);
      setSelectedOptionsBranch([]);
    }
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const creactrole = () => {
    const requestOptions = {
      roleName: rolename,
      restaurantId: selectedvaluesArray,
      branchIds: selectedBrancArray,
      is_perfect_user: isPerfectUser,
      permission: sidebarmenulist,
      restaurantLabel: selectedOptions,
      branchLabel: selectedOptionsBranch,
    };

    console.log("data", requestOptions);

    const data = {
      id: item._id,
      requestOption: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(requestOptions),
      },
    };

    try {
      dispatch(RoleAtive(data)).then((res) => {
        if (res?.payload?.status === true) {
          // setBrachList(res?.payload?.payload);
          toast.success(res?.payload?.message);
          navigate("/team-management");
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text=" Edit Role Management" img_icon={Firedark} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="mb-3">
              <Link to="/team-management" className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </Link>
            </div>
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <h6 class="text-navy-blue mb-2 space_div">Edit Roles</h6>
                      <div className="Line_div"></div>
                      {roletype === "1" || roletype === "2" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="Prefectuser space_div pb-0">
                            <input
                              type="checkbox"
                              checked={isPerfectUser}
                              onChange={handlePerfectUserChange}
                            />{" "}
                            <label>Is Prefect User</label>
                          </div>
                        </>
                      )}{" "}
                      {/* <div className="pageName">Roles</div> */}
                      <div className="row addrolemange space_div pt-1 pb-1">
                        <div className="col-sm-12 col-md-4">
                          <div
                            className="dataTables_length "
                            id="dataTable_length"
                          >
                            <label>Restaurant: </label>

                            {roletype === "1" || roletype === "2" ? (
                              <>
                                {" "}
                                {isperfect && roletype === "2" ? (
                                  <>
                                    <MultiSelect
                                      options={allrestaurantlist}
                                      value={selectedOptions}
                                      onChange={handleChange}
                                      labelledBy="Select Restaurant..."
                                      overrideStrings={{
                                        selectSomeItems: "Select Restaurant...",
                                        allItemsAreSelected:
                                          "All Restaurant are selected.",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Select
                                    // isMulti
                                    options={allrestaurantlist}
                                    value={selectedOptions}
                                    onChange={handleChange}
                                    isDisabled={filddisble}
                                    placeholder="Select Restaurant..."
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {" "}
                                <MultiSelect
                                  options={allrestaurantlist}
                                  value={selectedOptions}
                                  onChange={handleChange}
                                  labelledBy="Select Restaurant..."
                                  overrideStrings={{
                                    selectSomeItems: "Select Restaurant...",
                                    allItemsAreSelected:
                                      "All Restaurant are selected.",
                                  }}
                                />
                              </>
                            )}

                            <div>
                              {/* <ul>
                                {selectedOptions.map((option) => (
                                  <li key={option.value}>{option.label}</li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div
                            className="dataTables_length"
                            id="dataTable_length"
                          >
                            <label>Branch: </label>
                            {/* <Select
                              isMulti
                              options={allbrachList}
                              value={selectedOptionsBranch}
                              onChange={handleChangeBranch}
                              placeholder="Select Branch..."
                            /> */}

                            <MultiSelect
                              options={allbrachList}
                              value={selectedOptionsBranch}
                              onChange={handleChangeBranch}
                              labelledBy="Select Branch..."
                              overrideStrings={{
                                selectSomeItems: "Select Branch...",
                                allItemsAreSelected:
                                  "All branches are selected.",
                              }}
                            />
                            <div>
                              {/* <ul>
                                {selectedOptions.map((option) => (
                                  <li key={option.value}>{option.label}</li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter"
                          >
                            <label>Role Name:</label>

                            <input
                              type="search"
                              className="form-control"
                              placeholder="Enter Role Name"
                              onChange={handleRoleNameChange}
                              value={rolename}
                              // value={search}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer space_div pt-0"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "200px;", whiteSpace: "pre" }}
                                >
                                  Sr. No.
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "250px" }}
                                >
                                  Menu
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  // style="width: 204px;"
                                  style={{ width: "200px" }}
                                >
                                  View
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Create
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Edit
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Delete
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {Array.isArray(sidebarmenulist) &&
                                sidebarmenulist.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.View}
                                        onChange={() =>
                                          handleCheckboxClick(index, "View")
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.Create}
                                        onChange={() =>
                                          handleCheckboxClick(index, "Create")
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.Edit}
                                        onChange={() =>
                                          handleCheckboxClick(index, "Edit")
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={item.Delete}
                                        onChange={() =>
                                          handleCheckboxClick(index, "Delete")
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="Line_div"></div>
                      <div class="modal_btn_container role_btn space_div mt-0">
                        <Link to="/team-management">
                          {" "}
                          <div class="modal_cancel_btn">Cancel</div>
                        </Link>
                        <button
                          class="modal_cancel_btn"
                          type="submit"
                          onClick={creactrole}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Edit;
