import React, { useEffect, useState } from "react";
import whitedelete from "../../../../../img/white-delete.svg";
import whiteedit from "../../../../../img/white-edit.svg";

import whiteview from "../../../../../img/white-eye.svg";

import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  customizationList,
  customizationDelete,
  customizationStatusUpdate,
} from "../../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "./list.css";
import swal from "sweetalert";

const CategoryList = ({updateAPI , setUpdateAPI , setIShowSecondPart , setIDS , itemName}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const itemId = location.pathname.split('/')[3]
  const [allRole, setAllRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');


  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [totalCounts, setTotalCounts] = useState("");

  const getCustomisationList = () => {
    setIsLoading(true);
    const requestOption = {
      page: page,
      search: search,
      limit: limit,
      status:status,
      itemId:itemId
    };
    dispatch(customizationList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRole(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
        } 
        setUpdateAPI('')
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getCustomisationList();
  }, [page, limit, search, updateAPI]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  // const handleDeleteCustomisation = (id) => {
  //   const data = {
  //     itemId:itemId,
  //     id:id
  //   }
  //   try {
  //     dispatch(customizationDelete(data)).then((res) => {
  //       if (res?.payload?.status === true) {
  //         toast.success(res?.payload?.message);
  //         setAllRole(allRole.filter((branch) => branch.customization?._id !== id));
  //       } else {
  //         toast.error(res?.payload?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeleteCustomisation = (id) => {
    const data = {
      itemId:itemId,
      id:id
    }
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(customizationDelete(data)).then((res) => {
          if (res?.payload?.status == true) {
            toast.success(res?.payload?.message);          setAllRole(allRole.filter((branch) => branch.customization?._id !== id));

          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const handleStatusUpdate = (status, id) => {
    const formData = new FormData();
    formData.append('menuItemId', itemId);
    formData.append('isActive', !status);
    formData.append('customisationId', id);


    const requestOptions = {
      id:id,
      status:!status,
      itemId:itemId
    };
    
    try {
      dispatch(customizationStatusUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          getCustomisationList();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        {/* <section className="Restaurants Dashbored allsection"> */}

        {/* <div className="container-fluid"> */}

        <div className="custom-table">
          <div className="card  card-custom_box mb-4 ">
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="dataTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div className=" pe-2 ">
                    <h6 class="text-navy-blue mb-4 ">{`Item Customization (${itemName})`} </h6>
                    <hr />
                    <div className="sorting_filter_container mb-4">
                     
                      <select name="" id="" value={status} onChange={(e) => setStatus(e.target.value == true)} >
                        <option value="">Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>

                      </select>
                      <button className="search_btn" onClick={()=> getCustomisationList()}>Search</button>
                      <button className="reset_btn" onClick={() => {setStatus('') ; setSearch(''); getCustomisationList()}} >Reset</button>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-sm-12 col-md-4">
                        <div
                          className="dataTables_length my-0"
                          id="dataTable_length"
                        >
                          <label>
                            Show{" "}
                            <select
                              name="dataTable_length"
                              aria-controls="dataTable"
                              value={limit}
                              onChange={handleLimitChange}
                              className="custom-select custom-select-sm form-control form-control-sm"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              {/* <option value="100">100</option> */}
                            </select>{" "}
                            Entries
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-8">
                        <div
                          id="dataTable_filter"
                          className="dataTables_filter my-0"
                        >
                          <label>
                            Search:
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              aria-controls="dataTable"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        className="table dataTable no-footer"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                        role="grid"
                        aria-describedby="dataTable_info"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-sort="ascending"
                              aria-label="Restaurant Name: activate to sort column descending"
                              style={{ width: "700px" }}
                            >
                              Title
                            </th>
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-label="Mobile Number: activate to sort column ascending"
                              style={{ width: "300px" }}
                            >
                              Items
                            </th>
                            <th
                              className=""
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-label="Email: activate to sort column ascending"
                              style={{ width: "300px" }}
                            >
                              Status
                            </th>
                            <th
                              className="text-right"
                              tabindex="0"
                              aria-controls="dataTable"
                              rowspan="1"
                              colspan="1"
                              aria-label="Location: activate to sort column ascending"
                              style={{ width: "250px", whiteSpace: "pre" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {allRole.length == 0 ? (
                            // Render a loader while data is being fetched
                            <tr>
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                <h3>Empty List</h3>
                              </td>
                            </tr>
                          ) : (
                            allRole.map((item, index) => (
                              <>
                                <tr className="odd" key={item.index}>
                                  <td> {item.customization?.title}</td>
                                  <td> {item?.name}</td>


                                  <td
                                    className={
                                      item.isActive
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                    onClick={() =>
                                      handleStatusUpdate(
                                        item.customization?.isActive,
                                        item.customization?._id
                                      )
                                    }
                                  >
                                   
                                   <span data-toggle="tooltip" data-placement="bottom" title="Click to change status" > 
                                   {item.customization?.isActive ? "Active" : "Inactive"}
                                    </span>


                                  </td>

                                  <td>
                                    <ul className="action-btns d-flex gap-2 justify-content-end">
                                    <li>
                                        <button
                                          className="bg-green delete_btn"
                                          onClick={() =>
                                            {
                                              setIShowSecondPart('view') ;
                                              setIDS({
                                                itemId:itemId , 
                                                customizationId: item?.customization._id
                                              })
                                            }
                                          }
                                        >
                                          <img src={whiteview} />
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="bg-blue delete_btn"
                                          onClick={() => 
                                            {
                                            setIShowSecondPart('edit') ;
                                            setIDS({
                                              itemId:itemId , 
                                              customizationId: item?.customization._id
                                            })
                                          }

                                          }
                                        >
                                          <img src={whiteedit} />
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="bg-orange delete_btn"
                                          onClick={() =>
                                            handleDeleteCustomisation(item?.customization?._id)

                                          }
                                        >
                                          <img src={whitedelete} />
                                        </button>
                                      </li>
                                     
                                    </ul>
                                  </td>
                                </tr>
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row  align-items-center justify-content-between">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="dataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to {totalCounts} of{" "}
                        {allRole.length ? allRole.length : "0"} Entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={limit}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </section> */}
      </div>
    </div>
  );
};

export default CategoryList;
