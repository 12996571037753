import React, { useState } from "react";
import "./login.css";
import * as Yup from "yup";
import loginsvg from "../../img/newlog.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoginAPI } from "../../Helper/Redux/API";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    password: "",
    email: "",
    admin: "0",
  };

  const validation = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    dispatch(isLoginAPI(values)).then((res) => {
      if (res?.payload?.status === true) {
        let access_token = res?.payload.token;
        let Userid = res?.payload?.payload?._id;
        let UserName = res?.payload?.payload?.name;
        let roletype = res?.payload?.role;
        // toast.success(res?.payload?.message);
        if (access_token != "" && access_token != "") {
          // dispatch(
          //   loginSuccess({
          //     permission: "new",
          //   })
          // );

          // dispatch(setRoleType(roletype));
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("id", Userid);
          localStorage.setItem("RoleName", UserName);
          localStorage.setItem("roletype", roletype);
          //   localStorage.setItem("Roleid", Roleid);
          navigate("/dashboard");
          toast.success(res.payload?.message);
          console.log("res?.status", res.payload?.message);
        }
      } else {
        toast.error(res?.error.message);

        console.log("res?.statssssssus", res);
      }
    });
    navigate("/");
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="container">
          {/* Outer Row */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-6">
                <div className="card-body p-0">
                  {/* Nested Row within Card Body */}
                  <div className="row justify-content-center">
                    {/* <!-- <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> --> */}
                    <div className="col-lg-6">
                      <div className="p-5 login-inner">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">
                            Welcome Back to!
                          </h1>
                          <img className="img-fluid mb-4" src={loginsvg} />
                        </div>

                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validation}
                        >
                          <Form className="user">
                            <div className="form-group">
                              <Field
                                type="email"
                                name="email"
                                id="login_email"
                                className="form-control form-control-user"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address"
                              />

                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="form-group">
                              <div className="eys_input">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  Placeholder="Enter Password"
                                  id="password"
                                  name="password"
                                  className="form-control form-control-user"
                                />

                                <span
                                  className="password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <button
                              className="btn btn-primary btn-user btn-block"
                              type="submit"
                            >
                              Login
                            </button>
                          </Form>
                        </Formik>

                        <div className="text-center mt-4">
                          <Link className="small" to="/forgetpassword">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
