import react, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../../img/AddModal/Group 959.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { branchEdit, branchView } from "../../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import whiteedit from "../../../../../../img/white-edit.svg";
import "./edit.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "90vh", // Added height constraint
  outline: "none",
};

export default function EditBrachModal({ setUpdateAPI, id, parentId }) {
  const roletype = localStorage.getItem("roletype");
  const cleanPath = (path) => {
    if (path === null || path === undefined) {
      console.error("Received null or undefined path:", path);
      return "";
    }
    return path.replace(/^public\//, "");
  };

  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [binaryImage, setBinaryImage] = useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [initialValues, setInitialValues] = useState({
    branch_name: "",
    // email: "",
    // mobile_number: "",
    // whatsapp_number: "",
    house_street: "",
    locality: "",
    postCode: "",
    country: "",
    // countryCode: "",
  });

  const handleViewBranch = () => {
    const dataview = {};

    try {
      dispatch(branchView(id)).then((res) => {
        if (res?.payload?.status === true) {
          setInitialValues({
            branch_name: res?.payload?.payload?.name,
            // email: res?.payload?.payload?.email,
            // mobile_number: res?.payload?.payload?.mobileNumber,
            parentId: res?.payload?.payload?.parentId,
            house_street: res?.payload?.payload?.addressLineOne,
            locality: res?.payload?.payload?.addressLineTwo,
            postCode: res?.payload?.payload?.postCode,
            country: res?.payload?.payload?.country,
            // countryCode: res?.payload?.payload?.countryCode,
          });
          setPreviewImage(
            `${process.env.REACT_APP_BASE_URL}${cleanPath(
              res?.payload?.payload?.image
            )}`
          );
          setBinaryImage(res?.payload?.payload?.image);

          handleOpen();
        } else {
          setInitialValues(initialValues);
          setPreviewImage(null);
          setBinaryImage(null);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = yup.object({
    branch_name: yup.string().required("Brach name is required"),
    // email: yup
    //   .string()
    //   .required("Email is required")
    //   .email("Invalid email address"),
    // mobile_number: yup
    //   .string()
    //   .required("Mobile number is required")
    //   .matches(/^[0-9]+$/, "Mobile number must contain only digits"),
    // whatsapp_number: yup
    //   .string()
    //   .required("WhatsApp number is required")
    //   .matches(/^[0-9]+$/, "Mobile number must contain only digits"),
    // house_street: yup.string().required("House No. & street is required"),
    locality: yup.string().required("Locality is required"),
    postCode: yup
      .string()
      .required("Post Code is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Post Code must be alphanumeric and not contain special characters or spaces"
      ),
    country: yup.string().required("Country is required"),
    // countryCode: yup.string().required("Country Code is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("parentId", restaurant_id);
    formData.append("name", values.branch_name);
    // formData.append("email", values.email);
    // formData.append("countryCode", values.countryCode);
    // formData.append("mobileNumber", values.mobile_number);
    // formData.append("whatsapp", values.whatsapp_number);
    formData.append("addressLineOne", values.house_street);
    formData.append("addressLineTwo", values.locality);
    formData.append("post Code", values.postCode);
    formData.append("country", values.country);
    // formData.append("image", binaryImage);
    formData.append("branch", 1);

    const requestOptions = {
      id: id,
      requestOption: {
        method: "PUT",
        body: formData,
        Headers: {
          authorization: localStorage.getItem("token"),
        },
      },
    };
    try {
      dispatch(branchEdit(requestOptions)).then((res) => {
        setDisableSubmitBtn(false);

        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          setPreviewImage(null);
          setBinaryImage("");
          handleClose();
          setUpdateAPI("addrange");
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      setDisableSubmitBtn(false);
    }
  };
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setBinaryImage(file);
    }
  };
  return (
    <div>
      <button
        onClick={() => {
          handleViewBranch();
        }}
        className="edit_brach_btn bg-blue"
      >
        <img src={whiteedit} />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Edit Branch</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="field_cont ">
                      <div className="row">
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="branchName">
                            Branch Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Branch Name"
                            id="branchName"
                            name="branch_name"
                          />
                          <ErrorMessage
                            component="p"
                            name="branch_name"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="house_no">
                            House Number and Street{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter House No. & Street "
                            id="house_no"
                            name="house_street"
                          />
                          <ErrorMessage
                            component="p"
                            name="house_street"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="locality">
                            Locality (Post Town){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Locality "
                            id="locality"
                            name="locality"
                          />
                          <ErrorMessage
                            component="p"
                            name="locality"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="post_Code">
                            Post Code <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Post Code "
                            id="post_Code"
                            name="postCode"
                          />
                          <ErrorMessage
                            component="p"
                            name="postCode"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="country">
                            Country <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            Placeholder="Enter Country Name "
                            id="country"
                            name="country"
                          >
                            {countryCodes.map((country, i) => (
                              <option value={country.country} key={i}>
                                {country.country}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="p"
                            name="country"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal_btn_container">
                      <div
                        className="modal_cancel_btn"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </div>
                      <button
                        className="modal_cancel_btn"
                        disabled={disableSubmitBtn == true}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
