import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import "./Edit.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import whiteedit from "../../../../../img/white-edit.svg";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  branchAdd,
  restaurantList,
  branchrole,
  ProductList,
  HardwareView,
  EditHardware,
  Hardwarepassword,
  Rolebased,
  Profilelist,
} from "../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import Select from "react-select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "90vh", // Added height constraint
};

export default function EditModal({ setUpdateAPI, id, SrNo }) {
  const roletype = localStorage.getItem("roletype");
  const [open, setOpen] = React.useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = React.useState(null);
  const [selectedOptionsBranch, setSelectedOptionsBranch] = React.useState([]);
  const [binaryImage, setBinaryImage] = React.useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [allrestaurant, setAllRestaurant] = React.useState([]);
  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];
  const [brachList, setBrachList] = React.useState([]);
  const [selectedOptionsProduct, setSelectedOptionsProduct] = React.useState(
    []
  );
  const [filddisble, setFilddisble] = React.useState(false);
  const [branchid, setBranchID] = React.useState([]);
  const handleOpen2 = () => setOpen2(true);
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => setOpen2(false);
  // const handleOpen = () => setOpen(true);

  const [allproduct, setAllProduct] = React.useState("");
  const [Profiledata, setProfiledata] = React.useState("");
  const [isperfectuser, setIsPerfectUser] = React.useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  React.useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  const [initialValues, setInitialValues] = React.useState({
    // RestaurantName: "",
    // BranchName: "",
    // HardwareType: "",
    serialno: "",
    // password: "",
  });
  const validationSchema = yup.object({
    // RestaurantName: yup.string().required("Restaurant name is required"),
    // email: yup
    //   .string()
    //   .required("Email is required")
    //   .email("Invalid email address"),
    // BranchName: yup.string().required("Branch name is required"),
    // HardwareType: yup.string().required("Hardware type is required"),
    serialno: yup.string().required("Serial no is Required"),
    // password: yup
    //   .string()
    //   .required("password is required")
    //   .min(6, "Password should be at least 6 characters."),
    // confirmpassword: yup
    //   .string()
    //   .oneOf([yup.ref("password"), null], "Passwords must match")
    //   .required("Confirm password is required"),
  });

  const validationdata = yup.object().shape({
    password: yup
      .string()
      .min(6, "Password should be at least 6 characters.")
      .required("Password is Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is Required"),
  });

  const [initialReset, setInitialReset] = React.useState({
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (values, { resetForm }) => {
    // setDisableSubmitBtn(true);

    const requestOptions = {
      restaurantId: selectedRestaurant.value,
      branchId: selectedOptionsBranch.value
        ? selectedOptionsBranch.value
        : null,
      productId: selectedOptionsProduct.value,
      serialNo: values.serialno,
    };

    const data = {
      id: id,
      requestOption: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(requestOptions),
      },
    };

    console.log("data", data);
    try {
      dispatch(EditHardware(data)).then((res) => {
        // console.log("1111", data);
        // setDisableSubmitBtn(false);
        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          handleClose();
          setUpdateAPI("addrange");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const handleChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
    // console.log("selectedOptions", selectedOptions);
    setBranchID(selectedOptions.value);
    setSelectedOptionsBranch([{ value: "", label: "Select Branch Name" }]);
    const valuesArray = selectedOptions.value;
    // console.log("valuesArray", valuesArray);
    // setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    getBraches(valuesArray);

    getProductData(selectedOptions.value);
    console.log("selectedOptions", selectedOptions);
  };

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);
          // console.log("totalCounts", res?.payload?.payload);
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getProductData = (id) => {
    // const requestOption = {
    //   page: "",
    //   search: "",
    //   limit: "",
    // };
    dispatch(Rolebased(id))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllProduct(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allproductlist = Array.isArray(allproduct)
    ? allproduct.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    setSelectedOptionsBranch(selectedOptions);
    setBranchID(selectedOptions.value);
  };

  const handleOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
    // setOpen(true);
    getRestaurantListData();
    // getProductData();
    handleViewBranch();
  };

  const handleViewBranch = () => {
    try {
      dispatch(HardwareView(id)).then((res) => {
        if (res?.payload?.status === true) {
          console.log("mmmmm", res?.payload.payload);
          if (res?.payload?.payload) {
            console.log("sssssssssssss", res?.payload?.payload?.serialNo);
            setInitialValues({
              serialno: res?.payload?.payload?.serialNo
                ? res?.payload?.payload?.serialNo
                : SrNo,
              // restaurantname: res?.payload?.payload?.restaurantId,
              // productId: res?.payload?.payload?.productId,
              branchId: res?.payload?.payload?.branchId?._id
                ? res?.payload?.payload?.branchId?._id
                : "",

              // password: res?.payload?.payload?.password,
              // restaurantLabel: res?.payload?.payload?.restaurantLabel,
              userid: res?.payload?.payload?._id,
            });

            setSelectedRestaurant({
              value: res?.payload?.payload?.restaurantId._id
                ? res?.payload?.payload?.restaurantId._id
                : "",
              label: res?.payload?.payload?.restaurantId.name
                ? res?.payload?.payload?.restaurantId.name
                : "",
            });
            setSelectedOptionsBranch({
              value: res?.payload?.payload?.branchId?._id
                ? res?.payload?.payload?.branchId?._id
                : "",
              label: res?.payload?.payload?.branchId?.name
                ? res?.payload?.payload?.branchId?.name
                : "",
            });

            setSelectedOptionsProduct({
              value: res?.payload?.payload?.productId._id
                ? res?.payload?.payload?.productId._id
                : "",
              label: res?.payload?.payload?.productId.name
                ? res?.payload?.payload?.productId.name
                : "",
            });

            getBraches(
              res?.payload?.payload?.restaurantId._id
                ? res?.payload?.payload?.restaurantId._id
                : ""
            );
            if (roletype === "2") {
              // setSelectedOptions({
              //   value: localStorage.getItem("id"),
              //   label: localStorage.getItem("RoleName"),
              // });
              // setSelectedRestaurant({
              //   value: res?.payload?.payload[0]?._id,
              //   label: res?.payload?.payload[0]?.name,
              // });

              // setBranchID(res?.payload?.payload[0]?._id);
              // getProductData(res?.payload?.payload[0]?._id);

              setFilddisble(isperfectuser ? false : true);
              // getBraches(res?.payload?.payload[0]?._id);
            }
            setBranchID(
              res?.payload?.payload?.restaurantId._id
                ? res?.payload?.payload?.restaurantId._id
                : ""
            );

            getProductData(res?.payload?.payload?.restaurantId._id);
            // setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
            // setSelectedvaluesArray(res?.payload?.payload?.restaurantId || []);
            // setSelectedBrancArray(res?.payload?.payload?.branchIds || []);
            // handleOpen();
          } else {
            setInitialValues(initialValues);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // React.useEffect(() => {
  //   getRestaurantListData();
  //   getProductData();
  // }, []);
  const handleChangeProduct = (selectedOptions) => {
    setSelectedOptionsProduct(selectedOptions);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onforget = (values, { resetForm }) => {
    const data = {
      id: id,
      password: values.password,
    };

    console.log("data", data);
    dispatch(Hardwarepassword(data)).then((res) => {
      if (res?.payload?.status == true) {
        // console.log("red", res?.payload.response);
        // setUserEmail(res?.payload.response);
        handleOpen();
        handleClose2();
        toast.success(res?.payload?.message);

        // toast.success(res.payload.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  // console.log("selectedRestaurantdddd", selectedRestaurant);
  return (
    <div>
      <button
        onClick={() => {
          handleOpen();
        }}
        className="edit_brach_btn bg-blue"
      >
        <img src={whiteedit} />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Edit Allot Hardware</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="field_cont  custom_form">
                      <div className="row">
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="RestaurantName">
                            Restaurant Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="RestaurantName"
                            name="RestaurantName"
                            value={selectedRestaurant}
                            onChange={handleChange}
                            options={allrestaurantlist}
                            placeholder="Select Restaurant Name"
                            isSearchable
                            isDisabled={filddisble}
                          />
                          <ErrorMessage
                            component="p"
                            name="RestaurantName"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="BranchName">
                            Branch Name
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            id="BranchName"
                            name="BranchName"
                            value={selectedOptionsBranch}
                            onChange={handleChangeBranch}
                            options={allbrachList}
                            placeholder="Select Branch Name"
                            isSearchable
                          />
                          <ErrorMessage
                            component="p"
                            name="BranchName"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="HardwareType">
                            Product Type <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="HardwareType"
                            name="HardwareType"
                            value={selectedOptionsProduct}
                            onChange={handleChangeProduct}
                            options={allproductlist}
                            placeholder="Select Product Type"
                            isSearchable
                          />
                          <ErrorMessage
                            component="p"
                            name="Hardware Type"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="serialno">
                            Serial No. <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            placeholder="Enter Serial No"
                            id="serialno"
                            name="serialno"
                            disabled={filddisble}
                            // value={initialValues.serialNo}
                          />
                          <ErrorMessage
                            component="p"
                            name="serialno"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-12 d-flex flex-column mb-3 branchdisabled">
                          <label htmlFor="BranchId">
                            Branch Id <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            placeholder="BranchId"
                            id="BranchId"
                            name="BranchId"
                            disabled
                            value={branchid}
                          />
                        </div>
                        {/* <div className="col-md-6  mb-3">
                          <label htmlFor="password">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="eys_input">
                            <Field
                              type={showPassword ? "text" : "password"}
                              Placeholder="Enter Password"
                              id="password"
                              name="password"
                              style={{ width: "100%" }}
                            />

                            <span
                              className="password-toggle-icon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            component="p"
                            name="password"
                            className="text-danger"
                          />
                        </div> */}

                        <div className="col-md-6 d-flex flex-column mb-3">
                          {/* <label htmlFor="confirmpassword">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Confirm No "
                            id="confirmpassword"
                            name="confirmpassword"
                          />
                          <ErrorMessage
                            component="p"
                            name="confirmpassword"
                            className="text-danger"
                          /> */}

                          <div className="form-group">
                            <label
                              class="resetlabel"
                              aria-controls="collapseExample"
                              // onClick={handleOpen}
                            >
                              <Field
                                type="checkbox"
                                name="trailPreiod"
                                id="termsAndConditions2"
                                onChange={handleOpen2}
                                className="mr-2"
                              />{" "}
                              Reset Password{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal_btn_container">
                      <div
                        className="modal_cancel_btn"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </div>
                      <button
                        className="modal_cancel_btn"
                        disabled={disableSubmitBtn == true}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open2}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Reset Password</h1>
              </div>
              <div className="modal_btn_container">
                <Formik
                  validationSchema={validationdata}
                  initialValues={initialReset}
                  onSubmit={onforget}
                >
                  <Form className="custom_form">
                    <div className="field_cont ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="eys_input">
                              <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="login_password"
                                placeholder="Enter Your Password*"
                                className="form-control"
                              />
                              <span
                                className="password-toggle-icon"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEyeSlash : faEye}
                                />
                              </span>
                            </div>

                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              className="form-control"
                              id="login_Cpassword"
                              placeholder="Enter Confirm Password*"
                            />

                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div
                          className="modal_btn_container"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="modal_cancel_btn"
                            onClick={() => handleClose2()}
                          >
                            Cancel
                          </div>
                          <button
                            className="modal_cancel_btn"
                            // disabled={disableSubmitBtn == true}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
