import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import whiteedit from "../../../../../img/white-edit.svg";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import seting from "../../../../../img/seting.svg";
import default_image from "../../../../../img/back.png";
import { Vortex } from "react-loader-spinner";
import { MultiSelect } from "react-multi-select-component";
import {
  branchAdd,
  restaurantList,
  branchrole,
  ProductList,
  setingView,
  setingEdit,
  Proseting,
  Removeimg,
  AllproductSating,
} from "../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import Select from "react-select";
import "./seting.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "90vh", // Added height constraint
};

export default function SettingModal({
  setUpdateAPI,
  id,
  menid,
  branchId,
  resid,
}) {
  const [open, setOpen] = React.useState(false);
  const [buttoncolor, setButtonColor] = React.useState("#ffffff");
  const [backgroundcolor, setBackgroundColor] = React.useState("#ffffff");
  const [buttontextcolor, setButtonTextColor] = React.useState("#F27A21");
  const [headingtextcolor, setHeadingTextColor] = React.useState("#174B7C");
  const [titletextcolor, setTitleTextColor] = React.useState("");
  const [desiccationtextcolor, setDesiccationTextColor] = React.useState("");
  const [productselected, setProductSelected] = React.useState([]);
  const [selectedProduct, setselectedProduct] = React.useState([]);
  const [selectedProductid, setselectedProductid] = React.useState([]);
  const [restaurantname, setRestaurantName] = React.useState("");
  const [newid, setNewId] = React.useState("");
  const [subheadingtextcolor, setSubheadingTextColor] =
    React.useState("#454545");
  const dispatch = useDispatch();
  console.log("v", resid);
  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    ButtonColor: "",
    headingButtontextcolor: "",
    subButtontextcolor: "",
    backgroundcolor: "",
    Buttontextcolor: "",
    initialValue: "",
    initialValueSplash: "",
  };
  // const validationSchema = yup.object({
  //   RestaurantName: yup.string().required("Restaurant name is required"),
  //   // email: yup
  //   //   .string()
  //   //   .required("Email is required")
  //   //   .email("Invalid email address"),

  //   BranchName: yup.string().required("Branch name is required"),
  //   HardwareType: yup.string().required("Hardware type is required"),
  //   SerialNo: yup.string().required("Serial no is required"),
  //   password: yup
  //     .string()
  //     .required("password is required")
  //     .min(6, "Password should be at least 6 characters."),
  //   confirmpassword: yup
  //     .string()
  //     .oneOf([yup.ref("password"), null], "Passwords must match")
  //     .required("Confirm password is required"),
  // });

  const handleOpen = () => {
    setOpen(true);
    handleViewseting();
  };

  const colorchange = (e) => {
    setButtonColor(e.target.value);
  };

  const Backgroundcolorchange = (e) => {
    setBackgroundColor(e.target.value);
  };
  const [initialValue, setInitialValue] = React.useState({
    preview_img: null,
    send_media: null,
  });
  console.log(initialValue, "bg image ");
  const handleChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      setInitialValue((prev) => ({
        ...prev,
        preview_img: URL.createObjectURL(file),
        send_media: file,
      }));
    }
  };

  const buttoncolorchange = (e) => {
    setButtonTextColor(e.target.value);
  };

  const Headingcolorchange = (e) => {
    setHeadingTextColor(e.target.value);
  };

  const Subheadingcolorchange = (e) => {
    setSubheadingTextColor(e.target.value);
  };

  const [initialValueSplash, setInitialValueSplash] = React.useState({
    preview_img: [], // Initialize with an empty array
    send_media: [],
  });
  console.log(initialValueSplash, "initialValueSplashinitialValueSplash");
  const handleChangeSplashImage = (e) => {
    const files = Array.from(e.target.files);
    const previewImages = files.map((file) => URL.createObjectURL(file));

    setInitialValueSplash((prevState) => ({
      ...prevState,
      preview_img: [...prevState.preview_img, ...previewImages],
      send_media: prevState.send_media
        ? [...prevState.send_media, ...files]
        : files,
    }));
  };

  const [productid, setProductId] = React.useState([]);
  const handleViewseting = () => {
    try {
      dispatch(setingView({ id, resid })).then((res) => {
        if (res?.payload?.status === true) {
          const data = res?.payload?.payload[0];

          setBackgroundColor(data.backgroundColor);
          setButtonColor(data.buttonColor);
          setButtonTextColor(data.buttonTextColor);
          setHeadingTextColor(data.headingTextColor);
          setSubheadingTextColor(data.subheadingTextColor);

          setTitleTextColor(data.subHeading1);
          setDesiccationTextColor(data.subHeading2);
          setRestaurantName(data?.restaurantId?.name);
          setNewId(data._id);
          console.log(data._id, "wdata._id");
          const requestOptions = {
            productID: data?.productId,
            restroId: data?.restaurantId?._id,
          };

          try {
            dispatch(Proseting(requestOptions)).then((res) => {
              // setIsLoading(true);
              if (res?.payload?.status === true) {
                setProductSelected(res?.payload?.payload);
              }
            });
          } catch (error) {
            console.log(error, "while getting data");
          }

          if (data.backgroundImage) {
            setInitialValue({
              preview_img: data.backgroundImage,
              send_media: data.backgroundImage, // Since the image is fetched, there's no need to store the file
            });
          }

          if (data.splashImage) {
            setInitialValueSplash({
              preview_img: data.splashImage,
              send_media: data.splashImage, // Since the image is fetched, there's no need to store the file
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const onregister = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append("buttonColor", buttoncolor);
    data.append("productId", id);
    data.append("restaurantId", resid);
    data.append("headingTextColor", headingtextcolor);
    data.append("subheadingTextColor", subheadingtextcolor);
    data.append("backgroundColor", backgroundcolor);
    data.append("buttonTextColor", buttontextcolor);
    data.append("subHeading1", titletextcolor);
    data.append("subHeading2", desiccationtextcolor);

    data.append("backgroundImage", initialValue.send_media);
    initialValueSplash?.send_media?.map(
      (splash) => (
        console.log(splash, "splash"), data.append(`splashImage`, splash)
      )
    );

    let requestOptions = {
      id: id,
      requestOption: {
        method: "post",
        body: data,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      },
    };
    dispatch(setingEdit(requestOptions, id))
      .then((res) => {
        if (res?.payload?.status == true) {
          setIsLoading(true);
          toast.success(res?.payload?.message);
          // navigate("/Restaurants");
          handleClose();
          console.log("res?.payload?", res?.payload);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
    // console.log("Form submitted:", values);

    if (selectedProduct) {
      const selectedProductIds = selectedProduct?.map((res) => res?.value);
      const newFormData = new FormData();

      const sharedSettings = {
        productId: id,
        restaurantId: resid,
        buttonColor: buttoncolor,
        headingTextColor: headingtextcolor,
        subheadingTextColor: subheadingtextcolor,
        backgroundColor: backgroundcolor,
        buttonTextColor: buttontextcolor,
        subHeading1: titletextcolor,
        subHeading2: desiccationtextcolor,
        backgroundImage: initialValue.send_media,
        splashImage: initialValueSplash?.send_media?.map((splash) => splash),
      };

      newFormData.append("productId", "666185bc8b01f32a82583672");
      newFormData.append("sharedSettings", JSON.stringify(sharedSettings));

      let requestOptionsProduct = {
        method: "POST",
        body: newFormData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      };

      dispatch(AllproductSating(requestOptionsProduct))
        .then((res) => {
          if (res?.payload?.status == true) {
            setselectedProduct([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching test data:", error);
          // Handle error if needed
        });
    }
  };

  const allProductlist = Array.isArray(productselected)
    ? productselected.map((item) => ({
        label: item?.serialNo,
        value: item?._id,
      }))
    : [];

  // const onprolist = (e) => {
  //   e.preventDefault();
  //   const data = new FormData();
  //   // Append form values

  //   selectedProductId.forEach((id) => data.append("_id", id));
  //   // data.append("title", "Kiose");
  //   // data.append("desiccation", "Kiose");
  //   // Array to store all fetch promises
  //   data.append(
  //     "sharedSettings",
  //     JSON.stringify({
  //       buttonColor: buttoncolor,
  //       headingTextColor: headingtextcolor,
  //       subheadingTextColor: subheadingtextcolor,
  //       backgroundColor: backgroundcolor,
  //       buttonTextColor: buttontextcolor,
  //     })
  //   );

  //   data.append("backgroundImage", initialValue.send_media);
  //   initialValueSplash?.send_media?.map(
  //     (splash) => (
  //       console.log(splash, "splash"), data.append(`splashImage`, splash)
  //     )
  //   );

  //   console.log("data", data);

  //   dispatch(AllproductSating(data))
  //     .then((res) => {
  //       if (res?.payload?.status == true) {
  //         // navigate("/Restaurants");

  //         console.log("res?.payload?", res?.payload);
  //       } else {
  //         toast.error(res?.payload?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching test data:", error);
  //       // Handle error if needed
  //     })
  //     .finally(() => {
  //       setIsLoading(false); // Set loading to false after data fetching completes
  //     });
  //   // console.log("Form submitted:", values);
  // };
  const handleRemoveImage = (index, imgSrc) => {
    console.log("index", index);
    const updatedPreviewImages = [...initialValueSplash.preview_img];
    const updatedSendMedia = initialValueSplash.send_media
      ? [...initialValueSplash.send_media]
      : [];

    // Check if the index is within the bounds of the arrays
    if (index >= 0 && index < updatedPreviewImages.length) {
      // Remove the image at the specified index
      updatedPreviewImages.splice(index, 1);

      // Remove the corresponding media file if it exists
      if (index < updatedSendMedia.length) {
        updatedSendMedia.splice(index, 1);
      }
    }

    // Perform any other actions with the updated arrays, such as updating state
    // For example, if you are using React, you might update the state like this:
    setInitialValueSplash({
      ...initialValueSplash,
      preview_img: updatedPreviewImages,
      send_media: updatedSendMedia,
    });

    let requestOptions = {
      productId: id,
      urlToRemove: imgSrc,
      id: newid,
    };

    try {
      dispatch(Removeimg(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (selectedOptions) => {
    setselectedProduct(selectedOptions);
    const valuesArray = selectedOptions?.map((option) => option?.value);
    setselectedProductid(valuesArray);
    // console.log("selectedOptions", selectedOptions);
  };

  return (
    <div>
      <button
        onClick={() => {
          handleOpen();
        }}
        className="edit_brach_btn bg-gray"
      >
        <img src={seting} />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Kiosk Setting</h1>
              </div>
              <div className="modal_form_container">
                {/* <Formik
                  // validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={onregister}
                >
                  {(formik) => (
                  <Form> */}
                <div className="loader_icon">
                  {isLoading && (
                    <>
                      <Vortex
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="vortex-loading"
                        wrapperStyle={{}}
                        wrapperClass="vortex-wrapper"
                        colors={[
                          "#174B7C",
                          "#174B7C",
                          "#174B7C",
                          "#174B7C",
                          "#174B7C",
                          "#174B7C",
                        ]}
                      />
                    </>
                  )}
                </div>
                <form onSubmit={onregister}>
                  <div className="field_cont  custom_form">
                    <div className="row">
                      <div className="col-md-6 d-flex flex-column mb-2">
                        <label htmlFor="ButtonColor">
                          Button Color
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="color_div">
                          <input
                            type="text"
                            Placeholder="Button Color"
                            id="ButtonColor2text"
                            name="ButtonColor"
                            value={buttoncolor ? buttoncolor : "#ffffff"}
                            style={{ width: "100%" }}
                          />
                          <div className="colormenu">
                            <div
                              className="colordiv"
                              style={{
                                backgroundColor: buttoncolor
                                  ? buttoncolor
                                  : "#ffffff",
                              }}
                            ></div>

                            <input
                              type="color"
                              Placeholder="Button Color"
                              id="ButtonColornew"
                              onChange={(e) => colorchange(e)}
                              className="color_change"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex flex-column mb-2">
                        <label htmlFor="backgroundcolor">
                          Background Color
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="color_div">
                          <input
                            type="text"
                            Placeholder="Button Color"
                            id="ButtonColor2"
                            name="backgroundcolor"
                            value={
                              backgroundcolor ? backgroundcolor : "#4d37b9"
                            }
                            style={{ width: "100%" }}
                          />
                          <div className="colormenu">
                            <div
                              className="colordiv"
                              style={{
                                backgroundColor: backgroundcolor
                                  ? backgroundcolor
                                  : "#4d37b9",
                              }}
                            ></div>

                            <input
                              type="color"
                              Placeholder="Button Color"
                              id="BackgroundColor"
                              onChange={(e) => Backgroundcolorchange(e)}
                              className="color_change"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>

                        {/* <ErrorMessage
                            component="p"
                            name="RestaurantName"
                            className="text-danger"
                          /> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label htmlFor="Buttontextcolor">
                            Button Text Color
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <div className="color_div">
                            <input
                              type="text"
                              Placeholder="Button Color"
                              id="Buttontextcolor"
                              name="Buttontextcolor"
                              value={
                                buttontextcolor ? buttontextcolor : "#F27A21"
                              }
                              style={{ width: "100%" }}
                            />
                            <div className="colormenu">
                              <div
                                className="colordiv"
                                style={{
                                  backgroundColor: buttontextcolor
                                    ? buttontextcolor
                                    : "#F27A21",
                                }}
                              ></div>

                              <input
                                type="color"
                                Placeholder="Button Color"
                                id="Buttontextcolorall"
                                onChange={(e) => buttoncolorchange(e)}
                                className="color_change"
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="headingButtontextcolor">
                            Heading Text Color
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <div className="color_div">
                            <input
                              type="text"
                              Placeholder="Button Color"
                              id="headingcolor"
                              name="headingButtontextcolor"
                              value={
                                headingtextcolor ? headingtextcolor : "#174B7C"
                              }
                              style={{ width: "100%" }}
                            />
                            <div className="colormenu">
                              <div
                                className="colordiv"
                                style={{
                                  backgroundColor: headingtextcolor
                                    ? headingtextcolor
                                    : "#174B7C",
                                }}
                              ></div>

                              <input
                                type="color"
                                Placeholder="Button Color"
                                id="headingcolorall"
                                onChange={(e) => Headingcolorchange(e)}
                                className="color_change"
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="subButtontextcolor">
                            Subheading text Color
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <div className="color_div">
                            <input
                              type="text"
                              Placeholder="Button Color"
                              id="subcolor"
                              name="subButtontextcolor"
                              value={
                                subheadingtextcolor
                                  ? subheadingtextcolor
                                  : "#454545"
                              }
                              style={{ width: "100%" }}
                            />
                            <div className="colormenu">
                              <div
                                className="colordiv"
                                style={{
                                  backgroundColor: subheadingtextcolor
                                    ? subheadingtextcolor
                                    : "#454545",
                                }}
                              ></div>

                              <input
                                type="color"
                                Placeholder="Button Color"
                                id="subcolorall"
                                onChange={(e) => Subheadingcolorchange(e)}
                                className="color_change"
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="subHeading1">SubHeading 1</label>
                          <div className="color_div">
                            <input
                              type="text"
                              Placeholder="subHeading1 Text"
                              id="subHeading1"
                              name="subHeading1"
                              value={titletextcolor}
                              onChange={(e) =>
                                setTitleTextColor(e.target.value)
                              }
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="subHeading2">SubHeading 2</label>
                          <div className="color_div">
                            <input
                              type="text"
                              Placeholder="subHeading2 Text"
                              id="subHeading2"
                              value={desiccationtextcolor}
                              onChange={(e) =>
                                setDesiccationTextColor(e.target.value)
                              }
                              name="subHeading2"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 d-flex flex-row     justify-content-between">
                        <div className="form-group-first">
                          <label>Background Image </label>
                          <div className="profile_img_container ">
                            <img
                              src={
                                initialValue?.preview_img
                                  ? initialValue?.preview_img
                                  : default_image
                              }
                              className="preview_profile_image"
                            />
                            <div className="btn_img">
                              <button className="upload_image_btn">
                                Upload Image
                              </button>
                              <input
                                type="file"
                                accept=".jpg , .jpeg , .png"
                                onChange={handleChangeImage}
                                className="profile_upload_input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group-first">
                          <label>Splash Images</label>
                          <div className="profile_img_container Splash_img">
                            {initialValueSplash.preview_img.length > 0 ? (
                              initialValueSplash.preview_img.map(
                                (imgSrc, index) => (
                                  <div
                                    key={index}
                                    className="preview_image_wrapper"
                                  >
                                    <img
                                      src={imgSrc}
                                      className="preview_profile_image"
                                      alt={`Splash Image ${index + 1}`}
                                    />
                                    <button
                                      className="remove_image_button"
                                      onClick={() =>
                                        handleRemoveImage(index, imgSrc)
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                )
                              )
                            ) : (
                              <img
                                src={default_image}
                                className="preview_profile_image"
                                alt="Default Image"
                              />
                            )}
                          </div>

                          <div className="btn_img">
                            <button className="upload_image_btn">
                              Upload Images
                            </button>
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleChangeSplashImage}
                              className="profile_upload_input"
                              multiple
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 ">
                        <div className="form-group-first">
                          <label>
                            All Setting Apply For all system for{" "}
                            {restaurantname ? restaurantname : "No Restaurant"}{" "}
                          </label>
                        </div>

                        <div className="">
                          <MultiSelect
                            options={allProductlist}
                            value={selectedProduct}
                            onChange={handleChange}
                            labelledBy="Select Product"
                            overrideStrings={{
                              selectSomeItems: "Select Product",
                              allItemsAreSelected: "All Product are selected",
                            }}
                            // isDisabled={filddisble}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal_btn_container">
                    <div
                      className="modal_cancel_btn"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </div>
                    <button
                      className="modal_cancel_btn"
                      type="submit"
                      // disabled={disableSubmitBtn == true}
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {/* </Form>
                </Formik> */}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
