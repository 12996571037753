import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import Topheader from "../Topcart/topcart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import man from "../../../../../../img/man.svg";
import dis from "../../../../../../img/dis.svg";
import card from "../../../../../../img/card.svg";
import gift from "../../../../../../img/gift.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import tip from "../../../../../../img/tip.svg";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Removeorder from "../Removeorder/removeorder";
import "./myorder.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  myorderlist,
  myallorderRemove,
} from "../../../../../../Helper/Redux/API";
import gift2 from "../../../../../../img/clen.svg";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: 5,
  overflow: "auto",
  height: "60%", // Added height constraint
};
const Myoder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(
    location.state?.isChecked === true ? true : false || {}
  );
  const handleClose = () => setOpen(false);
  const handleopan = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const [tipprice, setTipPrice] = useState(0);
  const { totalprice } = location.state || {};
  // const { requestOptions } = location.state || {};
  // const Datalist = requestOptions;
  const [myallorder, setMyallorder] = useState("");
  const [comporder, setCompOrder] = useState("");
  const [Removeall, setRemoveAll] = useState("");
  const [servicechargeprice, setServiceChargePrice] = useState("");
  const [vatprice, setVATPrice] = useState(false);
  const [serviceapply, setServiceApply] = useState("");
  const [allproduct, setAllProduct] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [priceAmount, setPriceAmount] = useState("");
  const [activeTip, setActiveTip] = useState(null);
  const initialValues = {
    name: "",
    Email: "",
    DiscountCode: "",
  };
  const validationSchema = yup.object({
    name: yup.string().required(" User name is required"),
  });
  const backbtn = () => {
    const id = Removeall;

    try {
      dispatch(myallorderRemove(id)).then((res) => {
        if (res?.payload?.status === true) {
          // getmycartListData();

          setOpen(false);
          navigate("/ordermanagement/mycart");
        } else {
          //   setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getmycartListData = () => {
    // setIsLoading(true);

    dispatch(myorderlist())
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllProduct(res?.payload?.payload[0].Data);
          setMyallorder(res?.payload?.payload[0].Data);
          const orderTotalPrice =
            Number(res?.payload?.payload[0]?.OrdertotalPrice) || 0;
          const orderserviePrice =
            Number(res?.payload?.payload[0]?.ServiceCharge) || 0;

          const orderPrice = Number(res?.payload?.payload[0]?.VAT) || 0;
          setTotalAmount(res?.payload?.payload[0]?.OrdertotalPrice);
          const percentage = (orderTotalPrice * orderserviePrice) / 100;
          const percentage2 = (orderTotalPrice * orderPrice) / 100;

          if (res?.payload?.payload[0]?.ServiceChargeapply === true) {
            const result = orderTotalPrice + percentage2;
            const percentservice = (result * orderserviePrice) / 100;
            setServiceChargePrice(percentservice);
            const result2 = result + percentservice;
            setCompOrder(result2);
          } else {
            const result = orderTotalPrice + percentage + percentage2;
            setCompOrder(result);
            setServiceChargePrice(percentage);
          }
          setVATPrice(percentage2);
          // setCompOrder(res?.payload?.payload[0].OrdertotalPrice + "3");
          setRemoveAll(res?.payload?.payload[0]._id);

          setServiceApply(res?.payload?.payload[0]?.ServiceChargeapply);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getmycartListData();
  }, []);
  // console.log("kapil", myallorder, "matre", requestOptions);

  const removecartitem = () => {
    // setMyCartData((prevState) =>
    //   prevState.filter((item) => item._id !== removeid)
    // );
    const id = Removeall;

    try {
      dispatch(myallorderRemove(id)).then((res) => {
        if (res?.payload?.status === true) {
          getmycartListData();

          setOpen(false);
          navigate("/ordermanagement/orderadd");
        } else {
          //   setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Vieworder = (values, { resetForm }) => {
    const requestOptions = {
      name: values.name,
      Email: values.Email,
      type: "web",
      // email: values.email,
      tip: tipprice,
      price: comporder + tipprice,
      // customerId: "",
      restaurantId: localStorage.getItem("id"),
      completeOrder: allproduct,
      vatprice: vatprice,
      servicechargeprice: servicechargeprice,
    };
    console.log(
      "requestOptionsrequestOptionsrequestOptionsrequestOptions",
      requestOptions
    );
    // console.log("kapilll", JSON.stringify(requestOptions));
    navigate("/ordermanagement/pay", { state: { requestOptions } });
  };

  const incrementTipprice = () => {
    setTipPrice(tipprice + 1);
    setActiveTip(null);
  };

  // Function to decrement tipprice
  const decrementTipprice = () => {
    if (tipprice > 1) {
      // Change from `> 0` to `> 1` for safe decrement
      setTipPrice(tipprice - 1);
      setActiveTip(null); // Clear active tip when manually adjusting tip price
    } else {
      setTipPrice(0); // Ensure tipprice doesn't go below 0
    }
  };
  const handleTipPercentage = (percentage) => {
    const calculatedTip = (totalAmount * percentage) / 100;
    setTipPrice(calculatedTip);
    setActiveTip(percentage);
  };

  console.log("tipprice", tipprice);

  return (
    <div>
      {" "}
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection Myorder">
          <Topheader heding_text="MY Order" />
          <div className="container-fluid">
            <div className="mb-3">
              <a className="Back_brn" onClick={backbtn}>
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </a>
            </div>
            <div
              className="custom-control pl-0 custom-switch text-end mt-4 mb-4"
              // onClick={(e) => setIsChecked(e.target.checked)}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                checked={isChecked == true}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label
                className={`custom-control-label ${
                  isChecked === true ? "eat-in" : "take-away"
                }`}
                for="customSwitch1"
              ></label>
              <span
                className={
                  isChecked == true
                    ? "custom_input_label_left"
                    : " custom_input_label_right"
                }
              >
                {" "}
                {isChecked == true ? "Take A" : " Eat In"}
              </span>
            </div>

            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={Vieworder}
            >
              <Form>
                <div className="Detailsuser">
                  <div className="Yourdetails">
                    {" "}
                    <img src={man} alt="man" />
                    Your Details
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-6 d-flex flex-column">
                      <label htmlFor="name">
                        Enter Your Name
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        Placeholder="Enter Your Name"
                        id="name"
                        name="name"
                      />
                      <ErrorMessage
                        component="p"
                        name="name"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                      <label htmlFor="Email">
                        Enter Your Email Id{" "}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <Field
                        type="Email"
                        Placeholder="Enter Your Email"
                        id="Email"
                        name="Email"
                      />
                      <ErrorMessage
                        component="p"
                        name="Email"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>

                <div className="Detailsuser">
                  <div className="Yourdetails">
                    {" "}
                    <img src={dis} alt="man" />
                    Discount Code
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-12 d-flex flex-column">
                      <label htmlFor="DiscountCode">
                        Enter Your Discount Code
                      </label>
                      <div className="appcupan">
                        {" "}
                        <Field
                          type="text"
                          Placeholder="Enter Your Discount Code"
                          id="DiscountCode"
                          name="DiscountCode"
                        />
                        <button type="button" className="Apply">
                          Apply
                        </button>
                      </div>

                      <ErrorMessage
                        component="p"
                        name="DiscountCode"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
                <div className="GiftCard">
                  <div className="Card_list">
                    <div className="card_img">
                      <img src={card} alt="man" /> Gift Card
                    </div>
                    <button type="button" className="btn btncard">
                      ADD +
                    </button>
                  </div>
                </div>

                <div className="GiftCard">
                  <div className="Card_list">
                    <div className="card_img">
                      <img src={gift} alt="man" /> Add Disposable Cutlery
                    </div>
                    <BootstrapSwitchButton
                      checked={true}
                      onlabel="On"
                      onstyle="success"
                      offlabel="Off"
                    />
                  </div>
                </div>

                <div className="GiftCard">
                  <div className="Card_list">
                    <div className="card_img">
                      <img src={tip} alt="man" /> Tip
                    </div>
                    <div className="card_input">
                      <button className="Minus_btn" onClick={decrementTipprice}>
                        -
                      </button>
                      <span>£{parseFloat(tipprice).toFixed(2)}</span>
                      <button className="Minus_btn" onClick={incrementTipprice}>
                        +
                      </button>
                    </div>
                  </div>
                  <p>
                    Tipping helps our staff be rewarded for great services and
                    helps maintain a jolly environment
                  </p>
                  <div className="AddTip">
                    <h5>Add a Tip</h5>
                    <ul>
                      <li
                        className={activeTip === 0 ? "active" : ""}
                        onClick={() => handleTipPercentage(0)}
                      >
                        <span>No</span> <span>Tip</span>
                      </li>
                      <li
                        className={activeTip === 10 ? "active" : ""}
                        onClick={() => handleTipPercentage(10)}
                      >
                        <span>10%</span>{" "}
                        <span>£ {(totalAmount * 10) / 100}</span>
                      </li>
                      <li
                        className={activeTip === 20 ? "active" : ""}
                        onClick={() => handleTipPercentage(20)}
                      >
                        <span>20%</span>{" "}
                        <span>£ {(totalAmount * 20) / 100}</span>
                      </li>
                      <li
                        className={activeTip === 30 ? "active" : ""}
                        onClick={() => handleTipPercentage(30)}
                      >
                        <span>30%</span>{" "}
                        <span>£ {(totalAmount * 30) / 100}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5
                        className="mb-0 d-flex justify-content-between align-items-center"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <button className="btn btn-link">Order Summary</button>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <ul>
                          {Array.isArray(myallorder) &&
                            myallorder.map((item, i) => (
                              <div key={i}>
                                <li>
                                  <h6>{item.item_name}</h6>
                                  <p>
                                    £{parseFloat(item.totalPrice).toFixed(2)}
                                  </p>
                                </li>
                              </div>
                            ))}

                          {/* <li>
                            <h6>Tip</h6>
                            <p>£1.00</p>
                          </li> */}
                        </ul>

                        <ul className="Discoun_p">
                          <h5>Discount, VAT & Service Charges,Tip </h5>

                          <li>
                            <h6>Tip</h6>
                            <p>
                              £
                              {parseFloat(tipprice).toFixed(2)
                                ? parseFloat(tipprice).toFixed(2)
                                : "0"}
                            </p>
                          </li>
                          <li>
                            <h6>VAT</h6>
                            <p>
                              £
                              {parseFloat(vatprice).toFixed(2)
                                ? parseFloat(vatprice).toFixed(2)
                                : "0"}{" "}
                            </p>
                          </li>
                          <li>
                            <h6>Service Charge</h6>
                            <p>
                              £
                              {parseFloat(servicechargeprice).toFixed(2)
                                ? parseFloat(servicechargeprice).toFixed(2)
                                : "0"}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="Price_div">
                        <h5>Amount</h5>{" "}
                        <p>
                          £
                          {parseFloat(comporder + tipprice).toFixed(2)
                            ? parseFloat(comporder + tipprice).toFixed(2)
                            : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="view-order">
                  <button type="submit">
                    {" "}
                    Place Order £
                    {parseFloat(comporder + tipprice).toFixed(2)
                      ? parseFloat(comporder + tipprice).toFixed(2)
                      : "0"}{" "}
                  </button>
                  <button type="submit" onClick={handleopan}>
                    Cancel Order
                  </button>
                </div>
                {/* <div className="Save_div">
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, Duis aute irure dolor{" "}
                </div> */}
              </Form>
            </Formik>
          </div>

          {/* <Removeorder /> */}
        </section>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="modal_body_container removeoder">
                <div className="bg_img">
                  <img src={gift2} alt="man" className="img-fluid" />
                </div>
                <div className="modal_heading">
                  {" "}
                  <h5>
                    Are you sure want to <br /> cancel your order?
                  </h5>
                </div>
                <div className="modal_form_container">
                  <div className="close_btn">
                    <button onClick={removecartitem}>Yes</button>
                    <button onClick={handleClose}>No, Resume</button>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default Myoder;
