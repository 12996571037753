import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/team.svg";
import whitedelete from "../../../../../img/white-delete.svg";
import whiteedit from "../../../../../img/white-edit.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./list.css";
import {
  designationList,
  designationDelete,
  designationUpdate,
  Profilelist,
} from "../../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import swal from "sweetalert";

const Designationlist = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allRole, setAllRole] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [updateAPI, setUpdateAPI] = useState(1);

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [totalCounts, setTotalCounts] = useState("");
  const [Profiledata, setProfiledata] = useState("");
  const [isperfectuser, setIsPerfectUser] = useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
          setProfiledata(permissiondata.permission);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  let canViewDesignations = false;
  let canEditDesignations = false;
  let canDeleteDesignations = false;
  let canCreactDesignations = false;

  if (roletype !== "2") {
    canViewDesignations = true;
    canEditDesignations = true;
    canDeleteDesignations = true;
    canCreactDesignations = true;

    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const DesignationsPermission = userpermissions?.find(
      (permission) => permission.name === "Add Designations"
    );

    if (DesignationsPermission) {
      canViewDesignations = DesignationsPermission.View || false;
      canCreactDesignations = DesignationsPermission.Create || false;
      canEditDesignations = DesignationsPermission.Edit || false;
      canDeleteDesignations = DesignationsPermission.Delete || false;
    }
  }

  const getDesignationList = () => {
    setIsLoading(true);
    const requestOption = {
      page: page,
      search: search,
      limit: limit,
    };
    dispatch(designationList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRole(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getDesignationList();
  }, [page, limit, search, updateAPI]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  // const handleDeleteDesignation = (id) => {
  //   try {
  //     dispatch(designationDelete(id)).then((res) => {
  //       if (res?.payload?.status === true) {
  //         toast.success(res?.payload?.message);
  //         setAllRole(allRole.filter((branch) => branch._id !== id));
  //       } else {
  //         toast.error(res?.payload?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeleteDesignation = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(designationDelete(id)).then((res) => {
          if (res?.payload?.status === true) {
            toast.success(res?.payload?.message);
            setAllRole(allRole.filter((branch) => branch._id !== id));
          } else {
            toast.error(res?.payload?.message);
          }
        });

        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const handleStatusUpdate = (status, id) => {
    const requestOption = {
      id: id,
      data: {
        isActive: !status,
      },
    };
    try {
      dispatch(designationUpdate(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          getDesignationList();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Designation" img_icon={Firedark} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                {/* <div className="card shadow-sm card-custom_box h-100 py-3">
                  <div className="card-body">
                    <div className="row card-custom_box_row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="h5 mb-0 ">Total Designation</div>
                      </div>
                      <div className="col-auto">
                        <h4 className="count-text">
                          <img className="img-fluid" src={userimg} />
                          <span>{totalCounts}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="col-lg-4 col-md-12 mb-4">
                {roletype === "2" ? (
                  <>
                    {canCreactDesignations ? (
                      <>
                        {" "}
                        <Link to="/team-management/designation/create">
                          <div className="card card-custom_box h-100 py-3 bg-blue">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mt-2">
                                  <div className="h5 mb-0 text-center">
                                    Add New Designation
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Link to="/team-management/designation/create">
                      <div className="card card-custom_box h-100 py-3 bg-blue">
                        <div className="card-body">
                          <div className="row card-custom_box_row no-gutters align-items-center">
                            <div className="col mt-2">
                              <div className="h5 mb-0 text-center">
                                Add New Designation
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>

            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      {/* <div className="d-flex align-items-center justify-content-between mb-4 pe-2 "> */}
                      <h6 class="text-navy-blue mb-2">Designation</h6>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length "
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter customSearch"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}

                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            // style={{ width: "125%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "300px;" }}
                                >
                                  Sr. No.
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "300px" }}
                                >
                                  Designation
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  style={{ width: "300px" }}
                                >
                                  Status
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  style={{ width: "250px", whiteSpace: "pre" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {canViewDesignations ? (
                              <tbody>
                                {allRole && allRole?.length == 0 ? (
                                  // Render a loader while data is being fetched
                                  <tr>
                                    <td
                                      colSpan="5"
                                      style={{ textAlign: "center" }}
                                    >
                                      <h3>Empty List</h3>
                                    </td>
                                  </tr>
                                ) : (
                                  allRole.map((item, index) => (
                                    <>
                                      <tr className="odd" key={item?.index}>
                                        <td className="sorting_1">
                                          {index + 1}
                                        </td>
                                        <td> {item?.designationName}</td>

                                        {roletype === "2" ? (
                                          <>
                                            {" "}
                                            {isperfectuser === true &&
                                            canEditDesignations ? (
                                              <td
                                                className={
                                                  item.isActive
                                                    ? "text-success"
                                                    : "text-danger"
                                                }
                                                data-toggle="tooltip"
                                                title="Change status"
                                                onClick={() =>
                                                  handleStatusUpdate(
                                                    item.isActive,
                                                    item._id
                                                  )
                                                }
                                              >
                                                {item.isActive
                                                  ? "Active"
                                                  : "Inactive"}
                                              </td>
                                            ) : (
                                              <td
                                                className={
                                                  item.isActive
                                                    ? "text-success"
                                                    : "text-danger"
                                                }
                                                data-toggle="tooltip"
                                                title="Change status"
                                              >
                                                {item.isActive
                                                  ? "Active"
                                                  : "Inactive"}
                                              </td>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <td
                                              className={
                                                item.isActive
                                                  ? "text-success"
                                                  : "text-danger"
                                              }
                                              data-toggle="tooltip"
                                              title="Change status"
                                              onClick={() =>
                                                handleStatusUpdate(
                                                  item.isActive,
                                                  item._id
                                                )
                                              }
                                            >
                                              {item.isActive
                                                ? "Active"
                                                : "Inactive"}
                                            </td>
                                          </>
                                        )}

                                        <td>
                                          <ul className="action-btns d-flex gap-2">
                                            {roletype === "2" ? (
                                              <>
                                                {canEditDesignations ? (
                                                  <>
                                                    {" "}
                                                    <li>
                                                      <button
                                                        className="bg-blue delete_btn"
                                                        onClick={() =>
                                                          navigate(
                                                            `/team-management/designation/edit/${item?._id}`
                                                          )
                                                        }
                                                      >
                                                        <img src={whiteedit} />
                                                      </button>
                                                    </li>
                                                  </>
                                                ) : null}
                                              </>
                                            ) : (
                                              <>
                                                <li>
                                                  <button
                                                    className="bg-blue delete_btn"
                                                    onClick={() =>
                                                      navigate(
                                                        `/team-management/designation/edit/${item?._id}`
                                                      )
                                                    }
                                                  >
                                                    <img src={whiteedit} />
                                                  </button>
                                                </li>
                                              </>
                                            )}

                                            {roletype === "2" ? (
                                              <>
                                                {canDeleteDesignations ? (
                                                  <>
                                                    {" "}
                                                    <li>
                                                      <button
                                                        className="bg-orange delete_btn"
                                                        onClick={() =>
                                                          handleDeleteDesignation(
                                                            item?._id
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={whitedelete}
                                                        />
                                                      </button>
                                                    </li>
                                                  </>
                                                ) : null}
                                              </>
                                            ) : (
                                              <>
                                                <li>
                                                  <button
                                                    className="bg-orange delete_btn"
                                                    onClick={() =>
                                                      handleDeleteDesignation(
                                                        item?._id
                                                      )
                                                    }
                                                  >
                                                    <img src={whitedelete} />
                                                  </button>
                                                </li>
                                              </>
                                            )}
                                          </ul>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                                )}
                              </tbody>
                            ) : (
                              "No View Permission"
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="row  align-items-center justify-content-between">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to {totalCounts} of{" "}
                            {allRole?.length ? allRole?.length : "0"} entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Designationlist;
