import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/od.png";
import userimg from "../../../../../img/users-icon.svg";
import whitedelete from "../../../../../img/white-delete.svg";
import whiteeye from "../../../../../img/white-eye.svg";
import whiteedit from "../../../../../img/white-edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
// import img1 from '../../../../../img/payment module/Group 128.png'
// import img2 from '../../../../../img/payment module/Group 129.jpg'
// import { FaAlignJustify } from "react-icons/fa";
// import { FaBorderAll } from "react-icons/fa";
import image1 from "../../../../../img/icon3.png";
import image2 from "../../../../../img/icon2.png";

import "./list.css";

import {
  RestaurantEdit,
  Finalorderlist,
  RestaurantDELETE,
  Profilelist,
  Resstatus,
  selectedOrder,
} from "../../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Vortex } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const List = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allrestaurant, setAllRestaurant] = useState("");
  const [allrestaurantlength, setAllRestaurantLength] = useState("");
  const [totalCounts, setTotalCounts] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalRestaurants, setTotalRestaurants] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [Profiledata, setProfiledata] = useState();
  const [isperfectuser, setIsPerfectUser] = useState("");
  const [img, setImg] = useState("");
  // const [statusValue,setStatusValue] = useState(localStorage.getItem(`orderStatus-${item._id}`) || 'Pending');
  // const [statusValue,setStatusValue] = useState('Pending');

  const [orderStatuses, setOrderStatuses] = useState({});
  const [selectedImage, setSelectedImage] = useState("image1");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    if (image === "image2") {
      navigate("./orderlist");
    }
  };

  useEffect(() => {
    const storedStatuses =
      JSON.parse(localStorage.getItem("orderStatuses")) || {};
    setOrderStatuses(storedStatuses);
  }, []);

  const userpermissions = Profiledata ? Profiledata : [];

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
          setProfiledata(permissiondata.permission);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  console.log("isperfectuser", isperfectuser);

  let canViewOrder = false;
  let canEditOrder = false;
  let canDeleteOrder = false;
  let canCreactOrder = false;

  if (roletype !== "2") {
    canViewOrder = true;
    canEditOrder = true;
    canDeleteOrder = true;
    canCreactOrder = true;

    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const OrderPermission = userpermissions?.find(
      (permission) => permission.name === "Order Placement"
    );

    if (OrderPermission) {
      canViewOrder = OrderPermission.View || false;
      canCreactOrder = OrderPermission.Create || false;
      canEditOrder = OrderPermission.Edit || false;
      canDeleteOrder = OrderPermission.Delete || false;
    }
  }
  const reId = localStorage.getItem("id");
  console.log("profileDataprofileData000", userpermissions);
  const getRestaurantListData = () => {
    // setIsLoading(true);

    const requestOption = {
      page: page,
      search: search,
      limit: limit,
      Rid: roletype === 1 || roletype == 2 ? reId : "",
      id: "",
    };

    dispatch(Finalorderlist(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setTotalCounts(res?.payload?.totalCount);
          if (roletype === 2 && roletype === 1) {
            setImg(res?.payload?.payload?.image);
          }
          setAllRestaurantLength(res?.payload.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getRestaurantListData();
  }, [page, limit, search]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };

  const Editrestaurant = (item) => {
    console.log("itemeditres", item);
    const isperfect = isperfectuser;
    navigate("/restaurants/edit-restaurant", { state: { item, isperfect } });
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  const handleStatusUpdate = (status, id) => {
    const data = new FormData();
    data.append("isActive", !status);
    // Append image data if available

    let requestOptions = {
      id: id,
      requestOption: {
        method: "put",
        body: data,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      },
    };

    dispatch(RestaurantEdit(requestOptions)).then((res) => {
      if (res?.payload?.status == true) {
        toast.success(res?.payload?.message);
        getRestaurantListData();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleDELETE = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(RestaurantDELETE(id)).then((res) => {
          if (res?.payload?.status == true) {
            toast.success(res?.payload?.message);
            getRestaurantListData();
          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const [inputValue, setInputValue] = useState("");

  const showSwal = () => {
    withReactContent(Swal).fire({
      title: <i>Are You Deleted Restaurant</i>,
      // input: "text",
      // inputValue,
      preConfirm: () => {
        setInputValue(Swal.getInput()?.value || "");
      },
    });
  };

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);

    gethardwarestatus(event.target.value);

    // You can perform any additional actions here based on the selected value
  };

  // const handleOrderStatus = (event) =>{
  //   getSelectedOrderStatus(event.target.value)
  // }

  const gethardwarestatus = (status) => {
    const requestOption = {
      status: status,
    };
    console.log(requestOption);
    dispatch(Resstatus(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
          setAllRestaurantLength(res?.payload.total);
          // setUpdateAPI(1);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  // useEffect(() => {
  //   // Store the selected status in localStorage whenever it changes
  //   localStorage.setItem(`orderStatus-${item._id}`, statusValue);
  // }, [statusValue, item._id]);

  const getSelectedOrderStatus = async (id, value) => {
    const requestOption = {
      orderId: id,
      orderStatus: value,
    };
    try {
      const res = await dispatch(selectedOrder(requestOption));
      if (res?.payload?.status === true) {
        toast.success(res?.payload?.message);
        setOrderStatuses((prev) => {
          const updatedStatuses = { ...prev, [id]: value };
          localStorage.setItem(
            "orderStatuses",
            JSON.stringify(updatedStatuses)
          );
          return updatedStatuses;
        });
        getRestaurantListData();
      } else {
        toast.error(res?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionStyle = (value) => {
    switch (value) {
      case "Pending":
        return { color: "#E4003A" };
      case "Preparing":
        return { color: "#914F1E" };
      case "Ready to serve":
        return { color: "#88D66C" };
      case "Completed":
        return { color: "#399918" };
      case "Canceled":
        return { color: "#FFC700" };
      default:
        return {};
    }
  };

  // const handleStatusChange = (e) =>{
  //   const newValue = e.target.value;
  //   setStatusValue(newValue);
  //   getSelectedOrderStatus(id,newValue);
  // }

  const getresetstatus = () => {
    getRestaurantListData();
    setSearch("");
    setLimit("10");
    setSelectedStatus("");
  };

  const getLastFiveDigits = (id) => {
    return id.slice(-5);
  };
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Order Management" img_icon={Firedark} />
          {/* <button onClick={showSwal}>Deleted</button> */}
          {/* <div>Your input: {inputValue}</div> */}
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div className="card shadow-sm card-custom_box h-100 py-3">
                      <div className="card-body">
                        <div className="row card-custom_box_row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="h5 mb-0 ">Total </div>
                          </div>
                          <div className="col-auto">
                            <h4 className="count-text">
                              <img className="img-fluid" src={userimg} />
                              <span> {totalCounts ? totalCounts : "0"} </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* <div className="col-lg-3 col-md-12 mb-4">
                {roletype === "2" ? (
                  <>
                    {isperfectuser === true && (
                      <>
                        <Link to="/ordermanagement/orderadd">
                          <div className="card card-custom_box h-100 py-3 bg-blue">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mt-2">
                                  <div className="h5 mb-0 text-center">
                                    ADD Order
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {roletype === "1" ? (
                      <>
                        {" "}
                        <Link to="/ordermanagement/orderadd">
                          <div className="card card-custom_box h-100 py-3 bg-blue">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mt-2">
                                  <div className="h5 mb-0 text-center">
                                    ADD Order
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div> */}
            </div>
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <h6 class="text-navy-blue mb-2">Order</h6>
                      {roletype === "0" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="status_div d-flex">
                            <div class="form-group mb-0">
                              <select
                                class="form-control"
                                id="selectstatis"
                                onChange={handleChange}
                                value={selectedStatus}
                              >
                                <option selected>Status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                            </div>

                            <button
                              className="resetbutton"
                              onClick={getresetstatus}
                            >
                              Reset
                            </button>
                          </div>
                        </>
                      )}
                      <div className="row" style={{ height: "70px" }}>
                        <div className="col-sm-12 col-md-2">
                          <div
                            className="dataTables_length"
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter list-search"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                          {/* <div className="listiconContainer col-md-2">
                            <span className="icon">
                              <FaAlignJustify />
                            </span>
                            <span
                              className="icon"
                              onClick={() => {
                                navigate("./orderlist");
                              }}
                            >
                              <FaBorderAll />
                            </span>
                          </div> */}
                          {/* #F27A21 */}
                          {/* style={{backgroundColor:'#F27A21',border:'1px solid black',borderRadius:'4px',padding:'5px'}} */}
                          <div className="listiconContainer">
                            {/* <img src={image1} className="image1"/>
                                 <img src={image2} className="image2" onClick={()=>{
                                  navigate("./orderlist")
                                 }}/> */}

                            <img
                              src={image1}
                              className={`image1 ${
                                selectedImage === "image1" ? "selected" : ""
                              }`}
                              // onClick={() => handleImageClick('image1')}
                            />
                            <img
                              src={image2}
                              className={`image2 ${
                                selectedImage === "image2" ? "selected" : ""
                              }`}
                              onClick={() => handleImageClick("image2")}
                            />
                          </div>

                          {/* <img src={image} className="listiconContainer"/> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          {/* <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "150px;", whiteSpace: "pre",padding:'8px' }}
                                >
                                  Order ID
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "480px", padding:'8px' }}
                                >
                                  Customer Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  // style="width: 204px;"
                                  style={{ width: "300px" ,padding:'8px'}}
                                >
                                  Order Time
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "400px", padding:'8px' }}
                                >
                                  Order Date
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "250px",padding:'8px' }}
                                >
                                  Order Total
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "250px",padding:'8px' }}
                                >
                                  Order Status
                                </th>
                                

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "70px",padding:'8px'}}
                                >
                                  Status
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "150px",padding:'8px' }}
                                >
                                  Payment
                                </th>
                              </tr>
                            </thead>
                            {canViewOrder ? (
                              <>
                                {" "}
                                <tbody>
                                  {isLoading ? (
                                    // Render a loader while data is being fetched
                                    <tr>
                                      <td
                                        colSpan="5"
                                        style={{ textAlign: "center" }}
                                      >
                                        <h3>Empty List</h3>
                                      </td>
                                    </tr>
                                  ) : (
                                    Array.isArray(allrestaurant) &&
                                    allrestaurant?.map((item) => (
                                      <>
                                        <tr className="odd" key={item._id}>
                                          <td className="sorting_1">
                                            {item._id}
                                          </td>
                                          <td>{item.name}</td>
                                          <td>{formatTime(item.createdAt)}</td>
                                          <td> {formatDate(item.createdAt)}</td>
                                          <td>
                                            {" "}
                                            ${parseFloat(item.price).toFixed(2)}
                                          </td>
                                          <td>
                                            {" "}
                                
                                    

                    <select
                  onChange={(e) => getSelectedOrderStatus(item._id, e.target.value)}
                  className="selectedOrder"
                  value={orderStatuses[item._id] || 'Pending'}
                  style={getOptionStyle(orderStatuses[item._id] || 'Pending')}
                >
                  <option value="Pending" style={{ color: '#E4003A' }}>Pending</option>
                  <option value="Preparing" style={{ color: '#914F1E' }}>Preparing</option>
                  <option value="Ready to serve" style={{ color: '#88D66C' }}>Ready to serve</option>
                  <option value="Completed" style={{ color: '#399918' }}>Completed</option>
                  <option value="Canceled" style={{ color: '#FFC700' }}>Canceled</option>
                </select>
                                             


                                          </td>
                                         
                                          <td className="text-capitalize">
                                            {" "}
                                            {item.paymentStatus}
                                          </td>
                                          <td className="text-capitalize">
                                            {" "}
                                            {item.paymentType}
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                  )}
                                </tbody>
                              </>
                            ) : (
                              "No View Permission"
                            )}
                          </table> */}

                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  style={{
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Order ID
                                </th>
                                <th
                                  style={{
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Customer Name
                                </th>
                                <th
                                  style={{
                                    width: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Order Time
                                </th>
                                <th
                                  style={{
                                    width: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Order Date
                                </th>
                                <th
                                  style={{
                                    width: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Order Total
                                </th>
                                <th
                                  style={{
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Order Status
                                </th>
                                <th
                                  style={{
                                    width: "70px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Status
                                </th>
                                <th
                                  style={{
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    padding: "8px",
                                  }}
                                >
                                  Payment
                                </th>
                              </tr>
                            </thead>
                            {canViewOrder ? (
                              <tbody>
                                {isLoading ? (
                                  <tr>
                                    <td
                                      colSpan="8"
                                      style={{ textAlign: "center" }}
                                    >
                                      <h3>Empty List</h3>
                                    </td>
                                  </tr>
                                ) : (
                                  Array.isArray(allrestaurant) &&
                                  allrestaurant?.map((item) => (
                                    <tr className="odd" key={item._id}>
                                      <td
                                        className="sorting_1"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        #{getLastFiveDigits(item._id)}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.name}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {formatTime(item.createdAt)}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {formatDate(item.createdAt)}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        ${parseFloat(item.price).toFixed(2)}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <select
                                          onChange={(e) =>
                                            getSelectedOrderStatus(
                                              item._id,
                                              e.target.value
                                            )
                                          }
                                          className="selectedOrder"
                                          value={
                                            orderStatuses[item._id] || "Pending"
                                          }
                                          style={getOptionStyle(
                                            orderStatuses[item._id] || "Pending"
                                          )}
                                        >
                                          <option
                                            value="Pending"
                                            style={{ color: "#E4003A" }}
                                          >
                                            Pending
                                          </option>
                                          <option
                                            value="Preparing"
                                            style={{ color: "#914F1E" }}
                                          >
                                            Preparing
                                          </option>
                                          <option
                                            value="Ready to serve"
                                            style={{ color: "#88D66C" }}
                                          >
                                            Ready to serve
                                          </option>
                                          <option
                                            value="Completed"
                                            style={{ color: "#399918" }}
                                          >
                                            Completed
                                          </option>
                                          <option
                                            value="Canceled"
                                            style={{ color: "#FFC700" }}
                                          >
                                            Canceled
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        className="text-capitalize"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.paymentStatus}
                                      </td>
                                      <td
                                        className="text-capitalize"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.paymentType}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            ) : (
                              "No View Permission"
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 10 of{" "}
                            {allrestaurantlength ? allrestaurantlength : "0"}{" "}
                            entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          {/* <Pagination
                            currentPage={page}
                            totalItems={totalRestaurants}
                            itemsPerPage={limit}
                            onPageChange={handlePageChange}
                          /> */}

                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default List;
