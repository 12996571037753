import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import card_img from "../../../../../../img/cardpay.gif";
import PayntComponent from "../../../../../../Payment/paymentmachine";
import { useDispatch } from "react-redux";
import { Paymentregistering } from "../../../../../../Helper/Redux/API";
const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: "0px 0px 5.18px 0px #00000040",
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  outline: "none",
  border: "none",
};

export default function CardModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    handleRegister();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleRegister = () => {
    const data = {
      serialNumber: "0822870690",
      pairingCallback: "https://perfecttogo.codingserver.com",
    };

    console.log("requestOptions", data);

    try {
      dispatch(Paymentregistering(data)).then((res) => {
        // setDisableSubmitBtn(false);
        console.log("res.payload", res);
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };
  return (
    <div>
      <div className="payment_card" onClick={() => handleOpen()}>
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.580755 4.27983C0.580755 2.1704 2.29079 0.460358 4.40023 0.460358H30.7546C32.864 0.460358 34.574 2.1704 34.574 4.27983V7.71735H0.580755V4.27983ZM34.5734 10.3909H0.580078V22.2313C0.580078 24.3407 2.29011 26.0508 4.39955 26.0508H30.7539C32.8633 26.0508 34.5734 24.3407 34.5734 22.2313V10.3909ZM9.74681 20.7035C9.74681 22.391 8.37878 23.759 6.69123 23.759C5.00368 23.759 3.63565 22.391 3.63565 20.7035C3.63565 19.0159 5.00368 17.6479 6.69123 17.6479C8.37878 17.6479 9.74681 19.0159 9.74681 20.7035Z"
            fill="#1B4B7C"
          />
        </svg>
        <span> Pay through card </span>{" "}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="payment_type_inner scanner_inner card_inner">
              {/* <div> */}
              <p>Swipe your card on the payment terminal below</p>
              <div className="scanner_container text-center ">
                <img src={card_img} alt="" />
              </div>
              {/* <PayntComponent> */} {/* </PayntComponent> */}
              <button className="back_btn py-3 " onClick={() => handleClose()}>
                Back
              </button>
              {/* </div> */}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
