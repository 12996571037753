import React, { useState } from "react";
import "../login.css";
import * as Yup from "yup";
import loginsvg from "../../../img/newlog.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Resetpass } from "../../../Helper/Redux/API";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Confirmpassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const newtoken = searchParams.get("token");
  const roledata = searchParams.get("role");
  const initialValues = {
    password: "",
    // email: "",
    role: roledata,
  };

  const validation = Yup.object({
    // email: Yup.string()
    //   //   .email("Invalid email address")
    //   .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    // Add token to headers
    const requestOptions = {
      body: values,
      newToken: newtoken,
    };

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `${token}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(values), // Convert form data to JSON string
    // };

    console.log("requestOption", newtoken);
    // Dispatch the action with values and headers
    dispatch(Resetpass(requestOptions)).then((res) => {
      if (res?.payload?.success === true) {
        if (roledata === 1 && roledata === 2) {
          navigate("/restaurantslogin");
        } else {
          navigate("/");
        }

        console.log("sss", res?.payload);
        toast.success(res.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="container">
          {/* Outer Row */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-6">
                <div className="card-body p-0">
                  {/* Nested Row within Card Body */}
                  <div className="row justify-content-center">
                    {/* <!-- <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> --> */}
                    <div className="col-lg-6">
                      <div className="p-5 login-inner">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">
                            Set New Password
                          </h1>
                          <img className="img-fluid mb-4" src={loginsvg} />
                        </div>

                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validation}
                        >
                          <Form className="user">
                            <div className="form-group">
                              <div className="eys_input">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  Placeholder="Set New Password"
                                  id="password"
                                  name="password"
                                  className="form-control form-control-user"
                                />

                                <span
                                  className="password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <button
                              className="btn btn-primary btn-user btn-block"
                              type="submit"
                            >
                              Save
                            </button>
                          </Form>
                        </Formik>

                        {/* <div className="text-center mt-4">
                          <Link className="small" to="/forgetpassword">
                            Forgot Password?
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmpassword;
