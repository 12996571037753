import React, { useEffect, useState } from "react";
import Topheader from "../../TopHeader/topheader";
import { useDispatch } from "react-redux";
import {
  designationList,
  tippingDetalis,
  restaurantList,
  branchrole,
} from "../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import Sidebarpage from "../../../Sidebarpage/sidebarpage";
import TotalTipping from "../../../../img/ipack4.png";
import TotalTipping3 from "../../../../img/ipack3.png";
import TotalTipping2 from "../../../../img/ipack2.png";
import TotalTipping1 from "../../../../img/ipack1.png";
import Select from "react-select";
import "../TippingDetails/tippingdetalis.css";
import Tippopup from "./TipDetailsPopup/Tippopup";
import tip from "../../../../img/tip2.svg";
import { Link, useNavigate } from "react-router-dom";
const TippingDetails = () => {
  const roletype = localStorage.getItem("roletype");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allrestaurant, setAllRestaurant] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [updateAPI, setUpdateAPI] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [totalCounts, setTotalCounts] = useState("");
  const [allRole, setAllRole] = useState([]);
  const [brachList, setBrachList] = React.useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [filddisble, setFilddisble] = useState(false);
  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);

          if (roletype == 1) {
            console.log("hhgg", res?.payload?.payload[0]);
            setSelectedRestaurant({
              value: res?.payload?.payload[0]?._id,
              label: res?.payload?.payload[0]?.name,
            });
            setFilddisble(true);
            getBraches(res?.payload?.payload[0]?._id);
            getDesignationList(res?.payload?.payload[0]?._id, "");
          }
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  useEffect(() => {
    getRestaurantListData();
  }, []);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };
  const pageCount = Math.ceil(totalCounts / limit);
  const tippingview = (id, ordername) => {
    const tippingdata = {
      order_id: id,
      ordername: ordername,
    };
    navigate("/TippingManagement/tippingview", { state: { tippingdata } });
  };

  const handleChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
    getBraches(selectedOptions.value);
    setSelectedBranch("");
    getDesignationList(selectedOptions.value, "");
  };

  const handleBranch = (selectedOptions) => {
    setSelectedBranch(selectedOptions);
    getDesignationList(selectedRestaurant.value, selectedOptions.value);
  };

  const getDesignationList = (rid, bid) => {
    console.log("ddddd ", rid);
    setIsLoading(true);
    const requestOption = {
      RId: rid,
      BId: bid,
    };
    dispatch(tippingDetalis(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          console.log("res?.payload", res?.payload?.data?.teamMembers);
          setAllRole(res?.payload?.data?.teamMembers);
          // setTotalCounts();
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Designation" img_icon={tip} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body custom_form">
                  <h6 class="text-navy-blue mb-2">Tipping Details</h6>

                  <div className="row mt-4">
                    <div className="col-md-6 d-flex flex-column mb-3">
                      <label htmlFor="Restaurant">Restaurant</label>
                      <Select
                        options={allrestaurantlist}
                        value={selectedRestaurant}
                        onChange={handleChange}
                        placeholder="Select Restaurant"
                        isDisabled={filddisble}
                      />
                    </div>
                    <div className="col-md-6 d-flex flex-column mb-3">
                      <label htmlFor="Restaurant">Branch </label>
                      <Select
                        options={allbrachList}
                        value={selectedBranch}
                        onChange={handleBranch}
                        placeholder="Select Branch"
                      />
                    </div>
                  </div>
                  <div className="row mt-3 mb-5">
                    <div className="col-md-3">
                      <div className="Tippingmenu">
                        <div className="tip_img">
                          <img
                            src={TotalTipping}
                            className="img-fluid"
                            alt="logo"
                          />
                        </div>
                        <div className="tip_Text">
                          <h5>£ 12.1</h5>
                          <p>Total Tipping</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="Tippingmenu">
                        <div className="tip_img">
                          <img
                            src={TotalTipping3}
                            className="img-fluid"
                            alt="logo"
                          />
                        </div>
                        <div className="tip_Text">
                          <h5>£ 700.7</h5>
                          <p>Todays Tipping</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="Tippingmenu">
                        <div className="tip_img">
                          <img
                            src={TotalTipping2}
                            className="img-fluid"
                            alt="logo"
                          />
                        </div>
                        <div className="tip_Text">
                          <h5>£ 12</h5>
                          <p>Tipping Settled</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="Tippingmenu">
                        <div className="tip_img">
                          <img
                            src={TotalTipping1}
                            className="img-fluid"
                            alt="logo"
                          />
                        </div>
                        <div className="tip_Text">
                          <h5>£ 08.1</h5>
                          <p>Tipping Pending</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      {/* <div className="d-flex align-items-center justify-content-between mb-4 pe-2 "> */}

                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length "
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter customSearch"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}

                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            // style={{ width: "125%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "100px;" }}
                                >
                                  S.No
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "200px;" }}
                                >
                                  Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "300px" }}
                                >
                                  Designation
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  style={{ width: "100px" }}
                                >
                                  Tipping
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  View
                                </th>
                              </tr>
                            </thead>
                            {/* {canViewDesignations ? ( */}
                            <tbody>
                              {allRole && allRole?.length == 0 ? (
                                // Render a loader while data is being fetched
                                <tr>
                                  <td
                                    colSpan="5"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3>Empty List</h3>
                                  </td>
                                </tr>
                              ) : (
                                allRole.map((item, index) => (
                                  <>
                                    <tr className="odd" key={item?.index}>
                                      <td className="sorting_1">{index + 1}</td>
                                      <td> {item?.name}</td>
                                      <td> {item?.designationName}</td>
                                      <td> {item?.designationName}</td>
                                      <td>
                                        <ul className="action-btns d-flex gap-2">
                                          <li>
                                            <button
                                              className="Details_btn"
                                              onClick={() =>
                                                tippingview(
                                                  item._id,
                                                  item.designationName
                                                )
                                              }
                                            >
                                              Details
                                            </button>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </>
                                ))
                              )}
                            </tbody>
                            {/* ) : (
                              "No View Permission"
                            )} */}
                          </table>
                        </div>
                      </div>
                      {/* <div className="row  align-items-center justify-content-between">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to {totalCounts} of{" "}
                            {allRole?.length ? allRole?.length : "0"} entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TippingDetails;
