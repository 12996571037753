import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  branchrole,
  categoryUpdate,
  categoryView,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import SmallImageUploadComponent from "../add/smallImageCropper";
import BigImageCropper from "../add/bigImageCropper";
import getCroppedImg from "../add/Cropimage";
import s3 from "../../../../../Helper/Configure/aws-config";

const EditCategory = ({ setIShowSecondPart, categoryId, setUpdateAPI }) => {
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [branches, setBranches] = useState([]);
  const [initialValue, setInitialValue] = useState({
    restaurant_name: "",
    branch_name: [],
    category: "",
    description: "",
    sub_title: "",
    sub_title2: "",
  });

  const [croppedSmallImageAreaPixels, setCroppedSmallImageAreaPixels] =
    useState(null);
  const [croppedBigImageAreaPixels, setCroppedBigImageAreaPixels] =
    useState(null);
  const [bigPreviewImg, setBigPreviewImg] = useState("");
  const [bigImgUpload, setBigImgUpload] = useState("");
  const [smallPreviewImg, setSmallPreviewImg] = useState("");
  const [smallImgUpload, setSmallImgUpload] = useState("");
  const [smallCropImg, setSmallCropImg] = useState("");
  const [bigCropImg, setBigCropImg] = useState("");

  const [oldSmallImage, setOldSmallImage] = useState("");
  const [oldBigImage, setOldBigImage] = useState("");

  const [imageError, setImageError] = useState(false);
  const [showSmallImageCropper, setShowSmallImageCropper] = useState(false);
  const [showBigImageCropper, setShowBigImageCropper] = useState(false);
  const setBlankBoth = () => {
    setSmallPreviewImg("");
    setSmallImgUpload("");
    setBigPreviewImg("");
    setBigImgUpload("");
  };
  const handleSmallFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSmallPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
      setSmallImgUpload(file);
      setShowSmallImageCropper(true);
    } else {
      console.error("No file selected");
    }
  };

  const handleBigFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBigPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
      setBigImgUpload(file);
      setShowBigImageCropper(true);
    } else {
      console.error("No file selected");
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const deleteImage = (imageKey) => {
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME + "public/category",
        Key: imageKey,
      };

      return new Promise((resolve, reject) => {
        s3.deleteObject(params, (err, data) => {
          if (err) {
            console.error("Error deleting old file:", err);
            reject(err);
          } else {
            console.log("Successfully deleted old file:", data);
            resolve(data);
          }
        });
      });
    };

    const uploadImage = (imageBlob, imageUpload) => {
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME + "public/category",
        Key: imageUpload.name,
        Body: imageBlob,
        ContentType: imageUpload.type,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            console.error("Error uploading file:", err);
            reject(err);
          } else {
            console.log("Successfully uploaded file:", data);
            resolve(data.Location);
          }
        });
      });
    };

    try {
      let smallImageUploadResponse, bigImageUploadResponse;

      // Delete old images and upload new ones if applicable
      if (smallImgUpload) {
        if (oldSmallImage) await deleteImage(oldSmallImage);
        const croppedSmallImageBlob = await getCroppedImg(
          smallPreviewImg,
          croppedSmallImageAreaPixels
        );
        smallImageUploadResponse = await uploadImage(
          croppedSmallImageBlob,
          smallImgUpload
        );
      }

      if (bigImgUpload) {
        if (oldBigImage) await deleteImage(oldBigImage);
        const croppedBigImageBlob = await getCroppedImg(
          bigPreviewImg,
          croppedBigImageAreaPixels
        );
        bigImageUploadResponse = await uploadImage(
          croppedBigImageBlob,
          bigImgUpload
        );
      }

      setIsLoading(true);
      const branches = values.branch_name.map((item) => item.value);
      const branchesArray = JSON.stringify(branches);
      const formData = new FormData();

      formData.append("restaurantId", values.restaurant_name);
      formData.append("branchIds", branchesArray);
      formData.append("title", values.category);
      formData.append("description", values.description);
      formData.append(
        "image1",
        bigImgUpload ? bigImageUploadResponse : oldBigImage
      );
      formData.append(
        "image2",
        smallImgUpload ? smallImageUploadResponse : oldSmallImage
      );

      const requestOptions = {
        id: categoryId,
        head: {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
          },
        },
      };

      const res = await dispatch(categoryUpdate(requestOptions));

      setIsLoading(false);

      if (res?.payload?.status === true) {
        toast.success(res?.payload?.message);
        resetForm();
        setUpdateAPI("edit");
        setBlankBoth();
        setIShowSecondPart("add");
      } else {
        toast.error(res?.payload?.message);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("An error occurred while updating the category.");
    }
  };

  const validationSchemas = yup.object().shape({
    restaurant_name: yup.string().required("Restaurant is required"),

    category: yup.string().required("Category is required"),

    description: yup.string().required("Description is required"),
  });

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const getCategoryDetails = async () => {
    setIsLoading(false);
    try {
      const categoryRes = await dispatch(categoryView(categoryId));
      if (categoryRes?.payload?.status === true) {
        const restaurantId = categoryRes?.payload?.payload?.restaurantId;
        const branchRes = await dispatch(branchrole({ restaurantId }));
        if (branchRes?.payload?.status === true) {
          const branchesData = branchRes?.payload?.payload;
          const branchIds = categoryRes?.payload?.payload?.branchIds || [];
          const branchOptions = branchIds
            .map((id) => {
              const option = branchesData.find((branch) => branch._id === id);
              return option ? { value: option._id, label: option.name } : null;
            })
            .filter((option) => option !== null);

          setBranches(branchesData);
          setInitialValue({
            restaurant_name: restaurantId,
            branch_name: branchOptions,
            category: categoryRes?.payload?.payload?.title,
            description: categoryRes?.payload?.payload?.description,
            sub_title: categoryRes?.payload?.payload?.subHeading1,
            sub_title2: categoryRes?.payload?.payload?.subHeading2,
          });
          setSmallPreviewImg(categoryRes?.payload?.payload?.image2);
          setOldSmallImage(categoryRes?.payload?.payload?.image2);
          setBigPreviewImg(categoryRes?.payload?.payload?.image1);
          setOldBigImage(categoryRes?.payload?.payload?.image1);

          setIsLoading(true);
        }
      } else {
        setIsLoading(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };
  useEffect(() => {
    getCategoryDetails();
  }, []);

  return (
    <section className="add_category ">
      <div className="add_category_inner">
        <h6 className="heading_name">Edit Category</h6>
        <hr />
        <div className="form_container">
          {isLoading && (
            <Formik
              initialValues={initialValue}
              onSubmit={handleSubmit}
              validationSchema={validationSchemas}
            >
              {(formik) => (
                <Form>
                  <div
                    className="field_container d-flex flex-column gap-5"
                    style={{ gap: "8px" }}
                  >
                    <div className=" d-flex flex-column">
                      <label htmlFor="Restaurant">
                        Restaurant <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        placeholder="Enter Restaurant Name "
                        id="Restaurant"
                        name="restaurant_name"
                        onChange={(e) => {
                          formik.setFieldValue(
                            "restaurant_name",
                            e.target.value
                          );
                          getBraches(e.target.value);
                        }}
                        disabled={
                          localStorage.getItem("roletype") == 1 ||
                          localStorage.getItem("roletype") == 2
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {restaurant.map((res, i) => (
                          <option value={res?._id} key={i}>
                            {res?.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="p"
                        name="restaurant_name"
                        className="text-danger"
                      />
                    </div>
                    <div className=" d-flex flex-column">
                      <label htmlFor="branch_name">Branch</label>
                      <MultiSelect
                        options={allbrachList}
                        name="branch_name"
                        labelledBy="Select"
                        value={formik.values.branch_name}
                        onChange={(e) => formik.setFieldValue("branch_name", e)}
                      />
                    </div>
                    <div className=" d-flex flex-column">
                      <label htmlFor="category">
                        Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter category Name "
                        id="category"
                        name="category"
                      />
                      <ErrorMessage
                        component="p"
                        name="category"
                        className="text-danger"
                      />
                    </div>

                    <div className=" d-flex flex-column">
                      <label htmlFor="description">
                        Description <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="textarea"
                        placeholder="Enter description  "
                        id="description"
                        name="description"
                      />
                      <ErrorMessage
                        component="p"
                        name="description"
                        className="text-danger"
                      />
                    </div>
                    <div className="row">
                      <div
                        className=" col-md-6 d-flex flex-column"
                        style={{ gap: "10px" }}
                      >
                        <label htmlFor="Restaurant" className="m-0">
                          Upload Big Image{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="category_img_cont">
                          <img
                            src={bigCropImg ? bigCropImg : generic_img}
                            alt=""
                            className="category_images"
                          />
                        </div>
                        <div className="category_img_browse_container big_image">
                          <button>Browse</button>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => handleBigFileChange(e)}
                          />
                        </div>
                        {imageError && !bigImgUpload && (
                          <p className="text-danger"> Please select Image </p>
                        )}
                      </div>
                      {showBigImageCropper && (
                        <BigImageCropper
                          imageSrc={bigPreviewImg}
                          setBigCropImg={setBigCropImg}
                          openModal={showBigImageCropper}
                          setCroppedAreaPixels={setCroppedBigImageAreaPixels}
                          setShowBigImageCropper={setShowBigImageCropper}
                        />
                      )}

                      <div
                        className=" col-md-6 d-flex flex-column "
                        style={{ gap: "10px" }}
                      >
                        <label htmlFor="Restaurant" className="m-0">
                          Upload Small Image{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="category_img_cont">
                          <img
                            src={smallCropImg ? smallCropImg : generic_img}
                            alt=""
                            className="category_images"
                          />
                        </div>
                        <div className="category_img_browse_container small_image">
                          <button>Browse</button>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => handleSmallFileChange(e)}
                          />
                        </div>
                        {imageError && !smallImgUpload && (
                          <p className="text-danger"> Please select Image </p>
                        )}
                        {showSmallImageCropper && (
                          <SmallImageUploadComponent
                            imageSrc={smallPreviewImg}
                            setSmallCropImg={setSmallCropImg}
                            openModal={showSmallImageCropper}
                            setCroppedAreaPixels={
                              setCroppedSmallImageAreaPixels
                            }
                            setShowSmallImageCropper={setShowSmallImageCropper}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="submit_btn_container mt-4 d-flex gap-3 justify-content-center align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <div
                        className="category_cancel"
                        onClick={() => setIShowSecondPart("add")}
                      >
                        Cancel
                      </div>
                      <button className="category_save">Save</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </section>
  );
};

export default EditCategory;
