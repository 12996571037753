import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAUSNMNA7T6V4ZPFU4",
  secretAccessKey: "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",
  region: "us-east-1",
});

const s3 = new AWS.S3();

export default s3;
