import React, { useEffect, useMemo, useState } from "react";
import Sidebarpage from "../../../Sidebarpage/sidebarpage";
import userimg from "../../../../img/users-icon.svg";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CheckboxGroup from "../Addrestaurant/multicheckbox/multicheckbox";
import countryCodes from "country-calling-code";
import {
  RestaurantEdit,
  ForgetpasswordRestaurant,
  ProductList,
} from "../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import Firedark from "../../../../img/Fire-dark.svg";
import Topheader from "../../TopHeader/topheader";
import default_image from "../../../../img/Union.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "73%", // Added height constraint
};

const Editrestaurant = () => {
  const api_url = process.env.REACT_APP_BASE_URL;
  const roletype = localStorage.getItem("roletype");
  const [allproduct, setAllProduct] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { item } = location.state || {};
  const { isperfect } = location.state || {};
  const [edititem, setEdititem] = useState(item);
  const [open, setOpen] = React.useState(false);
  const [filddisble, setFilddisble] = React.useState(false);
  const [isCheckedDemo, setIsCheckedDemo] = useState(false);
  const [isperfectuser, setIsPerfectUser] = useState(isperfect);

  const handleOpen = () => {
    setIsCheckedDemo(true);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsCheckedDemo(false);
  };
  const [isChecked, setIsChecked] = useState(item?.trailPreiod);
  const validation = Yup.object().shape({
    // RestaurantName: Yup.string().required("Required"),
    // RestaurantEmail: Yup.string()
    //   .email("Invalid email format")
    //   .required("Required"),
    // RestaurantMobile: Yup.string().required("Required"),
    // RestaurantWhNo: Yup.string().required("Required"),
    // RestaurantHno: Yup.string().required("Required"),
    // RestaurantLocal: Yup.string().required("Required"),
    // RestaurantPCode: Yup.string().required("Required"),
    // RestaurantBranch: Yup.number().required("Required"),
    // RestaurantCountry: Yup.object()
    //   .shape({
    //     label: Yup.string().required("Required"),
    //     value: Yup.string().required("Required"),
    //   })
    //   .nullable()
    //   .required("Required"),
    // RestaurantServices: Yup.array()
    //   .min(1, "Select at least one service")
    //   .required("Required"),
    // password: Yup.string()
    //   .min(6, "Password should be at least 6 characters.")
    //   .required("Password is required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Confirm password is required"),

    RestaurantName: Yup.string().required("Restaurant Name Required"),
    // SubHeading1: Yup.string().required("Sub Heading First Text Required"),
    // SubHeading2: Yup.string().required("Sub Heading  Second Text Required"),
    RestaurantEmail: Yup.string()
      .email("Invalid email format")
      .required("Email Required"),
    RestaurantMobile: Yup.string()
      .required("Mobile number is required")
      // .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number should  required minimum 10 number.")
      .max(15, "Mobile number should  required maximum 15 number."),
    // RestaurantWhNo: Yup.string().required("Whatsapp Number Required"),
    RestaurantWhNo: Yup.string()
      .min(10, "Whatsapp Mobile number required minimum 10 number.")
      .max(15, "Whatsapp Mobile number required maximum 15 number."),
    RestaurantHno: Yup.string().required("House Number And Street Required"),
    RestaurantLocal: Yup.string().required("Locality (Post Town Required"),
    RestaurantPCode: Yup.string()
      .required("Post Code is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Post Code must be alphanumeric and not contain special characters or spaces"
      ),
    // RestaurantBranch: Yup.number().required("Required"),
    RestaurantBranch: Yup.number()
      .integer("Number of branches must be an integer")
      .min(0, "Number of branches must be greater than or equal to zero")
      .required("Number of branches is required"),
    country: Yup.string().required("Country is required"),

    VAT: Yup.number()
      .required("VAT (In %) is Required")
      .test(
        "is-decimal",
        "Must be a decimal with up to 2 digits after the decimal point",
        (value) => (value + "").match(/^\d+(\.\d{1,2})?$/)
      ),
    ServiceCharge: Yup.number()
      .nullable()
      .required("Service Charge (In %) is Required")
      .typeError("Must be a number")
      .positive("Service Charge Must be a Positive Value")
      .test(
        "is-decimal",
        "Must be a number with up to 2 digits after the decimal point",
        (value) =>
          value === null ||
          value === undefined ||
          Number.isInteger(value) ||
          /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    trailPreiod: Yup.boolean(),
    expireDays: Yup.string().when("trailPreiod", {
      is: true,
      then: Yup.string().required(
        "Expire Days is required when trial period is active"
      ),
      otherwise: Yup.string().notRequired(),
    }),
  });
  const validationdata = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password should be at least 6 characters.")
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords Must Match")
      .required("Confirm Password is Required"),
  });

  const [initialValues, setInitialValues] = useState({
    RestaurantName: "",
    RestaurantEmail: "",
    RestaurantMobile: "",
    RestaurantWhNo: "",
    RestaurantHno: "",
    RestaurantLocal: "",
    RestaurantPCode: "",
    RestaurantBranch: "",
    values: null,
    checkboxValues: [],
    password: "",
    trailPreiod: "",
    expireDays: "",
    expireDate: "",
    country: "",
    SubHeading1: "",
    SubHeading2: "",
    VAT: "",
    ServiceCharge: "",
    ServiceChargeapply: false,
    countryCodeMob: "",
    countryCodeWht: "",
  });

  const [initialReset, setInitialReset] = useState({
    password: "",
    confirmPassword: "",
  });

  const [value, setValue] = useState("");

  const options = useMemo(() => countryList().getData(), []);
  // console.log(options, "kkkkk");

  // const Product = [
  //   { value: "1", label: "Kiosk" },
  //   { value: "2", label: "POS" },
  //   { value: "3", label: "KDS" },
  // ];

  const getProductData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(ProductList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllProduct(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allproductlist = Array.isArray(allproduct)
    ? allproduct.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const onregister = (values, { resetForm }) => {
    console.log("initialValueimage", initialValueimage);
    const data = new FormData();

    // Append form values to the formData object
    data.append("name", values.RestaurantName);
    // data.append("id", initialValues.id);
    data.append("email", values.RestaurantEmail);
    data.append("mobileNumber", values.RestaurantMobile);
    data.append("countryCodeMob", values.countryCodeMob);
    data.append("countryCodeWht", values.countryCodeWht);
    data.append("whatsapp", values.RestaurantWhNo);
    data.append("addressLineOne", values.RestaurantHno);
    data.append("addressLineTwo", values.RestaurantLocal);
    data.append("postCode", values.RestaurantPCode);
    data.append("maxBranches", values.RestaurantBranch);
    data.append("country", values.country);
    data.append("expireDate", values.expireDate);
    data.append("expireDays", values.expireDays);
    data.append("trailPreiod", values.trailPreiod);
    data.append("subHeading1", values.SubHeading1);
    data.append("subHeading2", values.SubHeading2);
    data.append("VAT", values.VAT);
    data.append("ServiceCharge", values.ServiceCharge);
    data.append("ServiceChargeapply", values.ServiceChargeapply);

    // data.append("isActive", activedata.value);
    // Append image data if available

    data.append(
      "image",
      initialValueimage.send_media ? initialValueimage.send_media : item?.image
    );

    if (values.checkboxValues && values.checkboxValues.length > 0) {
      // Flatten the array and append each element individually
      const products = values.checkboxValues.flat();
      products.forEach((product, index) => {
        data.append(`products[${index}]`, product);
      });
    }
    // console.log("log", data);

    let requestOptions = {
      id: initialValues.id,
      requestOption: {
        method: "put",
        body: data,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      },
    };

    dispatch(RestaurantEdit(requestOptions, initialValues.id)).then((res) => {
      if (res?.payload?.status == true) {
        toast.success(res?.payload?.message);
        navigate("/Restaurants");
        console.log("res?.payload?", res?.payload);
      } else {
        toast.error(res?.payload?.message);
      }
    });

    console.log("Form submitted:", values);
  };

  const getImageUrl = (imgPath) => {
    return imgPath
      ? `${api_url}${imgPath.replace(/^public\//, "")}`
      : default_image;
  };

  useEffect(() => {
    if (item) {
      setInitialValues({
        RestaurantName: item?.name || "",
        id: item?._id || "",
        RestaurantEmail: item?.email || "",
        RestaurantMobile: item?.mobileNumber || "",
        RestaurantWhNo: item?.whatsapp || "",
        countryCodeMob: item?.countryCodeMob || "",
        countryCodeWht: item?.countryCodeWht || "",
        RestaurantHno: item?.addressLineOne || "",
        RestaurantLocal: item?.addressLineTwo || "",
        RestaurantPCode: item?.postCode || "",
        RestaurantBranch: item?.maxBranches,
        SubHeading1: item?.subHeading1,
        SubHeading2: item?.subHeading2,
        VAT: item?.VAT,
        ServiceCharge: item?.ServiceCharge,
        ServiceChargeapply: item?.ServiceChargeapply,
        // password: item?.password || "",
        checkboxValues: item?.products || [],
        country: item.country,
        trailPreiod: item?.trailPreiod || "",
        expireDays: item?.expireDays || "",
        expireDate: item?.expireDate
          ? moment(item?.expireDate).format("YYYY-MM-DD")
          : "",
      });
    }

    setInitialValueImage({
      preview_img: item?.image,
    });
  }, [item]);

  const [initialValueimage, setInitialValueImage] = useState({
    preview_img: null,
    send_media: null,
  });
  const handleChangeImage = (e) => {
    let file = e.target.files[0];
    console.log(file); // Inspect the file object
    if (file) {
      setInitialValueImage((prev) => ({
        ...prev,
        preview_img: URL.createObjectURL(file),
        send_media: file,
      }));
    } else {
      console.error("No file selected");
    }
  };

  // const Activestatus = [
  //   { value: "true", label: "Active" },
  //   { value: "false", label: "InActive" },

  // ];

  const [activedata, setActiveData] = useState("");
  useEffect(() => {
    if (item) {
      setActiveData({
        value: item.isActive,
        label: item.isActive ? "Active" : "Inactive",
      });
    }
  }, [item]);

  const changestatus = (selectedOption) => {
    setActiveData(selectedOption);
  };

  const [numberOfDays, setNumberOfDays] = useState("");
  const [fieldvalue, setFieldValue] = useState("");

  const handleNumberOfDaysChange = (e, setFieldValue) => {
    const days = e.target.value;
    const enteredValue = parseInt(e.target.value);
    // Check if the input value is empty
    if (days === "" || enteredValue <= 0) {
      // Clear the numberOfDays state and set the EnterNoofDays field to empty
      setNumberOfDays("");
      setFieldValue("expireDays", "");
      setFieldValue("expireDate", ""); // Optionally, clear the date field as well
      return; // Exit the function
    }

    // Parse the entered number of days
    const numberOfDays = parseInt(days);

    // Check if numberOfDays is a valid number
    if (!isNaN(numberOfDays)) {
      // Calculate the date for today + numberOfDays
      const currentDate = new Date();
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + numberOfDays);

      // Check if nextDate is a valid date
      if (!isNaN(nextDate.getTime())) {
        // Convert the next date to ISO string and set it to the Date field
        const formattedDate = nextDate.toISOString().substr(0, 10);

        // Update the state and Formik's field value
        setNumberOfDays(days);
        setFieldValue("expireDays", days);
        setFieldValue("expireDate", formattedDate);
      } else {
        // Handle invalid date
        console.error("Invalid date");
      }
    } else {
      // Handle invalid number of days
      console.error("Invalid number of days");
    }
  };

  const onforget = (values, { resetForm }) => {
    console.log("values", values);

    const data = {
      id: initialValues.id,
      password: values.password,
    };
    dispatch(ForgetpasswordRestaurant(data)).then((res) => {
      if (res?.payload?.status == true) {
        // console.log("red", res?.payload.response);
        // setUserEmail(res?.payload.response);
        setOpen(false);
        toast.success(res?.payload?.message);

        // toast.success(res.payload.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    if (roletype === "1" || roletype === "2") {
      console.log("1 or 2");
      setFilddisble(isperfectuser ? false : true);
      setIsChecked(isperfectuser ? false : true);
    } else {
      console.log("3");
      setFilddisble(false);
    }
  }, [roletype]);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="add-Restauran allsection">
          <Topheader heding_text=" Edit Restaurants" img_icon={Firedark} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <></>
                ) : (
                  <>
                    {/* <div className="card shadow-sm card-custom_box h-100 py-3">
                      <div className="card-body">
                        <div className="row card-custom_box_row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="h5 mb-0">Total Restaurants</div>
                          </div>
                          <div className="col-auto">
                            <h4 className="count-text">
                              <img className="img-fluid" src={userimg} />
                              <span>260k+</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
              </div>

              <div className="col-lg-3 col-md-12 mb-4">
                <Link to="/Restaurants">
                  <div className="card card-custom_box h-100 py-3 bg-orange">
                    <div className="card-body">
                      <div className="row card-custom_box_row no-gutters align-items-center">
                        <div className="col">
                          <div className="h5 mb-0 text-white text-center">
                            Cancel ×
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="add-new-restaurant-box">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div id="information" className="tab-pane active">
                      <div className="row">
                        <div className="col-lg-12">
                          <h6 className="text-navy-blue mb-4">
                            Edit Restaurant
                          </h6>

                          <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={onregister}
                            validationSchema={validation}
                          >
                            {(formik) => (
                              <Form className="custom_form">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Restaurant Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="RestaurantName"
                                        id="Restaurant_Name"
                                        className="form-control"
                                        placeholder="E.g. - Balito’s Cafe"
                                      />
                                      <ErrorMessage
                                        name="RestaurantName"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="email"
                                        className="form-control"
                                        name="RestaurantEmail"
                                        disabled={filddisble}
                                        id="Restaurant_Email"
                                        placeholder="E.g. - sample@mail.com"
                                      />

                                      <ErrorMessage
                                        name="RestaurantEmail"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Mobile Number{" "}
                                        <span className="text-danger">*</span>
                                      </label>

                                      <div className="allcountcode">
                                        <Field
                                          as="select"
                                          name="countryCodeMob"
                                          className="country_code_select_list"
                                        >
                                          {countryCodes.map((country, i) => (
                                            <option
                                              value={country.countryCodes}
                                              key={i}
                                            >{`+${country.countryCodes}`}</option>
                                          ))}
                                        </Field>
                                        <Field
                                          type="text"
                                          name="RestaurantMobile"
                                          id="Restaurant_Mobile"
                                          className="form-control"
                                          placeholder="000 000 0000"
                                        />
                                      </div>

                                      <ErrorMessage
                                        name="RestaurantMobile"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Whatsapp Number{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>

                                      <div className="allcountcode">
                                        <Field
                                          as="select"
                                          name="countryCodeWht"
                                          className="country_code_select_list"
                                        >
                                          {countryCodes.map((country, i) => (
                                            <option
                                              value={country.countryCodes}
                                              key={i}
                                            >{`+${country.countryCodes}`}</option>
                                          ))}
                                        </Field>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="RestaurantWhNo"
                                          id="Restaurant_WhNo"
                                          placeholder="000 000 0000"
                                        />
                                      </div>

                                      <ErrorMessage
                                        name="RestaurantWhNo"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        House Number And Street{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="RestaurantHno"
                                        id="Restaurant_Hno"
                                        placeholder="E.g. - Albert Road"
                                      />

                                      <ErrorMessage
                                        name="RestaurantHno"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Locality (Post Town){" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="RestaurantLocal"
                                        id="Restaurant_Local"
                                        className="form-control"
                                        placeholder="E.g. - Dubai Marina"
                                      />

                                      <ErrorMessage
                                        name="RestaurantLocal"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  {/* 
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Sub-Heading1{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="SubHeading1"
                                        id="SubHeading1"
                                        className="form-control"
                                        placeholder="Enter Sub Heading Test First"
                                      />

                                      <ErrorMessage
                                        name="SubHeading1"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div> */}

                                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Sub-Heading2{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="SubHeading2"
                                        id="SubHeading2"
                                        className="form-control"
                                        placeholder="Sub Heading Text Second"
                                      />

                                      <ErrorMessage
                                        name="SubHeading2"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div> */}

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Post Code{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="RestaurantPCode"
                                        id="Restaurant_PCode"
                                        placeholder="Post Code"
                                      />

                                      <ErrorMessage
                                        name="RestaurantPCode"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Add Number of Branches{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        disabled={filddisble}
                                        name="RestaurantBranch"
                                        id="RestaurantBranch"
                                        placeholder="Add Number of Branches"
                                        onKeyDown={(e) => {
                                          // Prevent default behavior for up and down arrow keys
                                          if (
                                            e.key === "ArrowUp" ||
                                            e.key === "ArrowDown" ||
                                            e.key === "-"
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group d-flex flex-column">
                                      <label>
                                        Country{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field as="select" name="country">
                                        <option value="">
                                          {" "}
                                          Select Country
                                        </option>
                                        {options?.map((res, i) => (
                                          <option value={res?.label} key={i}>
                                            {res?.label}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="country"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        VAT (In %)
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        name="VAT"
                                        step="0.01"
                                        id="VAT"
                                        placeholder="Enter VAT (In %)"
                                      />

                                      <ErrorMessage
                                        name="VAT"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Service Charge (In %)
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <Field
                                        type="number"
                                        className="form-control"
                                        name="ServiceCharge"
                                        step="0.01"
                                        id="ServiceCharge"
                                        placeholder="Enter Service Charge (In %)"
                                      />

                                      <div class="form-check">
                                        <label class="form-check-label">
                                          <Field
                                            type="checkbox"
                                            className="form-check-input"
                                            name="ServiceChargeapply"
                                            checked={
                                              formik.values.ServiceChargeapply
                                            }
                                          />
                                          Service Charges Apply on VAT
                                        </label>
                                      </div>
                                      <ErrorMessage
                                        name="ServiceCharge"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <Field
                                        type="checkbox"
                                        name="trailPreiod"
                                        id="termsAndConditions"
                                        className="mr-2"
                                        disabled={filddisble}
                                        checked={isChecked}
                                        onChange={(e) => {
                                          setIsChecked(e.target.checked);
                                          formik.setFieldValue(
                                            "trailPreiod",
                                            e.target.checked
                                          );
                                          if (!e.target.checked) {
                                            formik.setFieldValue(
                                              "expireDays",
                                              ""
                                            );
                                            formik.setFieldValue(
                                              "expireDate",
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                      <label htmlFor="termsAndConditions">
                                        Restaurant Trial Period{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <div className="d-flex">
                                        {roletype === "1" ||
                                        roletype === "2" ? (
                                          <>
                                            {" "}
                                            <Field
                                              type="number"
                                              className="form-control mr-1"
                                              name="expireDays"
                                              id="EnterNoofDays"
                                              placeholder="Enter No. of Days"
                                              disabled={filddisble}
                                              onChange={(e) =>
                                                handleNumberOfDaysChange(
                                                  e,
                                                  formik.setFieldValue
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <Field
                                              type="number"
                                              className="form-control mr-1"
                                              name="expireDays"
                                              id="EnterNoofDays"
                                              placeholder="Enter No. of Days"
                                              disabled={!isChecked}
                                              onChange={(e) =>
                                                handleNumberOfDaysChange(
                                                  e,
                                                  formik.setFieldValue
                                                )
                                              }
                                            />
                                          </>
                                        )}

                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="expireDate"
                                          id="Date"
                                          placeholder="Date"
                                          disabled={true}
                                          value={
                                            formik.values.expireDate
                                              ? moment(
                                                  formik.values.expireDate
                                                ).format("DD/MM/YYYY")
                                              : formik.values.expireDate
                                          }
                                        />
                                      </div>
                                      {formik.errors.expireDays &&
                                        formik.touched.expireDays && (
                                          <div className="text-danger">
                                            {formik.errors.expireDays}
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Product{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <CheckboxGroup
                                        name="checkboxValues"
                                        options={allproduct}
                                        label="Product"
                                        id="checkboxValues"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Restaurant Logo Image{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="profile_img_container ">
                                        <img
                                          src={
                                            initialValueimage?.preview_img
                                              ? initialValueimage?.preview_img
                                              : default_image
                                          }
                                          className="preview_profile_image"
                                        />
                                        <div className="btn_img">
                                          <button className="upload_image_btn">
                                            Browse
                                          </button>
                                          <input
                                            type="file"
                                            accept=".jpg , .jpeg , .png"
                                            onChange={handleChangeImage}
                                            className="profile_upload_input"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        class="resetlabel"
                                        aria-controls="collapseExample"
                                        onClick={handleOpen}
                                      >
                                        <Field
                                          type="checkbox"
                                          name="trailPreiod"
                                          checked={isCheckedDemo}
                                          id="termsAndConditions2"
                                          onChange={handleOpen}
                                          className="mr-2"
                                        />{" "}
                                        Reset Password{" "}
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="bottom-btns">
                                    <Link
                                      to="/Restaurants"
                                      className="border-btn mr-md-3 mb-3 mb-md-0"
                                      type="button"
                                    >
                                      Cancel
                                    </Link>
                                    <button className="dark-btn" type="submit">
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>

                        {/* <div className="col-lg-6 mt-5 mt-lg-0"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="modal_body_container">
                <div className="modal_heading">
                  <h1>Reset Password</h1>
                </div>
                <div className="modal_btn_container">
                  <Formik
                    validationSchema={validationdata}
                    initialValues={initialReset}
                    onSubmit={onforget}
                  >
                    <Form className="custom_form">
                      <div className="field_cont ">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Password <span className="text-danger">*</span>
                              </label>
                              <div className="eys_input">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  id="login_password"
                                  placeholder="Enter Your Password*"
                                  className="form-control"
                                />
                                <span
                                  className="password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>

                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                confirm Password{" "}
                                <span className="text-danger">*</span>
                              </label>

                              <div className="eys_input">
                                <Field
                                  type={showPassword2 ? "text" : "password"}
                                  name="confirmPassword"
                                  placeholder="Enter Confirm Password*"
                                  id="login_Cpassword"
                                  className="form-control form-control-user"
                                />

                                <span
                                  className="password-toggle-icon"
                                  onClick={() =>
                                    setShowPassword2(!showPassword2)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword2 ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>

                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div
                            className="modal_btn_container"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="modal_cancel_btn"
                              onClick={() => handleClose()}
                            >
                              Cancel
                            </div>
                            <button
                              className="modal_cancel_btn"
                              // disabled={disableSubmitBtn == true}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default Editrestaurant;
