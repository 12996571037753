import react, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  RoleallList,
  restaurantList,
  branchrole,
  designationList,
  TeamView,
  Teamedit,
  Forgetpasswordteam,
  teambased,
} from "../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import whiteedit from "../../../../../img/white-edit.svg";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./edit.css";
import Select from "react-select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "90vh", // Added height constraint
  outline: "none",
};

export default function EditTeamModal({ setUpdateAPI, id, activeuser }) {
  const cleanPath = (path) => {
    if (path === null || path === undefined) {
      console.error("Received null or undefined path:", path);
      return "";
    }
    return path.replace(/^public\//, "");
  };
  const [filddisble, setFilddisble] = useState(false);
  const roletype = localStorage.getItem("roletype");
  const [open, setOpen] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [allrestaurant, setAllRestaurant] = useState("");
  const [selectedOptionsBranch, setSelectedOptionsBranch] = useState([]);
  const [brachList, setBrachList] = useState([]);
  const [allDesignation, setAllDesignation] = useState("");
  const [allRole, setAllRole] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedvaluesArray, setSelectedvaluesArray] = useState([]);
  const [selectedBrancArray, setSelectedBrancArray] = useState([]);
  const [isPerfectUser, setIsPerfectUser] = useState(false);
  const dispatch = useDispatch();
  const [open2, setOpen2] = useState(false);
  const [isCheckedDemo, setIsCheckedDemo] = useState(false);
  const handleOpen2 = () => {
    setIsCheckedDemo(true);
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
    setIsCheckedDemo(false);
  };
  // const handleOpen2 = () => setOpen2(true);
  // const handleClose2 = () => setOpen2(false);

  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [initialValues, setInitialValues] = useState({
    MemberName: "",
    email: "",
    MobileNumber: "",
    Designation: "",
    RoleAssign: "",
    // password: "",
  });

  const handleViewBranch = () => {
    try {
      dispatch(TeamView(id)).then((res) => {
        if (res?.payload?.status === true) {
          setInitialValues({
            MemberName: res?.payload?.payload[0]?.name,
            email: res?.payload?.payload[0]?.email,
            MobileNumber: res?.payload?.payload[0]?.mobileNumber,
            Designation: res?.payload?.payload[0]?.designationId?._id,
            RoleAssign: res?.payload?.payload[0]?.rolesId?._id,
            password: res?.payload?.payload[0]?.password,
            restaurantLabel: res?.payload[0]?.payload?.restaurantLabel,
            userid: res?.payload?.payload[0]?._id,
          });
          setSelectedRestaurant(
            res?.payload?.payload[0]?.restaurantLabel || []
          );
          setSelectedOptionsBranch(res?.payload?.payload[0]?.branchLabel || []);
          setIsPerfectUser(res?.payload?.payload[0]?.is_perfect_member);
          setSelectedvaluesArray(res?.payload?.payload[0]?.restaurantId || []);
          setSelectedBrancArray(res?.payload?.payload[0]?.branchIds || []);

          const idString = res?.payload?.payload[0].restaurantId.join(",");
          console.log("res?.payload[0]?.payload?.restaurantId", idString);
          setTimeout(() => {
            // getRoleListData(res?.payload[0]?.payload?.restaurantId);
            getDesignationList(idString);
          }, 2000);

          handleOpen();
          getRestaurantListData();
          getBraches(res?.payload?.payload[0].restaurantId);
          console.log(res?.payload.payload, "ddd");
        } else {
          setInitialValues(initialValues);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("selectedvaluesArray+", selectedvaluesArray, selectedBrancArray);

  const validationSchema = yup.object({
    MemberName: yup.string().required("Member name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    MobileNumber: yup
      .string()
      .required("Mobile number is required")
      // .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number should required 10 number."),
    // whatsapp_number: yup
    //   .string()
    //   .required("WhatsApp number is required")
    //   .matches(/^[0-9]+$/, "Mobile number must contain only digits"),
    Designation: yup.string().required("Select Designation Required"),
    RoleAssign: yup.string().required("Select Role Assign"),
    // password: yup
    //   .string()
    //   .required("password is required")
    //   .min(6, "Password should be at least 6 characters."),
    // ConfirmPassword: yup
    //   .string()
    //   .oneOf([yup.ref("password"), null], "Passwords must match")
    //   .required("Confirm password is required"),
    // countryCode: yup.string().required("Country Code is required"),
  });

  const validationdata = yup.object().shape({
    password: yup
      .string()
      .min(6, "Password should be at least 6 characters.")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Confirm Password Must Match")
      .required("Confirm Password is required"),
  });

  const [initialReset, setInitialReset] = useState({
    password: "",
  });

  const handleSubmit = (values, { resetForm }) => {
    const requestOptions = {
      name: values.MemberName,
      mobileNumber: values.MobileNumber,
      email: values.email,
      // password: values.password,
      restaurantId: selectedvaluesArray,
      branchIds: selectedBrancArray,
      designationId: values.Designation,
      rolesId: values.RoleAssign,
      is_perfect_member: isPerfectUser,
      restaurantLabel: selectedRestaurant,
      branchLabel: selectedOptionsBranch,
    };

    const data = {
      id: id,
      requestOption: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(requestOptions),
      },
    };

    console.log("data", data);
    try {
      dispatch(Teamedit(data)).then((res) => {
        console.log("1111", data);
        // setDisableSubmitBtn(false);
        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          handleClose();
          setUpdateAPI("addrange");
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
    // console.log("selectedOptions", selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    // console.log("valuesArray", valuesArray);
    setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    // getRoleListData(valuesArray);
    getBraches(valuesArray);
    getDesignationList(valuesArray);
    console.log("selectedOptions", selectedOptions);
  };

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);

          // console.log("totalCounts", res?.payload?.payload);
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    setSelectedOptionsBranch(selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);

    setSelectedBrancArray(valuesArray);

    let restaurantArray;
    if (roletype == 1 || roletype == 2) {
      restaurantArray = selectedRestaurant[0].value;
      console.log("restaurantArray1", selectedRestaurant[0].value);
    } else {
      restaurantArray = selectedRestaurant?.map((option) => option?.value);
      // console.log("restaurantArray111");
    }

    getDesignationList(restaurantArray, valuesArray);
  };

  console.log("selectedOptions", allbrachList);
  const getDesignationList = (id, branchId) => {
    const requestOption = {
      id: id,
      branchId: branchId,
    };
    // setIsLoading(true);
    // const requestOption = {
    //   page: "",
    //   search: "",
    //   limit: "",
    // };
    dispatch(teambased(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllDesignation(res?.payload?.designation);
          setAllRole(res?.payload?.roles);
          // setTotalCounts(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const alldesignationList = Array.isArray(allDesignation)
    ? allDesignation.map((item) => ({
        label: item.designationName,
        value: item._id,
      }))
    : [];

  const getRoleListData = (id) => {
    // setIsLoading(true);
    // const requestOption = {
    //   page: "",
    //   search: "",
    //   limit: "",
    // };
    dispatch(teambased(id))
      .then((res) => {
        if (res?.payload?.status === true) {
          // setAllRole(res?.payload?.roles);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const allRoleList = Array.isArray(allRole)
    ? allRole.map((item) => ({
        label: item.roleName,
        value: item._id,
      }))
    : [];

  const handlePerfectUserChange = (event) => {
    const isChecked = event.target.checked;
    setIsPerfectUser(isChecked);

    if (isChecked) {
      setSelectedRestaurant(allrestaurantlist);
      setSelectedOptionsBranch(allbrachList);

      const valuesArray = allrestaurantlist.map((option) => option.value);

      console.log("valuesArray", valuesArray);
      setSelectedvaluesArray(valuesArray);

      // console.log("valuesArray", valuesArray);

      setTimeout(() => {
        const valuesArray2 = allbrachList.map((option) => option.value);
        getBraches(valuesArray);
        setSelectedBrancArray(valuesArray2);

        getDesignationList(valuesArray, valuesArray);
        console.log("valuesArray2", valuesArray2);
      }, 2000);

      console.log("sss", valuesArray);
    } else {
      setSelectedRestaurant([]);
      setSelectedOptionsBranch([]);
    }
  };

  useEffect(() => {
    // getRestaurantListData();
    // getBraches();
    // getDesignationList();
    // getRoleListData();
  }, []);

  useEffect(() => {
    if (roletype === "1" || roletype === "2") {
      setSelectedRestaurant({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("RoleName"),
      });
      getDesignationList(localStorage.getItem("id"));
      setSelectedvaluesArray(localStorage.getItem("id"));
      setFilddisble(true);
      getBraches(localStorage.getItem("id"));
    }
  }, []);

  console.log("activeuser", activeuser);

  const onforget = (values, { resetForm }) => {
    const data = {
      id: initialValues.userid,
      password: values.password,
    };

    console.log("data", data);
    dispatch(Forgetpasswordteam(data)).then((res) => {
      if (res?.payload?.status == true) {
        // console.log("red", res?.payload.response);
        // setUserEmail(res?.payload.response);
        handleOpen();
        handleClose2();
        toast.success(res?.payload?.message);

        // toast.success(res.payload.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <div>
      <button
        onClick={() => {
          handleViewBranch();
        }}
        className="edit_brach_btn bg-blue"
      >
        <img src={whiteedit} />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Edit Team</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="field_cont ">
                      <div className="Line_div"></div>
                      {roletype === "2" || roletype === "1" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="Prefectuser space_div pb-0 pl-0">
                            <input
                              type="checkbox"
                              checked={isPerfectUser}
                              onChange={handlePerfectUserChange}
                            />{" "}
                            <label>Is Prefect User</label>
                          </div>
                        </>
                      )}{" "}
                      <div className="row">
                        <div className="col-md-6 d-flex flex-column mb-3 addrolemange">
                          <label htmlFor="Restaurant">
                            Restaurant <span className="text-danger">*</span>
                          </label>

                          {/* <MultiSelect
                            options={allrestaurantlist}
                            value={selectedRestaurant}
                            onChange={handleChange}
                            labelledBy="Select Restaurant..."
                            overrideStrings={{
                              selectSomeItems: "Select Restaurant...",
                              allItemsAreSelected:
                                "All Restaurant are selected.",
                            }}
                          /> */}

                          {roletype === "1" || roletype === "2" ? (
                            <>
                              {" "}
                              {activeuser && roletype === "2" ? (
                                <MultiSelect
                                  options={allrestaurantlist}
                                  value={selectedRestaurant}
                                  onChange={handleChange}
                                  labelledBy="Select Restaurant..."
                                  overrideStrings={{
                                    selectSomeItems: "Select Restaurant",
                                    allItemsAreSelected:
                                      "All Restaurant are selected",
                                  }}
                                  // isDisabled={filddisble}
                                />
                              ) : (
                                <Select
                                  options={allrestaurantlist}
                                  value={selectedRestaurant}
                                  onChange={handleChange}
                                  placeholder="Select Restaurant"
                                  isDisabled={filddisble}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {" "}
                              <MultiSelect
                                options={allrestaurantlist}
                                value={selectedRestaurant}
                                onChange={handleChange}
                                labelledBy="Select Restaurant"
                                overrideStrings={{
                                  selectSomeItems: "Select Restaurant",
                                  allItemsAreSelected:
                                    "All Restaurant are selected.",
                                }}
                                // isDisabled={filddisble}
                              />
                            </>
                          )}
                          {/* <ErrorMessage
                            component="p"
                            name="branch_name"
                            className="text-danger"
                          /> */}
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3 addrolemange">
                          <label htmlFor="Branch">
                            Branch <span className="text-danger">*</span>
                          </label>
                          <MultiSelect
                            options={allbrachList}
                            value={selectedOptionsBranch}
                            onChange={handleChangeBranch}
                            labelledBy="Select Branch..."
                            overrideStrings={{
                              selectSomeItems: "Select Branch",
                              allItemsAreSelected: "All branches are selected",
                            }}
                          />
                          {/* <ErrorMessage
                            component="p"
                            name="house_street"
                            className="text-danger"
                          /> */}
                        </div>
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="MemberName">
                            Member Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter  Member Name "
                            id="MemberName"
                            name="MemberName"
                          />
                          <ErrorMessage
                            component="p"
                            name="MemberName"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="MobileNumber">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="number"
                            Placeholder="Enter Mobile Number"
                            id="MobileNumber"
                            name="MobileNumber"
                          />
                          <ErrorMessage
                            component="p"
                            name="MobileNumber"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="email"
                            Placeholder="Enter Email"
                            id="Email"
                            name="email"
                            disabled={filddisble}
                          />
                          <ErrorMessage
                            component="p"
                            name="email"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="Designation">
                            Designation <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            Placeholder="Enter Designation Name "
                            id="Designation"
                            name="Designation"
                          >
                            <option value="" disabled>
                              Select Designation
                            </option>
                            {alldesignationList.map((designation, i) => (
                              <option value={designation.value} key={i}>
                                {designation.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="p"
                            name="Designation"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="RoleAssign">
                            Role Assign <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            Placeholder="Role Assign"
                            id="RoleAssign"
                            name="RoleAssign"
                          >
                            <option value="" disabled>
                              Select Role
                            </option>
                            {allRoleList?.map((role, i) => (
                              <option value={role?.value} key={i}>
                                {role?.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="p"
                            name="RoleAssign"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          {/* <label htmlFor="MobileNumber">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="eys_input">
                            <Field
                              type={showPassword ? "text" : "password"}
                              Placeholder="Enter Password"
                              id="password"
                              name="password"
                              style={{ width: "100%" }}
                            />

                            <span
                              className="password-toggle-icon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            component="p"
                            name="password"
                            className="text-danger"
                          /> */}

                          <div className="form-group">
                            <label
                              class="resetlabel"
                              aria-controls="collapseExample"
                              // onClick={handleOpen}
                            >
                              <Field
                                type="checkbox"
                                checked={isCheckedDemo}
                                name="trailPreiod"
                                id="termsAndConditions2"
                                onChange={handleOpen2}
                                className="mr-2"
                              />{" "}
                              Reset Password{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                          </div>
                        </div>

                        {/* <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="MobileNumber">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Confirm Password"
                            id="ConfirmPassword"
                            name="ConfirmPassword"
                          />
                          <ErrorMessage
                            component="p"
                            name="ConfirmPassword"
                            className="text-danger"
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="modal_btn_container">
                      <div
                        className="modal_cancel_btn"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </div>
                      <button
                        className="modal_cancel_btn"
                        type="submit"
                        // disabled={disableSubmitBtn == true}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open2}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Reset Password</h1>
              </div>
              <div className="modal_btn_container">
                <Formik
                  validationSchema={validationdata}
                  initialValues={initialReset}
                  onSubmit={onforget}
                >
                  <Form className="custom_form">
                    <div className="field_cont ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="eys_input">
                              <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="login_password"
                                placeholder="Enter Your Password*"
                                className="form-control"
                              />
                              <span
                                className="password-toggle-icon"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEyeSlash : faEye}
                                />
                              </span>
                            </div>

                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Confirom Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              className="form-control"
                              id="login_Cpassword"
                              placeholder="Enter Confirm Password*"
                            />

                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div
                          className="modal_btn_container"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="modal_cancel_btn"
                            onClick={() => handleClose2()}
                          >
                            Cancel
                          </div>
                          <button
                            className="modal_cancel_btn"
                            // disabled={disableSubmitBtn == true}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
