import React, { useEffect, useState } from "react";
import "./receipt.css";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../../TopHeader//topheader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Finalorderlist } from "../../../../../../Helper/Redux/API";

const Receipt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { order_id } = location.state || {};
  const [Finalreciptlist, setFinalReceiptList] = useState("");
  const [Finalprice, setFinalPrice] = useState("");
  const [servicechargeprice, setServiceChargePrice] = useState("");
  const [vatprice, setVATPrice] = useState(false);
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  console.log("order_id", Finalreciptlist);

  const Finalrecipt = () => {
    const oid = order_id;
    const roleid = localStorage.getItem("id");
    const requestOption = {
      page: "",
      search: "",
      limit: "",
      Rid: roleid,
      id: oid,
    };

    try {
      dispatch(Finalorderlist(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setFinalReceiptList(res?.payload.payload[0]);
          setFinalPrice(res?.payload.payload[0].price);
          setVATPrice(res?.payload.payload[0].VAT);
          setServiceChargePrice(res?.payload.payload[0].ServiceCharge);
          // getmycartListData();
          // setOpen(false);
          // navigate("/ordermanagement/mycart");
        } else {
          //   setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Finalrecipt();
  }, []);

  const mainpage = () => {
    navigate("/ordermanagement/orderadd");
  };
  return (
    <div>
      {" "}
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection MyReceipt">
          <Topheader heding_text="Order Details" />

          <div className="container-fluid">
            <div className="myrediv">
              <h5 className="hef">Order Details</h5>

              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="per_div row">
                    {" "}
                    <div className="col-md-6">
                      <div className="usernameres">
                        <p>User Name:</p>
                        <h5>{Finalreciptlist?.name}</h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="usernameres">
                        <p>Order ID:</p>
                        <h5>{Finalreciptlist?._id}</h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="usernameres">
                        <p>Date:</p>
                        <h5>{formatDate(Finalreciptlist?.createdAt)}</h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="usernameres">
                        <p>Time:</p>
                        <h5>{formatTime(Finalreciptlist?.createdAt)}</h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="usernameres">
                        <p>Payment Status:</p>
                        <h5 className="text-capitalize">
                          {Finalreciptlist?.paymentStatus}
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="usernameres">
                        <p>Payment Type:</p>
                        <h5 className="succ">{Finalreciptlist?.paymentType}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table
                    className="table dataTable no-footer"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                    role="grid"
                    aria-describedby="dataTable_info"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className=""
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-sort="ascending"
                          aria-label="Restaurant Name: activate to sort column descending"
                          style={{ width: "60px;", whiteSpace: "pre" }}
                        >
                          Qty.
                        </th>
                        <th
                          className=""
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Mobile Number: activate to sort column ascending"
                          style={{ width: "700px" }}
                        >
                          Product
                        </th>
                        <th
                          className=""
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Email: activate to sort column ascending"
                          // style="width: 204px;"
                          style={{ width: "70px" }}
                        >
                          Price
                        </th>
                        <th
                          className=""
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Location: activate to sort column ascending"
                          // style="width: 130px;"
                          style={{ width: "70px" }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>{" "}
                    {Array.isArray(Finalreciptlist?.completeOrder) &&
                      Finalreciptlist?.completeOrder?.map((item, index) => (
                        <>
                          <tbody>
                            <tr className="odd" key={item._id}>
                              <td className="sorting_1">{index + 1}</td>
                              <td>{item.item_name}</td>
                              <td>
                                {" "}
                                ${parseFloat(item?.totalPrice).toFixed(2)}
                              </td>
                              <td>
                                {" "}
                                £
                                {parseFloat(Finalprice).toFixed(2)
                                  ? parseFloat(Finalprice).toFixed(2)
                                  : "0"}{" "}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                  </table>

                  <div className="boder_teb">
                    <table
                      class="table tabletotal"
                      // style={{ width: "100%" }}
                    >
                      <tbody>
                        {/* <tr style={{ width: "70px" }}>
                          <td className="label">Sub Total</td>
                          <td className="list_teb">
                            {" "}
                            £{" "}
                            {parseFloat(Finalprice).toFixed(2)
                              ? parseFloat(Finalprice).toFixed(2)
                              : "0"}
                          </td>
                        </tr> */}
                        <tr style={{ width: "70px" }}>
                          <td className="label">VAT</td>
                          <td className="list_teb">
                            £{vatprice ? vatprice : "0"}
                          </td>
                        </tr>

                        <tr style={{ width: "70px" }}>
                          <td className="label">Service Charge</td>
                          <td className="list_teb">
                            £{servicechargeprice ? servicechargeprice : "0"}
                          </td>
                        </tr>
                        <tr style={{ width: "70px" }}>
                          <td className="label total font-weight-bold">
                            Total
                          </td>
                          <td className="list_teb font-weight-bold">
                            £
                            {parseFloat(Finalprice).toFixed(2)
                              ? parseFloat(Finalprice).toFixed(2)
                              : "0"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="modal_btn_container receipt_btn">
                  <div className="modal_cancel_btn" onClick={mainpage}>
                    Add New Order
                  </div>
                  <button className="modal_cancel_btn" type="submit">
                    Print Receipt
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Receipt;
