import React, { useState } from "react";
import Sidebarpage from "../../../Sidebarpage/sidebarpage";
import Topheader from "../../TopHeader/topheader";
import menu_icon from "../../../../img/Vector.svg";

import CategoryList from "./list/list";
import AddCategory from "./add/add";
import EditCategory from "./edit/edit";
import ViewCategory from "./view/view";

const Category = () => {
  const [isShowSecondPart, setIShowSecondPart] = useState("add");

  const [updateAPI, setUpdateAPI] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [canCreateCategory, setCanCreateCategory] = useState(false);
  const [isPerfectUser, setIsPerfectUser] = useState(false);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Categories" img_icon={menu_icon} />
          <div className="container-fluid">
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-7 col-xxl-7">
                      <CategoryList
                        setIShowSecondPart={setIShowSecondPart}
                        updateAPI={updateAPI}
                        setUpdateAPI={setUpdateAPI}
                        setCategoryId={setCategoryId}
                        setCanCreateCategory={setCanCreateCategory}
                        setIsPerfectUserAdd={setIsPerfectUser}
                      />
                    </div>
                    <div className="col-md-12 col-lg-5 col-xxl-5 ">
                      {isShowSecondPart === "add" ? (
                        <AddCategory
                          setUpdateAPI={setUpdateAPI}
                          canCreateCategory={canCreateCategory}
                          isPerfectUser={isPerfectUser}
                        />
                      ) : isShowSecondPart === "edit" ? (
                        <EditCategory
                          categoryId={categoryId}
                          setIShowSecondPart={setIShowSecondPart}
                          setUpdateAPI={setUpdateAPI}
                        />
                      ) : isShowSecondPart === "view" ? (
                        <ViewCategory
                          setIShowSecondPart={setIShowSecondPart}
                          categoryId={categoryId}
                        />
                      ) : (
                        <AddCategory />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Category;
