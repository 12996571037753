import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import "./add.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../../img/AddModal/Group 959.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { branchAdd } from "../../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "90vh", // Added height constraint
};

export default function AddBrachModal({ setUpdateAPI }) {
  const [open, setOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState(null);
  const [binaryImage, setBinaryImage] = React.useState(null);
  const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialValues = {
    branch_name: "",
    // email: "",
    // mobile_number: "",
    // whatsapp_number: "",
    house_street: "",
    locality: "",
    postCode: "",
    country: "",
    // countryCode: "",
  };
  const validationSchema = yup.object({
    branch_name: yup.string().required("Brach name is required"),
    // email: yup
    //   .string()
    //   .required("Email is required")
    //   .email("Invalid email address"),
    // mobile_number: yup
    //   .string()
    //   .required("Mobile number is required")
    //   .matches(/^[0-9]+$/, "Mobile number must contain only digits"),
    // whatsapp_number: yup
    //   .string()
    //   .required("WhatsApp number is required")
    //   .matches(/^[0-9]+$/, "Mobile number must contain only digits"),
    house_street: yup.string().required("House No. & street is required"),
    locality: yup.string().required("Locality is required"),

    postCode: yup
      .string()
      .required("Post Code is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Post Code must be alphanumeric and not contain special characters or spaces"
      ),
    country: yup.string().required("Country is required"),
    // countryCode: yup.string().required("Country Code is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    console.log("data", values);
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("parentId", restaurant_id);
    formData.append("name", values.branch_name);
    // formData.append("email", values.email);
    // formData.append("countryCode", values.countryCode);
    // formData.append("mobileNumber", values.mobile_number);
    // formData.append("whatsapp", values.whatsapp_number);
    formData.append("addressLineOne", values.house_street);
    formData.append("addressLineTwo", values.locality);
    formData.append("postCode", values.postCode);
    formData.append("country", values.country);
    // formData.append("image", binaryImage);
    formData.append("branch", 1);
    const requestOption = {
      method: "POST",
      body: formData,
      Headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    try {
      dispatch(branchAdd(requestOption)).then((res) => {
        setDisableSubmitBtn(false);

        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          setPreviewImage(null);
          setBinaryImage("");
          handleClose();
          setUpdateAPI("addrange");
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      setDisableSubmitBtn(false);
    }
  };
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setBinaryImage(file);
    }
  };
  return (
    <div>
      <div
        className="card card-custom_box h-100 py-3 bg-blue"
        onClick={handleOpen}
      >
        <div className="card-body">
          <div className="row card-custom_box_row no-gutters align-items-center">
            <div className="col mt-2">
              <div className="h5 mb-0 text-center">
                <Button className="add_brach_btn">
                  {" "}
                  <h5> Add New Branch </h5>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container">
              <div className="modal_heading">
                <h1>Add Branch</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="field_cont ">
                      <div className="row">
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="branchName">
                            Branch Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Branch Name"
                            id="branchName"
                            name="branch_name"
                          />
                          <ErrorMessage
                            component="p"
                            name="branch_name"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="house_no">
                            House Number and Street{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter House No. & Street "
                            id="house_no"
                            name="house_street"
                          />
                          <ErrorMessage
                            component="p"
                            name="house_street"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="locality">
                            Locality (Post Town){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Locality "
                            id="locality"
                            name="locality"
                          />
                          <ErrorMessage
                            component="p"
                            name="locality"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="post_Code">
                            Post Code <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter Post Code "
                            id="post_Code"
                            name="postCode"
                          />
                          <ErrorMessage
                            component="p"
                            name="postCode"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="country">
                            Country <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            // Placeholder="Enter Country Name "
                            id="country"
                            name="country"
                            placeholder="Select Country"
                          >
                            <option value="">Select Country</option>
                            {countryCodes.map((country, i) => (
                              <option value={country.country} key={i}>
                                {country.country}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="p"
                            name="country"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      {/* <div className="row"> */}
                      {/* <div className="col-md-6 d-flex flex-column">
                          <label htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="email"
                            Placeholder="Enter Email "
                            id="email"
                            name="email"
                          />
                          <ErrorMessage
                            component="p"
                            name="email"
                            className="text-danger"
                          />
                        </div> */}
                      <div className="col-md-8 d-flex flex-column">
                        <label htmlFor="m_number">
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <div className="mobile_number_cont">
                          <Field
                            as="select"
                            name="countryCode"
                            className="country_code_select"
                          >
                            {countryCodes.map((country, i) => (
                              <option
                                value={country.countryCodes}
                                key={i}
                              >{`+${country.countryCodes}`}</option>
                            ))}
                          </Field>
                          <Field
                            type="text"
                            Placeholder="Enter Mobile Number "
                            id="m_number"
                            name="mobile_number"
                          />
                        </div>
                        <ErrorMessage
                          component="p"
                          name="mobile_number"
                          className="text-danger"
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="row"> */}
                      {/* <div className="col-md-6 d-flex flex-column">
                          <label htmlFor="w_number">
                            WhatsApp Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter WhatsApp Number "
                            id="w_number"
                            name="whatsapp_number"
                          />
                          <ErrorMessage
                            component="p"
                            name="whatsapp_number"
                            className="text-danger"
                          />
                        </div> */}
                      {/* </div> */}
                      <div className="row"></div>
                      <div className="row"></div>

                      {/* <div className="image_container">
                        <label htmlFor="">Upload Image</label>
                        <img
                          src={previewImage ? previewImage : generic_img}
                          alt=""
                          className="uploaded_image"
                        />
                        <div className="browser_img_btn">
                          <div>Browser</div>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={handleUploadImage}
                            className=""
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="modal_btn_container">
                      <div
                        className="modal_cancel_btn"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </div>
                      <button
                        className="modal_cancel_btn"
                        type="submit"
                        // disabled={disableSubmitBtn == true}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
