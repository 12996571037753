import React, { useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import bgimg from "../../../../../img/bggkiosksystem.png";
import branches from "../../../../../img/branchlog.png";
import take from "../../../../../img/t2.png";
import Eat from "../../../../../img/t1.png";
import "../add/add.css";
import { useLocation, useNavigate } from "react-router-dom";

const Add = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { update } = location.state || {};
  const [selectedOption, setSelectedOption] = useState(update);
  const [updateAPI, setUpdateAPI] = useState(1);
  const handleOptionChange = (event) => {
    setSelectedOption(parseInt(event.target.value, 10));
    const value = event.target.value;
    // console.log("value", value);

    if (value == 0) {
      // Condition when value is 0

      const takeAwaydata = true;
      // console.log("Navigate to Favorites page", takeAwaydata);
      setTimeout(() => {
        navigate("/ordermanagement/orderadd/Favorites", {
          state: { takeAwaydata },
        });
      }, 1000);
    } else {
      // Condition for other values (assuming value is not 0)

      const takeAwaydata = false;
      // console.log("Do not navigate", takeAwaydata);
      setTimeout(() => {
        navigate("/ordermanagement/orderadd/Favorites", {
          state: { takeAwaydata },
        });
      }, 1000);
      // Optionally, you can add a default action here if needed.
    }
  };

  return (
    <div>
      {" "}
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section
          className="orderplace Restaurants Dashbored allsection"
          style={{ backgroundImage: bgimg }}
        >
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center text-center">
              <div className="bg_log">
                <img
                  src={branches}
                  alt="branchlogo"
                  className="img-fluid branchlog"
                />

                <h1>
                  Where will you be <br />
                  eating today?
                </h1>
                <div className="menudata">
                  <div
                    className={`Input_box ${
                      selectedOption === 0 ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id="takeAway"
                      name="orderOption"
                      value="0"
                      onChange={handleOptionChange}
                      checked={selectedOption === 0}
                    />
                    <img src={take} alt="takeAway" className="img-fluid" />
                    <label htmlFor="takeAway">Take Away</label>
                  </div>
                  <div
                    className={`Input_box ${
                      selectedOption === 1 ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id="eatIn"
                      name="orderOption"
                      value="1"
                      onChange={handleOptionChange}
                      checked={selectedOption === 1}
                    />
                    <img src={Eat} alt="Eat" className="img-fluid " />
                    <label htmlFor="eatIn">Eat In</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add;
