import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../Sidebarpage/sidebarpage";
import Topheader from "../../TopHeader/topheader";
import tip from "../../../../img/tip2.svg";
import "../TippingSetting/Tippingsetting.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  designationList,
  restaurantList,
  getBraches,
  branchrole,
  teambased,
  tippingsave,
} from "../../../../Helper/Redux/API";
import Select, { components } from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

const Option = (props) => {
  return (
    <components.Option
      {...props}
      className="d-flex align-items-center justify-content-between"
    >
      <label>{props.label}</label>
      <input
        type="checkbox"
        className="menucheckbox"
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
    </components.Option>
  );
};

const Tippingsetting = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [alldesignation, setAlldesignation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [allrestaurant, setAllRestaurant] = useState("");
  const [brachList, setBrachList] = React.useState([]);
  const [restaurantid, setRestaurantId] = useState("");
  const validationSchema = yup.object({
    RestaurantName: yup.string().required("Restaurant  name is required"),
    // RestaurantBranch: yup.string().required("Branch name is required"),
  });
  const initialValues = {
    RestaurantName: "",
    RestaurantBranch: "",
    designations: [],
  };

  const handleCheckboxChange = (e, setFieldValue, values) => {
    const { value, checked } = e.target;
    const newDesignations = checked
      ? [...values.designations, value]
      : values.designations.filter((item) => item !== value);
    setFieldValue("designations", newDesignations);
  };

  const options = [
    { value: "1", label: "Daily" },
    { value: "2", label: "Weekly" },
    { value: "3", label: "Monthly" },
  ];

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);

          // const alllist = res?.payload?.payload;
          // const valuesArray = alllist.map((option) => option._id);

          // if (roletype === "2" && !activeuser) {
          //   setSelectedRestaurant({
          //     value: res?.payload?.payload[0]?._id,
          //     label: res?.payload?.payload[0]?.name,
          //   });
          //   getDesignationList(res?.payload?.payload[0]?._id);
          //   setSelectedvaluesArray(res?.payload?.payload[0]?._id);
          //   setFilddisble(activeuser ? false : true);
          //   getBraches(res?.payload?.payload[0]?._id);
          // }

          // console.log("ddd", valuesArray);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    console.log(valuesArray);

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };
  // const getDesignationList = () => {
  //   const requestOption = {
  //     page: page,
  //     search: search,
  //     limit: limit,
  //   };
  //   dispatch(designationList(requestOption))
  //     .then((res) => {
  //       if (res?.payload?.status === true) {
  //         setAlldesignation(res?.payload?.payload);
  //       } else {
  //         // toast.error(res?.payload?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching test data:", error);
  //       // Handle error if needed
  //     })
  //     .finally(() => {
  //       setIsLoading(false); // Set loading to false after data fetching completes
  //     });
  // };

  const getDesignationList = (id, branchId) => {
    const requestOption = {
      id: id,
      branchId: branchId,
    };
    dispatch(teambased(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAlldesignation(res?.payload?.designation);

          console.log(
            "res?.payload?.payload.designation",
            res?.payload?.designation
          );
          // setTotalCounts(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    // getDesignationList();
    getRestaurantListData();
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    const requestOptions = {
      designationIds: values.designations,
      tippingSettlement: selectedOption.value,
      restaurantId: values.RestaurantName,
      branchId: values.RestaurantBranch,
    };

    try {
      dispatch(tippingsave(requestOptions)).then((res) => {
        // setDisableSubmitBtn(false);

        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
    console.log("data", requestOptions);
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection tipping">
          <Topheader heding_text="Team Members" img_icon={tip} />
          <div className="container-fluid">
            <div className="custom-table">
              <div className="card">
                <div className="tippingcard">
                  <div className="">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <h6 class="text-navy-blue mb-2">Tipping Management</h6>
                      <p className="tipping_text">
                        Select the designation with whom want to share tip
                      </p>
                    </div>

                    <Formik
                      validationSchema={validationSchema}
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                    >
                      {({ setFieldValue, values }) => (
                        <Form className="modal_form_container">
                          <div className="tippinglist">
                            <div className="row">
                              <div className="col-md-6 d-flex flex-column mb-3">
                                <label htmlFor="Restaurant">
                                  Select Restaurant{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="select"
                                  id="Restaurant"
                                  name="RestaurantName"
                                  placeholder="Select Restaurant"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "RestaurantName",
                                      e.target.value
                                    );
                                    getBraches(e.target.value);
                                    getDesignationList(e.target.value, "");
                                    setRestaurantId(e.target.value);
                                  }}
                                >
                                  <option value="">Select Restaurant</option>
                                  {allrestaurantlist.map((allrestaurant, i) => (
                                    <option value={allrestaurant.value} key={i}>
                                      {allrestaurant.label}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  component="p"
                                  name="RestaurantName"
                                  className="text-danger"
                                />
                              </div>
                              <div className="col-md-6 d-flex flex-column mb-3">
                                <label htmlFor="BranchName">
                                  Select Branch
                                </label>
                                <Field
                                  as="select"
                                  id="BranchName"
                                  name="RestaurantBranch"
                                  placeholder="Select Branch Name"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "RestaurantBranch",
                                      e.target.value
                                    );
                                    getDesignationList(
                                      setRestaurantId,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">Select Branch</option>
                                  {allbrachList.map((allbranch, i) => (
                                    <option value={allbranch.value} key={i}>
                                      {allbranch.label}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="tippinglist">
                            <h6 className="text-navy-blue mb-4">Designation</h6>
                            <ul className="tippingsection">
                              {alldesignation.length === 0 ? (
                                "No Designation Available"
                              ) : (
                                <>
                                  {alldesignation.map((item) => (
                                    <li key={item._id}>
                                      <div className="form-check-tipping">
                                        <input
                                          className="form-check-input-tipping"
                                          type="checkbox"
                                          value={item._id}
                                          id={`Checkboxinput-${item._id}`}
                                          checked={values.designations.includes(
                                            item._id
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              setFieldValue,
                                              values
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label-tipping"
                                          htmlFor={`Checkboxinput-${item._id}`}
                                        >
                                          {item.designationName}
                                        </label>
                                      </div>
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          </div>

                          <div className="tippinglist mt-3 pb-5">
                            <div className="col-md-6 p-0">
                              <h6 className="text-navy-blue mb-4">
                                Tipping Settlement
                              </h6>
                              <Select
                                options={options}
                                components={{ Option }}
                                value={selectedOption}
                                onChange={handleChange}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                              />
                            </div>
                          </div>

                          <div className="modal_btn_container role_btn space_div mt-5">
                            <Link to="/team-management">
                              <div className="modal_cancel_btn">Cancel</div>
                            </Link>
                            <button className="modal_cancel_btn" type="submit">
                              Save
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Tippingsetting;
