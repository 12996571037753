import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import menu_icon from "../../../../../img/Vector.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import "./add.css";
import { toast } from "react-toastify";
import "./add.css";
import {
  branchrole,
  bundleAdd,
  designationAdd,
  itemsList,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";

const BundleAdd = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const roletype = localStorage.getItem("roletype");
  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);
  const [items, setItems] = useState([]);

  const [initialValue, setInitialValue] = useState({
    restaurant_name:
      localStorage.getItem("roletype") == "1" ? localStorage.getItem("id") : "",

    branch_name: [],
    bundle_name: "",
    bundleCategory: [
      {
        categoryName: "",
        items: [],
        isRequired: false,
        isMultiSelector: false,
        maximumNumber: "",
        minimumNumber: "",
      },
    ],
  });

  const handleSubmit = (values, { resetForm }) => {
    const branchesArray = values.branch_name.map((item) => item.value);

    const requestOption = {
      restaurantId: values?.restaurant_name,
      branchIds: branchesArray,
      bundleName: values?.bundle_name,
      bundleCategories: values?.bundleCategory?.map((res) => ({
        categoryName: res.categoryName,
        items: res?.items?.map((item) => item.value),
        isRequired: res.isRequired,
        isMultiSelector: res.isMultiSelector,
        maximumNumber: res.maximumNumber,
      })),
    };

    try {
      dispatch(bundleAdd(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          navigation(-1);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (Error) {
      console.log(Error, "while adding bundle");
    }
  };
  const validationSchemas = yup.object().shape({
    restaurant_name: yup.string().required("Restaurant is required"),
    bundle_name: yup.string().required("Bundle name is required"),
    // branch_name: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       label: yup.string().required(),
    //       value: yup.string().required(),
    //     })
    //   )
    //   .required("Branch name is required"),
    bundleCategory: yup.array().of(
      yup.object().shape({
        categoryName: yup.string().required("Category name is required"),
        items: yup
          .array()
          .min(1, "At least one item must be selected")
          .required("Category item is required"),
        isRequired: yup.boolean(),
        isMultiSelector: yup.boolean(),
        maximumNumber: yup
          .number()
          .typeError("Maximum Number must be a number")
          .when("isMultiSelector", {
            is: true,
            then: yup
              .number()
              .required("Maximum Number is required")
              .positive("Maximum Number must be a positive number"),
            otherwise: yup.number(),
          }),
        minimumNumber: yup
          .number()
          .typeError("Minimum Number must be a number")
          .when("isMultiSelector", {
            is: true,
            then: yup
              .number()
              .required("Minimum Number is required")
              .positive("Minimum Number must be a positive number"),
            otherwise: yup.number(),
          }),
      })
    ),
  });

  const [isLoadingRestro, setIsLoadingRestro] = useState(false);
  console.log(isLoadingRestro, "isLoadingRestro");
  const getRestaurantListData = () => {
    setIsLoadingRestro(false);
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
          }
          if (localStorage.getItem("roletype") == "2") {
            getBraches(res?.payload?.payload[0]?._id);
            setInitialValue((prevState) => ({
              ...prevState,
              restaurant_name: res?.payload?.payload[0]?._id,
            }));
            setIsLoadingRestro(true);
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };

  useEffect(() => {
    getRestaurantListData();
    if (roletype == 1) {
      getItems(localStorage.getItem("id"));
    }
  }, []);
  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const getItems = (id) => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
      restaurant: id,
      branch: "",
      status: "",
      categoryid: "",
    };

    try {
      dispatch(itemsList(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setItems(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));
  const allItemList = items.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const getDisabledItems = (formikValues) => {
    const selectedItems = formikValues.bundleCategory.flatMap(
      (category) => category?.items
    );
    return selectedItems.map((item) => item.value);
  };

  const shouldShowPage = () => {
    if (localStorage.getItem("roletype") != 2) {
      return true;
    } else if (localStorage.getItem("roletype") == 2 && isLoadingRestro) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Menu Management" img_icon={menu_icon} />
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4"></div>
              <div className="col-lg-3 col-md-12 mb-4">
                <Link to={-1}>
                  <div className="card card-custom_box h-100  bg-red">
                    <div className="card-body">
                      <div className="row card-custom_box_row no-gutters align-items-center">
                        <div className="col">
                          <div className="h5 mb-0 text-white text-center">
                            Cancel ×
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="card shadow-sm card-custom_box mb-4 adddesignation_card">
              <div className="card-body ">
                <div className=" designation_form_container">
                  <h6 class="text-navy-blue mb-4 mt-2">Add Bundle</h6>
                  {shouldShowPage() && (
                    <Formik
                      initialValues={initialValue}
                      validationSchema={validationSchemas}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => {
                        const disabledItems = getDisabledItems(formik.values);
                        const itemOptions = allItemList.map((item) => ({
                          ...item,
                          // disabled: disabledItems.includes(item.value),
                        }));
                        return (
                          <Form>
                            <div
                              className="d-flex flex-column  "
                              style={{ gap: "10px" }}
                            >
                              <div className="row addrolemange">
                                <div className="col-md-6 d-flex flex-column">
                                  <label htmlFor="Restaurant">
                                    Restaurant Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    Placeholder="Enter Restaurant Name "
                                    id="Restaurant"
                                    name="restaurant_name"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "restaurant_name",
                                        e.target.value
                                      );
                                      getBraches(e.target.value);
                                      getItems(e.target.value);
                                    }}
                                    disabled={
                                      localStorage.getItem("roletype") == 1 ||
                                      localStorage.getItem("roletype") == 2
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Select</option>
                                    {restaurant.map((res, i) => (
                                      <option value={res?._id} key={i}>
                                        {res?.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    component="p"
                                    name="restaurant_name"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6 d-flex flex-column">
                                  <label htmlFor="Branch">Branch Name</label>
                                  <MultiSelect
                                    options={allbrachList}
                                    name="branch_name"
                                    labelledBy="Select"
                                    value={formik.values.branch_name}
                                    onChange={(e) =>
                                      formik.setFieldValue("branch_name", e)
                                    }
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="branch_name"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 d-flex flex-column">
                                  <label htmlFor="designation">
                                    Bundle Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    Placeholder="Enter Bundle Name "
                                    id="designation"
                                    name="bundle_name"
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name="bundle_name"
                                    className="text-danger"
                                  />
                                </div>
                              </div>

                              <FieldArray name="bundleCategory">
                                {({ push, remove }) => (
                                  <>
                                    {formik.values.bundleCategory.map(
                                      (category, index) => (
                                        <>
                                          <div
                                            key={index}
                                            className="row addrolemange"
                                          >
                                            <div className="col-md-6 d-flex flex-column">
                                              <label
                                                htmlFor={`bundleCategory.${index}.categoryName`}
                                              >
                                                Category Name{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                type="text"
                                                placeholder="Enter Bundle Name"
                                                id={`bundleCategory.${index}.categoryName`}
                                                name={`bundleCategory.${index}.categoryName`}
                                              />
                                              <ErrorMessage
                                                component="p"
                                                name={`bundleCategory.${index}.categoryName`}
                                                className="text-danger"
                                              />
                                            </div>
                                            <div className="col-md-6 d-flex flex-column">
                                              <label
                                                htmlFor={`bundleCategory.${index}.items`}
                                              >
                                                Select Item For Category 1{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <MultiSelect
                                                // options={allItemList}
                                                options={itemOptions}
                                                value={category.items}
                                                onChange={(selected) =>
                                                  formik.setFieldValue(
                                                    `bundleCategory.${index}.items`,
                                                    selected
                                                  )
                                                }
                                                labelledBy="Select"
                                              />
                                              <ErrorMessage
                                                component="p"
                                                name={`bundleCategory.${index}.items`}
                                                className="text-danger"
                                              />
                                            </div>

                                            <div className="col-md-6 d-flex flex-column mt-3">
                                              <div
                                                className="d-flex align-items-center"
                                                style={{ gap: "10px" }}
                                              >
                                                <Field
                                                  type="checkbox"
                                                  name={`bundleCategory.${index}.isRequired`}
                                                  checked={category.isRequired}
                                                />
                                                <label
                                                  htmlFor={`bundleCategory.${index}.isRequired`}
                                                  className="m-0"
                                                >
                                                  Is required?
                                                </label>
                                              </div>
                                              <div
                                                className="d-flex align-items-center"
                                                style={{ gap: "10px" }}
                                              >
                                                <Field
                                                  type="checkbox"
                                                  name={`bundleCategory.${index}.isMultiSelector`}
                                                  checked={
                                                    category.isMultiSelector
                                                  }
                                                />
                                                <label
                                                  htmlFor={`bundleCategory.${index}.isMultiSelector`}
                                                  className="m-0"
                                                >
                                                  Is Multiselect?
                                                </label>
                                              </div>
                                            </div>
                                            {formik.values.bundleCategory[index]
                                              .isMultiSelector && (
                                              <div
                                                className="col-md-6 d-flex align-items-center mt-3"
                                                style={{ gap: "20px" }}
                                              >
                                                <div>
                                                  <label
                                                    htmlFor={`bundleCategory.${index}.maximumNumber`}
                                                    className="m-0 mr-1"
                                                  >
                                                    Enter Maximum Number
                                                  </label>
                                                  <Field
                                                    type="number"
                                                    name={`bundleCategory.${index}.maximumNumber`}
                                                    className="maximum_number_field"
                                                  />
                                                  <ErrorMessage
                                                    component="p"
                                                    name={`bundleCategory.${index}.maximumNumber`}
                                                    className="text-danger"
                                                  />
                                                </div>
                                                <div>
                                                  <label
                                                    htmlFor={`bundleCategory.${index}.maximumNumber`}
                                                    className="m-0 mr-1"
                                                  >
                                                    Enter Minimum Number
                                                  </label>
                                                  <Field
                                                    type="number"
                                                    name={`bundleCategory.${index}.minimumNumber`}
                                                    className="maximum_number_field"
                                                  />
                                                  <ErrorMessage
                                                    component="p"
                                                    name={`bundleCategory.${index}.minimumNumber`}
                                                    className="text-danger"
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            <hr />
                                          </div>
                                          <hr />
                                        </>
                                      )
                                    )}
                                    <div
                                      className="d-flex "
                                      style={{ gap: "10px" }}
                                    >
                                      <button
                                        type="button"
                                        className="add_more_category"
                                        onClick={() =>
                                          push(initialValue.bundleCategory[0])
                                        }
                                      >
                                        Add Category
                                      </button>
                                      {formik.values.bundleCategory.length >
                                        1 && (
                                        <button
                                          type="button"
                                          className="add_more_category"
                                          onClick={() => {
                                            const updatedBundleCategory =
                                              formik.values.bundleCategory.slice(
                                                0,
                                                -1
                                              );
                                            formik.setFieldValue(
                                              "bundleCategory",
                                              updatedBundleCategory
                                            );
                                          }}
                                        >
                                          Remove Category
                                        </button>
                                      )}
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </div>
                            <div class="modal_btn_container role_btn justify-content-start ">
                              {" "}
                              <div
                                className="add_more_category"
                                onClick={() => navigation(-1)}
                              >
                                Cancel
                              </div>
                              <button class="modal_submit_btn" type="submit">
                                Save
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BundleAdd;
