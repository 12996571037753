import React, { useEffect, useState } from "react";
import Sidebarpage from "../../Sidebarpage/sidebarpage";
import Topheader from "../TopHeader/topheader";
import Firedark from "../../../img/res.svg";
import userimg from "../../../img/users-icon.svg";
import whitedelete from "../../../img/white-delete.svg";
import whiteeye from "../../../img/white-eye.svg";
import whiteedit from "../../../img/white-edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import {
  RestaurantEdit,
  restaurantList,
  RestaurantDELETE,
  Profilelist,
  Resstatus,
} from "../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Vortex } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const Restaurants = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allrestaurant, setAllRestaurant] = useState("");
  const [allrestaurantlength, setAllRestaurantLength] = useState("");
  const [totalCounts, setTotalCounts] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalRestaurants, setTotalRestaurants] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [Profiledata, setProfiledata] = useState();
  const [isperfectuser, setIsPerfectUser] = useState("");
  const [img, setImg] = useState("");

  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
          setProfiledata(permissiondata.permission);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  console.log("isperfectuser", isperfectuser);

  let canViewRestaurant = false;
  let canEditRestaurant = false;
  let canDeleteRestaurant = false;
  let canCreactRestaurant = false;
  let canViewBranch = false;

  if (roletype !== "2") {
    canViewRestaurant = true;
    canEditRestaurant = true;
    canDeleteRestaurant = true;
    canCreactRestaurant = true;
    canViewBranch = true;
    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const restaurantPermission = userpermissions?.find(
      (permission) => permission.name === "Restaurant"
    );

    const restaurantbranch = userpermissions?.find(
      (permission) => permission.name === "Branch"
    );

    if (restaurantPermission) {
      canViewRestaurant = restaurantPermission.View || false;
      canCreactRestaurant = restaurantPermission.Create || false;
      canEditRestaurant = restaurantPermission.Edit || false;
      canDeleteRestaurant = restaurantPermission.Delete || false;
    }

    if (restaurantbranch) {
      canViewBranch = restaurantbranch.View || false;
    }
  }
  console.log("profileDataprofileData000", userpermissions);
  const getRestaurantListData = () => {
    // setIsLoading(true);
    const requestOption = {
      page: page,
      search: search,
      limit: limit,
      // isactive: selectedStatus,
    };

    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
          if (roletype === 2 && roletype === 1) {
            setImg(res?.payload?.payload?.image);
          }
          setAllRestaurantLength(res?.payload.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getRestaurantListData();
  }, [page, limit, search]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };

  const Editrestaurant = (item) => {
    console.log("itemeditres", item);
    const isperfect = isperfectuser;
    navigate("/restaurants/edit-restaurant", { state: { item, isperfect } });
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  const handleStatusUpdate = (status, id) => {
    const data = new FormData();
    data.append("isActive", !status);
    // Append image data if available

    let requestOptions = {
      id: id,
      requestOption: {
        method: "put",
        body: data,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      },
    };

    dispatch(RestaurantEdit(requestOptions)).then((res) => {
      if (res?.payload?.status == true) {
        toast.success(res?.payload?.message);
        getRestaurantListData();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleDELETE = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(RestaurantDELETE(id)).then((res) => {
          if (res?.payload?.status == true) {
            toast.success(res?.payload?.message);
            getRestaurantListData();
          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const [inputValue, setInputValue] = useState("");

  const showSwal = () => {
    withReactContent(Swal).fire({
      title: <i>Are You Deleted Restaurant</i>,
      // input: "text",
      // inputValue,
      preConfirm: () => {
        setInputValue(Swal.getInput()?.value || "");
      },
    });
  };

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);

    gethardwarestatus(event.target.value);
    // You can perform any additional actions here based on the selected value
  };

  const gethardwarestatus = (status) => {
    const requestOption = {
      status: status,
    };
    console.log(requestOption);
    dispatch(Resstatus(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
          setAllRestaurantLength(res?.payload.total);
          // setUpdateAPI(1);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };
  const getresetstatus = () => {
    getRestaurantListData();
    setSelectedStatus(true);
    setSearch("");
    setSelectedStatus("");
    setLimit("10");
  };
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Restaurants" img_icon={Firedark} />
          {/* <button onClick={showSwal}>Deleted</button> */}
          {/* <div>Your input: {inputValue}</div> */}
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div className="card shadow-sm card-custom_box h-100 py-3">
                      <div className="card-body">
                        <div className="row card-custom_box_row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="h5 mb-0 ">Total Restaurants</div>
                          </div>
                          <div className="col-auto">
                            <h4 className="count-text">
                              <img className="img-fluid" src={Firedark} />
                              <span>
                                {" "}
                                {allrestaurantlength
                                  ? allrestaurantlength
                                  : "0"}{" "}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-lg-4 col-md-12 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <>
                    {isperfectuser === true && (
                      <>
                        <Link to="/restaurants/create-restaurant">
                          <div className="card card-custom_box h-100 py-3 bg-blue">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mt-2">
                                  <div className="h5 mb-0 text-center">
                                    Add New Restaurant
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link to="/restaurants/create-restaurant">
                      <div className="card card-custom_box h-100 py-3 bg-blue">
                        <div className="card-body">
                          <div className="row card-custom_box_row no-gutters align-items-center">
                            <div className="col mt-2">
                              <div className="h5 mb-0 text-center">
                                Add New Restaurant
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <h6 class="text-navy-blue mb-2">Restaurant</h6>
                      {roletype === "1" || roletype === "2" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="status_div d-flex">
                            <div class="form-group mb-0">
                              <select
                                class="form-control"
                                id="selectstatis"
                                onChange={handleChange}
                                value={selectedStatus}
                              >
                                <option value="">Status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                            </div>

                            <button
                              className="resetbutton"
                              onClick={getresetstatus}
                            >
                              Reset
                            </button>
                          </div>
                        </>
                      )}
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter restroSearch"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            // style={{ width: "120%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "200px;" }}
                                >
                                  Restaurant Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "120px" }}
                                >
                                  Mobile Number
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  // style="width: 204px;"
                                  style={{ width: "130px" }}
                                >
                                  Email
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "100px" }}
                                >
                                  Location
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "70px" }}
                                >
                                  Status
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "100px" }}
                                >
                                  Branch
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action: activate to sort column ascending"
                                  // style="width: 132px;"
                                  style={{ width: "60px" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {canViewRestaurant ? (
                              <>
                                {" "}
                                <tbody>
                                  {isLoading ? (
                                    // Render a loader while data is being fetched
                                    <tr>
                                      <td
                                        colSpan="5"
                                        style={{ textAlign: "center" }}
                                      >
                                        {/* <img src={loder} className="img-fluid" alt="loder" /> */}
                                        {/* <h1>Plz wait</h1> */}
                                        {/* <Vortex
                                          visible={true}
                                          height="80"
                                          width="80"
                                          ariaLabel="vortex-loading"
                                          wrapperStyle={{}}
                                          wrapperClass="vortex-wrapper"
                                          colors={[
                                            "red",
                                            "green",
                                            "blue",
                                            "yellow",
                                            "orange",
                                            "purple",
                                          ]}
                                        /> */}
                                        <h3>Empty List</h3>
                                      </td>
                                    </tr>
                                  ) : (
                                    // Array.isArray(allrestaurant) &&
                                    // allrestaurant.map((item, index) => (

                                    Array.isArray(allrestaurant) &&
                                    allrestaurant.map((item, index) => (
                                      <>
                                        <tr className="odd" key={item._id}>
                                          <td className="sorting_1">
                                            {item.name}
                                          </td>
                                          <td> {item.mobileNumber}</td>
                                          <td> {item.email}</td>
                                          <td> {item.country}</td>

                                          {roletype === "1" ||
                                          roletype === "2" ? (
                                            <>
                                              {" "}
                                              {isperfectuser === true &&
                                              canEditRestaurant ? (
                                                <td
                                                  className={
                                                    item.isActive
                                                      ? "text-success"
                                                      : "text-danger"
                                                  }
                                                  data-toggle="tooltip"
                                                  title="Change status"
                                                  onClick={() =>
                                                    handleStatusUpdate(
                                                      item.isActive,
                                                      item._id
                                                    )
                                                  }
                                                >
                                                  {item.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                                </td>
                                              ) : (
                                                <td
                                                  className={
                                                    item.isActive
                                                      ? "text-success"
                                                      : "text-danger"
                                                  }
                                                  data-toggle="tooltip"
                                                  title="Change status"
                                                >
                                                  {item.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                                </td>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <td
                                                className={
                                                  item.isActive
                                                    ? "text-success"
                                                    : "text-danger"
                                                }
                                                onClick={() =>
                                                  handleStatusUpdate(
                                                    item.isActive,
                                                    item._id
                                                  )
                                                }
                                                data-toggle="tooltip"
                                                title="Change status"
                                              >
                                                {item.isActive
                                                  ? "Active"
                                                  : "Inactive"}
                                              </td>
                                            </>
                                          )}

                                          <td>
                                            {" "}
                                            {canViewBranch ? (
                                              <Link
                                                to={{
                                                  pathname: `/restaurants/branches/${item._id}`,
                                                  search: `/${item.maxBranches}`,
                                                }}
                                              >
                                                <button className="view_btn">
                                                  View Branch
                                                </button>
                                              </Link>
                                            ) : (
                                              "NO View Branch Permission"
                                            )}
                                          </td>
                                          <td>
                                            <ul className="action-btns">
                                              {roletype === "2" ? (
                                                <>
                                                  {" "}
                                                  {canEditRestaurant && (
                                                    <li
                                                      onClick={(e) =>
                                                        Editrestaurant(item)
                                                      }
                                                      data-toggle="tooltip"
                                                      title="Edit Restaurant"
                                                    >
                                                      <a className="bg-blue">
                                                        <img src={whiteedit} />
                                                      </a>
                                                    </li>
                                                  )}{" "}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <li
                                                    onClick={(e) =>
                                                      Editrestaurant(item)
                                                    }
                                                    data-toggle="tooltip"
                                                    title="Edit Restaurant"
                                                  >
                                                    <a className="bg-blue">
                                                      <img src={whiteedit} />
                                                    </a>
                                                  </li>
                                                </>
                                              )}

                                              {roletype === "1" ||
                                              roletype === "2" ? (
                                                <>
                                                  {" "}
                                                  {isperfectuser && (
                                                    <li
                                                      onClick={(e) =>
                                                        handleDELETE(item._id)
                                                      }
                                                      data-toggle="tooltip"
                                                      title="Deletet Restaurant"
                                                    >
                                                      <a
                                                        href="#"
                                                        className="bg-orange"
                                                        on
                                                      >
                                                        <img
                                                          src={whitedelete}
                                                        />
                                                      </a>
                                                    </li>
                                                  )}{" "}
                                                </>
                                              ) : (
                                                <>
                                                  <li
                                                    onClick={(e) =>
                                                      handleDELETE(item._id)
                                                    }
                                                    data-toggle="tooltip"
                                                    title="Deletet Restaurant"
                                                  >
                                                    <a
                                                      href="#"
                                                      className="bg-orange"
                                                      on
                                                    >
                                                      <img src={whitedelete} />
                                                    </a>
                                                  </li>
                                                </>
                                              )}
                                            </ul>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                  )}
                                </tbody>
                              </>
                            ) : (
                              "No View Permission"
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 10 of{" "}
                            {allrestaurantlength ? allrestaurantlength : "0"}{" "}
                            entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          {/* <Pagination
                            currentPage={page}
                            totalItems={totalRestaurants}
                            itemsPerPage={limit}
                            onPageChange={handlePageChange}
                          /> */}

                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Restaurants;
