import React, { useState } from "react";
import "../restaurantslogin.css";
import * as Yup from "yup";
import loginsvg from "../../../img/newlog.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Forgetpassword } from "../../../Helper/Redux/API";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Forgetpassw = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [labelText, setLabelText] = useState("Hardware ");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",
    role: false,
  });

  const validation = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const restaurantValue = values.role ? "2" : "1";
    const updatedValues = { ...values, role: restaurantValue };
    console.log("valuesccccc", updatedValues);

    dispatch(Forgetpassword(updatedValues)).then((res) => {
      if (res?.payload?.success === true) {
        console.log("res?.payloaddddddddddddd", res?.payload.message);

        setTimeout(() => {
          navigate("/restaurantslogin");
        }, 2000);
        toast.success(res?.payload.message);
      } else {
        toast.error(res?.payload?.message);
        // console.log("res?.statssssssus", res);
      }
    });
    // navigate("/restaurantslogin");
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="container">
          {/* Outer Row */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-6">
                <div className="card-body p-0">
                  {/* Nested Row within Card Body */}
                  <div className="row justify-content-center">
                    {/* <!-- <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> --> */}
                    <div className="col-lg-6">
                      <div className="p-5 login-inner">
                        <div className="text-center">
                          <img className="img-fluid mb-4" src={loginsvg} />
                          <h1 className="h4 text-gray-900 mb-4">
                            Forget Passwordddd
                          </h1>
                        </div>

                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validation}
                        >
                          <Form className="user">
                            <div className="form-group">
                              <Field
                                type="email"
                                name="email"
                                id="login_email"
                                className="form-control form-control-user"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address"
                              />

                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="form-group form-check">
                              <Field
                                type="checkbox"
                                id="role"
                                name="role"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="role"
                              >
                                Team Reset Password
                              </label>
                            </div>

                            {/* <div className="form-group">
                              <div className="eys_input">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  Placeholder="Enter Password"
                                  id="password"
                                  name="password"
                                  className="form-control form-control-user"
                                />

                                <span
                                  className="password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </div> */}

                            <button
                              className="btn btn-primary btn-user btn-block"
                              type="submit"
                            >
                              Send
                            </button>
                          </Form>
                        </Formik>

                        <div className="text-center mt-4">
                          <Link className="small" to="/restaurantslogin">
                            Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpassw;
