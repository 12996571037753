import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import Topheader from "../Topcart/topcart";
import burger from "../../../../../../img/Order/category/Layer 1aaaa 1.png";
import burgernew from "../../../../../../img/Order/category/burger.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import fries from "../../../../../../img/Order/category/friessoas.png";
import no from "../../../../../../img/no.jpg";
import "./customization.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewItem, Addtocart } from "../../../../../../Helper/Redux/API";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: 5,
  overflow: "auto",
  height: "95vh", // Added height constraint
};
const Customization = () => {
  const roletype = localStorage.getItem("id");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { itemDataCustom } = location.state || {};
  const [itemDataList, setItemDataList] = useState(
    location.state?.itemDataCustom || {}
  );
  const itemId = itemDataList.itemId;

  const [selecteditemid, setSelectedItemId] = useState(itemId);
  const [Dataitem, setItemData] = useState(itemId);
  const [bundlesitem, setBundlesItem] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [quantitiesbundles, setQuantitiesBundles] = useState({});
  const [open, setOpen] = useState(false);
  const [allbeundel, setAllBundle] = useState([]);
  const [allmeal, setAllMeal] = useState([]);
  const [ismealItem, setIsmealItem] = useState(false);
  const [ismealdata, setIsmealData] = useState([]);
  const [totalproductprice, setTotalProductPrice] = useState([]);
  const [quantitiesmeal, setQuantitiesMeal] = useState({});
  const itemsListView = () => {
    try {
      dispatch(ViewItem(selecteditemid)).then((res) => {
        if (res?.payload?.status === true) {
          setItemData(res?.payload?.payload);

          setBundlesItem(res?.payload?.payload?.bundles);

          console.log(
            JSON.stringify(res?.payload?.payload?.bundles, null, 2),
            "res?.payload?.payload?.bundles"
          );
          setIsmealItem(res?.payload.ismeal);
          setIsmealData(res?.payload?.payload?.mealCategory);
        } else {
          //   setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selecteditemid) {
      itemsListView();
    }
  }, [selecteditemid]);

  const backbtn = () => {
    navigate("/ordermanagement/mycart");
  };

  const [newSelectedBundle, setNewSelectedBundle] = useState(
    itemDataList.bundle || []
  );
  const [newSelectedCustomization, setNewSelectedCustomization] = useState(
    itemDataList?.customization
  );
  const [newSelectedItem, setNewSelectedItem] = useState([]);
  const [newSelectedMeal, setNewSelectedMeal] = useState(
    itemDataList?.mealbundle
  );
  console.log(JSON.stringify(newSelectedMeal, null, 2), "newSelectedBundle");
  // const transformData = (bundlesitem) => {
  //   return bundlesitem.map((bundle) => {
  //     // Extract base price and parent product ID
  //     const parentProductId = bundle.parentProduct_Id;
  //     const basePrice = parseFloat(bundle.price);

  //     // Initialize the result object
  //     const result = {
  //       parentProduct_Id: parentProductId,
  //       price: basePrice,
  //       totalPrice: 0,
  //       products: [],
  //     };

  //     // Process products
  //     bundle.products.forEach((product) => {
  //       const productItems = product.items.map((item) => ({
  //         _id: item._id,
  //         name: item.name,
  //         price: parseFloat(item.price),
  //       }));

  //       result.products.push({
  //         _id: product._id, // Assuming each product has an ID
  //         items: productItems,
  //       });
  //     });

  //     // Calculate totalPrice
  //     result.totalPrice =
  //       result.price +
  //       result.products.reduce((acc, product) => {
  //         return acc + product.items.reduce((sum, item) => sum + item.price, 0);
  //       }, 0);

  //     return result;
  //   });
  // };

  // const transformedData = transformData(itemDataList.bundle);

  // console.log(JSON.stringify(transformedData, null, 2), "kapil");
  // // FUNCTION FOR MERGE DATA IN ONE STATE BUNDLE DATA CUSTOMIZATION DATA WITH ITEM ID
  // // const mergeData = () => {
  // //   setNewSelectedItem([
  // //     {
  // //       itemId: Dataitem?._id,
  // //       bundle: newSelectedBundle,
  // //       customization: newSelectedCustomization,
  // //       item_image: Dataitem?.image,
  // //       item_name: Dataitem?.name,
  // //       item_desc: Dataitem?.description,
  // //       NewMeal: newSelectedMeal,
  // //     },
  // //   ]);
  // // };

  // // const mergeDatamulti = () => {
  // //   setNewSelectedItem([
  // //     {
  // //       itemId: Dataitem?._id,
  // //       item_image: Dataitem?.image,
  // //       item_name: Dataitem?.mealName,
  // //       item_desc: Dataitem?.description,
  // //       Mealbundle: newSelectedMeal,
  // //     },
  // //   ]);
  // // };

  // // useEffect(() => {
  // //   //this hook is working when we have select anu data for this items
  // //   if (
  // //     newSelectedBundle.length > 0 ||
  // //     newSelectedCustomization.length > 0 ||
  // //     newSelectedMeal.length > 0
  // //   ) {
  // //     mergeData();
  // //   }
  // // }, [newSelectedBundle, newSelectedCustomization, newSelectedMeal]);

  // // useEffect(() => {
  // //   //this hook is working when we have select anu data for this items
  // //   if (newSelectedMeal.length > 0) {
  // //     mergeDatamulti();
  // //   }
  // // }, [newSelectedMeal]);

  // // FUNCTION FOR ADD MULTIPLE CUSTOMIZATION

  useEffect(() => {
    setItemDataList((prevItemDataCustom) => ({
      ...prevItemDataCustom,
      customization: newSelectedCustomization,
    }));
  }, [newSelectedCustomization]);

  console.log("itemdatakaak", itemDataList);
  const handleChangeCustomizationMulti = (
    productId,
    item,
    maximumNumber,
    isRequired
  ) => {
    setNewSelectedCustomization((prevSelectedProducts) => {
      const productIndex = prevSelectedProducts.findIndex(
        (product) => product._id === productId
      );

      if (productIndex !== -1) {
        // Product exists, check if item already exists
        const existingProduct = prevSelectedProducts[productIndex];
        const existingItemIndex = existingProduct.items.findIndex(
          (existingItem) => existingItem._id === item._id
        );

        let updatedProducts = [...prevSelectedProducts];
        let updatedProduct = { ...existingProduct };
        let updatedItems = [...existingProduct.items];
        let newTotalPrice = updatedProduct.totalPrice;
        let newQuantity = updatedProduct.Quantity;

        if (existingItemIndex !== -1) {
          // Item exists, remove it
          updatedItems.splice(existingItemIndex, 1);
          newQuantity--;
          newTotalPrice -= parseFloat(item.price);

          // If no items left, remove the product entirely
          if (updatedItems.length === 0) {
            updatedProducts.splice(productIndex, 1);
          } else {
            // Update product with new items list, quantity, and totalPrice
            updatedProduct = {
              ...updatedProduct,
              items: updatedItems,
              Quantity: newQuantity,
              totalPrice: newTotalPrice,
            };
            updatedProducts[productIndex] = updatedProduct;
          }
        } else {
          // Item does not exist, add it to items list
          if (updatedItems.length >= maximumNumber) {
            toast.error(
              `You can only select customization item up to ${maximumNumber} options.`
            );
            return prevSelectedProducts;
          }

          updatedItems = [
            ...updatedItems,
            { _id: item._id, name: item.name, price: item.price },
          ];
          newQuantity++;
          newTotalPrice += parseFloat(item.price);

          // Update product with new items list, quantity, and totalPrice
          updatedProduct = {
            ...updatedProduct,
            items: updatedItems,
            Quantity: newQuantity,
            totalPrice: newTotalPrice,
          };
          updatedProducts[productIndex] = updatedProduct;
        }

        return updatedProducts;
      } else {
        // Product does not exist, add new product
        const newProduct = {
          _id: productId,
          items: [{ _id: item._id, name: item.name, price: item.price }],
          Quantity: 1,
          totalPrice: parseFloat(item.price),
        };
        return [...prevSelectedProducts, newProduct];
      }
    });
  };

  // FUNCTION FOR ADD SINGLE CUSTOMIZATION
  const handleChangeCustomization = (
    productId,
    item,
    maximumNumber,
    isRequired
  ) => {
    setNewSelectedCustomization((prevSelectedProducts) => {
      const productIndex = prevSelectedProducts.findIndex(
        (product) => product._id === productId
      );

      if (productIndex !== -1) {
        // Product exists, check if item already exists
        const existingProduct = prevSelectedProducts[productIndex];
        const existingItemIndex = existingProduct.items.findIndex(
          (existingItem) => existingItem._id === item._id
        );

        // Remove the item if it exists
        if (existingItemIndex !== -1) {
          const updatedItems = [...existingProduct.items];
          updatedItems.splice(existingItemIndex, 1);

          // If no items left, remove the entire product object
          if (updatedItems.length === 0) {
            const updatedProducts = [...prevSelectedProducts];
            updatedProducts.splice(productIndex, 1);
            // toast.success('Product removed');
            return updatedProducts;
          }

          // Update the product with the remaining items
          const updatedProduct = {
            ...existingProduct,
            items: updatedItems,
          };
          const updatedProducts = [...prevSelectedProducts];
          updatedProducts[productIndex] = updatedProduct;

          // toast.success('Item updated');
          return updatedProducts;
        } else {
          // Check if the number of items has reached the maximum limit
          if (existingProduct.items.length >= maximumNumber) {
            // alert(`You can only select up to ${maximumNumber} options.`);
            toast.error(
              `You can only select customization item up to ${maximumNumber} options.`
            );
            return prevSelectedProducts;
          }

          // Add the new item
          const updatedProduct = {
            ...existingProduct,
            items: [{ _id: item._id, name: item.name, price: item.price }],
          };

          const updatedProducts = [...prevSelectedProducts];
          updatedProducts[productIndex] = updatedProduct;

          // toast.success('Item updated');
          return updatedProducts;
        }
      } else {
        // Product does not exist, add new product with the selected item
        const newProduct = {
          _id: productId,
          items: [{ _id: item._id, name: item.name, price: item.price }],
        };
        // toast.success('Product added');
        return [...prevSelectedProducts, newProduct];
      }
    });
  };

  // Handle multiple BUNDLE CUSTOMIZATION
  const handleChangeBundleSizeMulti = (
    parentProductId,
    parentProductPrice,
    productId,
    item,
    maximumNumber,
    isRequired
  ) => {
    console.log("Parameters received:");
    console.log("parentProductId:", parentProductId);
    console.log("parentProductPrice:", parentProductPrice);
    console.log("productId:", productId);
    console.log("item:", item);

    setNewSelectedBundle((prevSelectedProducts) => {
      const parentProductIndex = prevSelectedProducts.findIndex(
        (product) => product.parentProduct_Id === parentProductId
      );

      if (parentProductIndex !== -1) {
        const existingParentProduct = prevSelectedProducts[parentProductIndex];
        const productIndex = existingParentProduct.products.findIndex(
          (product) => product._id === productId
        );
        console.log("productIndex productIndex:", productIndex);
        if (productIndex !== -1) {
          const existingProduct = existingParentProduct.products[productIndex];
          const existingItemIndex = existingProduct.items.findIndex(
            (existingItem) => existingItem._id === item._id
          );

          if (existingItemIndex !== -1) {
            // Item already exists, remove it
            const updatedItems = [...existingProduct.items];
            updatedItems.splice(existingItemIndex, 1);

            if (updatedItems.length === 0) {
              // Remove the product if no items left
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts.splice(productIndex, 1);

              if (updatedProducts.length === 0) {
                // Remove the parent product if no products left
                const updatedParentProducts = [...prevSelectedProducts];
                updatedParentProducts.splice(parentProductIndex, 1);
                return updatedParentProducts;
              } else {
                const updatedParentProduct = {
                  ...existingParentProduct,
                  products: updatedProducts,
                  totalPrice: updatedProducts.reduce(
                    (total, product) =>
                      total +
                      product.items.reduce(
                        (itemTotal, item) => itemTotal + parseFloat(item.price),
                        0
                      ),
                    0
                  ),
                  Quantity: 1, // Set Quantity to 1
                };
                const updatedParentProducts = [...prevSelectedProducts];
                updatedParentProducts[parentProductIndex] =
                  updatedParentProduct;
                return updatedParentProducts;
              }
            } else {
              const updatedProduct = {
                ...existingProduct,
                items: updatedItems,
              };
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts[productIndex] = updatedProduct;
              const updatedParentProduct = {
                ...existingParentProduct,
                products: updatedProducts,
                totalPrice: updatedProducts.reduce(
                  (total, product) =>
                    total +
                    product.items.reduce(
                      (itemTotal, item) => itemTotal + parseFloat(item.price),
                      0
                    ),
                  0
                ),
                Quantity: 1, // Set Quantity to 1
              };
              const updatedParentProducts = [...prevSelectedProducts];
              updatedParentProducts[parentProductIndex] = updatedParentProduct;
              return updatedParentProducts;
            }
          } else {
            // Add a new item to an existing product
            const existingQuantity =
              quantitiesbundles[productId]?.[item._id] || 0;

            if (existingProduct.items.length + 1 > maximumNumber) {
              toast.error(`You can only select up to ${maximumNumber} items.`);
              return prevSelectedProducts;
            }

            const updatedItems = [
              ...existingProduct.items,
              {
                _id: item._id,
                name: item.name,
                price: item.price,
              },
            ];
            const updatedProduct = {
              ...existingProduct,
              items: updatedItems,
            };
            const updatedProducts = [...existingParentProduct.products];
            updatedProducts[productIndex] = updatedProduct;
            const updatedParentProduct = {
              ...existingParentProduct,
              products: updatedProducts,
              totalPrice: updatedProducts.reduce(
                (total, product) =>
                  total +
                  product.items.reduce(
                    (itemTotal, item) => itemTotal + parseFloat(item.price),
                    0
                  ),
                0
              ),
              Quantity: 1, // Set Quantity to 1
            };
            const updatedParentProducts = [...prevSelectedProducts];
            updatedParentProducts[parentProductIndex] = updatedParentProduct;
            return updatedParentProducts;
          }
        } else {
          // Add a new product
          const newProduct = {
            _id: productId,
            items: [
              {
                _id: item._id,
                name: item.name,
                price: item.price,
              },
            ],
          };
          const updatedProducts = [
            ...existingParentProduct.products,
            newProduct,
          ];
          const updatedParentProduct = {
            ...existingParentProduct,
            products: updatedProducts,
            totalPrice: updatedProducts.reduce(
              (total, product) =>
                total +
                product.items.reduce(
                  (itemTotal, item) => itemTotal + parseFloat(item.price),
                  0
                ),
              0
            ),
            Quantity: 1, // Set Quantity to 1
          };
          const updatedParentProducts = [...prevSelectedProducts];
          updatedParentProducts[parentProductIndex] = updatedParentProduct;
          return updatedParentProducts;
        }
      } else {
        // Add a completely new parent product
        const newProduct = {
          _id: productId,
          items: [
            {
              _id: item._id,
              name: item.name,
              price: item.price,
            },
          ],
        };
        const newParentProduct = {
          parentProduct_Id: parentProductId,
          price: parentProductPrice,
          totalPrice: parseFloat(item.price),
          Quantity: 1, // Set Quantity to 1
          products: [newProduct],
        };
        return [...prevSelectedProducts, newParentProduct];
      }
    });
  };

  // Handle multiple SINGLE CUSTOMIZATION
  const handleChangeBundleSize = (
    parentProductId,
    parentProductPrice,
    productId,
    item,
    maximumNumber,
    isRequired
  ) => {
    setNewSelectedBundle((prevSelectedProducts) => {
      const parentProductIndex = prevSelectedProducts.findIndex(
        (product) => product.parentProduct_Id === parentProductId
      );

      if (parentProductIndex !== -1) {
        // Parent product exists, find the product inside it
        const existingParentProduct = prevSelectedProducts[parentProductIndex];
        const productIndex = existingParentProduct.products.findIndex(
          (product) => product._id === productId
        );

        if (productIndex !== -1) {
          // Product exists, check if item already exists
          const existingProduct = existingParentProduct.products[productIndex];
          const existingItemIndex = existingProduct.items.findIndex(
            (existingItem) => existingItem._id === item._id
          );

          if (existingItemIndex !== -1) {
            // Remove the item if it exists
            const updatedItems = [...existingProduct.items];
            updatedItems.splice(existingItemIndex, 1);

            // If no items left, remove the entire product object
            if (updatedItems.length === 0) {
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts.splice(productIndex, 1);

              // Update parent product with updated products array
              const updatedParentProduct = {
                ...existingParentProduct,
                products: updatedProducts,
              };

              // Remove parent product if no products are left
              if (updatedProducts.length === 0) {
                const updatedProductsArray = [...prevSelectedProducts];
                updatedProductsArray.splice(parentProductIndex, 1);
                // toast.success('Parent product removed');
                return updatedProductsArray;
              } else {
                const updatedProductsArray = [...prevSelectedProducts];
                updatedProductsArray[parentProductIndex] = updatedParentProduct;
                // toast.success('Product removed');
                return updatedProductsArray;
              }
            }
          } else {
            // Check if the number of items has reached the maximum limit
            const totalSelectedItems = existingParentProduct.products.flatMap(
              (product) => product.items
            ).length;
            if (totalSelectedItems >= maximumNumber) {
              toast.error(
                `You can only select up to ${maximumNumber} options.`
              );
              return prevSelectedProducts;
            }
            // Add or update the item
            const updatedProduct = {
              ...existingProduct,
              items: [{ _id: item._id, name: item.name, price: item.price }],
            };

            const updatedProducts = [...existingParentProduct.products];
            updatedProducts[productIndex] = updatedProduct;

            // Update parent product with updated products array
            const updatedParentProduct = {
              ...existingParentProduct,
              products: updatedProducts,
            };

            const updatedProductsArray = [...prevSelectedProducts];
            updatedProductsArray[parentProductIndex] = updatedParentProduct;

            // toast.success('Item updated');
            return updatedProductsArray;
          }
        } else {
          // Product does not exist, add new product with the selected item
          const newProduct = {
            _id: productId,
            items: [
              {
                _id: item._id,
                name: item.name,
                price: item.price,
                childcategory: item.name,
              },
            ],
          };
          const updatedProducts = [
            ...existingParentProduct.products,
            newProduct,
          ];

          // Update parent product with updated products array
          const updatedParentProduct = {
            ...existingParentProduct,
            products: updatedProducts,
          };

          const updatedProductsArray = [...prevSelectedProducts];
          updatedProductsArray[parentProductIndex] = updatedParentProduct;

          // toast.success('Product added');
          return updatedProductsArray;
        }
      } else {
        // Check if the number of items has reached the maximum limit
        // if (existingProduct.items.length >= maximumNumber) {
        //   // alert(`You can only select up to ${maximumNumber} options.`);
        //   toast.error(
        //     `You can only select customization item up to ${maximumNumber} options 1.`
        //   );
        //   return prevSelectedProducts;
        // }
        // Parent product does not exist, add new parent product with the product and item
        const newProduct = {
          _id: productId,
          items: [{ _id: item._id, name: item.name, price: item.price }],
        };
        const newParentProduct = {
          parentProduct_Id: parentProductId,
          price: parentProductPrice,
          products: [newProduct],
        };
        // toast.success('Parent product added');
        return [...prevSelectedProducts, newParentProduct];
      }
    });
  };

  // Handle multiple Meal CUSTOMIZATION
  const handleChangeMealizeMulti = (
    parentProductId,
    parentProductPrice,
    productId,
    item,
    maximumNumber,
    isRequired
  ) => {
    console.log("111");
    setNewSelectedMeal((prevSelectedProducts) => {
      console.log("Previous selected products:", prevSelectedProducts);
      const parentProductIndex = prevSelectedProducts.findIndex(
        (product) => product.parentProduct_Id === parentProductId
      );

      if (parentProductIndex !== -1) {
        const existingParentProduct = prevSelectedProducts[parentProductIndex];
        const productIndex = existingParentProduct.products.findIndex(
          (product) => product._id === productId
        );

        if (productIndex !== -1) {
          const existingProduct = existingParentProduct.products[productIndex];
          const existingItemIndex = existingProduct.items.findIndex(
            (existingItem) => existingItem._id === item._id
          );

          if (existingItemIndex !== -1) {
            const updatedItems = [...existingProduct.items];
            updatedItems.splice(existingItemIndex, 1);

            if (updatedItems.length === 0) {
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts.splice(productIndex, 1);

              if (updatedProducts.length === 0) {
                const updatedParentProducts = [...prevSelectedProducts];
                updatedParentProducts.splice(parentProductIndex, 1);
                console.log(
                  "Updated parent products after removal:",
                  updatedParentProducts
                );
                return updatedParentProducts;
              } else {
                const updatedParentProduct = {
                  ...existingParentProduct,
                  products: updatedProducts,
                  totalPrice: updatedProducts.reduce(
                    (total, product) =>
                      total +
                      product.items.reduce(
                        (itemTotal, item) => itemTotal + parseFloat(item.price),
                        0
                      ),
                    0
                  ),
                  Quantity: 1, // Set Quantity to 1
                };

                const updatedParentProducts = [...prevSelectedProducts];
                updatedParentProducts[parentProductIndex] =
                  updatedParentProduct;
                console.log(
                  "Updated parent products with total price:",
                  updatedParentProducts
                );
                return updatedParentProducts;
              }
            } else {
              const updatedProduct = {
                ...existingProduct,
                items: updatedItems,
              };
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts[productIndex] = updatedProduct;
              const updatedParentProduct = {
                ...existingParentProduct,
                products: updatedProducts,
                totalPrice: updatedProducts.reduce(
                  (total, product) =>
                    total +
                    product.items.reduce(
                      (itemTotal, item) => itemTotal + parseFloat(item.price),
                      0
                    ),
                  0
                ),
                Quantity: 1, // Set Quantity to 1
              };
              const updatedParentProducts = [...prevSelectedProducts];
              updatedParentProducts[parentProductIndex] = updatedParentProduct;
              console.log(
                "Updated parent products with updated items:",
                updatedParentProducts
              );
              return updatedParentProducts;
            }
          } else {
            if (existingProduct.items.length >= maximumNumber) {
              toast.error(
                `You can only select customization item up to ${maximumNumber} Item.`
              );
              return prevSelectedProducts;
            }

            const updatedItems = [
              ...existingProduct.items,
              {
                _id: item._id,
                name: item.name,
                price: parseFloat(item.discountPrice),
              },
            ];
            const updatedProduct = {
              ...existingProduct,
              items: updatedItems,
            };
            const updatedProducts = [...existingParentProduct.products];
            updatedProducts[productIndex] = updatedProduct;
            const updatedParentProduct = {
              ...existingParentProduct,
              products: updatedProducts,
              totalPrice: updatedProducts.reduce(
                (total, product) =>
                  total +
                  product.items.reduce(
                    (itemTotal, item) => itemTotal + parseFloat(item.price),
                    0
                  ),
                0
              ),

              Quantity: 1, // Set Quantity to 1
            };
            const updatedParentProducts = [...prevSelectedProducts];
            updatedParentProducts[parentProductIndex] = updatedParentProduct;
            console.log(
              "Updated parent products with new item:",
              updatedParentProducts
            );
            return updatedParentProducts;
          }
        } else {
          const newProduct = {
            _id: productId,
            items: [
              {
                _id: item._id,
                name: item.name,
                price: parseFloat(item.discountPrice),
              },
            ],
          };
          const updatedProducts = [
            ...existingParentProduct.products,
            newProduct,
          ];
          const updatedParentProduct = {
            ...existingParentProduct,
            products: updatedProducts,
            totalPrice: updatedProducts.reduce(
              (total, product) =>
                total +
                product.items.reduce(
                  (itemTotal, item) => itemTotal + parseFloat(item.price),
                  0
                ),
              0
            ),
            Quantity: 1, // Set Quantity to 1
          };

          const updatedParentProducts = [...prevSelectedProducts];
          updatedParentProducts[parentProductIndex] = updatedParentProduct;
          console.log(
            "Updated parent products with new product:",
            updatedParentProducts
          );
          return updatedParentProducts;
        }
      } else {
        const newProduct = {
          _id: productId,
          items: [
            {
              _id: item._id,
              name: item.name,
              price: parseFloat(item.discountPrice),
            },
          ],
        };
        const newParentProduct = {
          parentProduct_Id: parentProductId,
          price: parseFloat(parentProductPrice),
          totalPrice:
            parseFloat(parentProductPrice) + parseFloat(item.discountPrice),
          Quantity: 1, // Set Quantity to 1
          products: [newProduct],
        };
        const updatedParentProducts = [
          ...prevSelectedProducts,
          newParentProduct,
        ];
        console.log(
          "Updated parent products with new parent product:",
          updatedParentProducts
        );
        return updatedParentProducts;
      }
    });
  };

  // Handle Meal multiple SINGLE CUSTOMIZATION
  const handleChangeMealSize = (
    parentProductId,
    parentProductPrice,
    productId,
    item,
    maximumNumber,
    isRequired
  ) => {
    setNewSelectedMeal((prevSelectedProducts) => {
      const parentProductIndex = prevSelectedProducts.findIndex(
        (product) => product.parentProduct_Id === parentProductId
      );

      if (parentProductIndex !== -1) {
        const existingParentProduct = prevSelectedProducts[parentProductIndex];
        const productIndex = existingParentProduct.products.findIndex(
          (product) => product._id === productId
        );

        if (productIndex !== -1) {
          const existingProduct = existingParentProduct.products[productIndex];
          const existingItemIndex = existingProduct.items.findIndex(
            (existingItem) => existingItem._id === item._id
          );

          if (existingItemIndex !== -1) {
            const updatedItems = [...existingProduct.items];
            updatedItems.splice(existingItemIndex, 1);

            if (updatedItems.length === 0) {
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts.splice(productIndex, 1);

              const updatedParentProduct = {
                ...existingParentProduct,
                products: updatedProducts,
                totalPrice: updatedProducts.reduce(
                  (total, product) =>
                    total +
                    product.items.reduce(
                      (itemTotal, item) => itemTotal + parseFloat(item.price),
                      0
                    ),
                  0
                ),
                Quantity: 1, // Set Quantity to 1
              };

              if (updatedProducts.length === 0) {
                const updatedProductsArray = [...prevSelectedProducts];
                updatedProductsArray.splice(parentProductIndex, 1);
                return updatedProductsArray;
              } else {
                const updatedProductsArray = [...prevSelectedProducts];
                updatedProductsArray[parentProductIndex] = updatedParentProduct;
                return updatedProductsArray;
              }
            } else {
              const updatedProduct = {
                ...existingProduct,
                items: updatedItems,
              };
              const updatedProducts = [...existingParentProduct.products];
              updatedProducts[productIndex] = updatedProduct;

              const updatedParentProduct = {
                ...existingParentProduct,
                products: updatedProducts,
                totalPrice: updatedProducts.reduce(
                  (total, product) =>
                    total +
                    product.items.reduce(
                      (itemTotal, item) => itemTotal + parseFloat(item.price),
                      0
                    ),
                  0
                ),
                Quantity: 1, // Set Quantity to 1
              };

              const updatedProductsArray = [...prevSelectedProducts];
              updatedProductsArray[parentProductIndex] = updatedParentProduct;
              return updatedProductsArray;
            }
          } else {
            const totalSelectedItems = existingParentProduct.products.flatMap(
              (product) => product.items
            ).length;
            if (totalSelectedItems >= maximumNumber) {
              toast.error(`You can only select up to ${maximumNumber} Item.`);
              return prevSelectedProducts;
            }

            const updatedProduct = {
              ...existingProduct,
              items: [
                ...existingProduct.items,
                {
                  _id: item._id,
                  name: item.name,
                  price: parseFloat(item.discountPrice),
                },
              ],
            };
            const updatedProducts = [...existingParentProduct.products];
            updatedProducts[productIndex] = updatedProduct;

            const updatedParentProduct = {
              ...existingParentProduct,
              products: updatedProducts,
              totalPrice: updatedProducts.reduce(
                (total, product) =>
                  total +
                  product.items.reduce(
                    (itemTotal, item) => itemTotal + parseFloat(item.price),
                    0
                  ),
                0
              ),
              Quantity: 1, // Set Quantity to 1
            };

            const updatedProductsArray = [...prevSelectedProducts];
            updatedProductsArray[parentProductIndex] = updatedParentProduct;
            return updatedProductsArray;
          }
        } else {
          const newProduct = {
            _id: productId,
            items: [
              {
                _id: item._id,
                name: item.name,
                price: parseFloat(item.discountPrice),
                childcategory: item.name,
              },
            ],
          };
          const updatedProducts = [
            ...existingParentProduct.products,
            newProduct,
          ];

          const updatedParentProduct = {
            ...existingParentProduct,
            products: updatedProducts,
            totalPrice: updatedProducts.reduce(
              (total, product) =>
                total +
                product.items.reduce(
                  (itemTotal, item) => itemTotal + parseFloat(item.price),
                  0
                ),
              0
            ),
            Quantity: 1, // Set Quantity to 1
          };

          const updatedProductsArray = [...prevSelectedProducts];
          updatedProductsArray[parentProductIndex] = updatedParentProduct;
          return updatedProductsArray;
        }
      } else {
        const newProduct = {
          _id: productId,
          items: [
            {
              _id: item._id,
              name: item.name,
              price: parseFloat(item.discountPrice),
            },
          ],
        };
        const newParentProduct = {
          parentProduct_Id: parentProductId,
          price: parseFloat(parentProductPrice),
          products: [newProduct],
          totalPrice: parseFloat(item.discountPrice),
        };
        return [...prevSelectedProducts, newParentProduct];
      }
    });
  };

  const handleClose = () => setOpen(false);
  const handleSubmitdata = () => {
    handleClose();
  };

  // add CSS in the MODAL CARDS
  const isSelected = (option) => {
    // Check if newSelectedBundle exists and iterate over its items
    return newSelectedBundle?.some((parentRes) =>
      parentRes.products?.some((productRes) =>
        productRes.items.some((item) => item?._id === option._id)
      )
    );
  };

  const isSelectedMeal = (option) => {
    // Check if newSelectedBundle exists and iterate over its items
    return newSelectedMeal?.some((parentRes) =>
      parentRes.products?.some((productRes) =>
        productRes.items.some((item) => item?._id === option._id)
      )
    );
  };

  const handleInitialMealSelection = (bundlelist, ismeal) => {
    const customizationId = ismeal._id;
    const currentQuantities = quantitiesmeal[customizationId] || {};
    const currentQuantity = currentQuantities[bundlelist._id] || 0;
    const totalSelectedItems = Object.values(currentQuantities).reduce(
      (acc, qty) => acc + qty,
      0
    );

    if (
      currentQuantity < ismeal?.maximumNumber &&
      totalSelectedItems < ismeal?.maximumNumber
    ) {
      handleMealpopup(bundlelist._id, bundlelist, ismeal, currentQuantity);
    } else {
      setOpen(false);
      toast.error(`You have reached the maximum number of items.`);
    }
  };

  const updateMealQuantity = (
    productId,
    itemId,
    newQuantity,
    bundlelist,
    ismeal
  ) => {
    setQuantitiesMeal((prevQuantities) => {
      const updatedQuantities = {
        ...prevQuantities,
        [productId]: {
          ...prevQuantities[productId],
          [itemId]: newQuantity,
        },
      };

      // if (newQuantity === 0) {
      //   delete updatedQuantities[productId][itemId];
      // }

      if (newQuantity === 0) {
        const { [itemId]: _, ...remainingItems } = updatedQuantities[productId];
        return {
          ...updatedQuantities,
          [productId]: remainingItems,
        };
      }

      // Trigger price calculation with the updated quantities
      calculateMealPrice(updatedQuantities);
      return updatedQuantities;
    });

    setNewSelectedMeal((prevSelectedProducts) => {
      const updatedSelectedProducts = prevSelectedProducts.map(
        (parentProduct) => {
          if (parentProduct.parentProduct_Id === bundlelist._id) {
            const updatedParentProduct = { ...parentProduct };

            // Update Quantity without altering products array
            updatedParentProduct.Quantity = newQuantity;

            return updatedParentProduct;
          }

          return parentProduct; // No change to products that do not match
        }
      );

      // Add a new parent product if it does not exist
      if (
        !updatedSelectedProducts.some(
          (product) => product.parentProduct_Id === bundlelist._id
        )
      ) {
        updatedSelectedProducts.push({
          parentProduct_Id: bundlelist._id,
          price: bundlelist.parentProductPrice,
          Quantity: newQuantity,
          products: [],
        });
      }

      // console.log("updatedSelectedProducts", updatedSelectedProducts);

      return updatedSelectedProducts;
    });

    if (newQuantity === 0) {
      handleParentMealProductClick(itemId, bundlelist, ismeal, newQuantity);
    }
  };

  const handleParentMealProductClick = (
    selectedParentProductId,
    item,
    ismeal,
    currentQuantity
  ) => {
    setNewSelectedMeal((prevCustomization) => {
      const exists = prevCustomization.some(
        (mealCustomization) =>
          mealCustomization.parentProduct_Id === selectedParentProductId
      );

      if (exists) {
        return prevCustomization.filter(
          (mealCustomization) =>
            mealCustomization.parentProduct_Id !== selectedParentProductId
        );
      } else {
        return prevCustomization.concat({
          parentProduct_Id: selectedParentProductId,
          item,
        });
      }
    });
  };

  const handleMealpopup = (
    selectedParentProductId,
    item,
    ismeal,
    currentQuantity
  ) => {
    setAllMeal(item);
    // Check if the maximum number of items is selected
    if (currentQuantity < ismeal?.maximumNumber) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const updateBundleQuantity = (
    productId,
    itemId,
    newQuantity,
    bundlelist,
    bundle
  ) => {
    console.log("productId:");
    // console.log("itemId:", itemId);
    // console.log("newQuantity:", newQuantity);
    // console.log("bundlelist:", bundlelist);
    // console.log("bundle:", bundle);

    // Update quantitiesBundles state
    setQuantitiesBundles((prevQuantities) => {
      const updatedQuantities = {
        ...prevQuantities,
        [productId]: {
          ...prevQuantities[productId],
          [itemId]: newQuantity,
        },
      };

      if (newQuantity === 0) {
        const { [itemId]: _, ...remainingItems } = updatedQuantities[productId];
        return {
          ...updatedQuantities,
          [productId]: remainingItems,
        };
      }

      // Trigger price calculation with the updated quantities
      console.log(updatedQuantities);
      calculateBundlePrice(updatedQuantities);
      return updatedQuantities;
    });

    // Update newSelectedBundle state
    setNewSelectedBundle((prevSelectedProducts) => {
      const updatedSelectedProducts = prevSelectedProducts.map(
        (parentProduct) => {
          if (parentProduct.parentProduct_Id === bundlelist._id) {
            const updatedParentProduct = { ...parentProduct };

            // Update Quantity without altering products array
            updatedParentProduct.Quantity = newQuantity;

            return updatedParentProduct;
          }

          return parentProduct; // No change to products that do not match
        }
      );

      // Add a new parent product if it does not exist
      if (
        !updatedSelectedProducts.some(
          (product) => product.parentProduct_Id === bundlelist._id
        )
      ) {
        updatedSelectedProducts.push({
          parentProduct_Id: bundlelist._id,
          price: bundle.parentProductPrice,
          Quantity: newQuantity,
          products: [],
        });
      }

      // console.log("updatedSelectedProducts", updatedSelectedProducts);

      return updatedSelectedProducts;
    });

    if (newQuantity === 0) {
      handleParentProductClick(itemId, bundlelist, bundle, newQuantity);
    }
  };

  // FUNCTION FOR REMOVE SELECTED PRODCT FROM THE BUNDLE
  const handleParentProductClick = (
    selectedParentProductId,
    item,
    bundle,
    newQuantity
  ) => {
    console.log("currentQuantity", newQuantity);
    setNewSelectedBundle((prevCustomization) => {
      const exists = prevCustomization.some(
        (customization) =>
          customization.parentProduct_Id === selectedParentProductId
      );

      if (exists) {
        return prevCustomization.filter(
          (customization) =>
            customization.parentProduct_Id !== selectedParentProductId
        );
      } else {
        return prevCustomization.concat({
          parentProduct_Id: selectedParentProductId,
          item,
        });
      }
    });
  };

  const handleBundlepopup = (
    selectedParentProductId,
    item,
    bundle,
    currentQuantity
  ) => {
    setAllBundle(item);
    // Check if the maximum number of items is selected
    if (currentQuantity < bundle?.bundleCategories?.maximumNumber) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleInitialSelection = (bundlelist, bundle) => {
    const customizationId = bundle._id;
    const currentQuantities = quantitiesbundles[customizationId] || {};
    const currentQuantity = currentQuantities[bundlelist._id] || 0;
    const totalSelectedItems = Object.values(currentQuantities).reduce(
      (acc, qty) => acc + qty,
      0
    );

    if (
      currentQuantity < bundle?.bundleCategories?.maximumNumber &&
      totalSelectedItems < bundle?.bundleCategories?.maximumNumber
    ) {
      handleBundlepopup(bundlelist._id, bundlelist, bundle, currentQuantity);
    } else {
      setOpen(false);
      toast.error(`You have reached the maximum number of items.`);
    }
  };

  const [isMultiselect, setIsMultiselect] = useState(false);
  const [maximumNumber, setMaximumNumber] = useState(1);
  const handlemealProductClick = (
    selectedParentProductId,
    item,
    isMultiselect,
    maximumNumber,
    id
  ) => {
    console.log(item, "ddd");

    if (
      typeof isMultiselect === "undefined" ||
      typeof maximumNumber === "undefined"
    ) {
      console.error("isMultiselect or maximumNumber is undefined");
      return;
    }

    console.log("parentProduct_Id", item._id);
    setNewSelectedMeal((prevCustomization) => {
      // Check if the selected parentProduct_Id exists
      const exists = prevCustomization.some(
        (mealCustomization) =>
          mealCustomization.parentProduct_Id === selectedParentProductId
      );

      if (!exists) {
        console.log("dddd");
        setAllMeal(item);
        setOpen(true);
      }

      // If it exists, filter it out
      return prevCustomization.filter(
        (mealCustomization) =>
          mealCustomization.parentProduct_Id !== selectedParentProductId
      );
    });
  };

  const [bundleprice, setbundlePrice] = useState(0);
  const [mealprice, setMealPrice] = useState(0);
  //FUNCTION FOR CALCULATE Bundle Price PRICES
  const calculateBundlePrice = (updatedQuantities) => {
    console.log(updatedQuantities);
    const itemPrices = [];
    const productParentPrices = [];
    const itemQuantityprice = [];

    newSelectedBundle?.forEach((res) => {
      const productId = res.parentProduct_Id;
      const itemQuantitypricelocal = res.Quantity;
      const itemtotalPricelocal = res.totalPrice; // Assuming this is a mapping of item IDs to their quantities
      const Quantityprice = res.price; // Assuming this is the additional price to be added once
      const productQuantity = updatedQuantities?.[productId] || {};
      const allupdatedQuantities = updatedQuantities?.[undefined] || {}; // Handle undefined case
      const ovearallprice =
        (Number(itemtotalPricelocal) + Number(Quantityprice)) *
        itemQuantitypricelocal;
      itemPrices.push(ovearallprice);
      console.log("ovearallprice", ovearallprice);
      // Ensure allupdatedQuantities is an object and contains entries
      const obj = allupdatedQuantities;
      const [key, value] = Object.entries(obj)[0] || [];
      // Destructure to get the value
      const numericValue = Number(value);
      console.log("cehckingggggggg" + numericValue);
      const itemQuantity = updatedQuantities[productId] || 0;
      // Flag to check if Quantityprice has been added
      let isQuantitypriceAdded = false;

      if (res?.price) {
        productParentPrices.push(res.price);
      }

      if (res?.products) {
        res.products.forEach((product) => {
          if (product?.items) {
            product.items.forEach((item) => {
              if (item?.price) {
                const itemQuantity = productQuantity[item._id] || 1;

                // if (
                //   itemQuantity < itemQuantityprice &&
                //   numericValue !== itemQuantityprice
                // ) {
                //   itemPrices.push(
                //     item.price * itemQuantity * itemQuantityprice
                //   );
                //   console.log(
                //     numericValue,
                //     "updatedQuantities:",
                //     itemQuantityprice
                //   );

                //   if (
                //     !isQuantitypriceAdded &&
                //     itemQuantity < itemQuantityprice
                //   ) {
                //     itemPrices.push(Quantityprice);
                //     isQuantitypriceAdded = true;
                //     console.log("Quant:", Quantityprice);
                //   }
                // } else {
                //   if (itemQuantity === 1) {
                //     itemPrices.push(item.price * itemQuantity);
                //     console.log("iddddddd:", itemQuantity);
                //   }
                // }

                //if (itemQuantity === 1) {
                // if (productId == "6666a099052988c4d1c7cc3b") {
                //   const allitemPrice = Array.isArray(itemPrices)
                //     ? itemPrices.reduce(
                //         (accumulator, currentValue) =>
                //           parseInt(accumulator, 10) +
                //           parseInt(currentValue, 10),
                //         0
                //       )
                //     : 0;

                //   console.log(
                //     item.price,
                //     "item.price:",
                //     itemPrices,
                //     itemQuantity
                //   );
                // }

                // itemPrices.push(item.price * itemQuantityprice);
                // itemPrices.push(item.price);
                // itemQuantityprice.push(itemQuantitypricelocal);
                // if (productId == "6666a099052988c4d1c7cc3b") {
                //   console.log(itemPrices, itemQuantityprice);
                // }
                // console.log("item.price:", item.price);

                //}
              }
            });
          }
        });
      }
      // itemPrices.push(Quantityprice);
    });

    // Calculate additional prices based on the bundlelist and quantities
    // bundlesitem?.forEach((bundle) => {
    //   const productId = bundle?._id;
    //   const productQuantity = updatedQuantities?.[productId] || {};

    //   bundle?.bundleCategories?.bundleItems.forEach((bundlelist) => {
    //     const filteredItems = newSelectedBundle.filter(
    //       (item) => item.parentProduct_Id === bundlelist._id
    //     );

    //     const datalit =
    //       filteredItems.length > 0 ? filteredItems[0].totalPrice : 0;

    //     const allQuantity =
    //       filteredItems.length > 0 ? filteredItems[0].Quantity : 0;

    //     const Productprice =
    //       filteredItems.length > 0 ? filteredItems[0].price : 0;

    //     const itemQuantity = productQuantity[bundlelist._id] || 1;
    //     // const allpricedata = datalit;

    //     console.log(filteredItems, allQuantity, "allpricedata");

    //     // Add condition to calculate additionalPrice only if itemQuantity is greater than 1
    //     // if (itemQuantity > 1) {
    //     //   const additionalPrice =
    //     //     datalit + (Number(bundlelist?.sellPrice) || 0);
    //     //   itemPrices.push(additionalPrice);
    //     // }

    //     // if (itemQuantity > 1) {
    //     //   const additionalPrice = itemPrices;
    //     //   itemPrices.push(additionalPrice);
    //     // }
    //   });
    // });

    console.log(itemPrices);
    const itemPriceOut = itemPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );

    const productParentPriceOut = productParentPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );

    const totalItemPrice = itemPrices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    console.log(itemQuantityprice);
    // const Total = (productParentPriceOut + itemPriceOut) * itemQuantityprice[0];
    const Total = itemPriceOut;

    console.log(
      itemPriceOut + productParentPriceOut,
      "itemPrices",
      itemPrices,
      "productParentPrices",
      productParentPrices
    );

    // Update state values
    setbundlePrice(Total);
    setTotalProductPrice(totalItemPrice);

    // console.log("Total bundle price calculated:", totalItemPrice);
  };

  //FUNCTION FOR CALCULATE Bundle Price PRICES
  const calculateMealPrice = (updatedQuantities) => {
    const itemPrices = [];
    const productParentPrices = [];
    const itemQuantityprice = [];

    newSelectedMeal.forEach((res) => {
      const productId = res.parentProduct_Id;
      const productQuantity = updatedQuantities?.[productId] || {};
      const itemQuantitypricelocal = res.Quantity ? res.Quantity : 1;
      const itemtotalPricelocal = res.totalPrice;
      const Quantityprice = res.price;
      const allupdatedQuantities = updatedQuantities?.[undefined] || {}; // Handle undefined case
      const ovearallprice =
        (Number(itemtotalPricelocal) + Number(Quantityprice)) *
        itemQuantitypricelocal;
      itemPrices.push(ovearallprice);

      console.log(
        itemQuantitypricelocal,
        "dd",
        itemtotalPricelocal,
        "ovearallpricemeal",
        ovearallprice
      );
      if (res?.price) {
        productParentPrices.push(res.price);
      }
      // if (res?.products) {
      //   res.products.forEach((product) => {
      //     if (product?.items) {
      //       product.items.forEach((item) => {
      //         if (item?.price) {
      //           const itemQuantity = productQuantity[item._id] || 1;
      //           // Check if itemQuantity is greater than 1 to skip adding to itemPrices
      //           if (itemQuantity === 1) {
      //             itemPrices.push(item.price * itemQuantity);
      //           }
      //         }
      //       });
      //     }
      //   });
      // }
    });

    // Calculate additional prices based on the bundlelist and quantities
    // ismealdata?.forEach((ismeal) => {
    //   const productId = ismeal._id;
    //   const productQuantity = updatedQuantities?.[productId] || {};

    //   ismeal?.items.forEach((bundlelist) => {
    //     const filteredItems = newSelectedMeal.filter(
    //       (item) => item.parentProduct_Id === bundlelist._id
    //     );

    //     const datalit =
    //       filteredItems.length > 0 ? filteredItems[0].totalPrice : 0;
    //     const itemQuantity = productQuantity[bundlelist._id] || 1;

    //     // Add condition to calculate additionalPrice only if itemQuantity is greater than 1
    //     if (itemQuantity > 1) {
    //       const additionalPrice =
    //         datalit + (Number(bundlelist?.discountPrice) || 0);
    //       itemPrices.push(additionalPrice);
    //     }
    //   });
    // });

    const itemPriceOut = itemPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );
    const productParentPriceOut = productParentPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );
    const totalItemPrice = itemPrices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const Total = itemPriceOut;
    setMealPrice(Total);
    // console.log("Total", Total);
  };

  useEffect(() => {
    if (newSelectedMeal) {
      calculateMealPrice();
    }
  }, [newSelectedMeal]);

  useEffect(() => {
    if (newSelectedBundle) {
      calculateBundlePrice(quantitiesbundles);
    }
  }, [newSelectedBundle, , quantitiesbundles]);

  const [customizationPrice, setCustomizationPrice] = useState(0);
  //FUNCTION FOR CALCULATE Bundle Price PRICES
  const calculateCustomizationPrice = () => {
    const itemPrices = [];
    if (newSelectedCustomization) {
      newSelectedCustomization?.forEach((res) => {
        if (res?.items) {
          res.items.forEach((item) => {
            if (item?.price) {
              itemPrices.push(item.price);
            }
          });
        }
      });
    }
    const total = itemPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );

    setCustomizationPrice(total);
  };
  useEffect(() => {
    if (newSelectedCustomization) {
      calculateCustomizationPrice();
    }
  }, [newSelectedCustomization]);

  const [sellPrice, setSellPrice] = useState(Dataitem?.sellPrice || 0);
  const [sellPriceMeal, setSellPriceMeal] = useState(
    Dataitem?.discountMealPrice || 0
  );

  const sellpricedata = Number(Dataitem?.sellPrice) || 0;
  const sellMealdata = Number(Dataitem?.discountMealPrice) || 0;

  const newTotalPrice = sellpricedata + bundleprice + customizationPrice;

  const newTotalMealPrice = sellMealdata + mealprice;

  // console.log("newTotalMealPrice", sellMealdata + mealprice);

  const handleSubmit = () => {
    if (ismealItem === true) {
      // const mealbundleData = newSelectedItem[0].Mealbundle.map(
      //   (selectedItem) => ({
      //     parentProduct_Id: selectedItem.parentProduct_Id,
      //     price: selectedItem.price,
      //     products: selectedItem.products.map((product) => ({
      //       items: product.items.map((item) => ({
      //         _id: item._id,
      //         name: item.name,
      //         price: item.price,
      //         childcategory: item.name,
      //       })),
      //     })),
      //   })
      // );
      const requestOptions = {
        bundle: [],
        customization: [],
        itemId: itemDataList?.itemId,
        item_desc: itemDataList?.item_desc,
        item_image: itemDataList?.item_image,
        item_name: itemDataList?.item_name,
        restaurantId: localStorage.getItem("id"),
        totalPrice: newTotalMealPrice,
        mealbundle: newSelectedMeal,
        cartId: itemDataList._id,
      };

      console.log("requestOptions", requestOptions);
      try {
        dispatch(Addtocart(requestOptions)).then((res) => {
          // setDisableSubmitBtn(false);
          if (res.payload.status === true) {
            toast.success(res?.payload?.message);
            navigate("/ordermanagement/orderadd/Favorites");
          } else {
            toast.success(res?.payload?.message);
          }
        });
      } catch (error) {
        console.log(error);
        // setDisableSubmitBtn(false);
      }
    } else {
      // const bundleData = newSelectedItem[0]?.bundle?.map((selectedItem) => ({
      //   parentProduct_Id: selectedItem?.parentProduct_Id,
      //   price: selectedItem?.price,
      //   products: selectedItem?.products.map((product) => ({
      //     items: product?.items.map((item) => ({
      //       _id: item._id,
      //       name: item.name,
      //       price: item.price,
      //       childcategory: item.name,
      //     })),
      //   })),
      // }));

      // const requestOptions = {
      //   bundle: bundleData,
      //   customization: newSelectedItem[0]?.customization,
      //   itemId: newSelectedItem[0]?.itemId,
      //   item_desc: newSelectedItem[0]?.item_desc,
      //   item_image: newSelectedItem[0]?.item_image,
      //   item_name: newSelectedItem[0]?.item_name,
      //   restaurantId: localStorage.getItem("id"),
      //   totalPrice: newTotalPrice,
      // };

      const requestOptions = {
        bundle: newSelectedBundle,
        customization: newSelectedCustomization,
        itemId: Dataitem?._id,
        item_desc: Dataitem?.description,
        item_image: Dataitem?.image,
        item_name: Dataitem?.name,
        restaurantId: localStorage.getItem("id"),
        totalPrice: newTotalPrice,
        cartId: itemDataList._id,
      };

      console.log("requestOptions", requestOptions);
      try {
        dispatch(Addtocart(requestOptions)).then((res) => {
          // setDisableSubmitBtn(false);

          if (res.payload.status === true) {
            toast.success(res?.payload?.message);
            navigate("/ordermanagement/mycart");
          } else {
            toast.error(res?.payload?.error);
          }
        });
      } catch (error) {
        console.log(error);
        // setDisableSubmitBtn(false);
      }
    }

    // console.log("data", requestOptions);
  };
  const isSubmitDisabled = () => {
    // Check if any required customization options are not selected
    const customizationNotSelected = Dataitem?.customization?.some(
      (res) =>
        res.isRequired &&
        !newSelectedCustomization.some(
          (selectedCustomization) =>
            selectedCustomization._id === res._id &&
            selectedCustomization.items.length > 0
        )
    );

    // Check if any bundle categories have not met their selection requirements
    const bundleNotSelected = bundlesitem?.some((bundle) => {
      const customizationId = bundle._id;
      const currentQuantities = quantitiesbundles[customizationId] || {};
      const selectedCount = bundle?.bundleCategories?.bundleItems.reduce(
        (total, bundlelist) =>
          total +
          newSelectedBundle.filter(
            (res) => res.parentProduct_Id === bundlelist._id
          ).length,
        0
      );

      const newQuantity = bundle?.bundleCategories?.bundleItems.reduce(
        (acc, item) => {
          const matchingItems = newSelectedBundle.filter(
            (res) => res.parentProduct_Id === item._id
          );
          return (
            acc +
            (matchingItems.length > 0
              ? parseInt(matchingItems[0].Quantity, 10)
              : 0)
          );
        },
        0
      );

      console.log("datasss ", newQuantity);

      return (
        // Check if required bundles are not selected
        (bundle?.bundleCategories.isRequired && selectedCount === 0) ||
        // Check if the minimum number of items is not selected
        (bundle?.bundleCategories.minimumNumber &&
          selectedCount < bundle?.bundleCategories.minimumNumber &&
          newQuantity !== bundle?.bundleCategories.minimumNumber) ||
        // Check if the total quantity exceeds the maximum allowed
        (bundle?.bundleCategories.maximumNumber &&
          newQuantity > bundle?.bundleCategories.maximumNumber) ||
        // Check if selectedCount is 1 and totalCurrentQuantities does not match minimumNumber
        (bundle?.bundleCategories.minimumNumber &&
          newQuantity !== bundle?.bundleCategories.minimumNumber)
      );
    });

    return customizationNotSelected || bundleNotSelected;
  };

  // const isSubmitbun = () => {
  //   // Check if any required options are not selected
  //   return allbeundel?.customization?.some(
  //     (res) =>
  //       res.isRequired &&
  //       !newSelectedBundle.some(
  //         (selectedCustomization) =>
  //           selectedCustomization._id === res._id &&
  //           selectedCustomization.items.length > 0
  //       )
  //   );
  // };
  // console.log("newSelectedMeal", newSelectedMeal);

  const isSelectedCustomization = (option) => {
    return newSelectedCustomization?.some((res) =>
      res?.items.some((item) => item?._id === option?._id)
    );
  };

  const checkMaxOneItemSelected = (ismeal) => {
    const selectedCount = ismeal.items.reduce((total, bundlelist) => {
      return (
        total +
        newSelectedMeal.filter((res) => res.parentProduct_Id === bundlelist._id)
          .length
      );
    }, 0);
    return selectedCount > 1;
  };
  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Order" />

          <div className="container-fluid">
            <div className="mb-3">
              <a onClick={backbtn} className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back customization
              </a>
            </div>
            <div className="row ">
              <div className="col-md-4">
                <div className="item_add_left_side_cont">
                  <div className="image_cont">
                    {/* <p className="coutcat">641cals</p> */}
                    <img
                      src={Dataitem?.image ? Dataitem?.image : no}
                      alt="No Img"
                    />

                    {ismealItem == true ? (
                      <>
                        {" "}
                        <h5>
                          {Dataitem?.mealName ? Dataitem?.mealName : "NA"}
                        </h5>
                      </>
                    ) : (
                      <h5>{Dataitem?.name ? Dataitem?.name : "NA"}</h5>
                    )}

                    {ismealItem === true ? (
                      <>
                        {" "}
                        <p className="Discu">
                          {Dataitem?.description ? Dataitem?.description : "NA"}
                        </p>
                      </>
                    ) : (
                      <p className="Discu">
                        {Dataitem?.description ? Dataitem?.description : "NA"}
                      </p>
                    )}
                    {/* <p className="Discu">
                      £
                      {Dataitem?.originalPrice ? Dataitem?.originalPrice : "NA"}
                    </p> */}
                  </div>
                </div>

                {ismealItem === true ? (
                  <></>
                ) : (
                  <div className="Tab_menu">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Dietary Info
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-toggle="tab"
                          href="#profile"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Ingredients
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#contact"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          Nutrition
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <p>
                          {Dataitem?.dietaryInfo ? Dataitem?.dietaryInfo : "NA"}
                        </p>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <p>
                          {Dataitem?.ingredients ? Dataitem?.ingredients : "NA"}
                        </p>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="contact"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        {" "}
                        <ul className="indredent_item">
                          {Dataitem.nutrition?.map((res, i) => (
                            <>
                              <li key={i}>
                                <span>{res.name}</span>
                                <span className="value_text">{res.value}</span>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-8">
                <div className="item_section">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        id="headingOne"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h5 className="mb-0 d-flex align-items-center justify-content-between">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            {ismealItem === true
                              ? "Meal Category"
                              : "Customization"}
                          </button>

                          {ismealItem === true ? (
                            <>
                              {" "}
                              <span>
                                £{parseFloat(mealprice).toFixed(2)}
                              </span>{" "}
                            </>
                          ) : (
                            <span>
                              £{parseFloat(customizationPrice).toFixed(2)}
                            </span>
                          )}
                        </h5>
                      </div>
                      {ismealItem === true ? (
                        <>
                          <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="bundleCategories">
                                <div className="bundleCategories">
                                  {ismealdata?.map((ismeal, i) => {
                                    const customizationId = ismeal._id;
                                    const currentQuantities =
                                      quantitiesmeal[customizationId] || {};
                                    const selectedCount = ismeal.items.reduce(
                                      (total, bundlelist) =>
                                        total +
                                        newSelectedMeal.filter(
                                          (res) =>
                                            res.parentProduct_Id ===
                                            bundlelist._id
                                        ).length,
                                      0
                                    );

                                    const totalSelectedCounts =
                                      ismeal.items.reduce((acc, bundlelist) => {
                                        const count = newSelectedMeal.filter(
                                          (item) =>
                                            item.parentProduct_Id ===
                                            bundlelist._id
                                        ).length;
                                        acc[bundlelist._id] = count;
                                        return acc;
                                      }, {});

                                    const newQuantity = ismeal.items.reduce(
                                      (acc, item) => {
                                        const matchingItems =
                                          newSelectedMeal.filter(
                                            (res) =>
                                              res.parentProduct_Id === item._id
                                          );
                                        return (
                                          acc +
                                          (matchingItems.length > 0
                                            ? parseInt(
                                                matchingItems[0].Quantity,
                                                10
                                              )
                                            : 0)
                                        );
                                      },
                                      0
                                    );

                                    const hasMinimumNumber =
                                      ismeal?.minimumNumber &&
                                      Object.values(currentQuantities).some(
                                        (quantity) =>
                                          quantity >= ismeal.minimumNumber
                                      );

                                    // const showErrorMessage =
                                    //   // (bundle?.bundleCategories.isRequired &&
                                    //   //   selectedCount === 0) ||
                                    //   (ismeal?.minimumNumber &&
                                    //     selectedCount <
                                    //       ismeal?.minimumNumber) ||
                                    //   (ismeal?.maximumNumber &&
                                    //     selectedCount > ismeal?.maximumNumber);

                                    const showErrorMessage =
                                      (ismeal.isRequired &&
                                        selectedCount === 0) ||
                                      (ismeal.minimumNumber &&
                                        selectedCount < ismeal.minimumNumber &&
                                        !hasMinimumNumber &&
                                        newQuantity !== ismeal.minimumNumber) ||
                                      (ismeal.maximumNumber &&
                                        selectedCount > ismeal.maximumNumber) ||
                                      (ismeal.maximumNumber &&
                                        newQuantity > ismeal.maximumNumber);

                                    // Ensure default value of 1 if not set
                                    const normalizedQuantities =
                                      ismeal.items.reduce((acc, item) => {
                                        acc[item._id] =
                                          currentQuantities[item._id] || 1;
                                        return acc;
                                      }, {});

                                    // Calculate total selected count and total quantity
                                    const totalSelectedCount = Object.values(
                                      totalSelectedCounts
                                    ).reduce((acc, count) => acc + count, 0);

                                    console.log(
                                      selectedCount,
                                      "ssssss",
                                      totalSelectedCount,
                                      "selectedCount",
                                      newQuantity
                                    );

                                    const disablePlusButton =
                                      ismeal?.maximumNumber &&
                                      selectedCount >= ismeal?.maximumNumber;

                                    return (
                                      <div
                                        className="customizationbundle"
                                        key={i}
                                      >
                                        {/* Render bundle categories */}
                                        <>
                                          {" "}
                                          <h5>{ismeal?.categoryName}</h5>
                                        </>

                                        <div className="bundle_name">
                                          {ismeal?.items?.map(
                                            (bundlelist, j) => {
                                              const filteredItems =
                                                newSelectedMeal.filter(
                                                  (item) =>
                                                    item.parentProduct_Id ===
                                                    bundlelist._id
                                                );
                                              let datalit = 0;
                                              let allQuantity = 0;
                                              if (filteredItems.length > 0) {
                                                console.log(
                                                  "Filtered Items",
                                                  filteredItems
                                                );
                                                console.log(
                                                  "Total Price",
                                                  filteredItems[0].totalPrice
                                                );
                                                datalit =
                                                  filteredItems[0].totalPrice;
                                                allQuantity =
                                                  filteredItems[0].Quantity;
                                              } else {
                                                console.log(
                                                  "No items found with the given parentProduct_Id"
                                                );
                                              }

                                              const updatedQuantities = {
                                                ...currentQuantities,
                                                [bundlelist._id]: allQuantity,
                                              };

                                              const selectedCount =
                                                totalSelectedCounts[
                                                  bundlelist._id
                                                ] || 0;
                                              const currentQuantity =
                                                currentQuantities[
                                                  bundlelist._id
                                                ] || 1;

                                              return (
                                                <div
                                                  key={j}
                                                  className={`${
                                                    newSelectedMeal?.some(
                                                      (res) =>
                                                        res?.parentProduct_Id ===
                                                        bundlelist._id
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  } bundle_card`}
                                                  // onClick={() =>
                                                  //   handlemealProductClick(
                                                  //     bundlelist._id,
                                                  //     bundlelist,
                                                  //     ismeal.isMultiselect,
                                                  //     ismeal.maximumNumber,
                                                  //     ismeal?._id
                                                  //   )
                                                  // }

                                                  onClick={() =>
                                                    handleInitialMealSelection(
                                                      bundlelist,
                                                      ismeal
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      bundlelist?.image
                                                        ? bundlelist?.image
                                                        : no
                                                    }
                                                    className="bubimg"
                                                    alt="bundle-img"
                                                  />
                                                  <h5>
                                                    {bundlelist?.itemName}
                                                  </h5>

                                                  <p>
                                                    £
                                                    {((Number(datalit) || 0) +
                                                      (Number(
                                                        bundlelist?.discountPrice
                                                      ) || 0)) *
                                                      (updatedQuantities[
                                                        bundlelist._id
                                                      ] || 1)}
                                                  </p>

                                                  {ismeal.isMultiselect &&
                                                    newSelectedMeal.some(
                                                      (res) =>
                                                        res?.parentProduct_Id ===
                                                        bundlelist._id
                                                    ) && (
                                                      <div className="Cardplus">
                                                        <FontAwesomeIcon
                                                          icon={faMinus}
                                                          // onClick={(e) => {
                                                          //   e.stopPropagation();
                                                          //   const currentQuantity =
                                                          //     currentQuantities[
                                                          //       bundlelist._id
                                                          //     ] || 1;
                                                          //   if (
                                                          //     currentQuantity >
                                                          //     1
                                                          //   ) {
                                                          //     updateMealQuantity(
                                                          //       customizationId,
                                                          //       bundlelist._id,
                                                          //       currentQuantity -
                                                          //         1,
                                                          //       bundlelist,
                                                          //       ismeal
                                                          //     );
                                                          //   } else if (
                                                          //     currentQuantity ===
                                                          //     1
                                                          //   ) {
                                                          //     updateMealQuantity(
                                                          //       customizationId,
                                                          //       bundlelist._id,
                                                          //       0,
                                                          //       bundlelist,
                                                          //       ismeal
                                                          //     );
                                                          //   }
                                                          // }}

                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            console.log(
                                                              "Minus button clicked"
                                                            );
                                                            const currentQuantity =
                                                              updatedQuantities[
                                                                bundlelist._id
                                                              ] || 1;
                                                            console.log(
                                                              "Current Quantity:",
                                                              currentQuantity
                                                            );
                                                            if (
                                                              currentQuantity >
                                                              0
                                                            ) {
                                                              console.log(
                                                                "Calling updateBundleQuantity to decrease quantity"
                                                              );
                                                              updateMealQuantity(
                                                                customizationId,
                                                                bundlelist._id,
                                                                currentQuantity -
                                                                  1,
                                                                bundlelist,
                                                                ismeal
                                                              );
                                                            } else if (
                                                              currentQuantity ===
                                                              1
                                                            ) {
                                                              console.log(
                                                                "Calling updateBundleQuantity to set quantity to 0"
                                                              );
                                                              updateMealQuantity(
                                                                customizationId,
                                                                bundlelist._id,
                                                                0,
                                                                bundlelist,
                                                                ismeal
                                                              );
                                                            }
                                                          }}
                                                        />
                                                        <span>
                                                          {updatedQuantities[
                                                            bundlelist._id
                                                          ] || 1}
                                                        </span>

                                                        <FontAwesomeIcon
                                                          icon={faPlus}
                                                          id="plus_b"
                                                          className={
                                                            disablePlusButton
                                                              ? "disabled"
                                                              : ""
                                                          }
                                                          // onClick={(e) => {
                                                          //   e.stopPropagation();
                                                          //   if (
                                                          //     disablePlusButton
                                                          //   ) {
                                                          //     toast.error(
                                                          //       `You can only select up to ${ismeal?.maximumNumbe} items.`
                                                          //     );
                                                          //   } else {
                                                          //     const currentQuantity =
                                                          //       currentQuantities[
                                                          //         bundlelist._id
                                                          //       ] || 1;
                                                          //     const totalSelectedItems =
                                                          //       Object.values(
                                                          //         currentQuantities
                                                          //       ).reduce(
                                                          //         (acc, qty) =>
                                                          //           acc + qty,
                                                          //         0
                                                          //       );

                                                          //     if (
                                                          //       currentQuantity <
                                                          //         ismeal?.maximumNumber &&
                                                          //       totalSelectedItems <
                                                          //         ismeal?.maximumNumber
                                                          //     ) {
                                                          //       updateMealQuantity(
                                                          //         customizationId,
                                                          //         bundlelist._id,
                                                          //         currentQuantity +
                                                          //           1,
                                                          //         bundlelist,
                                                          //         ismeal
                                                          //       );
                                                          //     } else {
                                                          //       toast.error(
                                                          //         `You can only select up to ${ismeal?.maximumNumber} items.`
                                                          //       );
                                                          //     }
                                                          //   }
                                                          // }}
                                                          onClick={(e) => {
                                                            e.stopPropagation();

                                                            // Check if the plus button should be disabled
                                                            if (
                                                              disablePlusButton
                                                            ) {
                                                              toast.error(
                                                                `You can only select up to ${ismeal.maximumNumber} items.`
                                                              );
                                                            } else {
                                                              const currentQuantity =
                                                                Number(
                                                                  updatedQuantities[
                                                                    bundlelist
                                                                      ._id
                                                                  ]
                                                                ) || 1;
                                                              const totalSelectedItems =
                                                                Object.values(
                                                                  updatedQuantities
                                                                ).reduce(
                                                                  (acc, qty) =>
                                                                    acc +
                                                                    Number(
                                                                      qty || 0
                                                                    ),
                                                                  0
                                                                );

                                                              // Check if adding one more item exceeds the maximum number
                                                              if (
                                                                currentQuantity <
                                                                  (ismeal?.maximumNumber ||
                                                                    Infinity) &&
                                                                totalSelectedItems <
                                                                  (ismeal?.maximumNumber ||
                                                                    Infinity)
                                                              ) {
                                                                updateMealQuantity(
                                                                  customizationId,
                                                                  bundlelist._id,
                                                                  currentQuantity +
                                                                    1,
                                                                  bundlelist,
                                                                  ismeal
                                                                );
                                                              } else {
                                                                toast.error(
                                                                  `You can only select up to ${ismeal.maximumNumber} items.`
                                                                );
                                                              }
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                        {/* Conditionally display maximum selection message */}
                                        {/* {ismeal.isMultiselect && (
                                          <p
                                            style={{
                                              color: "red",
                                              marginBottom: "1rem",
                                              display: (() => {
                                                const selectedCount =
                                                  ismeal.items.reduce(
                                                    (total, bundlelist) => {
                                                      return (
                                                        total +
                                                        newSelectedMeal.filter(
                                                          (res) =>
                                                            res.parentProduct_Id ===
                                                            bundlelist._id
                                                        ).length
                                                      );
                                                    },
                                                    0
                                                  );

                                                if (
                                                  ismeal.minimumNumber &&
                                                  selectedCount <
                                                    ismeal.minimumNumber
                                                ) {
                                                  return "block";
                                                } else if (
                                                  selectedCount >
                                                  ismeal.maximumNumber
                                                ) {
                                                  return "block";
                                                } else {
                                                  return "none";
                                                }
                                              })(),
                                            }}
                                          >
                                            {(() => {
                                              const selectedCount =
                                                ismeal.items.reduce(
                                                  (total, bundlelist) => {
                                                    return (
                                                      total +
                                                      newSelectedMeal.filter(
                                                        (res) =>
                                                          res.parentProduct_Id ===
                                                          bundlelist._id
                                                      ).length
                                                    );
                                                  },
                                                  0
                                                );

                                              if (
                                                ismeal.minimumNumber &&
                                                selectedCount <
                                                  ismeal.minimumNumber
                                              ) {
                                                return `Select at least ${ismeal.minimumNumber} item(s).`;
                                              } else if (
                                                selectedCount >
                                                ismeal.maximumNumber
                                              ) {
                                                return `Maximum ${ismeal.maximumNumber} item(s) can be selected.`;
                                              } else {
                                                return "";
                                              }
                                            })()}
                                          </p>
                                        )}

                                        {ismeal.isMultiselect === false && (
                                          <p
                                            style={{
                                              color: "red",
                                              marginBottom: "1rem",
                                              display:
                                                "1" &&
                                                "1" >
                                                  ismeal?.items?.reduce(
                                                    (total, bundlelist) => {
                                                      return (
                                                        total +
                                                        newSelectedMeal.filter(
                                                          (res) =>
                                                            res?.parentProduct_Id ===
                                                            bundlelist._id
                                                        ).length
                                                      );
                                                    },
                                                    0
                                                  )
                                                  ? "block"
                                                  : newSelectedMeal.length > "1"
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            {"1" &&
                                            "1" >
                                              ismeal?.items?.reduce(
                                                (total, bundlelist) => {
                                                  return (
                                                    total +
                                                    newSelectedMeal.filter(
                                                      (res) =>
                                                        res?.parentProduct_Id ===
                                                        bundlelist._id
                                                    ).length
                                                  );
                                                },
                                                0
                                              )
                                              ? `Select at least 1 item(s)`
                                              : newSelectedMeal.length > "1"
                                              ? `Maximum 1 item(s) can be selected`
                                              : ""}
                                          </p>
                                        )} */}

                                        {ismeal.isMultiselect === false && (
                                          <>
                                            <p
                                              style={{
                                                color: "red",
                                                marginBottom: "1rem",
                                                display:
                                                  checkMaxOneItemSelected(
                                                    ismeal
                                                  )
                                                    ? "block"
                                                    : "none",
                                              }}
                                            >
                                              {checkMaxOneItemSelected(ismeal)
                                                ? `Maximum 1 item(s) can be selected`
                                                : ""}
                                            </p>
                                          </>
                                        )}

                                        {ismeal.isMultiselect !== false && (
                                          <>
                                            {showErrorMessage && (
                                              <p
                                                style={{
                                                  color: "red",
                                                  marginBottom: "1rem",
                                                }}
                                              >
                                                {ismeal.isRequired &&
                                                selectedCount === 0
                                                  ? "This Select item is required."
                                                  : ismeal.minimumNumber &&
                                                    selectedCount <
                                                      ismeal.minimumNumber &&
                                                    !hasMinimumNumber
                                                  ? `Select at least ${ismeal.minimumNumber} item(s).`
                                                  : ismeal.maximumNumber &&
                                                    selectedCount >
                                                      ismeal.maximumNumber
                                                  ? `Maximum ${ismeal.maximumNumber} item(s) can be selected.`
                                                  : ismeal.maximumNumber &&
                                                    newQuantity >
                                                      ismeal.maximumNumber
                                                  ? `Maximum ${ismeal.maximumNumber} items can be selected`
                                                  : ""}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent=""
                        >
                          <div className="card-body">
                            <div className="Itemsize">
                              {Dataitem?.customization?.map((res, i) => {
                                const hasSelectedRequiredOption =
                                  newSelectedCustomization.some(
                                    (selectedCustomization) =>
                                      selectedCustomization._id === res._id &&
                                      selectedCustomization.items.length > 0
                                  );

                                return (
                                  <div className="customizationdata" key={i}>
                                    <h5>{res.title}</h5>
                                    <div className="customizationrediobtn">
                                      {res.isMultiselect
                                        ? res.items.map((option, j) => (
                                            <div key={j}>
                                              {option.image ? (
                                                <div
                                                  className={`form-check Cardsection ${
                                                    isSelectedCustomization(
                                                      option
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={option?.image}
                                                    className="cardimg"
                                                    alt="catimg"
                                                  />
                                                  <div className="menu_input">
                                                    <label
                                                      htmlFor={`${option.name}`}
                                                      className="form-check-label"
                                                    >
                                                      {option.name}
                                                    </label>
                                                    <input
                                                      type="checkbox"
                                                      name={`${option.name}`}
                                                      value={option._id}
                                                      onChange={(e) =>
                                                        handleChangeCustomizationMulti(
                                                          res?._id,
                                                          option,
                                                          parseInt(
                                                            res.maximumNumber,
                                                            10
                                                          ),
                                                          res?.isRequired
                                                        )
                                                      }
                                                      className="form-check-input"
                                                      id={`${option._id}`}
                                                      checked={isSelectedCustomization(
                                                        option
                                                      )}
                                                    />
                                                  </div>
                                                  <h5>
                                                    £
                                                    {parseFloat(
                                                      option.price
                                                    ).toFixed(2)}{" "}
                                                  </h5>
                                                </div>
                                              ) : (
                                                <div
                                                  className={`form-check  ${
                                                    isSelectedCustomization(
                                                      option
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                >
                                                  <div className="menu_input">
                                                    <label
                                                      htmlFor={`${option.name}`}
                                                      className="form-check-label"
                                                    >
                                                      {option.name}
                                                    </label>
                                                    <input
                                                      type="checkbox"
                                                      name={`${option.name}`}
                                                      value={option._id}
                                                      onChange={(e) =>
                                                        handleChangeCustomizationMulti(
                                                          res?._id,
                                                          option,
                                                          parseInt(
                                                            res.maximumNumber,
                                                            10
                                                          ),
                                                          res?.isRequired
                                                        )
                                                      }
                                                      className="form-check-input"
                                                      id={`${option._id}`}
                                                      checked={isSelectedCustomization(
                                                        option
                                                      )}
                                                    />
                                                  </div>
                                                  <h5>
                                                    £
                                                    {parseFloat(
                                                      option.price
                                                    ).toFixed(2)}
                                                  </h5>
                                                </div>
                                              )}
                                            </div>
                                          ))
                                        : res.items.map((option, j) => (
                                            <div key={j}>
                                              {option.image ? (
                                                <div
                                                  className={`form-check Cardsection ${
                                                    isSelectedCustomization(
                                                      option
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={option?.image}
                                                    className="cardimg"
                                                    alt="catimg"
                                                  />
                                                  <div className="menu_input">
                                                    <label
                                                      htmlFor={`${option.name}`}
                                                      className="form-check-label"
                                                    >
                                                      {option.name}
                                                    </label>
                                                    <input
                                                      type="checkbox"
                                                      name={`${option.name}`}
                                                      value={option._id}
                                                      onChange={(e) =>
                                                        handleChangeCustomization(
                                                          res?._id,
                                                          option,
                                                          parseInt(
                                                            res.maximumNumber,
                                                            10
                                                          ),
                                                          res?.isRequired
                                                        )
                                                      }
                                                      className="form-check-input"
                                                      id={`${option._id}`}
                                                      checked={isSelectedCustomization(
                                                        option
                                                      )}
                                                    />
                                                  </div>
                                                  <h5>£{option.price}</h5>
                                                </div>
                                              ) : (
                                                <div
                                                  className={`form-check  ${
                                                    isSelectedCustomization(
                                                      option
                                                    )
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                >
                                                  <div className="menu_input">
                                                    <label
                                                      htmlFor={`${option.name}`}
                                                      className="form-check-label"
                                                    >
                                                      {option.name}
                                                    </label>
                                                    <input
                                                      type="checkbox"
                                                      name={`${option.name}`}
                                                      value={option._id}
                                                      onChange={(e) =>
                                                        handleChangeCustomization(
                                                          res?._id,
                                                          option,
                                                          parseInt(
                                                            res.maximumNumber,
                                                            10
                                                          ),
                                                          res?.isRequired
                                                        )
                                                      }
                                                      className="form-check-input"
                                                      id={`${option._id}`}
                                                      checked={isSelectedCustomization(
                                                        option
                                                      )}
                                                    />
                                                  </div>
                                                  <h5>
                                                    £
                                                    {parseFloat(
                                                      option.price
                                                    ).toFixed(2)}{" "}
                                                  </h5>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                    </div>
                                    {/* Validation for isRequired */}
                                    {res.isRequired &&
                                      !hasSelectedRequiredOption && (
                                        <div
                                          className="new_errror"
                                          style={{
                                            color: "red",
                                            marginTop: "0.5rem",
                                          }}
                                        >
                                          Please select at least one option for{" "}
                                          {res.title}.
                                        </div>
                                      )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {ismealItem === true ? (
                      ""
                    ) : (
                      <>
                        {" "}
                        {bundlesitem.length === 0 ? (
                          ""
                        ) : (
                          <>
                            {" "}
                            <div className="card">
                              <div className="card-header" id="headingTwo">
                                <h5 className="mb-0 d-flex align-items-center justify-content-between">
                                  <span>
                                    £{parseFloat(bundleprice).toFixed(2)}
                                  </span>
                                </h5>
                              </div>

                              <div
                                id="collapseTwo"
                                className="collapse show"
                                aria-labelledby="headingTwo"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <div className="bundleCategories">
                                    {bundlesitem?.map((bundle, i) => {
                                      const customizationId = bundle._id;
                                      const currentQuantities =
                                        quantitiesbundles[customizationId] ||
                                        {};
                                      const selectedCount =
                                        bundle?.bundleCategories?.bundleItems.reduce(
                                          (total, bundlelist) =>
                                            total +
                                            newSelectedBundle.filter(
                                              (res) =>
                                                res.parentProduct_Id ===
                                                bundlelist._id
                                            ).length,
                                          0
                                        );

                                      // Calculate if the minimum number requirement is met
                                      const hasMinimumNumber =
                                        bundle?.bundleCategories
                                          ?.minimumNumber &&
                                        Object.values(currentQuantities).some(
                                          (quantity) =>
                                            quantity >=
                                            bundle?.bundleCategories
                                              .minimumNumber
                                        );

                                      const newQuantity =
                                        bundle?.bundleCategories?.bundleItems.reduce(
                                          (acc, item) => {
                                            const matchingItems =
                                              newSelectedBundle.filter(
                                                (res) =>
                                                  res.parentProduct_Id ===
                                                  item._id
                                              );
                                            return (
                                              acc +
                                              (matchingItems.length > 0
                                                ? parseInt(
                                                    matchingItems[0].Quantity,
                                                    10
                                                  )
                                                : 0)
                                            );
                                          },
                                          0
                                        );

                                      const showErrorMessage =
                                        (bundle?.bundleCategories.isRequired &&
                                          selectedCount === 0) ||
                                        (bundle?.bundleCategories
                                          .minimumNumber &&
                                          selectedCount <
                                            bundle?.bundleCategories
                                              .minimumNumber &&
                                          !hasMinimumNumber &&
                                          newQuantity !==
                                            bundle?.bundleCategories
                                              .minimumNumber) ||
                                        (bundle?.bundleCategories
                                          .maximumNumber &&
                                          selectedCount >
                                            bundle?.bundleCategories
                                              .maximumNumber) ||
                                        (bundle?.bundleCategories
                                          .maximumNumber &&
                                          newQuantity >
                                            bundle?.bundleCategories
                                              .maximumNumber);

                                      const totalSelectedItems = Object.values(
                                        currentQuantities
                                      ).reduce((acc, qty) => acc + qty, 0);

                                      const disablePlusButton =
                                        bundle?.bundleCategories
                                          ?.maximumNumber &&
                                        selectedCount >=
                                          bundle?.bundleCategories
                                            .maximumNumber;

                                      console.log("newQuantity", newQuantity);
                                      return (
                                        <div
                                          className="customizationbundle"
                                          key={i}
                                        >
                                          <h5>
                                            {
                                              bundle?.bundleCategories
                                                ?.categoryName
                                            }
                                          </h5>
                                          <div className="bundle_name">
                                            {bundle?.bundleCategories?.bundleItems?.map(
                                              (bundlelist, j) => {
                                                const filteredItems =
                                                  newSelectedBundle.filter(
                                                    (item) =>
                                                      item.parentProduct_Id ===
                                                      bundlelist._id
                                                  );
                                                let datalit = 0;
                                                let allQuantity = 0;
                                                if (filteredItems.length > 0) {
                                                  console.log(
                                                    "Filtered Items",
                                                    filteredItems
                                                  );
                                                  console.log(
                                                    "Total Quantity",
                                                    filteredItems[0].Quantity
                                                  );
                                                  datalit =
                                                    filteredItems[0].totalPrice;
                                                  allQuantity =
                                                    filteredItems[0].Quantity;
                                                } else {
                                                  console.log(
                                                    "No items found with the given parentProduct_Id"
                                                  );
                                                }

                                                const updatedQuantities = {
                                                  ...currentQuantities,
                                                  [bundlelist._id]: allQuantity,
                                                };

                                                return (
                                                  <div
                                                    key={j}
                                                    className={`bundle_card ${
                                                      newSelectedBundle?.some(
                                                        (res) =>
                                                          res?.parentProduct_Id ===
                                                          bundlelist._id
                                                      )
                                                        ? "selected"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      handleInitialSelection(
                                                        bundlelist,
                                                        bundle
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        bundlelist?.image
                                                          ? bundlelist?.image
                                                          : no
                                                      }
                                                      className="bubimg"
                                                      alt="bundle-img"
                                                    />
                                                    <h5>{bundlelist?.name}</h5>
                                                    <p>
                                                      £
                                                      {((Number(datalit) || 0) +
                                                        (Number(
                                                          bundlelist?.sellPrice
                                                        ) || 0)) *
                                                        (updatedQuantities[
                                                          bundlelist._id
                                                        ] || 1)}
                                                    </p>

                                                    {bundle?.bundleCategories
                                                      .isMultiSelector &&
                                                      newSelectedBundle.some(
                                                        (res) =>
                                                          res?.parentProduct_Id ===
                                                          bundlelist._id
                                                      ) && (
                                                        <div className="Cardplus">
                                                          <FontAwesomeIcon
                                                            icon={faMinus}
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              console.log(
                                                                "Minus button clicked"
                                                              );
                                                              const currentQuantity =
                                                                updatedQuantities[
                                                                  bundlelist._id
                                                                ] || 1;
                                                              console.log(
                                                                "Current Quantity:",
                                                                currentQuantity
                                                              );
                                                              if (
                                                                currentQuantity >
                                                                0
                                                              ) {
                                                                console.log(
                                                                  "Calling updateBundleQuantity to decrease quantity"
                                                                );
                                                                updateBundleQuantity(
                                                                  customizationId,
                                                                  bundlelist._id,
                                                                  currentQuantity -
                                                                    1,
                                                                  bundlelist,
                                                                  bundle
                                                                );
                                                              } else if (
                                                                currentQuantity ===
                                                                1
                                                              ) {
                                                                console.log(
                                                                  "Calling updateBundleQuantity to set quantity to 0"
                                                                );
                                                                updateBundleQuantity(
                                                                  customizationId,
                                                                  bundlelist._id,
                                                                  0,
                                                                  bundlelist,
                                                                  bundle
                                                                );
                                                              }
                                                            }}
                                                          />
                                                          <span>
                                                            {updatedQuantities[
                                                              bundlelist._id
                                                            ] || 1}
                                                          </span>
                                                          <FontAwesomeIcon
                                                            icon={faPlus}
                                                            id="plus_b"
                                                            onClick={(e) => {
                                                              e.stopPropagation();

                                                              // Check if the plus button should be disabled
                                                              if (
                                                                disablePlusButton
                                                              ) {
                                                                toast.error(
                                                                  `You can only select up to ${bundle?.bundleCategories.maximumNumber} items.`
                                                                );
                                                              } else {
                                                                const currentQuantity =
                                                                  Number(
                                                                    updatedQuantities[
                                                                      bundlelist
                                                                        ._id
                                                                    ]
                                                                  ) || 1;
                                                                const totalSelectedItems =
                                                                  Object.values(
                                                                    updatedQuantities
                                                                  ).reduce(
                                                                    (
                                                                      acc,
                                                                      qty
                                                                    ) =>
                                                                      acc +
                                                                      Number(
                                                                        qty || 0
                                                                      ),
                                                                    0
                                                                  );

                                                                // Check if adding one more item exceeds the maximum number
                                                                if (
                                                                  currentQuantity <
                                                                    (bundle
                                                                      ?.bundleCategories
                                                                      ?.maximumNumber ||
                                                                      Infinity) &&
                                                                  totalSelectedItems <
                                                                    (bundle
                                                                      ?.bundleCategories
                                                                      ?.maximumNumber ||
                                                                      Infinity)
                                                                ) {
                                                                  updateBundleQuantity(
                                                                    customizationId,
                                                                    bundlelist._id,
                                                                    currentQuantity +
                                                                      1,
                                                                    bundlelist,
                                                                    bundle
                                                                  );
                                                                } else {
                                                                  toast.error(
                                                                    `You can only select up to ${bundle?.bundleCategories.maximumNumber} items.`
                                                                  );
                                                                }
                                                              }
                                                            }}
                                                            className={
                                                              disablePlusButton
                                                                ? "disabled"
                                                                : ""
                                                            }
                                                          />
                                                        </div>
                                                      )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                          {showErrorMessage && (
                                            <p
                                              style={{
                                                color: "red",
                                                marginBottom: "1rem",
                                              }}
                                            >
                                              {bundle?.bundleCategories
                                                .isRequired &&
                                              selectedCount === 0
                                                ? "This Select item is required."
                                                : bundle?.bundleCategories
                                                    .minimumNumber &&
                                                  selectedCount <
                                                    bundle?.bundleCategories
                                                      .minimumNumber &&
                                                  !hasMinimumNumber
                                                ? `Select at least ${bundle?.bundleCategories.minimumNumber} item(s).`
                                                : bundle?.bundleCategories
                                                    .maximumNumber &&
                                                  selectedCount >
                                                    bundle?.bundleCategories
                                                      .maximumNumber
                                                ? `Maximum ${bundle?.bundleCategories.maximumNumber} item(s) can be selected.`
                                                : bundle?.bundleCategories
                                                    .maximumNumber &&
                                                  newQuantity >
                                                    bundle?.bundleCategories
                                                      .maximumNumber
                                                ? `Maximum ${bundle?.bundleCategories.maximumNumber} items can be selected`
                                                : ""}
                                            </p>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end py-2 pb-5">
                  {!isSubmitDisabled() && (
                    <>
                      {ismealItem === true ? (
                        <>
                          {ismealdata?.every((ismeal) => {
                            // Check if every category meets the selection criteria
                            if (ismeal.isMultiselect === true) {
                              const selectedCount = ismeal?.items?.reduce(
                                (total, bundlelist) => {
                                  return (
                                    total +
                                    newSelectedMeal.filter(
                                      (res) =>
                                        res?.parentProduct_Id === bundlelist._id
                                    ).length
                                  );
                                },
                                0
                              );

                              // Check maximumNumber condition
                              if (
                                ismeal.maximumNumber &&
                                selectedCount > ismeal.maximumNumber
                              ) {
                                return false; // Do not render button if selected count exceeds maximumNumber
                              }

                              // Check minimumNumber condition
                              if (
                                ismeal.minimumNumber &&
                                selectedCount < ismeal.minimumNumber
                              ) {
                                return false; // Do not render button if selected count is less than minimumNumber
                              }
                            }

                            return true; // Render button if all conditions are met for this category
                          }) && (
                            <button
                              className="basket_button"
                              onClick={handleSubmit}
                            >
                              Add To Basket
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          className="basket_button"
                          onClick={handleSubmit}
                        >
                          Add To Basket
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style} className="bundlemodal">
                {ismealItem === true ? (
                  <>
                    {" "}
                    <div className="modal_body_container Team_modal container-fluid">
                      <div className="modal_heading mt-4">
                        <h1>Product Customization meal </h1>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="item_add_left_side_cont">
                            <div className="image_cont">
                              {/* <p className="coutcat">641cals</p> */}
                              <img
                                src={allmeal.image ? allmeal.image : no}
                                alt="No Img"
                              />
                              <h5>
                                {allmeal?.itemName ? allmeal?.itemName : "NA"}
                              </h5>
                              <p className="Discu">
                                {allmeal?.description
                                  ? allmeal?.description
                                  : "NA"}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="item_section">
                            <div id="accordion1">
                              <div className="card">
                                <div
                                  className="card-header"
                                  id="headingOne1"
                                  data-toggle="collapse"
                                  data-target="#collapseOne1"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  <h5 className="mb-0 d-flex align-items-center justify-content-between">
                                    <button
                                      className="btn btn-link"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Main
                                    </button>
                                    {/* <span>£10.00</span> */}
                                  </h5>
                                </div>

                                <div
                                  id="collapseOne1"
                                  className="collapse show"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion1"
                                >
                                  <div className="card-body">
                                    <div className="Itemsize">
                                      {allmeal?.mealCustomization?.map(
                                        (res, i) => {
                                          const hasSelectedRequiredOption3 =
                                            newSelectedMeal.some(
                                              (selectedCustomization) =>
                                                selectedCustomization.parentProduct_Id ===
                                                  allmeal?._id &&
                                                selectedCustomization.products.some(
                                                  (product) =>
                                                    product._id === res._id &&
                                                    product.items.length > 0
                                                )
                                            );

                                          return (
                                            <div
                                              className="customizationdata"
                                              key={i}
                                            >
                                              <h5>{res.title}</h5>

                                              <div className="customizationrediobtn">
                                                {res.isMultiselect
                                                  ? res.items.map(
                                                      (option, j) => (
                                                        <>
                                                          {" "}
                                                          <div>
                                                            {option.image ? (
                                                              <>
                                                                {" "}
                                                                <div
                                                                  key={j}
                                                                  className={`form-check Cardsection ${
                                                                    isSelectedMeal(
                                                                      option
                                                                    )
                                                                      ? "selected"
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  <img
                                                                    src={
                                                                      option?.image !==
                                                                      undefined
                                                                        ? option.image
                                                                        : no
                                                                    }
                                                                    className="cardimg"
                                                                    alt="catimg"
                                                                  />
                                                                  <div className="menu_input">
                                                                    <label
                                                                      htmlFor={`${option.name}`}
                                                                      className="form-check-label"
                                                                    >
                                                                      {
                                                                        option.name
                                                                      }
                                                                    </label>
                                                                    <input
                                                                      type="checkbox"
                                                                      name={`${option.name}`}
                                                                      value={
                                                                        option._id
                                                                      }
                                                                      onChange={() =>
                                                                        handleChangeMealizeMulti(
                                                                          allmeal?._id,
                                                                          allmeal?.discountPrice
                                                                            ? allmeal?.discountPrice
                                                                            : allmeal?.actualPrice,
                                                                          res?._id,
                                                                          option,
                                                                          parseInt(
                                                                            res.maximumNumber,
                                                                            10
                                                                          ),
                                                                          res?.isRequired
                                                                        )
                                                                      }
                                                                      className="form-check-input"
                                                                      id={`${option._id}`}
                                                                      checked={isSelected(
                                                                        option
                                                                      )}
                                                                    />
                                                                  </div>
                                                                  <h5>
                                                                    £
                                                                    {parseFloat(
                                                                      option.discountPrice
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </h5>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <div
                                                                  key={j}
                                                                  className={`form-check  ${
                                                                    isSelectedMeal(
                                                                      option
                                                                    )
                                                                      ? "selected"
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  <div className="menu_input">
                                                                    <label
                                                                      htmlFor={`${option.name}`}
                                                                      className="form-check-label"
                                                                    >
                                                                      {
                                                                        option.name
                                                                      }
                                                                    </label>
                                                                    <input
                                                                      type="checkbox"
                                                                      name={`${option.name}`}
                                                                      value={
                                                                        option._id
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeMealizeMulti(
                                                                          allmeal?._id,
                                                                          allmeal?.discountPrice
                                                                            ? allmeal?.discountPrice
                                                                            : allmeal?.actualPrice,
                                                                          res?._id,
                                                                          option,
                                                                          parseInt(
                                                                            res.maximumNumber,
                                                                            10
                                                                          ),
                                                                          res?.isRequired
                                                                        )
                                                                      }
                                                                      className="form-check-input"
                                                                      id={`${option._id}`}
                                                                      checked={isSelectedMeal(
                                                                        option
                                                                      )}
                                                                    />
                                                                  </div>
                                                                  <h5>
                                                                    £
                                                                    {
                                                                      option.discountPrice
                                                                    }
                                                                  </h5>
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </>
                                                      )
                                                    )
                                                  : res.items.map(
                                                      (option, j) => (
                                                        <>
                                                          {option.image ? (
                                                            <>
                                                              {" "}
                                                              <div
                                                                key={j}
                                                                className={`form-check Cardsection ${
                                                                  isSelectedMeal(
                                                                    option
                                                                  )
                                                                    ? "selected"
                                                                    : ""
                                                                }`}
                                                              >
                                                                <img
                                                                  src={
                                                                    option?.image !==
                                                                    undefined
                                                                      ? option.image
                                                                      : no
                                                                  }
                                                                  className="cardimg"
                                                                  alt="catimg"
                                                                />
                                                                <div className="menu_input">
                                                                  <label
                                                                    htmlFor={`${option.name}`}
                                                                    className="form-check-label"
                                                                  >
                                                                    {
                                                                      option.name
                                                                    }
                                                                  </label>
                                                                  <input
                                                                    type="checkbox"
                                                                    name={`${option.name}`}
                                                                    value={
                                                                      option._id
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeMealSize(
                                                                        allmeal?._id,
                                                                        allmeal?.discountPrice
                                                                          ? allmeal?.discountPrice
                                                                          : allmeal?.actualPrice,

                                                                        res?._id,
                                                                        option,
                                                                        parseInt(
                                                                          res.maximumNumber,
                                                                          10
                                                                        ),
                                                                        res?.isRequired
                                                                      )
                                                                    }
                                                                    className="form-check-input"
                                                                    id={`${option._id}`}
                                                                    checked={isSelectedMeal(
                                                                      option
                                                                    )}
                                                                  />
                                                                </div>
                                                                <h5>
                                                                  £
                                                                  {
                                                                    option.discountPrice
                                                                  }
                                                                </h5>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div
                                                                key={j}
                                                                className={`form-check  ${
                                                                  isSelectedMeal(
                                                                    option
                                                                  )
                                                                    ? "selected"
                                                                    : ""
                                                                }`}
                                                              >
                                                                <div className="menu_input">
                                                                  <label
                                                                    htmlFor={`${option.name}`}
                                                                    className="form-check-label"
                                                                  >
                                                                    {
                                                                      option.name
                                                                    }
                                                                  </label>
                                                                  <input
                                                                    type="checkbox"
                                                                    name={`${option.name}`}
                                                                    value={
                                                                      option._id
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeMealSize(
                                                                        allmeal?._id,
                                                                        allmeal?.discountPrice
                                                                          ? allmeal?.discountPrice
                                                                          : allmeal?.actualPrice,

                                                                        res?._id,
                                                                        option,
                                                                        parseInt(
                                                                          res.maximumNumber,
                                                                          10
                                                                        ),
                                                                        res?.isRequired
                                                                      )
                                                                    }
                                                                    className="form-check-input"
                                                                    id={`${option._id}`}
                                                                    checked={isSelectedMeal(
                                                                      option
                                                                    )}
                                                                  />
                                                                </div>
                                                                <h5>
                                                                  £
                                                                  {parseFloat(
                                                                    option.discountPrice
                                                                  ).toFixed(2)}
                                                                </h5>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                              </div>

                                              {res.isRequired &&
                                                !hasSelectedRequiredOption3 && (
                                                  <div
                                                    style={{
                                                      color: "red",
                                                      marginTop: "0.5rem",
                                                    }}
                                                  >
                                                    Please select at least one
                                                    option for {res.title}.
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal_btn_container">
                        <div
                          className="modal_cancel_btn"
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </div>

                        {/* {!isSubmitbun() && ( */}
                        <button
                          className="modal_submit_btn"
                          type="submit"
                          // disabled={disableSubmitBtn == true}
                          onClick={() => handleSubmitdata()}
                        >
                          Submit Meal
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="modal_body_container Team_modal container-fluid">
                      <div className="modal_heading mt-4">
                        <h1>Product Customization </h1>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="item_add_left_side_cont">
                            <div className="image_cont">
                              {/* <p className="coutcat">641cals</p> */}
                              <img
                                src={
                                  allbeundel.image ? allbeundel.image : burger
                                }
                                alt="No Img"
                              />
                              <h5>
                                {allbeundel?.name ? allbeundel?.name : "NA"}
                              </h5>
                              <p className="Discu">
                                {allbeundel?.description
                                  ? allbeundel?.description
                                  : "NA"}
                              </p>
                            </div>
                          </div>
                          <div className="Tab_menu">
                            <ul
                              className="nav nav-tabs"
                              id="mybundel"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  id="home-tab"
                                  data-toggle="tab"
                                  href="#home1"
                                  role="tab"
                                  aria-controls="home"
                                  aria-selected="true"
                                >
                                  Dietary Info
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="profile-tab"
                                  data-toggle="tab"
                                  href="#profile2"
                                  role="tab"
                                  aria-controls="profile"
                                  aria-selected="false"
                                >
                                  Ingredients
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="contact-tab"
                                  data-toggle="tab"
                                  href="#contact3"
                                  role="tab"
                                  aria-controls="contact"
                                  aria-selected="false"
                                >
                                  Nutrition
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content" id="myTabbundel">
                              <div
                                className="tab-pane fade show active"
                                id="home1"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                              >
                                <p>
                                  {allbeundel?.dietaryInfo
                                    ? allbeundel?.dietaryInfo
                                    : "NA"}
                                </p>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="profile2"
                                role="tabpanel"
                                aria-labelledby="profile-tab"
                              >
                                <p>
                                  {allbeundel?.ingredients
                                    ? allbeundel?.ingredients
                                    : "NA"}
                                </p>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="contact3"
                                role="tabpanel"
                                aria-labelledby="contact-tab"
                              >
                                {" "}
                                <ul className="indredent_item">
                                  {allbeundel.nutrition?.map((res, i) => (
                                    <>
                                      <li key={i}>
                                        <span>{res.name}</span>
                                        <span className="value_text">
                                          {res.value}
                                        </span>
                                      </li>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="item_section">
                            <div id="accordion1">
                              <div className="card">
                                <div
                                  className="card-header"
                                  id="headingOne1"
                                  data-toggle="collapse"
                                  data-target="#collapseOne1"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  <h5 className="mb-0 d-flex align-items-center justify-content-between">
                                    <button
                                      className="btn btn-link"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Main
                                    </button>
                                    {/* <span>£10.00</span> */}
                                  </h5>
                                </div>

                                <div
                                  id="collapseOne1"
                                  className="collapse show"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion1"
                                >
                                  <div className="card-body">
                                    <div className="Itemsize">
                                      {allbeundel?.customization?.map(
                                        (res, i) => {
                                          const hasSelectedRequiredOption2 =
                                            newSelectedBundle.some(
                                              (selectedCustomization) =>
                                                selectedCustomization.parentProduct_Id ===
                                                  allbeundel?._id &&
                                                selectedCustomization.products.some(
                                                  (product) =>
                                                    product._id === res._id &&
                                                    product.items.length > 0
                                                )
                                            );

                                          return (
                                            <div
                                              className="customizationdata"
                                              key={i}
                                            >
                                              <h5>{res.title}</h5>

                                              <div className="customizationrediobtn">
                                                {res.isMultiselect
                                                  ? res.items.map(
                                                      (option, j) => (
                                                        <>
                                                          {" "}
                                                          <div>
                                                            {option.image ? (
                                                              <>
                                                                {" "}
                                                                <div
                                                                  key={j}
                                                                  className={`form-check Cardsection ${
                                                                    isSelected(
                                                                      option
                                                                    )
                                                                      ? "selected"
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  <img
                                                                    src={
                                                                      option?.image
                                                                    }
                                                                    className="cardimg"
                                                                    alt="catimg"
                                                                  />
                                                                  <div className="menu_input">
                                                                    <label
                                                                      htmlFor={`${option.name}`}
                                                                      className="form-check-label"
                                                                    >
                                                                      {
                                                                        option.name
                                                                      }
                                                                    </label>
                                                                    <input
                                                                      type="checkbox"
                                                                      name={`${option.name}`}
                                                                      value={
                                                                        option._id
                                                                      }
                                                                      onChange={() =>
                                                                        handleChangeBundleSizeMulti(
                                                                          allbeundel?._id,
                                                                          allbeundel?.sellPrice
                                                                            ? allbeundel?.sellPrice
                                                                            : allbeundel?.originalPrice,
                                                                          res?._id,
                                                                          option,
                                                                          parseInt(
                                                                            res.maximumNumber,
                                                                            10
                                                                          ),
                                                                          res?.isRequired
                                                                        )
                                                                      }
                                                                      className="form-check-input"
                                                                      id={`${option._id}`}
                                                                      checked={isSelected(
                                                                        option
                                                                      )}
                                                                    />
                                                                  </div>
                                                                  <h5>
                                                                    £
                                                                    {parseFloat(
                                                                      option.price
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </h5>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <div
                                                                  key={j}
                                                                  className={`form-check  ${
                                                                    isSelected(
                                                                      option
                                                                    )
                                                                      ? "selected"
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  <div className="menu_input">
                                                                    <label
                                                                      htmlFor={`${option.name}`}
                                                                      className="form-check-label"
                                                                    >
                                                                      {
                                                                        option.name
                                                                      }
                                                                    </label>
                                                                    <input
                                                                      type="checkbox"
                                                                      name={`${option.name}`}
                                                                      value={
                                                                        option._id
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChangeBundleSizeMulti(
                                                                          allbeundel?._id,
                                                                          allbeundel?.sellPrice
                                                                            ? allbeundel?.sellPrice
                                                                            : allbeundel?.originalPrice,
                                                                          res?._id,
                                                                          option,
                                                                          parseInt(
                                                                            res.maximumNumber,
                                                                            10
                                                                          ),
                                                                          res?.isRequired
                                                                        )
                                                                      }
                                                                      className="form-check-input"
                                                                      id={`${option._id}`}
                                                                      checked={isSelected(
                                                                        option
                                                                      )}
                                                                    />
                                                                  </div>
                                                                  <h5>
                                                                    £
                                                                    {
                                                                      option.price
                                                                    }
                                                                  </h5>
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </>
                                                      )
                                                    )
                                                  : res.items.map(
                                                      (option, j) => (
                                                        <>
                                                          {option.image ? (
                                                            <>
                                                              {" "}
                                                              <div
                                                                key={j}
                                                                className={`form-check Cardsection ${
                                                                  isSelected(
                                                                    option
                                                                  )
                                                                    ? "selected"
                                                                    : ""
                                                                }`}
                                                              >
                                                                <img
                                                                  src={
                                                                    option?.image
                                                                  }
                                                                  className="cardimg"
                                                                  alt="catimg"
                                                                />
                                                                <div className="menu_input">
                                                                  <label
                                                                    htmlFor={`${option.name}`}
                                                                    className="form-check-label"
                                                                  >
                                                                    {
                                                                      option.name
                                                                    }
                                                                  </label>
                                                                  <input
                                                                    type="checkbox"
                                                                    name={`${option.name}`}
                                                                    value={
                                                                      option._id
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeBundleSize(
                                                                        allbeundel?._id,
                                                                        allbeundel?.sellPrice
                                                                          ? allbeundel?.sellPrice
                                                                          : allbeundel?.originalPrice,

                                                                        res?._id,
                                                                        option,
                                                                        parseInt(
                                                                          res.maximumNumber,
                                                                          10
                                                                        ),
                                                                        res?.isRequired
                                                                      )
                                                                    }
                                                                    className="form-check-input"
                                                                    id={`${option._id}`}
                                                                    checked={isSelected(
                                                                      option
                                                                    )}
                                                                  />
                                                                </div>
                                                                <h5>
                                                                  £
                                                                  {option.price}
                                                                </h5>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div
                                                                key={j}
                                                                className={`form-check  ${
                                                                  isSelected(
                                                                    option
                                                                  )
                                                                    ? "selected"
                                                                    : ""
                                                                }`}
                                                              >
                                                                <div className="menu_input">
                                                                  <label
                                                                    htmlFor={`${option.name}`}
                                                                    className="form-check-label"
                                                                  >
                                                                    {
                                                                      option.name
                                                                    }
                                                                  </label>
                                                                  <input
                                                                    type="checkbox"
                                                                    name={`${option.name}`}
                                                                    value={
                                                                      option._id
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeBundleSize(
                                                                        allbeundel?._id,
                                                                        allbeundel?.sellPrice
                                                                          ? allbeundel?.sellPrice
                                                                          : allbeundel?.originalPrice,

                                                                        res?._id,
                                                                        option,
                                                                        parseInt(
                                                                          res.maximumNumber,
                                                                          10
                                                                        ),
                                                                        res?.isRequired
                                                                      )
                                                                    }
                                                                    className="form-check-input"
                                                                    id={`${option._id}`}
                                                                    checked={isSelected(
                                                                      option
                                                                    )}
                                                                  />
                                                                </div>
                                                                <h5>
                                                                  £
                                                                  {parseFloat(
                                                                    option.price
                                                                  ).toFixed(2)}
                                                                </h5>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                              </div>

                                              {res.isRequired &&
                                                !hasSelectedRequiredOption2 && (
                                                  <div
                                                    style={{
                                                      color: "red",
                                                      marginTop: "0.5rem",
                                                    }}
                                                  >
                                                    Please select at least one
                                                    option for {res.title}.
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal_btn_container">
                        <div
                          className="modal_cancel_btn"
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </div>

                        {/* {!isSubmitbun() && ( */}
                        <button
                          className="modal_cancel_btn"
                          type="submit"
                          // disabled={disableSubmitBtn == true}
                          onClick={() => handleSubmitdata()}
                        >
                          Submit
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </Fade>
          </Modal>
        </section>
      </div>
    </div>
  );
};
export default Customization;
