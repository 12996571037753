import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
const loagin_id = localStorage.getItem("id");
// const roletype = localStorage.getItem("roletype");
const api_url = process.env.REACT_APP_BASE_URL;
// const api_url = "/api/";

// export const loginSuccess = () => ({
//   type: LOGIN_SUCCESS,
//   payload: userData,
// });

export const isLoginAPI = createAsyncThunk("user/login", async (body) => {
  const res = await fetch(api_url + "auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();
  if (res.ok) {
    // Login successful
    return data;
  } else {
    // Login failed
    throw new Error(data.error);
  }
});
export const setpermissionsType = createAction("user/setpermissionsType");
//API FOR Restaurantlist

export const restaurantList = createAsyncThunk(
  "restaurantList",
  async (requestOption) => {
    console.log("dddd", requestOption);
    const roletype = localStorage.getItem("roletype");
    const loagin_id = localStorage.getItem("id");
    let url;
    if (roletype === "2") {
      url = `${api_url}api/v1/restaurant-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&teamMemberId=${loagin_id}`;
    } else if (roletype === "1") {
      url = `${api_url}api/v1/restaurant-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${loagin_id}`;
    } else {
      url = `${api_url}api/v1/restaurant-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}`;
    }
    console.log("url:", url);
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);
export const Resstatus = createAsyncThunk("Resstatus", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-list?isActive=${data?.status}`,
    // `${api_url}api/v1/hardware-list`,
    // `${api_url}kiosk/final-order-list?isActive=${data?.status}`,

    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  // console.log(`${api_url}api/v1/restaurant-list?isActive=${data?.orderStatus}`);
  return res.json();
});

export const OrderStatus = createAsyncThunk(
  "OrderStatus",
  async (requestOption) => {
    // const loagin_id = localStorage.getItem("id");

    const res = await fetch(
      `${api_url}kiosk/final-order-list?orderId=${requestOption.orderId}&orderStatus=${requestOption.orderStatus}&page=${requestOption.page}`,
      {
        method: "GET",
        headers: {
          "content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      }
    );
    console.log(requestOption.orderStatus);

    console.log(res);
    return res.json();
  }
);

//API FOR Restaurant add
export const Restaurantcreate = createAsyncThunk(
  "Restaurantcreate",
  async (requestOptions) => {
    const res = await fetch(
      api_url + "api/v1/restaurant-create",
      requestOptions
    );
    return res.json();
  }
);

//API FOR ForgetpasswordRestaurant edit
export const ForgetpasswordRestaurant = createAsyncThunk(
  "ForgetpasswordRestaurant",
  async (body) => {
    const res = await fetch(api_url + "api/v1/reset-password", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
      // Include the authorization token
      body: JSON.stringify(body),
    });
    return res.json();
  }
);
//API FOR Restaurant edit

export const RestaurantEdit = createAsyncThunk(
  "RestaurantEdit",
  async (data) => {
    console.log(data, "data", data.id);
    const res = await fetch(
      `${api_url}api/v1/restaurant-update?id=${data.id}`,
      data.requestOption
    );
    return res.json();
  }
);

//API FOR Restaurant DELETE

export const RestaurantDELETE = createAsyncThunk(
  "RestaurantDELETE",
  async (id) => {
    const res = await fetch(
      `${api_url}api/v1/restaurant-delete?restaurantId=${id}`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

//API FOR BRACH LIST START ??????????????????????

export const branchList = createAsyncThunk("branchList", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-branch-list?parentId=${data?.restaurantId}&name=${data?.search}&page=${data?.page}&limit=${data?.search}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR ADD BRACH

export const branchAdd = createAsyncThunk("branchAdd", async (data) => {
  const res = await fetch(`${api_url}api/v1/create-restaurant-branch`, data);
  return res.json();
});

//API FOR VIEW BRACH

export const branchView = createAsyncThunk("branchView", async (id) => {
  const res = await fetch(`${api_url}api/v1/restaurant-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR ADD BRACH

export const branchEdit = createAsyncThunk("branchEdit", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-update?id=${data?.id}`,
    data?.requestOption
  );
  return res.json();
});

export const branchrole = createAsyncThunk("branchrole", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-branch-get?parentId=${data?.restaurantId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR DELETE BRACH

export const branchDelete = createAsyncThunk("branchDelete", async (id) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-delete?restaurantId=${id}&branch=1`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API Role -management

export const RoleAdd = createAsyncThunk("RoleAdd", async (body) => {
  const res = await fetch(`${api_url}api/v1/roles-create`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

//API FOR Role LIST ??????????????????????

export const RoleList = createAsyncThunk("RoleList", async (requestOption) => {
  const roletype = localStorage.getItem("roletype");
  const loagin_id = localStorage.getItem("id");
  let url;
  if (roletype === "2") {
    url = `${api_url}api/v1/roles-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&teamMemberId=${loagin_id}`;
  } else if (roletype === "1") {
    url = `${api_url}api/v1/roles-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${loagin_id}`;
  } else {
    url = `${api_url}api/v1/roles-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}`;
  }
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

export const RoleallList = createAsyncThunk(
  "RoleallList",
  async (requestOption) => {
    const res = await fetch(`${api_url}api/v1/roles-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

//API FOR Role Delete ??????????????????????
export const RoleDelete = createAsyncThunk("RoleDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1/roles-delete?roleId=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR Role edit ??????????????????????
export const RoleAtive = createAsyncThunk("RoleAtive", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/roles-update?roleId=${data.id}`,
    data?.requestOption
  );
  return res.json();
});

//API FOR Designation LIST ??????????????????????

export const designationList = createAsyncThunk(
  "designationList",
  async (requestOption) => {
    const roletype = localStorage.getItem("roletype");
    const loagin_id = localStorage.getItem("id");
    let url;
    if (roletype === "2") {
      url = `${api_url}api/v1/designation-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&teamMemberId=${loagin_id}`;
    } else if (roletype === "1") {
      url = `${api_url}api/v1/designation-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${loagin_id}`;
    } else {
      url = `${api_url}api/v1/designation-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}`;
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

// API FOR ADD DESIGNATIONS
export const designationAdd = createAsyncThunk(
  "designationAdd",
  async (body) => {
    const res = await fetch(`${api_url}api/v1/designation-create`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res.json();
  }
);

// API FOR VIEW DESIGNATIONS

export const designationView = createAsyncThunk(
  "designationView",
  async (id) => {
    const res = await fetch(
      `${api_url}api/v1/designation-list?designationId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

// API FOR UPDATE DESIGNATIONS
export const designationUpdate = createAsyncThunk(
  "designationUpdate",
  async (body) => {
    const res = await fetch(
      `${api_url}api/v1/designation-update/?designationId=${body?.id}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(body?.data),
      }
    );
    return res.json();
  }
);
// API FOR DELETE DESIGNATIONS

export const designationDelete = createAsyncThunk(
  "designationDelete",
  async (id) => {
    const res = await fetch(
      `${api_url}api/v1/designation-delete/?designationId=${id}`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

//API FOR Team Members LIST START ??????????????????????

export const TeamMembersList = createAsyncThunk(
  "TeamMembersList",
  async (data) => {
    const roletype = localStorage.getItem("roletype");
    const loagin_id = localStorage.getItem("id");
    let url;
    if (roletype === "2") {
      url = `${api_url}api/v1/member-list?name=${data?.search}&page=${data?.page}&limit=${data?.limit}&teamMemberId=${loagin_id}`;
    } else if (roletype === "1") {
      url = `${api_url}api/v1/member-list?name=${data?.search}&page=${data?.page}&limit=${data?.limit}&restaurantId=${loagin_id}`;
    } else {
      url = `${api_url}api/v1/member-list?name=${data?.search}&page=${data?.page}&limit=${data?.limit}`;
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

//API Team Members -management

export const Teamadd = createAsyncThunk("Teamadd", async (body) => {
  const res = await fetch(`${api_url}api/v1/member-create`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

// API FOR DELETE Team Members

export const TeammembersDelete = createAsyncThunk(
  "TeammembersDelete",
  async (id) => {
    const res = await fetch(`${api_url}api/v1/member-delete?id=${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

// API FOR TeamView  Members
export const TeamView = createAsyncThunk("TeamView", async (id) => {
  const res = await fetch(`${api_url}api/v1/member-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API Team Members Edit -management

export const Teamedit = createAsyncThunk("Teamedit", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/member-update?id=${data?.id}`,
    data?.requestOption
  );
  return res.json();
});

//API FOR Forgetpassword team management  edit
export const Forgetpasswordteam = createAsyncThunk(
  "Forgetpasswordteam",
  async (body) => {
    const res = await fetch(api_url + "api/v1/member-password-update", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
      // Include the authorization token
      body: JSON.stringify(body),
    });
    return res.json();
  }
);

//API FOR ProductList LIST ??????????????????????

export const ProductList = createAsyncThunk("ProductList", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/product-list?name=${data?.search}&page=${data?.page}&limit=${data?.limit}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR Rolebased id  ??????????????????????
export const Rolebased = createAsyncThunk("Rolebased", async (id) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-products?restaurantId=${id}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR Rolebased id  ??????????????????????
export const teambased = createAsyncThunk("teambased", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/get-designation-roles?restaurantIds=${data?.id}&branchIds=${data?.branchId}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR Addproduct team management  edit
export const Addproduct = createAsyncThunk("Addproduct", async (body) => {
  const res = await fetch(api_url + "api/v1/product-create", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`,
    },
    // Include the authorization token
    body: JSON.stringify(body),
  });
  return res.json();
});

// API FOR DELETE Team Members

export const ProductDelete = createAsyncThunk("ProductDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1/product-delete?id=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR Addproduct team management  edit

export const Editproduct = createAsyncThunk("Editproduct", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/product-update?id=${data?.id}`,
    data?.requestOption
  );
  return res.json();
});

// API FOR ProductView  Members
export const ProductView = createAsyncThunk("ProductView", async (id) => {
  const res = await fetch(`${api_url}api/v1/product-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR Hardware creact
export const Hardwarecreact = createAsyncThunk("Hardware", async (body) => {
  const res = await fetch(api_url + "api/v1/create-hardware", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`,
    },
    // Include the authorization token
    body: JSON.stringify(body),
  });
  return res.json();
});

//API FOR Hardware LIST ??????????????????????

export const HardwareList = createAsyncThunk("HardwareList", async (data) => {
  const roletype = localStorage.getItem("roletype");
  const loagin_id = localStorage.getItem("id");
  let url;
  if (roletype === "2") {
    url = `${api_url}api/v1/hardware-list?search=${data?.search}&page=${data?.page}&limit=${data?.limit}&teamMemberId=${loagin_id}`;
  } else if (roletype === "1") {
    url = `${api_url}api/v1/hardware-list?search=${data?.search}&page=${data?.page}&limit=${data?.limit}&restaurantId=${loagin_id}`;
  } else {
    url = `${api_url}api/v1/hardware-list?search=${data?.search}&page=${data?.page}&limit=${data?.limit}`;
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR ProductView  Members
export const HardwareView = createAsyncThunk("HardwareView", async (id) => {
  const res = await fetch(`${api_url}api/v1/hardware-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR Hardware DELETE Team Members

export const HardwareDelete = createAsyncThunk("HardwareDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1//hardware-delete?id=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR Hardware Edit
export const EditHardware = createAsyncThunk("EditHardware", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/hardware-update?id=${data?.id}`,
    data?.requestOption
  );
  return res.json();
});

//API FOR Hardwarepassword team management  edit
export const Hardwarepassword = createAsyncThunk(
  "Hardwarepassword",
  async (body) => {
    const res = await fetch(api_url + "api/v1/hardware-password-update", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
      // Include the authorization token
      body: JSON.stringify(body),
    });
    return res.json();
  }
);

// API FOR setingView  Members
export const setingView = createAsyncThunk("setingView", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/setting-list?productId=${data.id}&restaurantId=${data.resid}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR seting edit

export const setingEdit = createAsyncThunk("setingEdit", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/product-setting?productId=${data.id}`,
    data.requestOption
  );
  return res.json();
});

export const Hardwarestatus = createAsyncThunk("HardwareList", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/hardware-list?isActive=${data?.status}`,
    // `${api_url}api/v1/hardware-list`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

//API FOR CATEGORY LIST START ??????????????????????vc

export const categoryList = createAsyncThunk(
  "categoryList",
  async (requestOption) => {
    const roletype = localStorage.getItem("roletype");
    const loagin_id = localStorage.getItem("id");
    let url;
    if (roletype === "2") {
      url = `${api_url}api/v1/category-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&teamMemberId=${loagin_id}&branchIds=${requestOption.branch}&isActive=${requestOption.status}`;
    } else if (roletype == "1") {
      url = `${api_url}api/v1/category-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${loagin_id}&branchIds=${requestOption.branch}&isActive=${requestOption.status}`;
    } else {
      url = `${api_url}api/v1/category-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${requestOption.restaurant}&branchIds=${requestOption.branch}&isActive=${requestOption.status}`;
    }
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

// API FOR ADD CATEGORYIES
export const categoriesAdd = createAsyncThunk("categoriesAdd", async (body) => {
  const res = await fetch(`${api_url}api/v1/create-category`, body);
  return res.json();
});

// API FOR VIEW CATEGORY

export const categoryView = createAsyncThunk("categoryView", async (id) => {
  const res = await fetch(`${api_url}api/v1/category-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR UPDATE CATEGORY
export const categoryUpdate = createAsyncThunk(
  "categoryUpdate",
  async (body) => {
    const res = await fetch(
      `${api_url}api/v1/create-update?categoryId=${body?.id}`,
      body?.head
    );
    return res.json();
  }
);
// API FOR DELETE CATEGORY

export const categoryDelete = createAsyncThunk("categoryDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1/category-delete?categoryId=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR UPDATE CATEGORY LIST SEQUENCE
export const categorySequenceUpdate = createAsyncThunk(
  "categorySequenceUpdate",
  async (requestData) => {
    const res = await fetch(`${api_url}api/v1/categories-sequence-update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
      body: JSON.stringify(requestData),
    });
    return res.json();
  }
);

//API FOR CATEGORY LIST END ??????????????????????vc

//API FOR ITEMS LIST START ??????????????????????vc

export const itemsList = createAsyncThunk(
  "itemsList",
  async (requestOption) => {
    const roletype = localStorage.getItem("roletype");
    const loagin_id = localStorage.getItem("id");
    let url;

    if (roletype === "2") {
      url = `${api_url}api/v1/menu-item-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&teamMemberId=${loagin_id}&branchIds=${requestOption.branch}&isActive=${requestOption.status}&categoryId=${requestOption.categoryid}`;
    } else if (roletype == "1") {
      url = `${api_url}api/v1/menu-item-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${loagin_id}&branchIds=${requestOption.branch}&isActive=${requestOption.status}&categoryId=${requestOption.categoryid}`;
    } else {
      url = `${api_url}api/v1/menu-item-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${requestOption.restaurant}&branchIds=${requestOption.branch}&isActive=${requestOption.status}&categoryId=${requestOption.categoryid}`;
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

// API FOR ADD ITEM
export const itemAdd = createAsyncThunk("itemAdd", async (body) => {
  const res = await fetch(`${api_url}api/v1/create-menu`, body);
  return res.json();
});

// API FOR VIEW ITEM

export const itemView = createAsyncThunk("itemView", async (id) => {
  const res = await fetch(`${api_url}api/v1/menu-item-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR UPDATE ITEM
export const itemUpdate = createAsyncThunk("itemUpdate", async (body) => {
  const res = await fetch(
    `${api_url}api/v1/update-menu-item?menuItemId=${body?.id}`,
    body?.head
  );
  return res.json();
});

// POST ORDER STATUS

export const selectedOrder = createAsyncThunk("selectedOrder", async (body) => {
  const res = await fetch(`${api_url}kiosk/order-status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

// API FOR DELETE ITEM

export const itemDelete = createAsyncThunk("itemDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1/menu-item-delete?id=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR ITEMS LIST END ??????????????????????vc

//API FOR CUSTOMIZATION LIST START ??????????????????????vc

export const customizationList = createAsyncThunk(
  "customizationList",
  async (requestOption) => {
    let url = `${api_url}api/v1/list-customization?id=${requestOption?.itemId}`;

    if (requestOption.search) {
      url += `&page=${requestOption.page}`;
    }
    if (requestOption.restaurant) {
      url += `&name=${requestOption.search}`;
    }
    if (requestOption.branch) {
      url += `&limit=${requestOption.limit}`;
    }
    if (requestOption.status) {
      url += `&isActive=${requestOption.status}`;
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });

    return res.json();
  }
);

// API FOR ADD CUSTOMIZATION
export const customizationAdd = createAsyncThunk(
  "customizationAdd",
  async (requestOptions) => {
    const response = await fetch(
      `${api_url}api/v1/create-customization`,
      requestOptions
    );
    return response.json();
  }
);

// API FOR VIEW CUSTOMIZATION

export const customizationView = createAsyncThunk(
  "customizationView",
  async (ids) => {
    const res = await fetch(
      `${api_url}api/v1/view-customization?id=${ids?.customizationId}&itemId=${ids.itemId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

// API FOR STATUS UPDATE CUSTOMIZATION
export const customizationStatusUpdate = createAsyncThunk(
  "customizationStatusUpdate",
  async (data) => {
    const res = await fetch(
      `${api_url}api/v1/customization/status/?id=${data?.id}&itemId=${data?.itemId}&isActive=${data?.status}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

// API FOR DELETE CUSTOMIZATION

export const customizationDelete = createAsyncThunk(
  "customizationDelete",
  async (id) => {
    const res = await fetch(
      `${api_url}api/v1/delete-customization?itemId=${id.itemId}&id=${id.id}`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

//API FOR BUNDLE LIST START ??????????????????????vc

export const bundleList = createAsyncThunk(
  "bundleList",
  async (requestOption) => {
    const roletype = localStorage.getItem("roletype");
    const loagin_id = localStorage.getItem("id");
    let url;

    if (roletype === "2") {
      url = `${api_url}api/v1/bundle-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&teamMemberId=${loagin_id}&branchIds=${requestOption.branch}&isActive=${requestOption.status}`;
    } else if (roletype == "1") {
      url = `${api_url}api/v1/bundle-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${loagin_id}&branchIds=${requestOption.branch}&isActive=${requestOption.status}`;
    } else {
      url = `${api_url}api/v1/bundle-list?page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&restaurantId=${requestOption.restaurant}&branchIds=${requestOption.branch}&isActive=${requestOption.status}`;
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

// API FOR ADD BUNDLE
export const bundleAdd = createAsyncThunk("bundleAdd", async (data) => {
  const res = await fetch(`${api_url}api/v1/bundle-create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
    body: JSON.stringify(data),
  });
  return res.json();
});

// API FOR VIEW BUNDLE

export const bundleView = createAsyncThunk("bundleView", async (id) => {
  const res = await fetch(`${api_url}api/v1/bundle-list?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR UPDATE BUNDLE
export const bundleUpdate = createAsyncThunk("bundleUpdate", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/bundle-update?id=${data?.bundleId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
      body: JSON.stringify(data?.data),
    }
  );
  return res.json();
});
// API FOR DELETE BUNDLE

export const bundleDelete = createAsyncThunk("bundleDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1/bundle-delete?id=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

export const Profilelist = createAsyncThunk("Profilelist", async () => {
  const roletype = localStorage.getItem("roletype");
  const loagin_id = localStorage.getItem("id");
  let url;
  if (roletype === "2") {
    url = `${api_url}api/v1/member-roles?teamMemberId=${loagin_id}`;
  } else if (roletype === "1") {
    url = `${api_url}api/v1/api/v1/member-roles?restaurantId=${loagin_id}`;
  } else {
    url = `${api_url}api/v1/api/v1/member-roles`;
  }
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

//API FOR BUNDLE LIST START ??????????????????????vc

export const mealList = createAsyncThunk("mealList", async (requestOption) => {
  const roletype = localStorage.getItem("roletype");
  const loagin_id = localStorage.getItem("id");
  let url;
  if (roletype === "2") {
    url = `${api_url}api/v1/list-meal?page=${requestOption.page}&limit=${requestOption.limit}&teamMemberId=${loagin_id}`;
  } else if (roletype === "1") {
    url = `${api_url}api/v1/list-meal?page=${requestOption.page}&limit=${requestOption.limit}&restaurantId=${loagin_id}`;
  } else {
    url = `${api_url}api/v1/list-meal?page=${requestOption.page}&limit=${requestOption.limit}`;
  }

  if (requestOption.search) {
    url += `&name=${requestOption.search}`;
  }
  if (requestOption.restaurant) {
    url += `&restaurantId=${requestOption.restaurant}`;
  }
  if (requestOption.branch) {
    url += `&branchId=${requestOption.branch}`;
  }
  if (requestOption.status) {
    url += `&isActive=${requestOption.status}`;
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR ADD CUSTOMIZATION
export const mealAdd = createAsyncThunk("mealAdd", async (requestOptions) => {
  const response = await fetch(`${api_url}api/v1/create-meal`, requestOptions);
  return response.json();
});

// API FOR DELETE MEALS

export const mealDelete = createAsyncThunk("mealDelete", async (id) => {
  const res = await fetch(`${api_url}api/v1/delete-meal?id=${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR DELETE MEALS

export const mealStatusUpdate = createAsyncThunk(
  "mealStatusUpdate",
  async (data) => {
    const res = await fetch(
      `${api_url}api/v1/status-update-meal?isActive=${data?.status}&id=${data?.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

// API FOR View MEALS

export const mealView = createAsyncThunk("mealView", async (id) => {
  const res = await fetch(`${api_url}api/v1/view-meal?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// API FOR ADD CUSTOMIZATION
export const mealEdit = createAsyncThunk("mealEdit", async (requestOptions) => {
  const response = await fetch(`${api_url}api/v1/create-meal`, requestOptions);
  return response.json();
});

// forget password Api

export const Forgetpassword = createAsyncThunk(
  "Forgetpassword",
  async (body) => {
    const res = await fetch(`${api_url}auth/forgot-password`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res.json();
  }
);

export const Resetpass = createAsyncThunk("Resetpass", async (body) => {
  console.log(body, "token");
  const res = await fetch(`${api_url}auth/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: body?.newToken, // Include the authorization token
    },
    body: JSON.stringify(body?.body),
  });
  return res.json();
});

// kiosk api

export const categorieslist = createAsyncThunk(
  "categorieslist",
  async (data) => {
    const loagin_id = localStorage.getItem("id");
    const res = await fetch(
      `${api_url}kiosk/categories-list?restaurantId=${loagin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);

// dashboard LIST API

export const dashboard = createAsyncThunk("dashboard", async (data) => {
  const roletype = localStorage.getItem("roletype");
  const loagin_id = localStorage.getItem("id");
  let url;
  if (roletype === "2") {
    url = `${api_url}api/v1/dashboard?restaurantId=${loagin_id}`;
  } else if (roletype === "1") {
    url = `${api_url}api/v1/dashboard?restaurantId=${loagin_id}`;
  } else {
    url = `${api_url}api/v1/dashboard?`;
  }

  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

// kiosk
//ITEM LIST API
export const itemList = createAsyncThunk("itemList", async (id) => {
  const res = await fetch(`${api_url}kiosk/menu-list?categoryId=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});

export const ViewItem = createAsyncThunk("ViewItem", async (id) => {
  const loagin_id = localStorage.getItem("id");
  const res = await fetch(
    `${api_url}kiosk/item-list?restaurantId=${loagin_id}&itemId=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

// API FOR add-to-cart
export const Addtocart = createAsyncThunk("Addtocart", async (body) => {
  const res = await fetch(`${api_url}api/v1/add-to-cart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

// API FOR allOrder complete
export const allOrder = createAsyncThunk("allOrder", async (body) => {
  const res = await fetch(`${api_url}kiosk/order-create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

// API FOR allOrderlist complete

export const myorderlist = createAsyncThunk("myorderlist", async (data) => {
  const loagin_id = localStorage.getItem("id");
  const res = await fetch(
    `${api_url}kiosk/order-list?restaurantId=${loagin_id}&type=web`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

export const mycart = createAsyncThunk("mycart", async (data) => {
  const loagin_id = localStorage.getItem("id");
  const res = await fetch(
    `${api_url}api/v1/cart-item-list?resturantId=${loagin_id}                                                                                                                                                                                                                              `,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

// api for mycart remove
export const mycartremove = createAsyncThunk("mycartremove", async (id) => {
  const res = await fetch(`${api_url}api/v1/cart-item-delete?id=${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
  });
  return res.json();
});
export const mycartAllcart = createAsyncThunk("mycartAllcart", async (id) => {
  const res = await fetch(
    `${api_url}api/v1/cart-item-delete?restaurantId=${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

export const myallorderRemove = createAsyncThunk(
  "myallorderRemove",
  async (id) => {
    const res = await fetch(`${api_url}kiosk/order-delete?id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    });
    return res.json();
  }
);

// Final order Api

export const Finalorder = createAsyncThunk("Finalorder", async (body) => {
  const res = await fetch(`${api_url}kiosk/final-order-create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

// Final order list Api

export const Finalorderlist = createAsyncThunk(
  "Finalorderlist",
  async (requestOption) => {
    // const loagin_id = localStorage.getItem("id");
    const res = await fetch(
      `${api_url}kiosk/final-order-list?orderId=${requestOption.id}&restaurantId=${requestOption.Rid}&page=${requestOption.page}&name=${requestOption.search}&limit=${requestOption.limit}&search=${requestOption.search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    console.log(res);
    return res.json();
  }
);

// API FOR DELETE MEALS

export const Proseting = createAsyncThunk("Proseting", async (data) => {
  const res = await fetch(
    `${api_url}api/v1/restaurant-setting-list?productId=${data.productID}&restaurantId=${data?.restroId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
      },
    }
  );
  return res.json();
});

export const Removeimg = createAsyncThunk("Removeimg", async (body) => {
  const res = await fetch(`${api_url}api/v1/remove-splash-image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

export const AllproductSating = createAsyncThunk(
  "AllproductSating",
  async (body) => {
    const res = await fetch(`${api_url}api/v1/update-product-setting`, body);
    return res.json();
  }
);

// pax payment

export const Paymentregistering = createAsyncThunk(
  "Paymentregistering",
  async (body) => {
    const res = await fetch(
      `https://api.acquiring.uat.valitor.com/CloudLinkApi/register`,
      {
        method: "POST",
        headers: {
          accept: "*/*",
          "X-API-KEY": "paynt_uat.yX74LnJemkrloB5jGMWLYHNq3Uz1dQMse50UxGBLv28=",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    return res.json();
  }
);

export const tippingsave = createAsyncThunk("tippingsave", async (body) => {
  const res = await fetch(`${api_url}api/v1/tipping-create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
    },
    body: JSON.stringify(body),
  });
  return res.json();
});

export const tippingDetalis = createAsyncThunk(
  "tippingDetalis",
  async (tipping) => {
    const res = await fetch(
      `${api_url}api/v1/tipping-member-list?restaurantId=${tipping.RId}&branchId=${tipping?.BId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("access_token")}`, // Include the authorization token
        },
      }
    );
    return res.json();
  }
);
