import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../../TopHeader/topheader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./page.css";
import QRModal from "../QRModal/qrmodal";
import CardModal from "../card/card";
import EnterCard from "../EnterCard/EnterCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Finalorder, mycartAllcart } from "../../../../../../Helper/Redux/API";
import { toast } from "react-toastify";

const ChoosePayMethod = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { requestOptions } = location.state || {};
  console.log("requestOptions", requestOptions);

  const Backbtn = () => {
    console.log("Cashon");
    navigate("/ordermanagement/myorder");
  };

  const Cashon = () => {
    const data = {
      name: requestOptions.name,
      email: requestOptions.email ? requestOptions.email : " ",
      price: requestOptions.price,
      type: requestOptions.type,
      restaurantId: requestOptions.restaurantId,
      paymentType: "Cash",
      paymentStatus: "paid",
      orderType: "take way",
      status: "Pending",
      tip: requestOptions.tip,
      completeOrder: requestOptions.completeOrder,
      VAT: requestOptions.vatprice,
      ServiceCharge: requestOptions.servicechargeprice,
    };

    console.log("data", data);

    try {
      dispatch(Finalorder(data)).then((res) => {
        // setDisableSubmitBtn(false);
        if (res.payload.status === true) {
          console.log("res.payload", res.payload.payload.paymentStatus);
          // res.payload.
          const order_id = res.payload.payload._id;
          if (res.payload.payload.paymentStatus == "paid") {
            navigate("/ordermanagement/receipt", { state: { order_id } });
            toast.success(res?.payload?.message);
            removecartitem();
          }
        } else {
          // toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const removecartitem = () => {
    const roleid = localStorage.getItem("id");

    try {
      dispatch(mycartAllcart(roleid)).then((res) => {
        if (res?.payload?.status === true) {
          // getmycartListData();
          // setOpen(false);
        } else {
          //   setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="order" />

          <div className="container-fluid">
            <din className="container">
              <div className="payment_type_container">
                <div className="payment_type_inner">
                  {/* <div> */}
                  <p>How do you prefer to proceed with the payment?</p>
                  <div>
                    <QRModal />
                  </div>
                  <div>
                    <CardModal />
                  </div>

                  {/* <div>
                    <EnterCard />
                  </div> */}
                  <div>
                    <div className="payment_card" onClick={Cashon}>
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.2338 6.59559C13.2338 3.49077 17.9536 0.97345 23.7753 0.97345C29.5971 0.97345 34.3169 3.49077 34.3169 6.59559C34.3169 9.70043 29.5971 12.2177 23.7753 12.2177C17.9536 12.2177 13.2338 9.70043 13.2338 6.59559ZM23.7753 15.0288C22.3318 15.0288 20.9629 14.8672 19.7119 14.5861C17.8018 13.1538 14.6717 12.2177 11.1255 12.2177C5.30377 12.2177 0.583984 14.7351 0.583984 17.8399C0.583984 20.9447 5.30377 23.462 11.1255 23.462C16.9472 23.462 21.667 20.9447 21.667 17.8399C21.667 17.7991 21.6572 17.7598 21.6558 17.719H21.667V20.651C21.667 23.7558 16.9472 26.2731 11.1255 26.2731C5.30377 26.2731 0.583984 23.7558 0.583984 20.651V23.462C0.583984 26.5669 5.30377 29.0842 11.1255 29.0842C16.9472 29.0842 21.667 26.5669 21.667 23.462V26.2731C21.667 29.3779 16.9472 31.8952 11.1255 31.8952C5.30377 31.8952 0.583984 29.3779 0.583984 26.2731V29.0842C0.583984 32.189 5.30377 34.7063 11.1255 34.7063C16.9472 34.7063 21.667 32.189 21.667 29.0842V28.9717C22.3487 29.0448 23.0529 29.0842 23.7753 29.0842C29.5971 29.0842 34.3169 26.5669 34.3169 23.462V20.651C34.3169 23.6293 29.9709 26.0609 24.4781 26.2548V23.4438C29.9709 23.2498 34.3169 20.8196 34.3169 17.8399V15.0288C34.3169 18.0071 29.9709 20.4387 24.4781 20.6327V17.8216C29.9709 17.6276 34.3169 15.1975 34.3169 12.2177V9.40667C34.3169 12.5115 29.5971 15.0288 23.7753 15.0288Z"
                          fill="#1B4B7C"
                        />
                      </svg>
                      <span> Cash </span>
                    </div>
                  </div>
                  {/* <button className="basket_button py-3 ">Continue</button> */}
                  <button className="basket_button py-3 " onClick={Backbtn}>
                    cancel
                  </button>

                  {/* </div> */}
                </div>
              </div>
            </din>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ChoosePayMethod;
