import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { customizationView } from "../../../../../Helper/Redux/API";
import preview_generic_image from "../../../../../img/AddModal/Group 839.png";

const ViewCategory = ({ setUpdateAPI, setIShowSecondPart, ids }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValue, setInitialValue] = useState({
    title: "",
    isRequired: false,
    isMultiselect: false,
    maximumNumber: "",
  });

  const [customization, setCustomization] = useState([
    {
      name: "",
      price: "",
      image: "",
      imagePreview: "",
    },
  ]);

  const getCustomizationDetails = () => {
    setIsLoading(false);

    try {
      dispatch(customizationView(ids)).then((res) => {
        setIsLoading(true);
        if (res?.payload?.status === true) {
          setInitialValue({
            title: res?.payload?.payload?.customization[0]?.title,
            isRequired: res?.payload?.payload?.customization[0]?.isRequired,
            isMultiselect:
              res?.payload?.payload?.customization[0]?.isMultiselect,
            maximumNumber:
              res?.payload?.payload?.customization[0]?.maximumNumber,
            minimumNumber:
              res?.payload?.payload?.customization[0]?.minimumNumber,
          });
          setCustomization(
            res?.payload?.payload?.customization[0]?.items?.map((item, i) => ({
              name: item?.name,
              price: item?.price,
              image: item?.image,
              imagePreview: item?.image,
            }))
          );
        }
      });
    } catch (error) {
      console.log(error, "while getting data");
    }
  };
  useEffect(() => {
    getCustomizationDetails();
  }, []);
  return (
    <section className="add_category ">
      <div className="add_category_inner">
        <h6 className="heading_name">View Customization</h6>
        <hr />
        <div className="form_container">
          {isLoading && (
            <Formik initialValues={initialValue}>
              {(formik) => (
                <Form>
                  <div
                    className="field_container d-flex flex-column gap-5"
                    style={{ gap: "8px" }}
                  >
                    <div className=" d-flex flex-column">
                      <label htmlFor="title">
                        Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Title Name "
                        id="title"
                        name="title"
                        disabled
                      />
                    </div>

                    <div className="row">
                      <div className="col-md-4">Name</div>
                      <div className="col-md-3">Price (£)</div>
                    </div>
                    {customization.map((res, index) => (
                      <div className="row align-items-center">
                        <div className="col-md-4 d-flex flex-column">
                          <Field
                            type="text"
                            Placeholder="Enter name "
                            id="designation"
                            name="designation"
                            value={customization[index].name}
                            disabled
                          />
                        </div>
                        <div className="col-md-3 pl-0 d-flex flex-column">
                          <Field
                            type="text"
                            Placeholder="Enter price "
                            id="designation"
                            name="designation"
                            value={customization[index].price}
                            disabled
                          />
                        </div>
                        <div
                          className=" col-5 p-0 d-flex align-items-center "
                          style={{ gap: "10px" }}
                        >
                          <img
                            src={
                              customization[index].imagePreview
                                ? customization[index].imagePreview
                                : preview_generic_image
                            }
                            alt=""
                            style={{ width: "55px", height: "45px" }}
                          />
                        </div>
                      </div>
                    ))}

                    <div className="row align-items-end mt-5">
                      <div className="col-md-5">
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            checked={formik.values.isRequired == true}
                            disabled
                          />

                          <label htmlFor="" className="m-0">
                            Is required?
                          </label>
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            checked={formik.values.isMultiselect == true}
                            disabled
                          />

                          <label htmlFor="" className="m-0">
                            Is Multiselect?
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.values.isMultiselect && (
                      <div
                        className="row align-items-end "
                        style={{ gap: "10px" }}
                      >
                        <div className="col-md-12 ">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <label htmlFor="" className="m-0 mr-1">
                              Enter Maximum Number
                            </label>
                            <Field
                              type="number"
                              name="maximumNumber"
                              id=""
                              className="maximum_number_field"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <label htmlFor="" className="m-0 mr-1">
                              Enter Minimum Number
                            </label>
                            <Field
                              type="number"
                              name="minimumNumber"
                              id=""
                              className="maximum_number_field"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="submit_btn_container mt-4 d-flex gap-3 justify-content-center align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <div
                        className="category_cancel"
                        onClick={() => setIShowSecondPart("add")}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </section>
  );
};

export default ViewCategory;
