import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../../Sidebarpage/sidebarpage";
import Topheader from "../Topcart/topcart";
import burger from "../../../../../../img/Order/category/Layer 1aaaa 1.png";
import burgernew from "../../../../../../img/Order/category/burger.png";

import fries from "../../../../../../img/Order/category/aaa 1.png";
import salad from "../../../../../../img/Order/category/salad 1.png";
import no from "../../../../../../img/no.jpg";
import "./category.css";
import { useDispatch } from "react-redux";
import { categorieslist, itemList } from "../../../../../../Helper/Redux/API";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const OrderCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const [update, setUpdate] = useState(
    location.state?.isChecked === true ? true : false || {}
  );
  console.log("updateupdate", update);
  const [orderCategory, setOrderCategory] = useState([
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },

    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },

    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
    {
      image: burgernew,
      name: "Starter",
    },
  ]);
  const [orderItem, setOrderItem] = useState([
    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: fries,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },

    {
      tag1: "Tag 1",
      image: burger,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
    {
      tag1: "Tag 1",
      image: salad,
      description: "Peanut Satay Chicken Rice Box",
      sale_price: "7.49",
      original_price: "10.00",
      tag2: "Tag 2",
    },
  ]);
  const [ismealItem, setIsmealItem] = useState(false);
  const [isChecked, setIsChecked] = useState(update);
  const [selectedCategory, setSelectedCategory] = useState(id);

  const categoryList = () => {
    try {
      dispatch(categorieslist()).then((res) => {
        if (res?.payload?.status === true) {
          let categories = res?.payload?.payload;

          // Find the index of the category with the specified id
          const selectedIndex = categories.findIndex((cat) => cat._id === id);

          if (selectedIndex !== -1) {
            // Create a new array with the selected category at the beginning
            const selectedCategory = categories[selectedIndex];
            categories = [
              selectedCategory,
              ...categories.slice(0, selectedIndex),
              ...categories.slice(selectedIndex + 1),
            ];
          }

          setOrderCategory(categories);
          setSelectedCategory(id ? id : res?.payload?.payload[0]?._id); // Assuming id is the _id of the selected category
        } else {
          setOrderCategory([]);
          setSelectedCategory("");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    categoryList();
  }, []);

  const itemsList = () => {
    try {
      dispatch(itemList(selectedCategory)).then((res) => {
        if (res?.payload?.status === true) {
          setOrderItem(res?.payload?.payload);
          // console.log(res?.payload.ismeal, "ssss");
          setIsmealItem(res?.payload.ismeal);
        } else {
          setOrderItem([]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   categoryList();
  // }, []);

  useEffect(() => {
    if (selectedCategory) {
      itemsList();
    }
  }, [selectedCategory]);

  const getItemList = (id) => {
    setSelectedCategory(id);
    console.log("getItemList", id);
  };

  const backbtn = () => {
    navigate("/ordermanagement/orderadd/Favorites");
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection  menuorder">
          <Topheader heding_text="Order" />

          <div className="container-fluid">
            <div className="mb-3 pb-4">
              <a onClick={backbtn} className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </a>
            </div>
            <div className="row ">
              <div className="col-md-2">
                <div
                  className="d-flex flex-column order_category  "
                  style={{ gap: "30px" }}
                >
                  <div className="custom-control ml-0 pl-0 custom-switch text-end">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      checked={isChecked == true}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <label
                      className={`custom-control-label ${
                        isChecked === true ? "eat-in" : "take-away"
                      }`}
                      for="customSwitch1"
                    ></label>
                    <span
                      className={
                        isChecked == true
                          ? "custom_input_label_left"
                          : " custom_input_label_right"
                      }
                    >
                      {isChecked == true ? "Take A" : " Eat In"}
                    </span>
                  </div>
                  <div className="d-flex flex-column category_menu_container">
                    {orderCategory?.map((res, i) => (
                      <div
                        className={
                          selectedCategory == res?._id
                            ? "order_category_cont active"
                            : "order_category_cont"
                        }
                        onClick={() => getItemList(res?._id)}
                        key={i}
                      >
                        <img
                          src={res?.image1 ? res?.image1 : no}
                          alt=""
                          className="category_image_1"
                        />
                        <p className="m-0">{res?.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <p className="category_page_subheading2 ">
                  Grab Your Delicious Food!
                </p>
                <div className="category_item_container category_menu_container">
                  {orderItem?.map((res, i) => (
                    <div
                      className="item_Container"
                      key={i}
                      onClick={() =>
                        navigate(`/ordermanagement/item/${res?._id}`)
                      }
                    >
                      {ismealItem === true ? (
                        <></>
                      ) : (
                        <p className="m-0 tag_1"> {res?.label1} </p>
                      )}

                      <div className="text-center">
                        <img
                          src={res?.image ? res?.image : no}
                          alt=""
                          className="category_item_img "
                        />
                      </div>

                      {ismealItem === true ? (
                        <>
                          {" "}
                          <p className="m-0 item_label">{res?.mealName}</p>
                        </>
                      ) : (
                        <p className="m-0 item_label">{res?.name}</p>
                      )}

                      <div className="d-flex align-items-center justify-content-between">
                        {ismealItem === true ? (
                          <>
                            {" "}
                            <div
                              className="d-flex align-items-center "
                              style={{ gap: "10px" }}
                            >
                              <span className="item_original_price">
                                £{parseFloat(res?.discountMealPrice).toFixed(2)}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div
                            className="d-flex align-items-center "
                            style={{ gap: "10px" }}
                          >
                            {res?.originalPrice === res?.sellPrice ? (
                              <></>
                            ) : (
                              <span className="item_sell_price">
                                £{parseFloat(res?.originalPrice).toFixed(2)}
                              </span>
                            )}

                            <span className="item_original_price">
                              £{parseFloat(res?.sellPrice).toFixed(2)}
                            </span>
                          </div>
                        )}

                        <p className="m-0 tag_2">{res?.label2}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default OrderCategory;
