import React, { useEffect, useState } from "react";
// import "./add.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  customizationAdd,
  customizationView,
  designationAdd,
} from "../../../../../Helper/Redux/API";
import cross_image from "../../../../../img/AddModal/Group 82624.png";
import preview_generic_image from "../../../../../img/AddModal/Group 839.png";
import upload_generic_image from "../../../../../img/AddModal/Group 82553.png";

const EditCategory = ({ setUpdateAPI, setIShowSecondPart, ids }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const itemId = location.pathname.split("/")[3];
  const [isLoading, setIsLoading] = useState(false);
  const [initialValue, setInitialValue] = useState({
    title: "",
    isRequired: false,
    isMultiselect: false,
    maximumNumber: "",
    minimumNumber: "",
  });

  const [customization, setCustomization] = useState([
    {
      name: "",
      price: "",
      image: "",
      imagePreview: "",
      oldImageUrl: "",
    },
  ]);
  console.log(typeof customization[0]?.image, "customization");
  const addField = () => {
    setCustomization([...customization, { name: "", price: "", image: "" }]);
  };

  const removeField = (index) => {
    const newNutrition = customization.filter((_, i) => i !== index);
    setCustomization(newNutrition);
  };
  const handleSubmit = async (values, { resetForm }) => {
    const customizationData = {
      title: values.title,
      isRequired: values.isRequired,
      isMultiselect: values.isMultiselect,
      maximumNumber: values.maximumNumber,
      minimumNumber: values?.minimumNumber,

      items: customization.map((res) => {
        // Create the base object
        const item = {
          name: res?.name || "",
          price: res?.price || "",
        };

        // Conditionally add the image property
        if (typeof res?.image !== "object") {
          item.image = res?.oldImageUrl;
        }

        return item;
      }),
    };

    console.log("customizationDatadddd", customizationData);

    try {
      const formData = new FormData();
      formData.append("menuItemId", itemId);
      formData.append("customizationId", ids?.customizationId);

      formData.append("customization", JSON.stringify(customizationData));

      customization.forEach((res, i) => {
        if (res.image) {
          formData.append(`customizationImages[0][${i}]`, res.image);
        }
      });

      const requestOptions = {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      };

      const response = await dispatch(customizationAdd(requestOptions));

      if (response?.payload?.status) {
        toast.success(response.payload.message);
        setCustomization([
          {
            name: "",
            price: "",
            image: "",
            imagePreview: "",
          },
        ]);
        resetForm();
        setIShowSecondPart("add");

        setUpdateAPI("added");
      } else {
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const validationSchemas = yup.object().shape({
    title: yup.string().required("Title is required"),
    maximumNumber: yup
      .number()
      .typeError("Maximum Number must be a number")
      .when("isMultiselect", {
        is: true,
        then: yup
          .number()
          .required("Maximum Number is required")
          .positive("Maximum Number must be a positive number"),
        otherwise: yup.number(),
      }),
    minimumNumber: yup
      .number()
      .typeError("Minimum Number must be a number")
      .when("isMultiselect", {
        is: true,
        then: yup
          .number()
          .required("Minimum Number is required")
          .positive("Minimum Number must be a positive number"),
        otherwise: yup.number(),
      }),
  });

  const handleCustomizationNameChange = (index) => (e) => {
    const updatedNutrition = [...customization]; // Clone the array
    updatedNutrition[index] = {
      ...updatedNutrition[index],
      name: e.target.value,
    }; // Update the specific element

    setCustomization(updatedNutrition); // Set the new state
  };
  const handleCustomizationValueChange = (index) => (e) => {
    const updatedNutrition = [...customization]; // Clone the array
    updatedNutrition[index] = {
      ...updatedNutrition[index],
      price: e.target.value,
    }; // Update the specific element

    setCustomization(updatedNutrition); // Set the new state
  };

  const handleCustomizationImageChange = (index) => (e) => {
    const file = e.target.files[0];

    if (file) {
      const updatedNutrition = [...customization]; // Clone the array
      updatedNutrition[index] = {
        ...updatedNutrition[index],
        image: file,
        imagePreview: URL.createObjectURL(file),
      }; // Update the specific element

      setCustomization(updatedNutrition);
    } // Set the new state
  };

  const getCustomizationDetails = () => {
    setIsLoading(false);

    try {
      dispatch(customizationView(ids)).then((res) => {
        setIsLoading(true);
        if (res?.payload?.status === true) {
          setInitialValue({
            title: res?.payload?.payload?.customization[0]?.title,
            isRequired: res?.payload?.payload?.customization[0]?.isRequired,
            isMultiselect:
              res?.payload?.payload?.customization[0]?.isMultiselect,
            maximumNumber:
              res?.payload?.payload?.customization[0]?.maximumNumber,
            minimumNumber:
              res?.payload?.payload?.customization[0]?.minimumNumber,
          });
          setCustomization(
            res?.payload?.payload?.customization[0]?.items?.map((item, i) => ({
              name: item?.name,
              price: item?.price,
              imagePreview: item?.image,
              oldImageUrl: item?.image,
            }))
          );
        }
      });
    } catch (error) {
      console.log(error, "while getting data");
    }
  };
  useEffect(() => {
    getCustomizationDetails();
  }, []);
  return (
    <section className="add_category ">
      <div className="add_category_inner">
        <h6 className="heading_name">Edit Customization</h6>
        <hr />
        <div className="form_container">
          {isLoading && (
            <Formik
              initialValues={initialValue}
              onSubmit={handleSubmit}
              validationSchema={validationSchemas}
            >
              {(formik) => (
                <Form>
                  <div
                    className="field_container d-flex flex-column gap-5"
                    style={{ gap: "8px" }}
                  >
                    <div className=" d-flex flex-column">
                      <label htmlFor="title">
                        Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Title Name "
                        id="title"
                        name="title"
                      />
                      <ErrorMessage
                        component="p"
                        name="title"
                        className="text-danger"
                      />
                    </div>
                    <div className="d-flex justify-content-end ">
                      <p
                        className="add_more_field_btn"
                        onClick={() => addField()}
                      >
                        Add details
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-4">Name</div>
                      <div className="col-md-3">Price (£)</div>
                    </div>
                    {customization.map((res, index) => (
                      <div className="row align-items-center">
                        <div className="col-md-4 d-flex flex-column">
                          <Field
                            type="text"
                            Placeholder="Enter name "
                            id="designation"
                            name="designation"
                            value={customization[index].name}
                            onChange={handleCustomizationNameChange(index)}
                          />

                          <ErrorMessage
                            component="p"
                            name="designation"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-3 pl-0 d-flex flex-column">
                          <Field
                            type="text"
                            Placeholder="Enter price "
                            id="designation"
                            name="designation"
                            value={customization[index].price}
                            onChange={handleCustomizationValueChange(index)}
                          />

                          <ErrorMessage
                            component="p"
                            name="designation"
                            className="text-danger"
                          />
                        </div>
                        <div
                          className=" col-5 p-0 d-flex align-items-center "
                          style={{ gap: "10px" }}
                        >
                          <div className="customization_image_upload_btn">
                            <img
                              src={upload_generic_image}
                              alt=""
                              style={{ width: "45px" }}
                            />
                            <input
                              type="file"
                              onChange={handleCustomizationImageChange(index)}
                            />
                          </div>
                          <img
                            src={
                              customization[index].imagePreview
                                ? customization[index].imagePreview
                                : preview_generic_image
                            }
                            alt=""
                            style={{ width: "55px", height: "45px" }}
                          />

                          {index !== 0 && (
                            <img
                              src={cross_image}
                              alt="cross image"
                              style={{ width: "45px" }}
                              onClick={() => removeField(index)}
                            />
                          )}
                        </div>
                      </div>
                    ))}

                    <div className="row align-items-end mt-5">
                      <div className="col-md-5">
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            checked={formik.values.isRequired == true}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "isRequired",
                                e.target.checked
                              )
                            }
                          />

                          <label htmlFor="" className="m-0">
                            Is required?
                          </label>
                        </div>
                        <div
                          className="d-flex align-items-center my-1"
                          style={{ gap: "10px" }}
                        >
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            checked={formik.values.isMultiselect == true}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "isMultiselect",
                                e.target.checked
                              )
                            }
                          />

                          <label htmlFor="" className="m-0">
                            Is Multiselect?
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.values.isMultiselect && (
                      <div
                        className="row align-items-end "
                        style={{ gap: "10px" }}
                      >
                        <div className="col-md-12 ">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <label htmlFor="" className="m-0 mr-1">
                              Enter Maximum Number
                            </label>
                            <Field
                              type="number"
                              name="maximumNumber"
                              id=""
                              className="maximum_number_field"
                            />
                          </div>
                          <ErrorMessage
                            component="p"
                            name="maximumNumber"
                            className="text-danger text-end"
                          />
                        </div>
                        <div className="col-md-12 ">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <label htmlFor="" className="m-0 mr-1">
                              Enter Minimum Number
                            </label>
                            <Field
                              type="number"
                              name="minimumNumber"
                              id=""
                              className="maximum_number_field"
                            />
                          </div>
                          <ErrorMessage
                            component="p"
                            name="minimumNumber"
                            className="text-danger text-end"
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="submit_btn_container mt-4 d-flex gap-3 justify-content-center align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <div
                        className="category_cancel"
                        onClick={() => setIShowSecondPart("add")}
                      >
                        Cancel
                      </div>
                      <button type="submit" className="category_save">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </section>
  );
};

export default EditCategory;
