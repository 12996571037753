import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../Sidebarpage/sidebarpage";
import Topheader from "../../TopHeader/topheader";
import Firedark from "../../../../img/team.svg";

import CategoryList from "./list/list";
import AddCategory from "./add/add";
import EditCategory from "./edit/edit";
import ViewCategory from "./view/view";
import { useLocation } from "react-router-dom";

const Customization = () => {
 

 
  const location = useLocation();
  const { name } = location.state || {}; // Destructure name from state, default to empty object if state is undefined


  const [isShowSecondPart , setIShowSecondPart]=useState('add')

  const [updateAPI , setUpdateAPI] = useState('')
  const [ids , setIDS] = useState('')

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Menu Management" img_icon={Firedark} />
          <div className="container-fluid">
           

            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-7">
                       <CategoryList itemName={name} updateAPI={updateAPI} setUpdateAPI={setUpdateAPI} setIShowSecondPart={setIShowSecondPart} setIDS={setIDS} />
                    </div>
                    <div className="col-md-5">
                      {
                        isShowSecondPart == 'add' ? 
                      <AddCategory  setUpdateAPI={setUpdateAPI} /> : 
                      isShowSecondPart == 'edit' ? 
                      <EditCategory  setUpdateAPI={setUpdateAPI} setIShowSecondPart={setIShowSecondPart} ids={ids} /> :       isShowSecondPart == 'view' ? <ViewCategory  setUpdateAPI={setUpdateAPI} setIShowSecondPart={setIShowSecondPart} ids={ids} /> :
                      <AddCategory  setUpdateAPI={setUpdateAPI} /> 



                      }
                    </div>
                  </div>
                   
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Customization;
