import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import "./add.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import countryCodes from "country-calling-code";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  RoleallList,
  restaurantList,
  branchrole,
  designationList,
  Teamadd,
  teambased,
} from "../../../../../Helper/Redux/API";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  height: "95vh", // Added height constraint
};

export default function AddTeamModal({ setUpdateAPI, activeuser }) {
  const roletype = localStorage.getItem("roletype");
  const [filddisble, setFilddisble] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = React.useState([]);
  const [allrestaurant, setAllRestaurant] = React.useState("");
  const [selectedOptionsBranch, setSelectedOptionsBranch] = React.useState([]);
  const [brachList, setBrachList] = React.useState([]);
  const [allDesignation, setAllDesignation] = React.useState("");
  const [allRole, setAllRole] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [selectedvaluesArray, setSelectedvaluesArray] = useState([]);
  const [selectedBrancArray, setSelectedBrancArray] = useState([]);
  const [isPerfectUser, setIsPerfectUser] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const restaurant_id = location.pathname.split("/")[3];

  const handleClose = () => setOpen(false);
  const initialValues = {
    MemberName: "",
    email: "",
    MobileNumber: "",
    Designation: "",
    RoleAssign: "",
    password: "",
    // ConfirmPassword: "",
  };
  const validationSchema = yup.object({
    MemberName: yup.string().required("Member Name is Required"),
    email: yup
      .string()
      .required("Email is Required")
      .email("Invalid Email Address"),
    MobileNumber: yup
      .string()
      .required("Mobile Number is Required")
      // .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile Number Should Required 10 No."),
    // whatsapp_number: yup
    //   .string()
    //   .required("WhatsApp number is required")
    //   .matches(/^[0-9]+$/, "Mobile number must contain only digits"),
    Designation: yup.string().required("Select Designation Required"),
    RoleAssign: yup.string().required("Select Role Assign"),
    password: yup
      .string()
      .required("Password is Required")
      .min(6, "Password should be at least 6 characters."),
    ConfirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Confirm Password Must Match")
      .required("Confirm Password is Required"),
    // countryCode: yup.string().required("Country Code is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const requestOptions = {
      name: values.MemberName,
      mobileNumber: values.MobileNumber,
      email: values.email,
      password: values.password,
      restaurantId: selectedvaluesArray,
      branchIds: selectedBrancArray,
      designationId: values.Designation,
      rolesId: values.RoleAssign,
      is_perfect_member: isPerfectUser,
      restaurantLabel: selectedRestaurant,
      branchLabel: selectedOptionsBranch,
    };
    console.log("data", requestOptions);

    try {
      dispatch(Teamadd(requestOptions)).then((res) => {
        // setDisableSubmitBtn(false);

        if (res.payload.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          handleClose();
          setIsPerfectUser(false);
          setSelectedRestaurant([]);
          setSelectedOptionsBranch("");
          setUpdateAPI("addrange");
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
      // setDisableSubmitBtn(false);
    }
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);

          const alllist = res?.payload?.payload;
          const valuesArray = alllist.map((option) => option._id);

          if (roletype === "2" && !activeuser) {
            setSelectedRestaurant({
              value: res?.payload?.payload[0]?._id,
              label: res?.payload?.payload[0]?.name,
            });
            getDesignationList(res?.payload?.payload[0]?._id);
            setSelectedvaluesArray(res?.payload?.payload[0]?._id);
            setFilddisble(activeuser ? false : true);
            getBraches(res?.payload?.payload[0]?._id);
          }

          // console.log("ddd", valuesArray);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
    // console.log("selectedOptions", selectedOptions);

    const valuesArray = selectedOptions?.map((option) => option?.value);
    // console.log("valuesArray", valuesArray);
    setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    getBraches(valuesArray);
    getDesignationList(valuesArray);
    // getRoleListData(valuesArray);

    console.log("selectedOptions", selectedOptions);
  };

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);

          // console.log("totalCounts", res?.payload?.payload);
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedBranch) => {
    setSelectedOptionsBranch(selectedBranch);
    const valuesArray = selectedBranch.map((option) => option.value);
    setSelectedBrancArray(valuesArray);

    let restaurantArray;
    if (roletype == 1 || roletype == 2) {
      restaurantArray = selectedRestaurant.value;
      // console.log("restaurantArray1", restaurantArray);
    } else {
      restaurantArray = selectedRestaurant?.map((option) => option?.value);
      // console.log("restaurantArray111");
    }

    // console.log("restaurantArray", restaurantArray);
    getDesignationList(restaurantArray, valuesArray);
  };

  const getDesignationList = (id, branchId) => {
    const requestOption = {
      id: id,
      branchId: branchId,
    };
    dispatch(teambased(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllDesignation(res?.payload?.designation);
          setAllRole(res?.payload?.roles);
          console.log(
            "res?.payload?.payload.designation",
            res?.payload?.designation
          );
          // setTotalCounts(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const alldesignationList = Array.isArray(allDesignation)
    ? allDesignation.map((item) => ({
        label: item.designationName,
        value: item._id,
      }))
    : [];

  const getRoleListData = (id) => {
    // setIsLoading(true);
    // const requestOption = {
    //   page: "",
    //   search: "",
    //   limit: "",
    // };
    dispatch(teambased(id))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRole(res?.payload?.roles);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const allRoleList = Array.isArray(allRole)
    ? allRole.map((item) => ({
        label: item.roleName,
        value: item._id,
      }))
    : [];

  const handlePerfectUserChange = (event) => {
    const isChecked = event.target.checked;
    setIsPerfectUser(isChecked);

    if (isChecked) {
      setSelectedRestaurant(allrestaurantlist);

      const valuesArray = allrestaurantlist.map((option) => option.value);

      setSelectedvaluesArray(valuesArray);

      // console.log("valuesArray", valuesArray);

      setTimeout(() => {
        setSelectedOptionsBranch(allbrachList);
        const valuesArray2 = allbrachList.map((option) => option.value);
        getBraches(valuesArray);
        getDesignationList(valuesArray, valuesArray2);
        setSelectedBrancArray(valuesArray2);
        console.log("valuesArray2", allbrachList);
      }, 2000);

      console.log("sss", valuesArray);
    } else {
      setSelectedRestaurant([]);
      setSelectedOptionsBranch([]);
    }
  };

  const handleOpen = () => {
    getRestaurantListData();
    // getBraches();
    // getDesignationList();
    // getRoleListData();
    setOpen(true);
  };

  React.useEffect(() => {
    if (roletype === "1") {
      setSelectedRestaurant({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("RoleName"),
      });

      setSelectedvaluesArray(localStorage.getItem("id"));
      setFilddisble(true);
      getBraches(localStorage.getItem("id"));

      getDesignationList(localStorage.getItem("id"));
    }
  }, []);
  return (
    <div>
      <div
        className="card card-custom_box h-100 py-3 bg-blue"
        onClick={handleOpen}
      >
        <div className="card-body">
          <div className="row card-custom_box_row no-gutters align-items-center">
            <div className="col mt-2">
              <div className="h5  text-center">
                <Button className="add_brach_btn">
                  {" "}
                  <h5> Add Team Members </h5>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container Team_modal">
              <div className="modal_heading">
                <h1>Add Team</h1>
              </div>
              <div className="modal_form_container">
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="field_cont ">
                      {roletype === "1" || roletype === "2" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="Prefectuser space_div pb-0 pl-0">
                            <input
                              type="checkbox"
                              checked={isPerfectUser}
                              onChange={handlePerfectUserChange}
                            />{" "}
                            <label>Is Prefect User</label>
                          </div>
                        </>
                      )}{" "}
                      <div className="row">
                        <div className="col-md-6 d-flex flex-column mb-3 addrolemange">
                          <label htmlFor="Restaurant">
                            Restaurant <span className="text-danger">*</span>
                          </label>

                          {roletype === "1" || roletype === "2" ? (
                            <>
                              {activeuser && roletype === "2" ? (
                                <MultiSelect
                                  options={allrestaurantlist}
                                  value={selectedRestaurant}
                                  onChange={handleChange}
                                  labelledBy="Select Restaurant..."
                                  overrideStrings={{
                                    selectSomeItems: "Select Restaurant",
                                    allItemsAreSelected:
                                      "All Restaurant are selected",
                                  }}
                                  // isDisabled={filddisble}
                                />
                              ) : (
                                <Select
                                  options={allrestaurantlist}
                                  value={selectedRestaurant}
                                  onChange={handleChange}
                                  placeholder="Select Restaurant"
                                  isDisabled={filddisble}
                                />
                              )}{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              <MultiSelect
                                options={allrestaurantlist}
                                value={selectedRestaurant}
                                onChange={handleChange}
                                labelledBy="Select Restaurant..."
                                overrideStrings={{
                                  selectSomeItems: "Select Restaurant",
                                  allItemsAreSelected:
                                    "All Restaurant are selected",
                                }}
                                // isDisabled={filddisble}
                              />
                            </>
                          )}

                          {/* <ErrorMessage
                            component="p"
                            name="branch_name"
                            className="text-danger"
                          /> */}
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3 addrolemange">
                          <label htmlFor="Branch">
                            Branch
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <MultiSelect
                            options={allbrachList}
                            value={selectedOptionsBranch}
                            onChange={handleChangeBranch}
                            labelledBy="Select Branch..."
                            overrideStrings={{
                              selectSomeItems: "Select Branch",
                              allItemsAreSelected: "All branches are selected",
                            }}
                          />
                          {/* <ErrorMessage
                            component="p"
                            name="house_street"
                            className="text-danger"
                          /> */}
                        </div>
                        <div className="col-md-12 d-flex flex-column mb-3">
                          <label htmlFor="MemberName">
                            Member Name <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="text"
                            Placeholder="Enter  Member Name "
                            id="MemberName"
                            name="MemberName"
                          />
                          <ErrorMessage
                            component="p"
                            name="MemberName"
                            className="text-danger"
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="MobileNumber">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="number"
                            Placeholder="Enter Mobile Number"
                            id="MobileNumber"
                            name="MobileNumber"
                          />
                          <ErrorMessage
                            component="p"
                            name="MobileNumber"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="email">
                            Email <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="email"
                            Placeholder="Enter Email"
                            id="Email"
                            name="email"
                          />
                          <ErrorMessage
                            component="p"
                            name="email"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="Designation">
                            Designation <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            Placeholder="Enter Designation Name"
                            id="Designation"
                            name="Designation"
                          >
                            <option value="" disabled>
                              Select Designation
                            </option>
                            {alldesignationList.map((designation, i) => (
                              <option value={designation.value} key={i}>
                                {designation.label}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            component="p"
                            name="Designation"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="RoleAssign">
                            Role Assign <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            Placeholder="Role Assign"
                            id="RoleAssign"
                            name="RoleAssign"
                          >
                            <option value="" disabled>
                              Select Role
                            </option>
                            {allRoleList?.map((role, i) => (
                              <option value={role?.value} key={i}>
                                {role?.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="p"
                            name="RoleAssign"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="MobileNumber">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="eys_input">
                            <Field
                              type={showPassword ? "text" : "password"}
                              Placeholder="Enter Password"
                              id="password"
                              name="password"
                              style={{ width: "100%" }}
                            />

                            <span
                              className="password-toggle-icon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            component="p"
                            name="password"
                            className="text-danger"
                          />
                        </div>

                        <div className="col-md-6 d-flex flex-column mb-3">
                          <label htmlFor="MobileNumber">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          {/* <Field
                            type="text"
                            Placeholder="Enter Confirm Password"
                            id="ConfirmPassword"
                            name="ConfirmPassword"
                          /> */}

                          <div className="eys_input">
                            <Field
                              type={showPassword2 ? "text" : "password"}
                              Placeholder="Enter Password"
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              style={{ width: "100%" }}
                            />

                            <span
                              className="password-toggle-icon"
                              onClick={() => setShowPassword2(!showPassword2)}
                            >
                              <FontAwesomeIcon
                                icon={showPassword2 ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            component="p"
                            name="ConfirmPassword"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal_btn_container">
                      <div
                        className="modal_cancel_btn"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </div>
                      <button
                        className="modal_cancel_btn"
                        type="submit"
                        // disabled={disableSubmitBtn == true}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
