import React, { useState, useEffect } from "react";
import "./add.css";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import menu_icon from "../../../../../img/Vector.svg";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MultiSelect } from "react-multi-select-component";
import preview_generic_image from "../../../../../img/AddModal/Group 839.png";
import { useDispatch } from "react-redux";
import {
  branchrole,
  customizationList,
  itemsList,
  mealAdd,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import upload_generic_image from "../../../../../img/AddModal/Group 82553.png";
import { json, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import cross_image from "../../../../../img/AddModal/Group 82624.png";

const AddMeal = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);
  const [items, setItems] = useState([]);
  const [customizationLists, setCustomizationList] = useState([]);
  const [addCustomizationShow, setAddCustomizationShow] = useState(false);

  const [itemImagePreview, setItemImagePreview] = useState("");
  const [itemImageUpload, setItemImageUpload] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setItemImageUpload(file);
      setItemImagePreview(URL.createObjectURL(file));
    }
  };
  const [checkImageError, setCheckImageError] = useState(false);

  const initialCategoryItemMealCustomizationItems = {
    item: "",
    actual_price: "",
    discount_price: "",
    image: "",
    imagePreview: "",
    isSelect: false,
  };

  const initialCategoryItemMealCustomization = {
    title: "",
    items: [initialCategoryItemMealCustomizationItems],
    isNew: false,
  };

  const initialCategoryItem = {
    customizeItem: false,
    item: "",
    actual_price: "",
    discount_price: "",
    image: "",
    imagePreview: "",

    mealCustomization: [initialCategoryItemMealCustomization],
  };

  const initialCategory = {
    categoryName: "",
    isMultiSelector: false,
    maximumNumber: "",
    minimumNumber: "",
    items: [initialCategoryItem],
  };

  const [initialValue, setInitialValue] = useState({
    restaurant_name:
      localStorage.getItem("roletype") == "1" ? localStorage.getItem("id") : "",

    branch_name: [],
    mealName: "",
    mealDescription: "",
    category: [initialCategory],
    meal_actual_prise: "",
    meal_original_price: "",
  });
  console.log(initialValue, "initialValue");

  //FUNCTION FOR DELETE MEAL CUSTOMIZATION ITEMS
  const deleteCustomizationItem = (
    categoryIndex,
    itemIndex,
    mealIndex,
    mealItemIndex
  ) => {
    setInitialValue((prevState) => {
      const updatedCategories = [...prevState.category];
      const updatedItems = [...updatedCategories[categoryIndex].items];
      const updatedMealCustomizations = [
        ...updatedItems[itemIndex].mealCustomization,
      ];

      // Remove the item at the specified index
      updatedMealCustomizations[mealIndex].items.splice(mealItemIndex, 1);

      // Update the state
      updatedItems[itemIndex].mealCustomization = updatedMealCustomizations;
      updatedCategories[categoryIndex].items = updatedItems;
      return {
        ...prevState,
        category: updatedCategories,
      };
    });
  };

  // Function for add Items
  const handleAddItem = (categoryIndex, itemPush) => {
    const newItem = { ...initialCategoryItem };

    // Add new item to initialValue state
    const updatedCategories = [...initialValue.category];
    updatedCategories[categoryIndex].items.push(newItem);
    setInitialValue({ ...initialValue, category: updatedCategories });

    // Also add the new item to Formik's field array
    itemPush(newItem);
  };

  const handleRemoveItem = (categoryIndex, itemIndex, itemRemove) => {
    // Remove item from initialValue state
    const updatedCategories = [...initialValue.category];
    updatedCategories[categoryIndex].items.splice(itemIndex, 1);
    setInitialValue({ ...initialValue, category: updatedCategories });

    // Also remove the item from Formik's field array
    itemRemove(itemIndex);
  };

  // Function for add Category
  const handleAddCategory = (categoryPush) => {
    const newCategory = { ...initialCategory };

    // Add new category to initialValue state
    setInitialValue((prevValues) => ({
      ...prevValues,
      category: [...prevValues.category, newCategory],
    }));

    // Also add the new category to Formik's field array
    categoryPush(newCategory);
  };

  const handleRemoveCategory = (categoryIndex, categoryRemove) => {
    // Remove category from initialValue state
    setInitialValue((prevValues) => ({
      ...prevValues,
      category: prevValues.category.filter(
        (_, index) => index !== categoryIndex
      ),
    }));

    // Also remove the category from Formik's field array
    categoryRemove(categoryIndex);
  };

  // Function for add Meal Customization

  const handleAddCustomization = (categoryIndex, itemIndex) => {
    setAddCustomizationShow(true);
    setInitialValue((prevState) => {
      const newCategory = [...prevState.category];
      const newItems = [...newCategory[categoryIndex].items];
      let newMealCustomization = [...newItems[itemIndex].mealCustomization];

      const newCustomization = {
        ...initialCategoryItemMealCustomization,
        isNew: true, // Set isNew parameter to true
        items: initialCategoryItemMealCustomization.items.map((item) => ({
          ...item,
          isSelect: true,
        })),
      };

      // Check if the first item is blank
      const firstItem = newMealCustomization[0];
      const isBlank =
        !firstItem?.title &&
        firstItem?.items.every(
          (item) =>
            !item.item &&
            !item.actual_price &&
            !item.discount_price &&
            !item.image &&
            !item.imagePreview
        );

      if (isBlank) {
        // Replace the first blank object with the new one
        newMealCustomization[0] = newCustomization;
      } else {
        // Add the new customization object to the array
        newMealCustomization = [...newMealCustomization, newCustomization];
      }

      newItems[itemIndex] = {
        ...newItems[itemIndex],
        mealCustomization: newMealCustomization,
      };

      newCategory[categoryIndex] = {
        ...newCategory[categoryIndex],
        items: newItems,
      };

      return { ...prevState, category: newCategory };
    });
  };

  const handleAddCustomizationItem = (
    categoryIndex,
    itemIndex,
    customizationIndex
  ) => {
    setInitialValue((prevState) => {
      const newCategory = [...prevState.category];
      const newItems = [...newCategory[categoryIndex].items];
      const newMealCustomization = [...newItems[itemIndex].mealCustomization];
      const newCustomizationItem = {
        ...initialCategoryItemMealCustomizationItems,
        isSelect: true, // Set isSelect to true
      };
      newMealCustomization[customizationIndex].items.push(newCustomizationItem); // Push new item into items array
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        mealCustomization: newMealCustomization,
      };
      newCategory[categoryIndex] = {
        ...newCategory[categoryIndex],
        items: newItems,
      };
      return { ...prevState, category: newCategory };
    });
  };

  const removeMealCustomization = (
    categoryIndex,
    itemIndex,
    customizationIndex
  ) => {
    setInitialValue((prevState) => {
      const newCategory = [...prevState.category];
      const newItems = [...newCategory[categoryIndex].items];
      const newMealCustomization = newItems[itemIndex].mealCustomization.filter(
        (_, index) => index !== customizationIndex
      );
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        mealCustomization: newMealCustomization,
      };
      newCategory[categoryIndex] = {
        ...newCategory[categoryIndex],
        items: newItems,
      };
      return { ...prevState, category: newCategory };
    });
  };

  const validationSchema = Yup.object().shape({
    restaurant_name: Yup.string().required("Restaurant name is required"),
    // branch_name: Yup.array().min(1, "At least one branch is required"),
    mealName: Yup.string().required("Meal name is required"),
    mealDescription: Yup.string().required("Meal description is required"),
    category: Yup.array().of(
      Yup.object().shape({
        categoryName: Yup.string().required("Category name is required"),
        maximumNumber: Yup.number()
          .typeError("Maximum Number must be a number")
          .when("isMultiselect", {
            is: true,
            then: Yup.number()
              .required("Maximum Number is required")
              .positive("Maximum Number must be a positive number"),
            otherwise: Yup.number(),
          }),
        minimumNumber: Yup.number()
          .typeError("Minimum Number must be a number")
          .when("isMultiselect", {
            is: true,
            then: Yup.number()
              .required("Minimum Number is required")
              .positive("Minimum Number must be a positive number"),
            otherwise: Yup.number(),
          }),
        items: Yup.array().of(
          Yup.object().shape({
            // actual_price: Yup.number()
            //   .nullable()
            //   .test(
            //     "is-decimal",
            //     "Must be a number with up to 2 digits after the decimal point",
            //     (value) =>
            //       value === null ||
            //       value === undefined ||
            //       Number.isInteger(value) ||
            //       /^\d+(\.\d{1,2})?$/.test(value.toString())
            //   ),
            // discount_price: Yup.number().required("Discount price is required"),
            // .test(
            //   "is-decimal",
            //   "Must be a decimal with up to 2 digits after the decimal point",
            //   (value) => (value + "").match(/^\d+(\.\d{1,2})?$/)
            // ),
          })
        ),
      })
    ),
    // meal_actual_prise: Yup.number()
    //   .required("Meal actual price is required")
    //   .test(
    //     "is-decimal",
    //     "Must be a decimal with up to 2 digits after the decimal point",
    //     (value) => (value + "").match(/^\d+(\.\d{1,2})?$/)
    //   ),
    // meal_original_price: Yup.number()
    //   .required("Meal original price is required")
    //   .test(
    //     "is-decimal",
    //     "Must be a decimal with up to 2 digits after the decimal point",
    //     (value) => (value + "").match(/^\d+(\.\d{1,2})?$/)
    //   ),
  });

  const [isLoadingRestro, setIsLoadingRestro] = useState(false);

  const getRestaurantListData = () => {
    setIsLoadingRestro(false);
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
          }
          if (localStorage.getItem("roletype") == "2") {
            getBraches(res?.payload?.payload[0]?._id);
            getItems(res?.payload?.payload[0]?._id);
            setInitialValue((prevState) => ({
              ...prevState,
              restaurant_name: res?.payload?.payload[0]?._id,
            }));
            setIsLoadingRestro(true);
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };

  const getItems = (id) => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
      restaurant: id,
      branch: "",
      status: "",
      categoryid: "",
    };

    try {
      dispatch(itemsList(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          setItems(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };
  useEffect(() => {
    getRestaurantListData();
    // getItems();
    if (roletype == 1) {
      getItems(localStorage.getItem("id"));
    }
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allBrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  // FUNCTION FOR SET ITEM ACTUAL VALUE WHEN CHANGE OR SELECT ITEMS
  const handleChangeItemsValue = (categoryIndex, itemIndex, e) => {
    if (e) {
      let itemObject;
      try {
        itemObject = JSON.parse(e);

        const selectedItem = items.find(
          (data) => data?._id == itemObject.value
        );
        console.log(selectedItem, "selectedItem");
        if (selectedItem) {
          setInitialValue((prevState) => {
            const updatedCategory = [...prevState.category];
            updatedCategory[categoryIndex] = {
              ...updatedCategory[categoryIndex],
              items: updatedCategory[categoryIndex].items.map((item, index) => {
                if (index === itemIndex) {
                  return {
                    ...item,
                    actual_price: selectedItem.originalPrice,
                    image: selectedItem.image,
                  };
                }
                return item;
              }),
            };
            return {
              ...prevState,
              category: updatedCategory,
            };
          });
        }
      } catch (error) {
        console.log("no item found");
      }
    }
  };

  const [loadingCustomization, setLoadingCustomization] = useState(false);
  const [categoryIndex, setCategoeyIndex] = useState(null);
  const [itemIndex, setItemIndex] = useState(null);

  const getCustomizationList = (itemObject, newCategoryIndex, newItemIndex) => {
    if (itemObject) {
      let convertIntoParse;

      try {
        // Try to parse the input as JSON
        convertIntoParse = JSON.parse(itemObject);
        console.log("Input parsed as JSON:", convertIntoParse);

        const requestOption = {
          page: "",
          search: "",
          limit: "",
          status: "",
          itemId: convertIntoParse.value,
        };

        dispatch(customizationList(requestOption))
          .then((res) => {
            if (res?.payload?.status === true) {
              const customizationsFromAPI = res?.payload?.payload;

              // Map API response to newCustomizations
              const newCustomizations = customizationsFromAPI.map((res) => {
                // Initialize isSelect based on existing selection in initialValue
                const selectedItems = res?.customization?.items.map((item) => {
                  const initialValueCategory =
                    initialValue.category[newCategoryIndex];
                  const initialValueItem =
                    initialValueCategory.items[newItemIndex];

                  // Check if this item was selected in initialValue
                  const isSelected = initialValueItem.mealCustomization.some(
                    (mcRes) =>
                      mcRes.items.some(
                        (mc) => mc.item === item.name && mc.isSelect === true
                      )
                  );

                  return {
                    item: item.name,
                    actual_price: item.price,
                    discount_price: "",
                    image: item.image,
                    isSelect: isSelected,
                  };
                });

                return {
                  title: res?.customization?.title,
                  items: selectedItems,
                  isNew: false,
                };
              });

              console.log(newCustomizations, "newCustomizations");

              // Update state with newCustomizations or blank if initial value is empty
              setInitialValue((prevState) => {
                const updatedCategory = [...prevState.category];
                updatedCategory[newCategoryIndex] = {
                  ...updatedCategory[newCategoryIndex],
                  items: updatedCategory[newCategoryIndex].items.map(
                    (item, index) => {
                      if (index === newItemIndex) {
                        // Merge existing mealCustomization with newCustomizations
                        let mergedCustomizations = [];

                        // Check if mealCustomization is empty
                        if (
                          item.mealCustomization.length === 0 ||
                          (item.mealCustomization.length === 1 &&
                            !item.mealCustomization[0].title)
                        ) {
                          // If empty, use newCustomizations
                          mergedCustomizations = newCustomizations;
                        } else {
                          // Otherwise, merge existing with new customizations
                          const newCustomizationItems = newCustomizations.find(
                            (nc) => nc.title === item.mealCustomization[0].title
                          );

                          // Add existing mealCustomization items not present in newCustomizations
                          item.mealCustomization.forEach((mc) => {
                            if (
                              !newCustomizationItems ||
                              mc.title !== newCustomizationItems.title
                            ) {
                              mergedCustomizations.push(mc);
                            }
                          });

                          // Add newCustomizations if any
                          if (newCustomizationItems) {
                            mergedCustomizations.push(newCustomizationItems);
                          }
                        }

                        // Return updated item with merged customizations
                        return {
                          ...item,
                          mealCustomization: mergedCustomizations.length
                            ? mergedCustomizations
                            : item.mealCustomization,
                        };
                      }
                      return item;
                    }
                  ),
                };
                return {
                  ...prevState,
                  category: updatedCategory,
                };
              });
            }
            setLoadingCustomization(true); // Set loading to false when the request completes
          })
          .catch((error) => {
            console.error("Error fetching customization data:", error);
            setLoadingCustomization(true); // Set loading to false on error
            // Handle error if needed
          });
      } catch (e) {
        // If parsing fails, assume the input is already a string
        convertIntoParse = itemObject;
        console.log("Input is a string:", convertIntoParse);
        // Set initialValue with empty customization
        //  setInitialValue((prevState) => {
        //   const updatedCategory = [...prevState.category];
        //   updatedCategory[newCategoryIndex] = {
        //     ...updatedCategory[newCategoryIndex],
        //     items: updatedCategory[newCategoryIndex].items.map((item, index) => {
        //       if (index === newItemIndex) {
        //         // If there is no title, set mealCustomization to [{}]
        //         if (item.mealCustomization.length === 0 ||
        //             (item.mealCustomization.length === 1 && !item.mealCustomization[0].title)) {
        //           return {
        //             ...item,
        //             mealCustomization: [{}],
        //           };
        //         }
        //         // Otherwise, keep existing mealCustomization
        //         return {
        //           ...item,
        //           mealCustomization: item.mealCustomization,
        //         };
        //       }
        //       return item;
        //     }),
        //   };
        //   return {
        //     ...prevState,
        //     category: updatedCategory,
        //   };
        // });
        setLoadingCustomization(true); // Set loading to false when the request completes
      }

      // Set the indices for category and item
      setCategoeyIndex(newCategoryIndex);
      setItemIndex(newItemIndex);
    }
  };

  // FUNCTION FOR CHANGE VALUE OF SELECTED CUSTOMIZATION

  const handleSelectCustomization = (
    customizationIndex,
    customizationItemIndex,
    e
  ) => {
    const { checked } = e.target;
    const newState = { ...initialValue }; // Create a copy of the state

    // Check if customizationIndex is valid
    if (
      newState.category[categoryIndex] &&
      newState.category[categoryIndex].items[itemIndex] &&
      newState.category[categoryIndex].items[itemIndex].mealCustomization[
        customizationIndex
      ] &&
      newState.category[categoryIndex].items[itemIndex].mealCustomization[
        customizationIndex
      ].items[customizationItemIndex]
    ) {
      newState.category[categoryIndex].items[itemIndex].mealCustomization[
        customizationIndex
      ].items[customizationItemIndex].isSelect = checked; // Update only the isSelect field

      setInitialValue(newState); // Set the updated state
    } else {
      console.error("Invalid customization or item index");
    }
  };

  /// FUNCTION FOR CHANGE CUSTOMIZATION DISCOUNT PRICE  START
  const handleChangeDiscount = (
    customizationIndex,
    customizationItemIndex,
    e
  ) => {
    const { value } = e.target;
    const newState = { ...initialValue }; // Create a copy of the state

    if (
      newState.category[categoryIndex] &&
      newState.category[categoryIndex].items[itemIndex] &&
      newState.category[categoryIndex].items[itemIndex].mealCustomization[
        customizationIndex
      ] &&
      newState.category[categoryIndex].items[itemIndex].mealCustomization[
        customizationIndex
      ].items[customizationItemIndex]
    ) {
      newState.category[categoryIndex].items[itemIndex].mealCustomization[
        customizationIndex
      ].items[customizationItemIndex].discount_price = value; // Update only the isSelect field

      setInitialValue(newState); // Set the updated state
    } else {
      console.error("Invalid customization or item index");
    }
  };

  //FUNCTION FOR CHANGE ITEM NAME  OF ITEM
  const handleChangeItemName = (categoryIndex, itemIndex, e) => {
    const newState = { ...initialValue }; // Create a copy of the state
    newState.category[categoryIndex].items[itemIndex].item = e; // Update only the discount_price field
    setInitialValue(newState); // Set the updated state
  };

  //FUNCTION FOR CHANGE ITEM Image  OF ITEM
  const handleChangeItemImage = (categoryIndex, itemIndex, e) => {
    const newState = { ...initialValue }; // Create a copy of the state
    newState.category[categoryIndex].items[itemIndex].image = e; // Update only the discount_price field
    newState.category[categoryIndex].items[itemIndex].imagePreview =
      URL.createObjectURL(e); // Update only the discount_price field

    setInitialValue(newState); // Set the updated state
  };

  const handleBlankItem = (categoryIndex, itemIndex) => {
    // Create a copy of the initial state
    const newState = { ...initialValue };

    // Update the item and actual_price fields
    newState.category[categoryIndex].items[itemIndex].item = "";
    newState.category[categoryIndex].items[itemIndex].actual_price = "";

    // Set the updated state
    setInitialValue(newState);
  };
  //FUNCTION FOR CHANGE ACTUAL PRICE OF ITEM
  const handleChangeItemActualPrice = (categoryIndex, itemIndex, e) => {
    const { value } = e.target;
    const newState = { ...initialValue }; // Create a copy of the state
    newState.category[categoryIndex].items[itemIndex].actual_price = value; // Update only the discount_price field
    setInitialValue(newState); // Set the updated state
  };
  //FUNCTION FOR CHANGE DISCOUNT PRICE OF ITEM
  const handleChangeItemDiscountPrice = (categoryIndex, itemIndex, e) => {
    const { value } = e.target;
    const newState = { ...initialValue }; // Create a copy of the state
    newState.category[categoryIndex].items[itemIndex].discount_price = value; // Update only the discount_price field
    setInitialValue(newState); // Set the updated state
  };

  const validateCategoryItems = () => {
    let hasError = false;

    initialValue.category.forEach((category) => {
      category.items.forEach((item) => {
        if (item.item === "") {
          hasError = true;
        } else if (item.image == "") {
          hasError = true;
        }
      });
    });

    return hasError;
  };

  const handleSubmit = (values, { resetForm }) => {
    if (
      itemImageUpload == null ||
      itemImageUpload == undefined ||
      itemImageUpload == "" ||
      validateCategoryItems()
    ) {
      setCheckImageError(true);
    } else {
      const branchess = values?.branch_name?.map((res) => res.value);
      console.log(values.category, "values.category");
      const mealCategory = values.category?.map((res, i) => ({
        categoryName: res?.categoryName,
        items: res?.items?.map((itemres, itemI) => {
          const itemValuesSend =
            itemres.customizeItem == false
              ? JSON.parse(initialValue.category[i].items[itemI].item)
              : "";

          return {
            isCustomizeItem: itemres?.customizeItem,
            itemName:
              itemres.customizeItem == false
                ? itemValuesSend.label
                : initialValue.category[i].items[itemI].item,
            itemId: itemres.customizeItem == false ? itemValuesSend.value : "",
            actualPrice: initialValue.category[i].items[itemI].actual_price,
            discountPrice:
              initialValue.category[i].items[itemI]?.discount_price,
            mealCustomization: initialValue.category[i].items[
              itemI
            ].mealCustomization
              .map((res) => {
                const isBlank =
                  !res?.title &&
                  res?.items.every(
                    (item) =>
                      !item.item &&
                      !item.actual_price &&
                      !item.discount_price &&
                      !item.image &&
                      !item.imagePreview
                  );
                if (!isBlank) {
                  const selectedItems = res.items.filter(
                    (item) => item.isSelect
                  );
                  if (selectedItems.length > 0) {
                    return {
                      title: res.title,
                      isNew: res?.isNew,
                      items: selectedItems.map((item) => ({
                        name: item.item,
                        actualPrice: item.actual_price,
                        discountPrice: item.discount_price,
                        isSelect: item?.isSelect,
                      })),
                    };
                  }
                }
                return null; // Return null for non-selected items
              })
              .filter(Boolean), // Filter out null values
          };
        }),
        isMultiselect: res?.isMultiSelector,
        maximumNumber: res?.maximumNumber ? res?.maximumNumber : "1",
        minimumNumber: res?.minimumNumber ? res?.minimumNumber : "1",
      }));

      const mealFormData = new FormData();
      mealFormData.append("restaurantId", values.restaurant_name);
      mealFormData.append("branchIds", JSON.stringify(branchess));
      mealFormData.append("mealName", values.mealName);
      mealFormData.append("description", values.mealDescription);
      mealFormData.append("mealCategory", JSON.stringify(mealCategory));
      // mealFormData.append("mealCustomization", JSON.stringify(mealCustomization));

      mealFormData.append(
        "actualMealPrice",
        mealActualPrice == NaN ? 0 : mealActualPrice
      );
      mealFormData.append(
        "discountMealPrice",
        mealDiscountPrice === NaN ? 0 : mealDiscountPrice
      );
      mealFormData.append("mealImage", itemImageUpload);

      initialValue?.category?.forEach((res, catIndex) => {
        res?.items?.map((itemRes, itemIndex) => {
          mealFormData.append(
            `categoryImages[${catIndex}][${itemIndex}]`,
            itemRes?.image
          );
        });
      });
      initialValue?.category?.forEach((catres, catIndex) => {
        catres?.items?.forEach((itemres, itemIndex) => {
          itemres?.mealCustomization?.forEach((mealres, mealIndex) => {
            mealres?.items?.forEach((mealItemRes, mealItemIndex) => {
              if (mealItemRes.isSelect == true) {
                mealFormData.append(
                  `customizationImages[${catIndex}][${itemIndex}][${mealIndex}][${mealItemIndex}]`,
                  mealItemRes?.image
                );
              }
            });
          });
        });
      });
      const requestOption = {
        method: "POST",
        body: mealFormData,
        Headers: {
          authorization: `${localStorage.getItem("access_token")}`,
        },
      };
      try {
        dispatch(mealAdd(requestOption)).then((res) => {
          if (res?.payload?.status === true) {
            navigate(-1);
            toast.success(res?.payload?.message);
          } else {
            toast.error(res?.payload?.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const shouldShowPage = () => {
    if (localStorage.getItem("roletype") != 2) {
      return true;
    } else if (localStorage.getItem("roletype") == 2 && isLoadingRestro) {
      return true;
    } else {
      return false;
    }
  };

  // NEW CUSTOMIZATION VALUES CHANGES
  const handleTitleChange = (
    e,
    categoryIndex,
    itemIndex,
    customizationIndex,
    field
  ) => {
    const newValue = e.target.value;

    setInitialValue((prevState) => {
      const newCategory = [...prevState.category];
      const newItems = [...newCategory[categoryIndex].items];
      const newMealCustomization = [...newItems[itemIndex].mealCustomization];

      // Update the specific field based on the 'field' parameter
      if (field === "title") {
        newMealCustomization[customizationIndex] = {
          ...newMealCustomization[customizationIndex],
          title: newValue,
        };
      }

      newItems[itemIndex] = {
        ...newItems[itemIndex],
        mealCustomization: newMealCustomization,
      };
      newCategory[categoryIndex] = {
        ...newCategory[categoryIndex],
        items: newItems,
      };
      return { ...prevState, category: newCategory };
    });
  };

  // NEW CUSTOMIZATION VALUES CHANGES
  const handleCustomizationItemsChange = (
    e,
    categoryIndex,
    itemIndex,
    customizationIndex,
    customizationItemIndex,
    field
  ) => {
    console.log(customizationItemIndex, "customizationItemIndex");
    const newValue = e.target.value;
    const newFile = e.target.files ? e.target.files[0] : null; // Handle file input for image

    setInitialValue((prevState) => {
      const newCategory = [...prevState.category];
      const newItems = [...newCategory[categoryIndex].items];
      const newMealCustomization = [...newItems[itemIndex].mealCustomization];
      const newMealCustomizationItems = [
        ...newMealCustomization[customizationIndex].items,
      ];

      if (field === "item") {
        newMealCustomizationItems[customizationItemIndex] = {
          ...newMealCustomizationItems[customizationItemIndex],
          item: newValue,
        };
      } else if (field === "actual_price") {
        newMealCustomizationItems[customizationItemIndex] = {
          ...newMealCustomizationItems[customizationItemIndex],
          actual_price: newValue,
        };
      } else if (field === "discount_price") {
        newMealCustomizationItems[customizationItemIndex] = {
          ...newMealCustomizationItems[customizationItemIndex],
          discount_price: newValue,
        };
      } else if (field === "image") {
        newMealCustomizationItems[customizationItemIndex] = {
          ...newMealCustomizationItems[customizationItemIndex],
          image: newFile,
        };
      }

      newMealCustomization[customizationIndex] = {
        ...newMealCustomization[customizationIndex],
        items: newMealCustomizationItems,
      };
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        mealCustomization: newMealCustomization,
      };
      newCategory[categoryIndex] = {
        ...newCategory[categoryIndex],
        items: newItems,
      };
      return { ...prevState, category: newCategory };
    });
  };

  //FUNCTION FOR CALCULATE MEAL ACTUAL  PRICES
  const [mealActualPrice, setMealActualPrice] = useState(0);
  const totalActualPrice = () => {
    const categoryItemPrices = [];

    initialValue.category?.forEach((category) => {
      if (category?.items) {
        category.items.forEach((item) => {
          if (item?.actual_price) {
            categoryItemPrices.push(item.actual_price || 0);
          }
        });
      }
    });

    const categoryItemPriceOut = categoryItemPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );

    setMealActualPrice(categoryItemPriceOut);
  };
  useEffect(() => {
    totalActualPrice();
  }, [initialValue]);

  //FUNCTION FOR CALCULATE MEAL ACTUAL  PRICES
  const [mealDiscountPrice, setMealDiscountPrice] = useState(0);

  const totalDiscountPrice = () => {
    const categoryItemPrices = [];

    initialValue.category?.forEach((category) => {
      if (category?.items) {
        category.items.forEach((item) => {
          if (item?.actual_price) {
            categoryItemPrices.push(item.discount_price || 0);
          }
        });
      }
    });

    const categoryItemPriceOut = categoryItemPrices?.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );

    setMealDiscountPrice(categoryItemPriceOut);
  };
  useEffect(() => {
    totalDiscountPrice();
  }, [initialValue]);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Menu Management" img_icon={menu_icon} />
          <div className="container-fluid">
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  {shouldShowPage() && (
                    <Formik
                      initialValues={initialValue}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => (
                        <Form className="form-container">
                          <div className="row">
                            <div className="col-md-7">
                              <section className="add_category ">
                                <div className="add_category_inner">
                                  <h6 className="heading_name">Add Meal</h6>
                                  <hr />
                                  <div className="form_container">
                                    <div className="field-container">
                                      <div className="row">
                                        <div className="col-lg-6 d-flex flex-column">
                                          <label htmlFor="Restaurant">
                                            Restaurant{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            as="select"
                                            id="Restaurant"
                                            name="restaurant_name"
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                "restaurant_name",
                                                e.target.value
                                              );
                                              getBraches(e.target.value);
                                              getItems(e.target.value);
                                            }}
                                            disabled={
                                              localStorage.getItem(
                                                "roletype"
                                              ) == 1 ||
                                              localStorage.getItem(
                                                "roletype"
                                              ) == 2
                                                ? true
                                                : false
                                            }
                                          >
                                            <option value="">
                                              Select Restaurant Name
                                            </option>
                                            {restaurant.map((res, i) => (
                                              <option value={res?._id} key={i}>
                                                {res?.name}
                                              </option>
                                            ))}
                                          </Field>
                                          <ErrorMessage
                                            component="p"
                                            name="restaurant_name"
                                            className="text-danger"
                                          />
                                        </div>
                                        <div className="col-lg-6 d-flex flex-column">
                                          <label htmlFor="branch_name">
                                            Branch{" "}
                                          </label>
                                          <MultiSelect
                                            options={allBrachList}
                                            name="branch_name"
                                            labelledBy="Select"
                                            value={formik.values.branch_name}
                                            onChange={(e) =>
                                              formik.setFieldValue(
                                                "branch_name",
                                                e
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            component="p"
                                            name="branch_name"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <label htmlFor="mealName">
                                          Meal Name{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Enter Meal Name"
                                          id="mealName"
                                          name="mealName"
                                        />
                                        <ErrorMessage
                                          component="p"
                                          name="mealName"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <label htmlFor="mealDescription">
                                          Description{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          as="textarea"
                                          placeholder="Write Meal Description here"
                                          id="mealDescription"
                                          name="mealDescription"
                                          rows={4}
                                          cols={4}
                                        />
                                        <ErrorMessage
                                          component="p"
                                          name="mealDescription"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className=" ">
                                        <label htmlFor="item_image">
                                          Upload Image
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="item_image_container">
                                          <div className="item_image_upload_btn">
                                            <button>Upload Image</button>
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={(e) =>
                                                handleImageChange(e)
                                              }
                                            />
                                          </div>
                                          <div className="uploaded_img_container">
                                            <img
                                              src={
                                                itemImagePreview
                                                  ? itemImagePreview
                                                  : preview_generic_image
                                              }
                                              alt=""
                                              className="item_uploaded_img"
                                            />
                                          </div>
                                        </div>
                                        {checkImageError &&
                                          !itemImageUpload && (
                                            <p className="m-0 text-danger">
                                              Please upload image
                                            </p>
                                          )}
                                      </div>

                                      <FieldArray name="category">
                                        {({
                                          push: categoryPush,
                                          remove: categoryRemove,
                                        }) => (
                                          <>
                                            {formik.values.category.map(
                                              (category, categoryIndex) => (
                                                <>
                                                  <hr />
                                                  <div
                                                    key={categoryIndex}
                                                    className="category-section"
                                                  >
                                                    <div className="d-flex flex-column">
                                                      <label
                                                        htmlFor={`category.${categoryIndex}.categoryName`}
                                                      >
                                                        Category{" "}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                      <Field
                                                        type="text"
                                                        placeholder="Enter Category"
                                                        id={`category.${categoryIndex}.categoryName`}
                                                        name={`category.${categoryIndex}.categoryName`}
                                                      />
                                                      <ErrorMessage
                                                        component="p"
                                                        name={`category.${categoryIndex}.categoryName`}
                                                        className="text-danger"
                                                      />
                                                    </div>

                                                    <FieldArray
                                                      name={`category.${categoryIndex}.items`}
                                                    >
                                                      {({
                                                        push: itemPush,
                                                        remove: itemRemove,
                                                      }) => (
                                                        <>
                                                          <div className="d-flex align-items-center justify-content-between my-3">
                                                            <p className="m-0 font-weight-bold">
                                                              Items{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </p>
                                                            <p
                                                              className="add_more_field_btn"
                                                              onClick={() => {
                                                                console.log(
                                                                  "Adding new item"
                                                                );
                                                                handleAddItem(
                                                                  categoryIndex,
                                                                  itemPush
                                                                );
                                                              }}
                                                            >
                                                              Add More Item
                                                            </p>
                                                          </div>
                                                          {formik.values.category[
                                                            categoryIndex
                                                          ].items.map(
                                                            (
                                                              item,
                                                              itemIndex
                                                            ) => (
                                                              <div
                                                                key={itemIndex}
                                                                className="item-section"
                                                              >
                                                                <div className="row">
                                                                  <div className="col-lg-8 d-flex flex-column">
                                                                    <div
                                                                      className="d-flex"
                                                                      style={{
                                                                        gap: "10px",
                                                                      }}
                                                                    >
                                                                      <Field
                                                                        type="checkbox"
                                                                        className="input_check_box"
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.customizeItem`}
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          formik.setFieldValue(
                                                                            `category.${categoryIndex}.items.${itemIndex}.customizeItem`,
                                                                            e
                                                                              .target
                                                                              .checked
                                                                          );
                                                                          handleBlankItem(
                                                                            categoryIndex,
                                                                            itemIndex
                                                                          );
                                                                        }}
                                                                      />
                                                                      <label
                                                                        htmlFor={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      >
                                                                        Customize
                                                                        Item or
                                                                        new Item{" "}
                                                                        <span className="text-danger">
                                                                          *
                                                                        </span>
                                                                      </label>
                                                                    </div>
                                                                    <Field
                                                                      as="select"
                                                                      placeholder="Enter Item Name"
                                                                      id={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      name={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                      disabled={
                                                                        formik
                                                                          .values
                                                                          .category[
                                                                          categoryIndex
                                                                        ].items[
                                                                          itemIndex
                                                                        ]
                                                                          .customizeItem
                                                                      }
                                                                      value={
                                                                        initialValue
                                                                          .category[
                                                                          categoryIndex
                                                                        ].items[
                                                                          itemIndex
                                                                        ].item
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        handleChangeItemName(
                                                                          categoryIndex,
                                                                          itemIndex,
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                        handleChangeItemsValue(
                                                                          categoryIndex,
                                                                          itemIndex,
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                      }}
                                                                    >
                                                                      <option value="">
                                                                        Select
                                                                        Item
                                                                      </option>
                                                                      {items?.map(
                                                                        (
                                                                          res,
                                                                          i
                                                                        ) => (
                                                                          <option
                                                                            value={JSON.stringify(
                                                                              {
                                                                                value:
                                                                                  res?._id,
                                                                                label:
                                                                                  res?.name,
                                                                              }
                                                                            )}
                                                                            key={
                                                                              i
                                                                            }
                                                                          >
                                                                            {
                                                                              res?.name
                                                                            }
                                                                          </option>
                                                                        )
                                                                      )}
                                                                    </Field>
                                                                    {checkImageError &&
                                                                      !initialValue
                                                                        .category[
                                                                        categoryIndex
                                                                      ].items[
                                                                        itemIndex
                                                                      ]
                                                                        .item && (
                                                                        <p className="m-0 text-danger">
                                                                          Item
                                                                          is
                                                                          required
                                                                        </p>
                                                                      )}
                                                                  </div>
                                                                  <div className="col-md-4">
                                                                    <div
                                                                      className="choose_customize"
                                                                      onClick={() =>
                                                                        getCustomizationList(
                                                                          initialValue
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ]
                                                                            .item,
                                                                          categoryIndex,
                                                                          itemIndex
                                                                        )
                                                                      }
                                                                    >
                                                                      Choose
                                                                      Customize
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {formik.values
                                                                  .category[
                                                                  categoryIndex
                                                                ].items[
                                                                  itemIndex
                                                                ]
                                                                  .customizeItem && (
                                                                  <div className="row my-2">
                                                                    <div className="col-lg-7 d-flex flex-column">
                                                                      <div
                                                                        className="d-flex"
                                                                        style={{
                                                                          gap: "10px",
                                                                        }}
                                                                      ></div>
                                                                      <Field
                                                                        type="text"
                                                                        placeholder="Enter Item Name"
                                                                        id={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.item`}
                                                                        value={
                                                                          initialValue
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ].item
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          handleChangeItemName(
                                                                            categoryIndex,
                                                                            itemIndex,
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                        }}
                                                                      />
                                                                      {checkImageError &&
                                                                        !initialValue
                                                                          .category[
                                                                          categoryIndex
                                                                        ].items[
                                                                          itemIndex
                                                                        ]
                                                                          .item && (
                                                                          <p className="m-0 text-danger">
                                                                            Item
                                                                            is
                                                                            required
                                                                          </p>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                      className="col-5 p-0 d-flex align-items-center"
                                                                      style={{
                                                                        gap: "10px",
                                                                      }}
                                                                    >
                                                                      <div className="customization_image_upload_btn">
                                                                        <img
                                                                          src={
                                                                            upload_generic_image
                                                                          }
                                                                          alt=""
                                                                          style={{
                                                                            width:
                                                                              "45px",
                                                                          }}
                                                                        />
                                                                        <input
                                                                          type="file"
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            const file =
                                                                              e
                                                                                .target
                                                                                .files[0];
                                                                            handleChangeItemImage(
                                                                              categoryIndex,
                                                                              itemIndex,
                                                                              file
                                                                            );
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <img
                                                                        src={
                                                                          initialValue
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ]
                                                                            .imagePreview
                                                                            ? initialValue
                                                                                .category[
                                                                                categoryIndex
                                                                              ]
                                                                                .items[
                                                                                itemIndex
                                                                              ]
                                                                                .imagePreview
                                                                            : preview_generic_image
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "55px",
                                                                          height:
                                                                            "45px",
                                                                        }}
                                                                      />
                                                                    </div>

                                                                    {checkImageError &&
                                                                      !initialValue
                                                                        .category[
                                                                        categoryIndex
                                                                      ].items[
                                                                        itemIndex
                                                                      ]
                                                                        .image && (
                                                                        <p className="m-0 text-danger">
                                                                          Image
                                                                          is
                                                                          required
                                                                        </p>
                                                                      )}
                                                                  </div>
                                                                )}
                                                                <div className="row">
                                                                  <div className="col-md-5 d-flex flex-column my-2">
                                                                    <div
                                                                      className="d-flex align-items-center"
                                                                      style={{
                                                                        gap: "20px",
                                                                      }}
                                                                    >
                                                                      <label
                                                                        htmlFor={`category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                        className="m-0"
                                                                      >
                                                                        Actual
                                                                        Price
                                                                        (£){" "}
                                                                        <span className="text-danger">
                                                                          *
                                                                        </span>
                                                                      </label>
                                                                      <Field
                                                                        type="number"
                                                                        placeholder="Actual Price"
                                                                        step="0.01"
                                                                        id={`category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.actual_price`}
                                                                        className="meal_item_price_inputs"
                                                                        value={
                                                                          initialValue
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ]
                                                                            .actual_price
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeItemActualPrice(
                                                                            categoryIndex,
                                                                            itemIndex,
                                                                            e
                                                                          )
                                                                        }
                                                                        disabled={
                                                                          !formik
                                                                            .values
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ]
                                                                            .customizeItem
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-md-6 d-flex flex-column my-2">
                                                                    <div
                                                                      className="d-flex align-items-center"
                                                                      style={{
                                                                        gap: "20px",
                                                                      }}
                                                                    >
                                                                      <label
                                                                        htmlFor={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                      >
                                                                        Discounted
                                                                        Price
                                                                        (£)
                                                                      </label>
                                                                      <Field
                                                                        type="number"
                                                                        step="0.01"
                                                                        placeholder="Discounted Price"
                                                                        id={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                        name={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                        value={
                                                                          initialValue
                                                                            .category[
                                                                            categoryIndex
                                                                          ]
                                                                            .items[
                                                                            itemIndex
                                                                          ]
                                                                            .discount_price
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChangeItemDiscountPrice(
                                                                            categoryIndex,
                                                                            itemIndex,
                                                                            e
                                                                          )
                                                                        }
                                                                        className="meal_item_price_inputs"
                                                                      />
                                                                    </div>
                                                                    {/* <ErrorMessage
                                                                      component="p"
                                                                      name={`category.${categoryIndex}.items.${itemIndex}.discount_price`}
                                                                      className="text-danger"
                                                                    /> */}
                                                                  </div>
                                                                </div>
                                                                <button
                                                                  type="button"
                                                                  className="Remove_btn"
                                                                  onClick={() => {
                                                                    handleRemoveItem(
                                                                      categoryIndex,
                                                                      itemIndex,
                                                                      itemRemove
                                                                    );
                                                                  }}
                                                                >
                                                                  Remove Item
                                                                </button>
                                                              </div>
                                                            )
                                                          )}
                                                        </>
                                                      )}
                                                    </FieldArray>

                                                    <div
                                                      className="d-flex justify-content-between align-items-center my-2 flex-wrap "
                                                      style={{ gap: "10px" }}
                                                    >
                                                      <div
                                                        className="d-flex  align-items-center"
                                                        style={{ gap: "10px" }}
                                                      >
                                                        <Field
                                                          type="checkbox"
                                                          id={`category.${categoryIndex}.isMultiSelector`}
                                                          name={`category.${categoryIndex}.isMultiSelector`}
                                                        />
                                                        <label
                                                          htmlFor={`category.${categoryIndex}.isMultiSelector`}
                                                          className="m-0"
                                                        >
                                                          Is Multiselect?
                                                        </label>
                                                      </div>
                                                      {formik.values.category[
                                                        categoryIndex
                                                      ].isMultiSelector && (
                                                        <>
                                                          <div
                                                            className="d-flex  align-items-center"
                                                            style={{
                                                              gap: "10px",
                                                            }}
                                                          >
                                                            <label
                                                              className="m-0"
                                                              htmlFor={`category.${categoryIndex}.minimumNumber`}
                                                            >
                                                              Enter Minimum
                                                              Number
                                                            </label>
                                                            <Field
                                                              type="number"
                                                              id={`category.${categoryIndex}.minimumNumber`}
                                                              name={`category.${categoryIndex}.minimumNumber`}
                                                              className="maximum_number_field"
                                                            />
                                                          </div>
                                                          <div
                                                            className=" d-flex align-items-center"
                                                            style={{
                                                              gap: "10px",
                                                            }}
                                                          >
                                                            <label
                                                              className="m-0"
                                                              htmlFor={`category.${categoryIndex}.maximumNumber`}
                                                            >
                                                              Enter Maximum
                                                              Number
                                                            </label>
                                                            <Field
                                                              type="number"
                                                              id={`category.${categoryIndex}.maximumNumber`}
                                                              name={`category.${categoryIndex}.maximumNumber`}
                                                              className="maximum_number_field"
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            )}
                                            <div
                                              className="d-flex justify-content-end my-2"
                                              style={{ gap: "5px" }}
                                            >
                                              <p
                                                type="button"
                                                onClick={() =>
                                                  handleAddCategory(
                                                    categoryPush
                                                  )
                                                }
                                                className="add_more_field_btn "
                                              >
                                                Add More Category
                                              </p>
                                              {formik.values.category.length >
                                                1 && (
                                                <p
                                                  type="button"
                                                  onClick={() =>
                                                    handleRemoveCategory(
                                                      categoryIndex,
                                                      categoryRemove
                                                    )
                                                  }
                                                  className="add_more_field_btn ms-1 "
                                                >
                                                  Remove Category
                                                </p>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </FieldArray>
                                      <div className="row mt-2">
                                        <div className="col-md-6">
                                          <div className="d-flex flex-column">
                                            <label htmlFor="meal_actual_prise">
                                              Meal Actual Price (£)
                                            </label>
                                            <Field
                                              type="number"
                                              id="meal_actual_prise"
                                              name="meal_actual_prise"
                                              placeholder="200"
                                              // step="0.01"
                                              disabled
                                              value={mealActualPrice}
                                            />
                                            {/* <ErrorMessage
                                              component="p"
                                              name="meal_actual_prise"
                                              className="text-danger"
                                            /> */}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex flex-column">
                                            <label htmlFor="meal_original_price">
                                              Meal Discounted Price (£)
                                            </label>
                                            <Field
                                              type="number"
                                              id="meal_original_price"
                                              name="meal_original_price"
                                              placeholder="150"
                                              value={mealDiscountPrice}
                                              disabled
                                              // step="0.01"
                                            />
                                            {/* <ErrorMessage
                                              component="p"
                                              name="meal_original_price"
                                              className="text-danger"
                                            /> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mt-4">
                                        <p
                                          type="button"
                                          className="category_cancel m-0"
                                          onClick={() => navigate(-1)}
                                        >
                                          Cancel
                                        </p>
                                        <button
                                          type="submit"
                                          className="category_save"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="col-md-5">
                              <section
                                className="add_category mealCustomization_section "
                                style={{ height: "100%" }}
                              >
                                <div className="add_category_inner">
                                  <h6 className="heading_name">
                                    Choose Customization
                                  </h6>
                                  <hr />
                                  {loadingCustomization && (
                                    <>
                                      <div className="form_container">
                                        {/* {customizationLists?.length > 0 && ( */}
                                        <div className="field-container">
                                          {initialValue.category[
                                            categoryIndex
                                          ].items[
                                            itemIndex
                                          ].mealCustomization?.map(
                                            (res, customizationIndex) => (
                                              <>
                                                {!res?.isNew && (
                                                  <div key={customizationIndex}>
                                                    <div className="d-flex flex-column">
                                                      <label
                                                        htmlFor={`mealCustomization.${customizationIndex}.title`}
                                                      >
                                                        Title
                                                        <span className="text-danger">
                                                          {" "}
                                                          *
                                                        </span>
                                                      </label>
                                                      <Field
                                                        type="text"
                                                        placeholder="Size"
                                                        value={
                                                          initialValue.category[
                                                            categoryIndex
                                                          ].items[itemIndex]
                                                            .mealCustomization[
                                                            customizationIndex
                                                          ].title
                                                        }
                                                        disabled
                                                      />
                                                    </div>

                                                    <div className="row align-items-center my-2">
                                                      <div className="col-3">
                                                        Name
                                                      </div>
                                                      <div className="col-3">
                                                        Actual Price (£) *
                                                      </div>
                                                      <div className="col-3">
                                                        Discount Price (£) *
                                                      </div>
                                                      <div className="col-3"></div>
                                                    </div>

                                                    <FieldArray
                                                      name={`category.${categoryIndex}.items.${itemIndex}.mealCustomization.${customizationIndex}.item`}
                                                    >
                                                      {() =>
                                                        res?.items?.map(
                                                          (
                                                            item,
                                                            customizationItemIndex
                                                          ) => (
                                                            <div
                                                              className="row align-items-center"
                                                              key={
                                                                customizationItemIndex
                                                              }
                                                            >
                                                              <div className="col-3">
                                                                <Field
                                                                  type="text"
                                                                  placeholder="Name"
                                                                  value={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      .mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ].item
                                                                  }
                                                                  className="meal_customization_field"
                                                                  disabled
                                                                />
                                                              </div>
                                                              <div className="col-3">
                                                                <Field
                                                                  type="text"
                                                                  placeholder="Actual Price"
                                                                  value={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      ?.mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ]
                                                                      .actual_price
                                                                  }
                                                                  className="meal_customization_field"
                                                                  disabled
                                                                />
                                                              </div>
                                                              <div className="col-3">
                                                                <input
                                                                  type="text"
                                                                  placeholder="Discount Price"
                                                                  value={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      ?.mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ]
                                                                      .discount_price
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeDiscount(
                                                                      customizationIndex,
                                                                      customizationItemIndex,
                                                                      e
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      ?.mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ]
                                                                      .isSelect !=
                                                                    true
                                                                  }
                                                                  className="meal_customization_field"
                                                                />
                                                              </div>
                                                              <div className="col-3">
                                                                <div
                                                                  className="d-flex align-items-center"
                                                                  style={{
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={
                                                                      item?.image
                                                                        ? item?.image
                                                                        : preview_generic_image
                                                                    }
                                                                    alt=""
                                                                    className="item_uploaded_img"
                                                                  />
                                                                  {!item.title && (
                                                                    <input
                                                                      type="checkbox"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleSelectCustomization(
                                                                          customizationIndex,
                                                                          customizationItemIndex,
                                                                          e
                                                                        )
                                                                      }
                                                                      className="input_check_box"
                                                                      checked={
                                                                        initialValue
                                                                          ?.category[
                                                                          categoryIndex
                                                                        ].items[
                                                                          itemIndex
                                                                        ]
                                                                          .mealCustomization[
                                                                          customizationIndex
                                                                        ].items[
                                                                          customizationItemIndex
                                                                        ]
                                                                          .isSelect ==
                                                                        true
                                                                      }
                                                                    />
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )
                                                      }
                                                    </FieldArray>
                                                  </div>
                                                )}
                                              </>
                                            )
                                          )}
                                        </div>
                                        {/* )} */}
                                      </div>

                                      {addCustomizationShow &&
                                        initialValue.category[
                                          categoryIndex
                                        ].items[
                                          itemIndex
                                        ].mealCustomization.map(
                                          (res, customizationIndex) => (
                                            <>
                                              {res?.isNew && (
                                                <>
                                                  <div key={customizationIndex}>
                                                    <div className="d-flex flex-column">
                                                      <label
                                                        htmlFor={`mealCustomization.${customizationIndex}.title`}
                                                      >
                                                        Title
                                                        <span className="text-danger">
                                                          {" "}
                                                          *
                                                        </span>
                                                      </label>
                                                      <Field
                                                        type="text"
                                                        placeholder="Size"
                                                        value={
                                                          initialValue.category[
                                                            categoryIndex
                                                          ].items[itemIndex]
                                                            .mealCustomization[
                                                            customizationIndex
                                                          ].title
                                                        }
                                                        onChange={(e) =>
                                                          handleTitleChange(
                                                            e,
                                                            categoryIndex,
                                                            itemIndex,
                                                            customizationIndex,
                                                            "title"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                      <p
                                                        onClick={() =>
                                                          handleAddCustomizationItem(
                                                            categoryIndex,
                                                            itemIndex,
                                                            customizationIndex
                                                          )
                                                        }
                                                        className="add_more_field_btn my-2"
                                                      >
                                                        Add details
                                                      </p>
                                                    </div>

                                                    <div className="row  align-items-center my-2">
                                                      <div className="col-3">
                                                        Name
                                                      </div>
                                                      <div className="col-3">
                                                        Actual Price (£) *
                                                      </div>
                                                      <div className="col-3">
                                                        Discount Price (£) *
                                                      </div>
                                                      <div className="col-3"></div>
                                                    </div>

                                                    <FieldArray
                                                      name={`category.${categoryIndex}.items.${itemIndex}.mealCustomization.${customizationIndex}.item`}
                                                    >
                                                      {() =>
                                                        res?.items?.map(
                                                          (
                                                            item,
                                                            customizationItemIndex
                                                          ) => (
                                                            <div
                                                              className="row align-items-center"
                                                              key={
                                                                customizationItemIndex
                                                              }
                                                            >
                                                              <div className="col-3">
                                                                <Field
                                                                  type="text"
                                                                  placeholder="Name"
                                                                  value={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      .mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ].item
                                                                  }
                                                                  className="meal_customization_field"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleCustomizationItemsChange(
                                                                      e,
                                                                      categoryIndex,
                                                                      itemIndex,
                                                                      customizationIndex,
                                                                      customizationItemIndex,
                                                                      "item"
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-3">
                                                                <Field
                                                                  type="text"
                                                                  step="0.01"
                                                                  placeholder="Actual Price"
                                                                  value={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      ?.mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ]
                                                                      .actual_price
                                                                  }
                                                                  className="meal_customization_field"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleCustomizationItemsChange(
                                                                      e,
                                                                      categoryIndex,
                                                                      itemIndex,
                                                                      customizationIndex,
                                                                      customizationItemIndex,
                                                                      "actual_price"
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-3">
                                                                <input
                                                                  type="text"
                                                                  step="0.01"
                                                                  placeholder="Discount Price"
                                                                  value={
                                                                    initialValue
                                                                      .category[
                                                                      categoryIndex
                                                                    ].items[
                                                                      itemIndex
                                                                    ]
                                                                      ?.mealCustomization[
                                                                      customizationIndex
                                                                    ].items[
                                                                      customizationItemIndex
                                                                    ]
                                                                      .discount_price
                                                                  }
                                                                  className="meal_customization_field"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleCustomizationItemsChange(
                                                                      e,
                                                                      categoryIndex,
                                                                      itemIndex,
                                                                      customizationIndex,
                                                                      customizationItemIndex,
                                                                      "discount_price"
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-12 p-0">
                                                                <div
                                                                  className="d-flex justify-content-end mt-1 align-items-center"
                                                                  style={{
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <div className="customization_image_upload_btn">
                                                                    <img
                                                                      src={
                                                                        upload_generic_image
                                                                      }
                                                                      alt=""
                                                                      style={{
                                                                        width:
                                                                          "45px",
                                                                      }}
                                                                    />
                                                                    <input
                                                                      type="file"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleCustomizationItemsChange(
                                                                          e,
                                                                          categoryIndex,
                                                                          itemIndex,
                                                                          customizationIndex,
                                                                          customizationItemIndex,
                                                                          "image"
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <img
                                                                    src={
                                                                      initialValue
                                                                        .category[
                                                                        categoryIndex
                                                                      ].items[
                                                                        itemIndex
                                                                      ]
                                                                        ?.mealCustomization[
                                                                        customizationIndex
                                                                      ].items[
                                                                        customizationItemIndex
                                                                      ].image
                                                                        ? URL.createObjectURL(
                                                                            initialValue
                                                                              .category[
                                                                              categoryIndex
                                                                            ]
                                                                              .items[
                                                                              itemIndex
                                                                            ]
                                                                              ?.mealCustomization[
                                                                              customizationIndex
                                                                            ]
                                                                              .items[
                                                                              customizationItemIndex
                                                                            ]
                                                                              .image
                                                                          )
                                                                        : preview_generic_image
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                      width:
                                                                        "55px",
                                                                      height:
                                                                        "45px",
                                                                    }}
                                                                  />
                                                                  <img
                                                                    src={
                                                                      cross_image
                                                                    }
                                                                    alt="cross image govind "
                                                                    style={{
                                                                      width:
                                                                        "45px",
                                                                    }}
                                                                    onClick={() => {
                                                                      deleteCustomizationItem(
                                                                        categoryIndex,
                                                                        itemIndex,
                                                                        customizationIndex,
                                                                        customizationItemIndex
                                                                      );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )
                                                      }
                                                    </FieldArray>
                                                  </div>
                                                  <div className="d-flex justify-content-end">
                                                    <p
                                                      onClick={() =>
                                                        removeMealCustomization(
                                                          categoryIndex,
                                                          itemIndex,
                                                          customizationIndex
                                                        )
                                                      }
                                                      className="category_cancel m-0 mt-1"
                                                    >
                                                      Remove
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          )
                                        )}

                                      <div className="d-flex justify-content-between  my-2 ">
                                        {initialValue.category[categoryIndex]
                                          .items[itemIndex].mealCustomization
                                          .length > 0 && (
                                          <p
                                            className="category_save  my-2 "
                                            onClick={() =>
                                              setLoadingCustomization(false)
                                            }
                                          >
                                            Save
                                          </p>
                                        )}
                                        <p
                                          className="category_save  my-2 "
                                          onClick={() =>
                                            handleAddCustomization(
                                              categoryIndex,
                                              itemIndex
                                            )
                                          }
                                        >
                                          Add More Customization
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </section>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddMeal;
