import { createSlice } from "@reduxjs/toolkit";
import * as API from "./API";
const apiArray = [
  API.isLoginAPI,
  API.Restaurantcreate,
  API.restaurantList,
  API.RestaurantEdit,
  API.RestaurantDELETE,
  API.branchList,
  API.branchAdd,
  API.branchView,
  API.branchEdit,
  API.branchDelete,
  API.ForgetpasswordRestaurant,
  API.branchrole,
  API.RoleAdd,
  API.RoleDelete,
  API.RoleAtive,
  API.designationAdd,
  API.designationList,
  API.designationView,
  API.designationDelete,
  API.designationUpdate,
  API.Teamadd,
  API.TeamMembersList,
  API.TeamView,
  API.Teamedit,
  API.Forgetpasswordteam,
  API.ProductList,
  API.Addproduct,
  API.ProductView,
  API.Editproduct,
  API.ProductDelete,
  API.Hardwarecreact,
  API.HardwareView,
  API.HardwareDelete,
  API.Hardwarepassword,
  API.setingView,
  API.setingEdit,
  API.Hardwarestatus,
  API.categoryList,
  API.categoriesAdd,
  API.categoryDelete,
  API.categoryUpdate,
  API.categoryView,
  API.customizationAdd,
  API.customizationList,
  API.customizationDelete,
  API.customizationStatusUpdate,
  API.bundleList,
  API.bundleUpdate,
  API.bundleDelete,
  API.bundleAdd,
  API.bundleView,
  API.teambased,
  API.Profilelist,
  API.Forgetpassword,
  API.Resetpass,
  API.categorieslist,
  API.dashboard,
  API.ViewItem,
  API.Proseting,
  API.Removeimg,
  API.AllproductSating,
  API.mycart,
  API.mycartremove,
  API.allOrder,
  API.myorderlist,
  API.myallorderRemove,
  API.Finalorder,
  API.Finalorderlist,
  API.OrderStatus,
  API.tippingsave,
  API.tippingDetalis,
];

const userslice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    data: [],
    error: false,
    permissions: null,
    requestMycart: [],
  },

  reducers: {
    setpermissionsType: (state, action) => {
      state.permissions = action.payload;
    },
    setRequestMycart: (state, action) => {
      state.requestMycart.push(action.payload);
    },
    removeRequestMycart(state, action) {
      state.requestMycart.splice(action.payload, 1); // Remove item by index
    },
  },

  extraReducers: (builder) => {
    apiArray.forEach((api) => {
      builder
        .addCase(api.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(api.fulfilled, (state, action) => {
          state.isLoading = false;
          state.data = action.payload;
        })
        .addCase(api.rejected, (state, action) => {
          state.isLoading = false;
          state.error = true;
        });
    });
  },
});
export const { setpermissionsType, setRequestMycart, removeRequestMycart } =
  userslice.actions;

export default userslice.reducer;
