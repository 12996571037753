import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  branchrole,
  categoryView,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";
import generic_img from "../../../../../img/AddModal/Group 959.png";

const ViewCategory = ({ setIShowSecondPart, categoryId }) => {
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [branches, setBranches] = useState([]);
  const [initialValue, setInitialValue] = useState({
    restaurant_name: "",
    branch_name: [],
    category: "",
    description: "",
    sub_title: "",
    sub_title2: "",
  });

  const [smallPreviewImg, setSmallPreviewImg] = useState("");
  const [bigPreviewImg, setBigPreviewImg] = useState("");

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
          setBranches([]);
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const getCategoryDetails = async () => {
    setIsLoading(false);
    try {
      const categoryRes = await dispatch(categoryView(categoryId));
      if (categoryRes?.payload?.status === true) {
        const restaurantId = categoryRes?.payload?.payload?.restaurantId;
        const branchRes = await dispatch(branchrole({ restaurantId }));
        if (branchRes?.payload?.status === true) {
          const branchesData = branchRes?.payload?.payload;
          const branchIds = categoryRes?.payload?.payload?.branchIds || [];
          const branchOptions = branchIds
            .map((id) => {
              const option = branchesData.find((branch) => branch._id === id);
              return option ? { value: option._id, label: option.name } : null;
            })
            .filter((option) => option !== null);

          setBranches(branchesData);
          setInitialValue({
            restaurant_name: restaurantId,
            branch_name: branchOptions,
            category: categoryRes?.payload?.payload?.title,
            description: categoryRes?.payload?.payload?.description,
            sub_title: categoryRes?.payload?.payload?.subHeading1,
            sub_title2: categoryRes?.payload?.payload?.subHeading2,
          });
          setSmallPreviewImg(categoryRes?.payload?.payload?.image2);

          setBigPreviewImg(categoryRes?.payload?.payload?.image1);

          setIsLoading(true);
        }
      } else {
        setIsLoading(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };
  useEffect(() => {
    getCategoryDetails();
  }, []);

  return (
    <section className="add_category ">
      <div className="add_category_inner">
        <h6 className="heading_name">View Category</h6>
        <hr />
        <div className="form_container">
          {isLoading && (
            <Formik initialValues={initialValue}>
              {(formik) => (
                <Form>
                  <div
                    className="field_container d-flex flex-column gap-5"
                    style={{ gap: "8px" }}
                  >
                    <div className=" d-flex flex-column">
                      <label htmlFor="Restaurant">
                        Restaurant <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        placeholder="Enter Restaurant Name "
                        id="Restaurant"
                        name="restaurant_name"
                        disabled
                        onChange={(e) => {
                          formik.setFieldValue(
                            "restaurant_name",
                            e.target.value
                          );
                          getBraches(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {restaurant.map((res, i) => (
                          <option value={res?._id} key={i}>
                            {res?.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="p"
                        name="restaurant_name"
                        className="text-danger"
                      />
                    </div>
                    <div className=" d-flex flex-column">
                      <label htmlFor="branch_name">Branch</label>
                      <MultiSelect
                        options={allbrachList}
                        name="branch_name"
                        labelledBy="Select"
                        value={formik.values.branch_name}
                        onChange={(e) => formik.setFieldValue("branch_name", e)}
                        disabled
                      />
                    </div>
                    <div className=" d-flex flex-column">
                      <label htmlFor="category">
                        Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter category Name "
                        id="category"
                        name="category"
                        disabled
                      />
                    </div>
                    {/* <div className=" d-flex flex-column">
                      <label htmlFor="sub_title">
                        Sub-Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Enter Sub-Title Name "
                        id="sub_title"
                        name="sub_title"
                        disabled
                      />
                  
                    </div>
                    <div className=" d-flex flex-column">
                    <label htmlFor="sub_title2">
                      Sub-Heading2 <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter sub-title Name "
                      id="sub_title2"
                      name="sub_title2"
                      disabled
                    />
                   
                  </div> */}
                    <div className=" d-flex flex-column">
                      <label htmlFor="description">
                        Description <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="textarea"
                        placeholder="Enter description  "
                        id="description"
                        name="description"
                        disabled
                      />
                      <ErrorMessage
                        component="p"
                        name="description"
                        className="text-danger"
                      />
                    </div>
                    <div className="row">
                      <div
                        className=" col-md-6 d-flex flex-column"
                        style={{ gap: "10px" }}
                      >
                        <label htmlFor="Restaurant" className="m-0">
                          Upload Big Image{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="category_img_cont">
                          <img
                            src={bigPreviewImg ? bigPreviewImg : generic_img}
                            alt=""
                            className="category_images"
                          />
                        </div>
                        {/* <div className="category_img_browse_container big_image">
                          <button>Browse</button>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => handleChangeBigImage(e)}
                          />
                        </div> */}
                      </div>
                      <div
                        className=" col-md-6 d-flex flex-column "
                        style={{ gap: "10px" }}
                      >
                        <label htmlFor="Restaurant" className="m-0">
                          Upload Small Image{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="category_img_cont">
                          <img
                            src={
                              smallPreviewImg ? smallPreviewImg : generic_img
                            }
                            alt=""
                            className="category_images"
                          />
                        </div>
                        {/* <div className="category_img_browse_container small_image">
                          <button>Browse</button>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) => handleChangeSmallImage(e)}
                          />
                        </div> */}
                        {/* {
                        showCropper && 
        <Croper image={smallPreviewImg} setSmallPreviewImg={setSmallPreviewImg} cropperOpen={showCropper}/>

                      } */}
                      </div>
                    </div>
                    <div
                      className="submit_btn_container mt-4 d-flex gap-3 justify-content-center align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <div
                        className="category_cancel"
                        onClick={() => setIShowSecondPart("add")}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </section>
  );
};

export default ViewCategory;
