import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/team.svg";
import preview_generic_image from "../../../../../img/AddModal/Group 839.png";
import cross_image from "../../../../../img/AddModal/Group 82624.png";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  branchrole,
  categoryList,
  itemUpdate,
  itemView,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";

const EditItems = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);
  const [itemImagePreview, setItemImagePreview] = useState("");
  const [itemImageUpload, setItemImageUpload] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const itemId = location.pathname.split("/")[4];
  const [categories, setCategories] = useState([]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setItemImageUpload(file);
      setItemImagePreview(URL.createObjectURL(file));
    }
  };

  const [initialValue, setInitialValue] = useState({
    restaurant_name: "",
    branch_name: "",
    category: "",
    item: "",
    description: "",
    label_1: "",
    label_2: "",
    sell_price: "",
    original_price: "",
    dietary_info: "",
    ingredients: "",
    contain_alcohol: "",
    // ia_number:'',
    eligible_replacement: "",
    eligible_substitution: "",
    // operational_name: "",
    // price_lookup_code: "",
    // tax_rate: "",
    // type: "",
    // allergies: "",
    // external_data: "",
    // highlights: "",
    nutrition: [
      {
        name: "",
        value: "",
      },
    ],
  });

  const handleSubmit = (values, { resetForm }) => {
    const branches = values.branch_name.map((item) => item.value);
    const formData = new FormData();
    formData.append("restaurantId", values.restaurant_name);
    formData.append("categoryId", values.category);
    formData.append("name", values.item);
    formData.append("description", values.description);
    formData.append("dietaryInfo", values.dietary_info);
    formData.append("ingredients", values.ingredients);
    formData.append("label1", values.label_1);
    formData.append("label2", values.label_2);
    formData.append("nutrition", JSON.stringify(values?.nutrition));
    formData.append("image", itemImageUpload);
    formData.append("sellPrice", values.sell_price);
    formData.append("originalPrice", values.original_price);
    values?.branch_name?.map((res) => {
      formData.append("branchIds", res?.value);
    });

    formData.append("contain_alcohol", values.contain_alcohol);

    // formData.append("ia_number", values.ia_number);
    formData.append("eligible_replacement", values.eligible_replacement);
    formData.append("eligible_substitution", values.eligible_substitution);
    // formData.append("operational_name", values.operational_name);
    // formData.append("price_lookup_code", values.price_lookup_code);
    // formData.append("tax_rate", values.tax_rate);
    // formData.append("type", values.type);
    // formData.append("allergies", values.allergies);
    // formData.append("external_data", values.external_data);
    // formData.append("highlights", values.highlights);
    //  formData.append('branchIds' ,JSON.stringify(branches))

    const requestOptions = {
      id: itemId,
      head: {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      },
    };
    try {
      dispatch(itemUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          navigation(-1);

          setItemImagePreview("");
          setItemImageUpload("");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchemas = Yup.object().shape({
    restaurant_name: Yup.string().required("Restaurant Name is Required"),
    // branch_name: Yup.array().of(
    //     Yup.object().shape({
    //       label: Yup.string().required(),
    //       value: Yup.string().required(),
    //     })
    //   ).required('branch_name is required'),
    category: Yup.string().required("Category is Required"),
    item: Yup.string().required("Item is Required"),
    description: Yup.string().required("Description is Required"),
    // label_1: Yup.string().required('Label 1 is required'),
    // label_2: Yup.string().required('Label 2 is required'),
    // sell_price: Yup.number()
    //   .typeError('Sell price must be a number')
    //   .positive('Sell price must be a positive number')
    // //   .integer('Sell price must be an integer')
    //   .required('Sell price is required'),
    original_price: Yup.number()
      .typeError("Original Price Must be a Number")
      .positive("Original Price Must be a Positive Number")
      // .integer('Original price must be an integer')
      .required("Original price is required")
      .test(
        "is-decimal",
        "Must be a decimal with up to 2 digits after the decimal point",
        (value) => (value + "").match(/^\d+(\.\d{1,2})?$/)
      ),

    sell_price: Yup.number()
      .nullable()
      .typeError("Must be a Number")
      .test(
        "is-decimal",
        "Must be a number with up to 2 digits after the decimal point",
        (value) =>
          value === null ||
          value === undefined ||
          Number.isInteger(value) ||
          /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    // dietary_info: Yup.string().required('Dietary info is required'),
    // ingredients: Yup.string().required('Ingredients are required'),
    nutrition: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Nutrition Name is Required"),
        value: Yup.string().required("Nutrition Value is Required"),
        // .test(
        //   "is-decimal",
        //   "Must be a number with up to 2 digits after the decimal point",
        //   (value) =>
        //     value === null ||
        //     value === undefined ||
        //     Number.isInteger(value) ||
        //     /^\d+(\.\d{1,2})?$/.test(value.toString())
        // ),
      })
    ),
    contain_alcohol: Yup.string().required("Please Select One Option"),
    // ia_number:Yup.string().required("International Article No.  is required"),
    eligible_replacement: Yup.string().required("Please Select One Option"),
    eligible_substitution: Yup.string().required("Please Select One Option"),
    // operational_name: Yup.string().required("Operational name  is required"),
    // price_lookup_code: Yup.string().required("Price lookup is required"),
    // tax_rate: Yup.string().required("Tax rate is required"),
    // type: Yup.string().required("item type is required"),
  });

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
            getCategoryList(localStorage.getItem("id"));
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  const getCategoryList = (id) => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
      restaurant: id,
      branch: "",
      status: "",
    };
    dispatch(categoryList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setCategories(res?.payload?.payload);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();
    // getCategoryList();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const getItemDetails = async () => {
    setIsLoading(false);
    try {
      const categoryRes = await dispatch(itemView(itemId));
      if (categoryRes?.payload?.status === true) {
        const restaurantId = categoryRes?.payload?.payload[0]?.restaurantId;
        const branchRes = await dispatch(branchrole({ restaurantId }));
        if (branchRes?.payload?.status === true) {
          const branchesData = branchRes?.payload?.payload;
          const branchIds = categoryRes?.payload?.payload[0]?.branchIds || [];
          const branchOptions = branchIds
            .map((id) => {
              const option = branchesData.find((branch) => branch._id === id);
              return option ? { value: option._id, label: option.name } : null;
            })
            .filter((option) => option !== null);

          setBranches(branchesData);
          setInitialValue({
            restaurant_name: categoryRes?.payload?.payload[0]?.restaurantId,
            branch_name: branchOptions,
            category: categoryRes?.payload?.payload[0]?.categoryId._id,
            item: categoryRes?.payload?.payload[0]?.name,
            description: categoryRes?.payload?.payload[0]?.description,
            label_1: categoryRes?.payload?.payload[0]?.label1,
            label_2: categoryRes?.payload?.payload[0]?.label2,
            sell_price: categoryRes?.payload?.payload[0]?.sellPrice,
            original_price: categoryRes?.payload?.payload[0]?.originalPrice,
            dietary_info: categoryRes?.payload?.payload[0]?.dietaryInfo,
            ingredients: categoryRes?.payload?.payload[0]?.ingredients,
            nutrition: categoryRes?.payload?.payload[0]?.nutrition,
            contain_alcohol: categoryRes?.payload?.payload[0]?.contain_alcohol,
            ia_number: categoryRes?.payload?.payload[0]?.ia_number,
            eligible_replacement:
              categoryRes?.payload?.payload[0]?.eligible_replacement,
            eligible_substitution:
              categoryRes?.payload?.payload[0]?.eligible_substitution,
            operational_name:
              categoryRes?.payload?.payload[0]?.operational_name,
            // price_lookup_code:
            //   categoryRes?.payload?.payload[0]?.price_lookup_code,
            // tax_rate: categoryRes?.payload?.payload[0]?.tax_rate,
            // type: categoryRes?.payload?.payload[0]?.type,
            // allergies: categoryRes?.payload?.payload[0]?.allergies,
            // external_data: categoryRes?.payload?.payload[0]?.external_data,
            // highlights: categoryRes?.payload?.payload[0]?.highlights,
          });

          getCategoryList(restaurantId);
          setItemImagePreview(categoryRes?.payload?.payload[0]?.image);
          setItemImageUpload(categoryRes?.payload?.payload[0]?.image);

          setIsLoading(true);
        }
      } else {
        setIsLoading(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };
  useEffect(() => {
    getItemDetails();
  }, []);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Edit Item" img_icon={Firedark} />
          <div className="container-fluid">
            <div className="card shadow-sm card-custom_box mb-4 adddesignation_card">
              <div className="card-body ">
                <div className=" designation_form_container">
                  <h6 class="text-navy-blue mb-3">Edit Item</h6>
                  {isloading && (
                    <Formik
                      initialValues={initialValue}
                      validationSchema={validationSchemas}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div
                                className="d-flex flex-column  "
                                style={{ gap: "10px" }}
                              >
                                <div className="row addrolemange">
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="Restaurant">
                                      Restaurant Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      as="select"
                                      Placeholder="Enter Restaurant Name "
                                      id="Restaurant"
                                      name="restaurant_name"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "restaurant_name",
                                          e.target.value
                                        );
                                        getBraches(e.target.value);
                                        getCategoryList(e.target.value);
                                      }}
                                      disabled={
                                        localStorage.getItem("roletype") == 1 ||
                                        localStorage.getItem("roletype") == 2
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="">Select</option>
                                      {restaurant.map((res, i) => (
                                        <option value={res?._id} key={i}>
                                          {res?.name}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      component="p"
                                      name="restaurant_name"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="Branch">Branch Name</label>
                                    <MultiSelect
                                      options={allbrachList}
                                      name="branch_name"
                                      labelledBy="Select"
                                      value={formik.values.branch_name}
                                      onChange={(e) =>
                                        formik.setFieldValue("branch_name", e)
                                      }
                                    />
                                    {/* <ErrorMessage
                                    component="p"
                                    name="branch_name"
                                    className="text-danger"
                                  /> */}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="category">
                                      Category{" "}
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Field
                                      as="select"
                                      Placeholder="Enter category Name "
                                      id="category"
                                      name="category"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "category",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {categories.map((res, i) => (
                                        <option value={res?._id} key={i}>
                                          {res?.title}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      component="p"
                                      name="category"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="item">
                                      Item Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter item "
                                      id="item"
                                      name="item"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="item"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12 d-flex flex-column">
                                    <label htmlFor="description">
                                      Description{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      as="textarea"
                                      Placeholder="Enter description "
                                      id="description"
                                      name="description"
                                      rows={4}
                                      cols={4}
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="description"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                                <div className=" ">
                                  <label htmlFor="item_image">
                                    Upload Image
                                  </label>
                                  <div className="item_image_container">
                                    <div className="item_image_upload_btn">
                                      <button>Upload Image</button>
                                      <input
                                        type="file"
                                        name=""
                                        id=""
                                        onChange={handleImageChange}
                                      />
                                    </div>
                                    <div className="uploaded_img_container">
                                      <img
                                        src={
                                          itemImagePreview
                                            ? itemImagePreview
                                            : preview_generic_image
                                        }
                                        alt=""
                                        className="item_uploaded_img"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="label_1">
                                      Is contains alcohol?{" "}
                                      <span className="text-danger">*</span>{" "}
                                    </label>
                                    <div
                                      className="radio_label_cont d-flex align-items-center "
                                      style={{ gap: "10px" }}
                                    >
                                      <Field
                                        type="radio"
                                        value="true"
                                        id="alcohol_yes"
                                        name="contain_alcohol"
                                      />
                                      <label
                                        htmlFor="alcohol_yes"
                                        className="m-0"
                                      >
                                        {" "}
                                        Yes{" "}
                                      </label>
                                    </div>

                                    <div
                                      className="radio_label_cont d-flex align-items-center "
                                      style={{ gap: "10px" }}
                                    >
                                      <Field
                                        type="radio"
                                        id="alcohol_no"
                                        name="contain_alcohol"
                                        value="false"
                                      />
                                      <label
                                        htmlFor="alcohol_no"
                                        className="m-0"
                                      >
                                        {" "}
                                        No{" "}
                                      </label>
                                    </div>
                                    <ErrorMessage
                                      component="p"
                                      name="contain_alcohol"
                                      className="text-danger"
                                    />
                                  </div>

                                  {/* <div className="col-md-6 d-flex flex-column">
                                  <label htmlFor="ian">
                                    International Article Number <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    Placeholder="Enter International Article Number "
                                    id="ian"
                                    name="ia_number"
                                  />

                                  <ErrorMessage
                                    component="p"
                                    name="ia_number"
                                    className="text-danger"
                                  />
                                </div> */}
                                </div>

                                <div className="row">
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="label_1">
                                      Is eligible as replacement?{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div
                                      className="radio_label_cont d-flex align-items-center "
                                      style={{ gap: "10px" }}
                                    >
                                      <Field
                                        type="radio"
                                        id="replacement_yes"
                                        name="eligible_replacement"
                                        value="true"
                                      />
                                      <label
                                        htmlFor="replacement_yes"
                                        className="m-0"
                                      >
                                        {" "}
                                        Yes{" "}
                                      </label>
                                    </div>

                                    <div
                                      className="radio_label_cont d-flex align-items-center "
                                      style={{ gap: "10px" }}
                                    >
                                      <Field
                                        type="radio"
                                        id="replacement_no"
                                        name="eligible_replacement"
                                        value="false"
                                      />
                                      <label
                                        htmlFor="replacement_no"
                                        className="m-0"
                                      >
                                        {" "}
                                        No{" "}
                                      </label>
                                    </div>
                                    <ErrorMessage
                                      component="p"
                                      name="eligible_replacement"
                                      className="text-danger"
                                    />
                                  </div>

                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="label_1">
                                      Is eligible as substitution?{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div
                                      className="radio_label_cont d-flex align-items-center "
                                      style={{ gap: "10px" }}
                                    >
                                      <Field
                                        type="radio"
                                        id="substitution_yes"
                                        name="eligible_substitution"
                                        value="true"
                                      />
                                      <label
                                        htmlFor="substitution_yes"
                                        className="m-0"
                                      >
                                        {" "}
                                        Yes{" "}
                                      </label>
                                    </div>

                                    <div
                                      className="radio_label_cont d-flex align-items-center "
                                      style={{ gap: "10px" }}
                                    >
                                      <Field
                                        type="radio"
                                        id="substitution_no"
                                        name="eligible_substitution"
                                        value="false"
                                      />
                                      <label
                                        htmlFor="substitution_no"
                                        className="m-0"
                                      >
                                        {" "}
                                        No{" "}
                                      </label>
                                    </div>
                                    <ErrorMessage
                                      component="p"
                                      name="eligible_substitution"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="label_1">Label 1 </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter label  "
                                      id="label_1"
                                      name="label_1"
                                      maxLength="15"
                                    />

                                    {/* <ErrorMessage
                                    component="p"
                                    name="label_1"
                                    className="text-danger"
                                  /> */}
                                  </div>
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="label_2">Label 2 </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter label "
                                      id="label_2"
                                      name="label_2"
                                      maxLength="10"
                                    />

                                    {/* <ErrorMessage
                                    component="p"
                                    name="label_2"
                                    className="text-danger"
                                  /> */}
                                  </div>
                                </div>

                                <div className="row">
                                  {/* <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="operational_name">
                                      operational Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter operational name "
                                      id="operational_name"
                                      name="operational_name"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="operational_name"
                                      className="text-danger"
                                    />
                                  </div> */}
                                  {/* <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="price_lookup_code">
                                      Price lookup Code{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter  Price lookup Code "
                                      id="price_lookup_code"
                                      name="price_lookup_code"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="price_lookup_code"
                                      className="text-danger"
                                    />
                                  </div> */}

                                  {/* <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="tax_rate">
                                      Tax Rate{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter tax rate  "
                                      id="tax_rate"
                                      name="tax_rate"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="tax_rate"
                                      className="text-danger"
                                    />
                                  </div> */}
                                </div>

                                <div className="row">
                                  {/* <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="tax_rate">
                                      Tax Rate{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter tax rate  "
                                      id="tax_rate"
                                      name="tax_rate"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="tax_rate"
                                      className="text-danger"
                                    />
                                  </div> */}
                                  {/* <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="type">
                                      Type{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter item type"
                                      id="type"
                                      name="type"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="type"
                                      className="text-danger"
                                    />
                                  </div> */}
                                </div>
                                <p className="my-3">Base Price</p>
                                <div className="row">
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="sell_price">
                                      Sell Price{" "}
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter sell price "
                                      id="sell_price"
                                      step="0.01"
                                      name="sell_price"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="sell_price"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-6 d-flex flex-column">
                                    <label htmlFor="original_price">
                                      Original Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      Placeholder="Enter original price "
                                      id="original_price"
                                      step="0.01"
                                      name="original_price"
                                    />

                                    <ErrorMessage
                                      component="p"
                                      name="original_price"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="d-flex flex-column  "
                                style={{ gap: "10px" }}
                              >
                                <div className="row">
                                  <div className="col-md-12 d-flex flex-column">
                                    <label htmlFor="dietary_info">
                                      Dietary Info{" "}
                                    </label>
                                    <Field
                                      as="textarea"
                                      Placeholder="Enter dietary info "
                                      id="dietary_info"
                                      name="dietary_info"
                                      rows={5}
                                      cols={5}
                                    />
                                    {/* 
                                  <ErrorMessage
                                    component="p"
                                    name="dietary_info"
                                    className="text-danger"
                                  /> */}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12 d-flex flex-column">
                                    <label htmlFor="ingredients">
                                      Ingredients{" "}
                                    </label>
                                    <Field
                                      as="textarea"
                                      Placeholder="Enter ingredients "
                                      id="ingredients"
                                      name="ingredients"
                                      rows={5}
                                      cols={5}
                                    />

                                    {/* <ErrorMessage
                                    component="p"
                                    name="ingredients"
                                    className="text-danger"
                                  /> */}
                                  </div>
                                </div>
                                {/* 
                                <div className="row">
                                  <div className="col-md-12 d-flex flex-column">
                                    <label htmlFor="allergies">
                                      Allergies{" "}
                                    </label>
                                    <Field
                                      as="textarea"
                                      Placeholder="Enter allergies  "
                                      id="allergies"
                                      name="allergies"
                                      rows={5}
                                      cols={5}
                                    />

                                    <ErrorMessage
                                    component="p"
                                    name="dietary_info"
                                    className="text-danger"
                                  />
                                  </div>
                                </div> */}

                                {/* <div className="row">
                                  <div className="col-md-12 d-flex flex-column">
                                    <label htmlFor="external_data">
                                      External data{" "}
                                    </label>
                                    <Field
                                      as="textarea"
                                      Placeholder="Enter external data  "
                                      id="external_data"
                                      name="external_data"
                                      rows={5}
                                      cols={5}
                                    />

                                    <ErrorMessage
                                    component="p"
                                    name="dietary_info"
                                    className="text-danger"
                                  />
                                  </div>
                                </div> */}

                                {/* <div className="row">
                                  <div className="col-md-12 d-flex flex-column">
                                    <label htmlFor="highlights">
                                      Highlights{" "}
                                    </label>
                                    <Field
                                      as="textarea"
                                      Placeholder="Enter highlights  "
                                      id="highlights"
                                      name="highlights"
                                      rows={5}
                                      cols={5}
                                    />

                                    <ErrorMessage
                                    component="p"
                                    name="dietary_info"
                                    className="text-danger"
                                  />
                                  </div>
                                </div> */}

                                <FieldArray name="nutrition">
                                  {({ push: itemPush, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-between align-items-center my-2 ">
                                        <p>Nutrition</p>
                                        <p
                                          className="add_more_field_btn"
                                          onClick={() =>
                                            itemPush({
                                              name: "",
                                              value: "",
                                            })
                                          }
                                        >
                                          Add More
                                        </p>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-5">
                                          <label htmlFor="">Name</label>
                                        </div>
                                        <div className="col-md-6">
                                          <label htmlFor="">Value</label>
                                        </div>
                                      </div>

                                      {formik.values.nutrition.map(
                                        (nutrition, index) => (
                                          <>
                                            <div
                                              className="row align-items-center"
                                              key={index}
                                            >
                                              <div className="col-md-5 d-flex flex-column">
                                                <Field
                                                  type="text"
                                                  Placeholder="Enter Name "
                                                  id="designation"
                                                  name={`nutrition.${index}.name`}
                                                />

                                                <ErrorMessage
                                                  component="p"
                                                  name={`nutrition.${index}.name`}
                                                  className="text-danger"
                                                />
                                              </div>
                                              <div className="col-md-5 d-flex flex-column">
                                                <Field
                                                  type="text"
                                                  Placeholder="Enter Value "
                                                  id="designation"
                                                  step="0.01"
                                                  name={`nutrition.${index}.value`}
                                                />

                                                <ErrorMessage
                                                  component="p"
                                                  name={`nutrition.${index}.value`}
                                                  className="text-danger"
                                                />
                                              </div>
                                              {formik.values.nutrition.length >
                                                1 && (
                                                <div className=" col-2 remove_more_field">
                                                  <img
                                                    src={cross_image}
                                                    alt=""
                                                    onClick={() => {
                                                      const updateNutrition =
                                                        formik.values.nutrition.filter(
                                                          (_, i) => i !== index
                                                        );
                                                      formik.setFieldValue(
                                                        "nutrition",
                                                        updateNutrition
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          </div>

                          <div className="items_save_btn_container">
                            {" "}
                            <div
                              class="modal_cancel_btn"
                              onClick={() => navigation(-1)}
                            >
                              Cancel
                            </div>
                            <button class="modal_cancel_btn" type="submit">
                              Save
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditItems;
