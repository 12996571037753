import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import getCroppedImg from './Cropimage';
import getCroppedImgUrl from '../../../../../Helper/imageCroper/cropimageURL';

const SmallImageUploadComponent = ({imageSrc , openModal , setCroppedAreaPixels, setShowSmallImageCropper,setSmallCropImg}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        height:500,
        bgcolor: 'background.paper',
        boxShadow: 24,
    
      };
    
    
    
      const savebtnStyle = {
        position: 'absolute',
        bottom: '1%',
        right: '5%',
        backgroundColor: '#F27A21',
        color: 'white',
        padding: '10px 25px',
        border: 'none', // Make sure border is set to none if it appears
        borderRadius:'5px'
      };
      const [open, setOpen] = useState(openModal);
  
      const handleClose = () => {  showCroppedImage(); setOpen(false) ; setShowSmallImageCropper(false)}
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);


const [newCroppedAreaPixels , setNewCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setNewCroppedAreaPixels(croppedAreaPixels)

  }, []);




  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImgUrl(
        imageSrc,
        newCroppedAreaPixels,
        
      )
      console.log('donee', { croppedImage })

      setSmallCropImg(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }


  return (
    <div>
    
    
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="cropper-container">
   
        <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={319/321}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
      <button style={savebtnStyle} onClick={() => handleClose()}  >Save</button>
    </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SmallImageUploadComponent;
