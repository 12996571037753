import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../Add/Topcart/topcart";
import Firedark from "../../../../../img/od.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { categorieslist } from "../../../../../Helper/Redux/API";
import { useDispatch } from "react-redux";
import no from "../../../../../../src/img/no.jpg";

const BoatHouse = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const { takeAwaydata } = location.state || {};
  const [update, setUpdate] = useState(location.state?.takeAwaydata || {});
  const [imglist, setImgList] = useState("");
  const [isChecked, setIsChecked] = useState(update == true ? true : false);

  const [isLoading, setIsLoading] = useState(true);
  const backbtn = () => {
    navigate("/ordermanagement/orderadd", { state: { update } });
  };

  console.log("value", update, "update", isChecked);

  const getimgdata = () => {
    dispatch(categorieslist())
      .then((res) => {
        if (res?.payload?.status === true) {
          setImgList(res?.payload?.payload);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getimgdata();
  }, []);

  const ViewCategories = (id) => {
    console.log("id", id);
    navigate("/ordermanagement/category", { state: { id, isChecked } });
  };
  const [showFirstImage, setShowFirstImage] = useState(true);
  return (
    <div>
      {" "}
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="orderboat Restaurants Dashbored allsection">
          <Topheader heding_text="Order Management" img_icon={Firedark} />
          <div className="container-fluid order_category">
            <div className="mb-3">
              <a onClick={backbtn} className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </a>
            </div>
            <div
              className="custom-control pl-0 custom-switch text-end mt-4 mb-4"
              // onClick={(e) => setIsChecked(e.target.checked)}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                checked={isChecked == true}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label
                className={`custom-control-label ${
                  isChecked === true ? "eat-in" : "take-away"
                }`}
                for="customSwitch1"
              ></label>
              <span
                className={
                  isChecked == true
                    ? "custom_input_label_left"
                    : " custom_input_label_right"
                }
              >
                {" "}
                {isChecked == true ? "Take A" : "Eat In"}
              </span>
            </div>

            <div className="odersection">
              <h5>The Boat House Favorites</h5>
              <div className="img_group">
                {}

                {/* {roletype == 0 ? ( */}
                <>
                  {" "}
                  {isLoading ? (
                    // Render a loader while data is being fetched
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <h3>Empty List</h3>
                      </td>
                    </tr>
                  ) : (
                    Array.isArray(imglist) &&
                    imglist.map((item, index) => (
                      <>
                        <div
                          className="box_img d-flex"
                          key={item._id}
                          onClick={(e) => ViewCategories(item?._id)}
                        >
                          {/* <img
                            src={item?.image1 ? item?.image1 : no}
                            alt={item?.title}
                            className="img-fluid big_img"
                          />
                          <img
                            src={item?.image2 ? item?.image2 : no}
                            alt={item?.title}
                            className="img-fluid smail_img"
                          /> */}

                          {index % 2 === 0 ? (
                            <img
                              src={item.image1 ? item.image1 : no}
                              alt={item.title}
                              className="img-fluid big_img"
                            />
                          ) : (
                            <img
                              src={item.image2 ? item.image2 : no}
                              alt={item.title}
                              className="img-fluid smail_img"
                            />
                          )}
                          <p>{item?.title}</p>
                        </div>
                      </>
                    ))
                  )}
                </>
                {/* ) : (
                  "No View Permission"
                )} */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BoatHouse;
