import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import "./datatable.css";
import Routepage from "./Routes/Routepage";

function App() {
  return (
    <div className="App">
      <Routepage />
    </div>
  );
}

export default App;
