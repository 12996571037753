import React , {useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import scanner_img from '../../../../../../img/payment module/path3093.png'
const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: "0px 0px 5.18px 0px #00000040",
  p: 4,
  borderRadius: 5,
  overflow: "auto",
  outline:'none',
  border:"none"
};

export default function QRModal() {
  const [open, setOpen] = useState(false);
 

 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 
 

 
  return (
    <div>
      <div className="payment_card" onClick={() => handleOpen()} > 
<svg width="35" height="40" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.0654 10.5461H8.85631C9.67637 10.5461 10.3412 9.88126 10.3412 9.0612C10.3412 8.24114 9.67637 7.57635 8.85631 7.57635H2.17449C1.02641 7.57635 0.0957031 8.50706 0.0957031 9.65514V16.4855C0.0957031 17.3055 0.760493 17.9703 1.58055 17.9703C2.40061 17.9703 3.0654 17.3055 3.0654 16.4855V10.5461Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.0654 31.1855H8.85631C9.67637 31.1855 10.3412 31.8502 10.3412 32.6703C10.3412 33.4904 9.67637 34.1552 8.85631 34.1552H2.17449C1.02641 34.1552 0.0957031 33.2244 0.0957031 32.0764V25.2461C0.0957031 24.426 0.760493 23.7612 1.58055 23.7612C2.40061 23.7612 3.0654 24.426 3.0654 25.2461V31.1855Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.4463 10.5461H18.6554C17.8353 10.5461 17.1706 9.88126 17.1706 9.0612C17.1706 8.24114 17.8353 7.57635 18.6554 7.57635H25.3372C26.4853 7.57635 27.416 8.50706 27.416 9.65514V16.4855C27.416 17.3055 26.7512 17.9703 25.9312 17.9703C25.1111 17.9703 24.4463 17.3055 24.4463 16.4855V10.5461Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.4463 31.1855H18.6554C17.8353 31.1855 17.1706 31.8502 17.1706 32.6703C17.1706 33.4904 17.8353 34.1552 18.6554 34.1552H25.3372C26.4853 34.1552 27.416 33.2244 27.416 32.0764V25.2461C27.416 24.426 26.7512 23.7612 25.9312 23.7612C25.1111 23.7612 24.4463 24.426 24.4463 25.2461V31.1855Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.8281 3.12172C11.8281 1.4816 13.1577 0.152023 14.7978 0.152023H37.0706C38.7107 0.152023 40.0403 1.4816 40.0403 3.12172V40.243C40.0403 41.8831 38.7107 43.2127 37.0706 43.2127H14.7978C13.1577 43.2127 11.8281 41.8831 11.8281 40.243V34.0066H14.6495V35.4914C14.6495 36.3114 15.3143 36.9762 16.1344 36.9762H35.7344C36.5544 36.9762 37.2192 36.3114 37.2192 35.4914V6.23985C37.2192 5.41979 36.5544 4.755 35.7344 4.755H16.1344C15.3143 4.755 14.6495 5.41979 14.6495 6.23985V9.65509H11.8281V3.12172Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.07031 13.9612C5.07031 13.0181 5.83482 12.2536 6.77789 12.2536H11.2324C12.1755 12.2536 12.94 13.0181 12.94 13.9612V18.4158C12.94 19.3588 12.1755 20.1233 11.2324 20.1233H6.77789C5.83482 20.1233 5.07031 19.3588 5.07031 18.4158V13.9612ZM7.29759 14.4809V17.8961H10.7127V14.4809H7.29759Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.07031 23.3157C5.07031 22.3726 5.83482 21.6081 6.77789 21.6081H11.2324C12.1755 21.6081 12.94 22.3726 12.94 23.3157V27.7702C12.94 28.7133 12.1755 29.4778 11.2324 29.4778H6.77789C5.83482 29.4778 5.07031 28.7133 5.07031 27.7702V23.3157ZM7.29759 23.8354V27.2505H10.7127V23.8354H7.29759Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2754 13.9612C14.2754 13.0181 15.0399 12.2536 15.983 12.2536H20.4375C21.3806 12.2536 22.1451 13.0181 22.1451 13.9612V18.4158C22.1451 19.3588 21.3806 20.1233 20.4375 20.1233H15.983C15.0399 20.1233 14.2754 19.3588 14.2754 18.4158V13.9612ZM16.5027 14.4809V17.8961H19.9178V14.4809H16.5027Z" fill="#F27A21"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2754 23.3157C14.2754 22.3726 15.0399 21.6081 15.983 21.6081H20.4375C21.3806 21.6081 22.1451 22.3726 22.1451 23.3157V27.7702C22.1451 28.7133 21.3806 29.4778 20.4375 29.4778H15.983C15.0399 29.4778 14.2754 28.7133 14.2754 27.7702V23.3157ZM16.5027 23.8354V27.2505H19.9178V23.8354H16.5027Z" fill="#F27A21"/>
</svg>


 <span> Pay With QR Codes </span></div> 

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          <div className="payment_type_inner scanner_inner">
                {/* <div> */}
                    <p>Scan to pay or use
card for payment</p>
<div className="scanner_container text-center ">
    <img src={scanner_img} alt="" />
</div>



<button className="back_btn py-3 " onClick={() => handleClose()} >Back</button>


                {/* </div> */}

              </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
