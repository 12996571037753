import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/od.png";
import userimg from "../../../../../img/users-icon.svg";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaAlignJustify } from "react-icons/fa";
import { FaBorderAll } from "react-icons/fa";
import { TbCalendarClock } from "react-icons/tb";
import image1 from "../../../../../img/icon4.png";
import image2 from "../../../../../img/icon1.png";
import "./list.css";

import {
  Finalorderlist,
  OrderStatus,
  Profilelist,
  Resstatus,
} from "../../../../../Helper/Redux/API";

import OrderPopup from "./OrderPopup";

import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
const OrderList = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allrestaurant, setAllRestaurant] = useState("");
  const [allrestaurantlength, setAllRestaurantLength] = useState("");
  const [totalCounts, setTotalCounts] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const [Profiledata, setProfiledata] = useState();
  const [isperfectuser, setIsPerfectUser] = useState("");
  const [img, setImg] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedImage, setSelectedImage] = useState("image2");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    if (image === "image1") {
      navigate("/ordermanagement/orderplacement");
    }
  };

  const userpermissions = Profiledata ? Profiledata : [];

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const getProfilelogin = () => {
    setIsLoading(true);
    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          const permissiondata = res?.payload?.payload?.rolesId;
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
          setProfiledata(permissiondata.permission);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  console.log("isperfectuser", isperfectuser);

  let canViewOrder = false;

  if (roletype !== "2") {
    canViewOrder = true;
  }

  if (roletype === "2") {
    const OrderPermission = userpermissions?.find(
      (permission) => permission.name === "Order Placement"
    );

    if (OrderPermission) {
      canViewOrder = OrderPermission.View || false;
    }
  }
  const reId = localStorage.getItem("id");
  console.log("profileDataprofileData000", userpermissions);
  const getRestaurantListData = () => {
    setIsLoading(true);

    const requestOption = {
      page: page,
      search: searchTerm,
      limit: limit,
      Rid: roletype === 1 || roletype == 2 ? reId : "",
      id: "",
    };

    dispatch(Finalorderlist(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setTotalCounts(res?.payload?.totalCount);
          if (roletype === 2 && roletype === 1) {
            setImg(res?.payload?.payload?.image);
          }
          setAllRestaurantLength(res?.payload.total);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getRestaurantListData();
  }, [page, limit, searchTerm]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };

  const handleCardClick = (order) => {
    setSelectedOrder(order);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setSelectedOrder(null);
    setShowPopup(false);
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  const getLastFiveDigits = (id) => {
    return id.slice(-5);
  };

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);

    gethardwarestatus(event.target.value);
  };

  const gethardwarestatus = (status) => {
    const requestOption = {
      orderId: "",
      orderStatus: status,
      page: page,
    };
    console.log(requestOption);
    dispatch(OrderStatus(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
          setAllRestaurantLength(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
        console.log(res);
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // useEffect(()=>{
  //   gethardwarestatus();
  // },[selectedStatus])

  const getresetstatus = () => {
    getRestaurantListData();
    setSearchTerm("");
    setLimit("3");
    setSelectedStatus("");
  };
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Order Management" img_icon={Firedark} />

          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div className="card shadow-sm card-custom_box h-100 py-3">
                      <div className="card-body">
                        <div className="row card-custom_box_row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="h5 mb-0 ">Total </div>
                          </div>
                          <div className="col-auto">
                            <h4 className="count-text">
                              <img className="img-fluid" src={userimg} />
                              <span> {totalCounts ? totalCounts : "0"} </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* <div className="col-lg-3 col-md-12 mb-4">
                {roletype === "2" ? (
                  <>
                    {isperfectuser === true && (
                      <>
                        <Link to="#">
                          <div className="card card-custom_box h-100  bg-orange">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mt-2">
                                  <div className="h5 mb-0 text-center text-light">
                                    Add order
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {roletype === "1" ? (
                      <>
                        {" "}
                        <Link to="#">
                          <div className="card card-custom_box h-100 bg-orange">
                            <div className="card-body">
                              <div className="row card-custom_box_row no-gutters align-items-center">
                                <div className="col mt-2">
                                  <div className="h5 mb-0 text-center text-light">
                                    Add order
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div> */}
            </div>
            <div className="custom-table">
              <div
                className="card shadow-sm card-custom_box mb-4 mainDiv"
                style={{ height: "410px", height: "auto" }}
              >
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      {roletype === "0" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div
                            className="status_div d-flex statusCon"
                            style={{
                              marginLeft: "66%",
                              fontSize: "10px",
                              position: "relative",
                              top: "48px",
                            }}
                          >
                            <div class="form-group mb-0 statusCon1">
                              <select
                                class="form-control"
                                id="selectstatis"
                                onChange={handleChange}
                                value={selectedStatus}
                              >
                                <option selected>Status</option>
                                <option>Pending</option>
                                <option>Completed</option>
                              </select>
                            </div>

                            <button
                              className="resetbutton statusCon1"
                              onClick={getresetstatus}
                            >
                              Reset
                            </button>
                          </div>
                        </>
                      )}
                      <div
                        className="row show"
                        style={{ height: "63px", bottom: "33px" }}
                      >
                        <div className="col-sm-12 col-md-2 ">
                          <div
                            className="dataTables_length showDiv"
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                {/* <option value={3}>3</option> */}
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter searchCon"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm inputSearch "
                                placeholder="name"
                                aria-controls="dataTable"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{ marginRight: "40px" }}
                              />
                            </label>
                          </div>

                          {/* <div className="iconContainer">
                            <span
                              className="icon"
                              onClick={() => {
                                navigate("/ordermanagement/orderplacement");
                              }}
                            >
                              <FaAlignJustify />
                            </span>
                            <span className="icon">
                              <FaBorderAll />
                            </span>
                          </div> */}

                          <div className="iconContainer">
                            {/* <img src={image1} className="image1"  onClick={() => {
                                navigate("/ordermanagement/orderplacement");
                              }} />
                          <img src={image2} className="image2"/> */}

                            <img
                              src={image1}
                              className={`image1 ${
                                selectedImage === "image1" ? "selected" : ""
                              }`}
                              onClick={() => handleImageClick("image1")}
                              // style={{backgroundColor:'#FFDFD6'}}
                            />
                            <img
                              src={image2}
                              className={`image2 ${
                                selectedImage === "image2" ? "selected" : ""
                              }`}
                              // onClick={() => handleImageClick('image2')}
                            />
                          </div>
                        </div>
                      </div>
                      {showPopup && selectedOrder && (
                        <OrderPopup
                          orderData={selectedOrder}
                          onClose={handleClosePopup}
                        />
                      )}

                      <div className="card-Container">
                        {Array.isArray(allrestaurant) &&
                          allrestaurant?.map((item) => (
                            <div
                              key={item._id}
                              className="card"
                              onClick={() => handleCardClick(item)}
                            >
                              <div className="card-head">
                                <p>#{getLastFiveDigits(item._id)}</p>
                                <p className="clock">
                                  <TbCalendarClock />{" "}
                                  {formatTime(item.createdAt)},{" "}
                                  {formatDate(item.createdAt)}
                                </p>
                                <p className="status">{item.paymentStatus}</p>
                              </div>
                              <div
                                className="card-body"
                                style={{ height: "150px" }}
                              >
                                <div className="order-item-header">
                                  <p>Item</p>
                                  <p>Qty</p>
                                </div>
                                <div className="order-items">
                                  {item?.completeOrder?.map(
                                    (orderItem, index) => (
                                      <div key={index} className="order-item">
                                        <p>{orderItem.item_name}</p>
                                        <p>
                                          {" "}
                                          {orderItem.mealbundle.length + 1}
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="row footcon">
                        <div className="col-sm-12 col-md-5 ">
                          <div
                            className="dataTables_info footshow"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 3 of{" "}
                            {allrestaurantlength ? allrestaurantlength : "0"}{" "}
                            entries
                          </div>
                        </div>
                        <div
                          className="col-sm-12 col-md-7 paginateFoot "
                          style={{ marginTop: "20px" }}
                        >
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderList;
