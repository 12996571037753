import React, { useEffect, useState } from "react";
import "./add.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "./add.css";
import {
  branchrole,
  categoriesAdd,
  restaurantList,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";
import generic_img from "../../../../../img/AddModal/Group 959.png";
import Croper from "../../../../../Helper/imageCroper/croper";
import SmallImageUploadComponent from "./smallImageCropper";
import s3 from "../../../../../Helper/Configure/aws-config";
import BigImageCropper from "./bigImageCropper";
import getCroppedImg from "./Cropimage";

const AddCategory = ({ setUpdateAPI, canCreateCategory, isPerfectUser }) => {
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);

  const [initialValue, setInitialValue] = useState({
    restaurant_name:
      localStorage.getItem("roletype") == "1" ? localStorage.getItem("id") : "",
    branch_name: [],
    category: "",
    description: "",
    sub_title: "",
    sub_title2: "",
    smailimg: null,
    bigimg: "",
  });

  const [croppedSmallImageAreaPixels, setCroppedSmallImageAreaPixels] =
    useState(null);
  const [croppedBigImageAreaPixels, setCroppedBigImageAreaPixels] =
    useState(null);
  const [bigPreviewImg, setBigPreviewImg] = useState("");
  const [bigImgUpload, setBigImgUpload] = useState("");
  const [smallPreviewImg, setSmallPreviewImg] = useState("");
  const [smallImgUpload, setSmallImgUpload] = useState("");
  const [smallCropImg, setSmallCropImg] = useState("");
  const [bigCropImg, setBigCropImg] = useState("");

  const [imageError, setImageError] = useState(false);
  const [showSmallImageCropper, setShowSmallImageCropper] = useState(false);
  const [showBigImageCropper, setShowBigImageCropper] = useState(false);

  const setBlankBoth = () => {
    setSmallPreviewImg("");
    setSmallImgUpload("");
    setBigPreviewImg("");
    setBigImgUpload("");
    setShowSmallImageCropper(false);
    setShowBigImageCropper(false);
  };

  const handleSmallFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSmallPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
      setSmallImgUpload(file);
      setShowSmallImageCropper(true);
    } else {
      console.error("No file selected");
    }
  };

  const handleBigFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setBigPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
      setBigImgUpload(file);
      setShowBigImageCropper(true);
    } else {
      console.error("No file selected");
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    if (!smallImgUpload || !bigImgUpload) {
      setImageError(true);
    } else {
      try {
        const croppedSmallImageBlob = await getCroppedImg(
          smallPreviewImg,
          croppedSmallImageAreaPixels
        );

        const paramsSmallImage = {
          Bucket: process.env.REACT_APP_BUCKET_NAME + "public/category",
          Key: smallImgUpload.name,
          Body: croppedSmallImageBlob,
          ContentType: smallImgUpload.type,
        };

        const smallImageUploadResponse = await new Promise(
          (resolve, reject) => {
            s3.upload(paramsSmallImage, (err, data) => {
              if (err) {
                console.error("Error uploading file:", err);
                reject(err);
              } else {
                console.log("Successfully uploaded file:", data);
                resolve(data.Location);
              }
            });
          }
        );

        const croppedBigImageBlob = await getCroppedImg(
          bigPreviewImg,
          croppedBigImageAreaPixels
        );
        const paramsBigImage = {
          Bucket: process.env.REACT_APP_BUCKET_NAME + "public/category",
          Key: bigImgUpload.name,
          Body: croppedBigImageBlob,
          ContentType: bigImgUpload.type,
        };

        const bigImageUploadResponse = await new Promise((resolve, reject) => {
          s3.upload(paramsBigImage, (err, data) => {
            if (err) {
              console.error("Error uploading file:", err);
              reject(err);
            } else {
              console.log("Successfully uploaded file:", data);
              resolve(data.Location);
            }
          });
        });
        console.log(croppedBigImageBlob, "croppedBigImageBlob");

        setIsLoading(true);
        const branches = values.branch_name.map((item) => item.value);
        const branchesArray = JSON.stringify(branches);
        const formData = new FormData();

        formData.append("restaurantId", values.restaurant_name);
        formData.append("branchIds", branchesArray);
        formData.append("title", values.category);
        formData.append("description", values.description);
        formData.append("image1", bigImageUploadResponse);
        formData.append("image2", smallImageUploadResponse);

        const requestOptions = {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
          },
        };

        const res = await dispatch(categoriesAdd(requestOptions));

        setIsLoading(false);

        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          setUpdateAPI("added");
          setBlankBoth();
        } else {
          toast.error(res?.payload?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validationSchemas = yup.object().shape({
    restaurant_name: yup.string().required("Restaurant is required"),
    category: yup.string().required("Category is required"),

    description: yup.string().required("Description is required"),
    // bigimg: yup
    //   .mixed()
    //   .required("Big img is required")
    //   .test(
    //     "fileSize",
    //     "The file is too large",
    //     (value) => value && value.size <= 1024 * 1024 // 1MB
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) =>
    //       value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    //   ),
    // smailimg: yup
    //   .mixed()
    //   .required("Small img is required")
    //   .test(
    //     "fileSize",
    //     "The file is too large",
    //     (value) => value && value.size <= 1024 * 1024 // 1MB
    //   )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) =>
    //       value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    //   ),
  });

  const [isLoadingRestro, setIsLoadingRestro] = useState(false);
  const getRestaurantListData = () => {
    setIsLoadingRestro(false);
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
          if (localStorage.getItem("roletype") == "1") {
            getBraches(localStorage.getItem("id"));
          }
          if (localStorage.getItem("roletype") == "2") {
            getBraches(res?.payload?.payload[0]?._id);
            setInitialValue((prevState) => ({
              ...prevState,
              restaurant_name: res?.payload?.payload[0]?._id,
            }));
            setIsLoadingRestro(true);
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = branches.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const shouldShowPage = () => {
    if (localStorage.getItem("roletype") != 2) {
      return true;
    } else if (localStorage.getItem("roletype") == 2 && isLoadingRestro) {
      return true;
    } else {
      return false;
    }
  };

  const isVisibleCreateIcon = () => {
    if (localStorage.getItem("roletype") == "2" && canCreateCategory == false) {
      return false;
    }
    if (
      localStorage.getItem("roletype") == "2" &&
      isPerfectUser == true &&
      canCreateCategory == true
    ) {
      return true;
    } else {
      return true;
    }
  };

  return (
    <section className="add_category ">
      <div className="add_category_inner">
        <h6 className="heading_name">Add Category</h6>
        <hr />
        <div className="form_container">
          {isVisibleCreateIcon() ? (
            shouldShowPage() && (
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={validationSchemas}
              >
                {(formik) => (
                  <Form>
                    <div
                      className="field_container d-flex flex-column gap-5"
                      style={{ gap: "8px" }}
                    >
                      <div className=" d-flex flex-column">
                        <label htmlFor="Restaurant">
                          Restaurant <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="select"
                          placeholder="Enter Restaurant Name "
                          id="Restaurant"
                          name="restaurant_name"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "restaurant_name",
                              e.target.value
                            );
                            getBraches(e.target.value);
                          }}
                          disabled={
                            localStorage.getItem("roletype") == 1 ||
                            localStorage.getItem("roletype") == 2
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Restaurant</option>
                          {restaurant.map((res, i) => (
                            <option value={res?._id} key={i}>
                              {res?.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="p"
                          name="restaurant_name"
                          className="text-danger"
                        />
                      </div>
                      <div className=" d-flex flex-column">
                        <label htmlFor="branch_name">Branch</label>
                        <MultiSelect
                          options={allbrachList}
                          name="branch_name"
                          value={formik.values.branch_name}
                          onChange={(e) =>
                            formik.setFieldValue("branch_name", e)
                          }
                          labelledBy="Select Branch"
                          overrideStrings={{
                            selectSomeItems: "Select Branch...",
                            allItemsAreSelected: "All Branch are selected.",
                          }}
                        />
                      </div>
                      <div className=" d-flex flex-column">
                        <label htmlFor="category">
                          Title <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          placeholder="Enter category Name "
                          id="category"
                          name="category"
                        />
                        <ErrorMessage
                          component="p"
                          name="category"
                          className="text-danger"
                        />
                      </div>

                      <div className=" d-flex flex-column">
                        <label htmlFor="description">
                          Description <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="textarea"
                          placeholder="Enter description  "
                          id="description"
                          name="description"
                        />
                        <ErrorMessage
                          component="p"
                          name="description"
                          className="text-danger"
                        />
                      </div>
                      <div className="row">
                        <div
                          className=" col-md-6 d-flex flex-column"
                          style={{ gap: "10px" }}
                        >
                          <label htmlFor="Restaurant" className="m-0">
                            Upload Big Image{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="category_img_cont">
                            <img
                              src={bigCropImg ? bigCropImg : generic_img}
                              alt=""
                              className="category_images"
                            />
                          </div>
                          <div className="category_img_browse_container big_image">
                            <button>Browse</button>
                            <input
                              type="file"
                              name="bigimg"
                              id=""
                              onChange={(e) => handleBigFileChange(e)}
                            />
                          </div>

                          {imageError && !bigImgUpload && (
                            <p className="text-danger"> Please select Image </p>
                          )}
                        </div>
                        {showBigImageCropper && (
                          <BigImageCropper
                            imageSrc={bigPreviewImg}
                            setBigCropImg={setBigCropImg}
                            openModal={showBigImageCropper}
                            setCroppedAreaPixels={setCroppedBigImageAreaPixels}
                            setShowBigImageCropper={setShowBigImageCropper}
                          />
                        )}
                        <div
                          className=" col-md-6 d-flex flex-column "
                          style={{ gap: "10px" }}
                        >
                          <label htmlFor="Restaurant" className="m-0">
                            Upload Small Image{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="category_img_cont">
                            <img
                              src={smallCropImg ? smallCropImg : generic_img}
                              alt=""
                              className="category_images"
                            />
                          </div>
                          <div className="category_img_browse_container small_image">
                            <button>Browse</button>
                            <input
                              type="file"
                              name="smailimg"
                              id=""
                              onChange={(e) => handleSmallFileChange(e)}
                            />
                          </div>
                          {imageError && !smallImgUpload && (
                            <p className="text-danger"> Please select Image </p>
                          )}
                          {showSmallImageCropper && (
                            <SmallImageUploadComponent
                              imageSrc={smallPreviewImg}
                              setSmallCropImg={setSmallCropImg}
                              openModal={showSmallImageCropper}
                              setCroppedAreaPixels={
                                setCroppedSmallImageAreaPixels
                              }
                              setShowSmallImageCropper={
                                setShowSmallImageCropper
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="submit_btn_container mt-4 d-flex gap-3 justify-content-center align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <button
                          className="category_save"
                          disabled={isLoading == true}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )
          ) : (
            <p>You don't have add permission</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default AddCategory;
