import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import menu_icon from "../../../../../img/Vector.svg";

import whitedelete from "../../../../../img/white-delete.svg";
import whiteedit from "../../../../../img/white-edit.svg";
import whiteview from "../../../../../img/white-eye.svg";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  branchrole,
  categoryList,
  restaurantList,
  mealList,
  mealDelete,
  mealStatusUpdate,
  Profilelist,
} from "../../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "./list.css";
import swal from "sweetalert";
const MealList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allRole, setAllRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [updateAPI, setUpdateAPI] = useState(1);
  const [restaurant, setRestaurant] = useState("");
  const [category, setCategory] = useState("");
  const [branch, setBranch] = useState("");
  const [status, setStatus] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [filtersCleared, setFiltersCleared] = useState(false);
  const handleBlankFilter = () => {
    console.log("Resetting filters...");
    setRestaurant("");
    setCategory("");
    setBranch("");
    setStatus("");
    setFiltersCleared(true);
  };

  useEffect(() => {
    if (filtersCleared) {
      getBundlesList();
      setFiltersCleared(false);
    }
  }, [filtersCleared]);

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [totalCounts, setTotalCounts] = useState("");

  const roletype = localStorage.getItem("roletype");

  const [Profiledata, setProfiledata] = useState("");
  const [isperfectuser, setIsPerfectUser] = useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  let canView = false;
  let canEdit = false;
  let canDelete = false;
  let canCreate = false;

  if (roletype !== "2") {
    canView = true;
    canEdit = true;
    canDelete = true;
    canCreate = true;

    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const HardAllotPermission = userpermissions?.find(
      (permission) => permission.name === "Meal creation"
    );

    if (HardAllotPermission) {
      canView = HardAllotPermission.View || false;
      canCreate = HardAllotPermission.Create || false;
      canEdit = HardAllotPermission.Edit || false;
      canDelete = HardAllotPermission.Delete || false;
    }
  }
  const isVisibleViewIcon = () => {
    if (roletype == "2" && canView == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canView == true) {
      return true;
    } else {
      return true;
    }
  };
  const isVisibleEditIcon = () => {
    if (roletype == "2" && canEdit == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canEdit == true) {
      return true;
    } else {
      return true;
    }
  };
  const isVisibleDeleteIcon = () => {
    if (roletype == "2" && canDelete == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canDelete == true) {
      return true;
    } else {
      return true;
    }
  };

  const isVisibleCreateIcon = () => {
    if (roletype == "2" && canCreate == false) {
      return false;
    }
    if (roletype == "2" && isperfectuser == true && canCreate == true) {
      return true;
    } else {
      return true;
    }
  };

  const getBundlesList = () => {
    setIsLoading(true);
    const requestOption = {
      page: page,
      search: search,
      limit: limit,
      restaurant: restaurant,
      branch: branch,
      status: status,
    };
    dispatch(mealList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRole(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getBundlesList();
  }, [page, limit, search, updateAPI]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  const handleDeleteBundle = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(mealDelete(id)).then((res) => {
          if (res?.payload?.status == true) {
            toast.success(res?.payload?.message);
            setAllRole(allRole.filter((branch) => branch._id !== id));
          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const handleStatusUpdate = (status, id) => {
    const requestOption = {
      id: id,
      status: !status,
    };
    try {
      dispatch(mealStatusUpdate(requestOption)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          getBundlesList();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurants(res?.payload?.payload);
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  const getCategoryList = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
      restaurant: "",
      branch: "",
      status: "",
    };
    dispatch(categoryList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setCategorys(res?.payload?.payload);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();
    getCategoryList();
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranchs(res?.payload?.payload);
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Menu Management" img_icon={menu_icon} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4"></div>

              <div className="col-lg-3 col-md-12 mb-4">
                {isVisibleCreateIcon() && (
                  <Link to="/menu-management/meal/create">
                    <div className="card card-custom_box h-100  bg-blue">
                      <div className="card-body">
                        <div className=" card-custom_box_row no-gutters align-items-center">
                          <div className="">
                            <div className="h5 mb-0 text-center">Add Meal</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>

            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  <div className="table-responsive menu-items-list">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      {/* <div className="d-flex align-items-center justify-content-between mb-4 pe-2 "> */}
                      <h6 class="text-navy-blue m-3">Meal Creation</h6>
                      <hr className="mb-2" />
                      <div className="sorting_filter_container mb-2 ml-3 ">
                        <select
                          name=""
                          id=""
                          value={restaurant}
                          onChange={(e) => {
                            setRestaurant(e.target.value);
                            getBraches(e.target.value);
                          }}
                        >
                          <option value="">Restaurant</option>
                          {restaurants.map((res, i) => (
                            <option value={res?._id} key={i}>
                              {res?.name}
                            </option>
                          ))}
                        </select>

                        <select
                          name=""
                          id=""
                          value={branch}
                          onChange={(e) => setBranch(e.target.value)}
                        >
                          <option value="">Branch</option>
                          {branchs.map((res, i) => (
                            <option value={res?._id} key={i}>
                              {res?.name}
                            </option>
                          ))}
                        </select>

                        <select
                          name=""
                          id=""
                          value={status}
                          onChange={(e) => setStatus(status === "true")}
                        >
                          <option value="">Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        <button
                          className="search_btn"
                          onClick={() => getBundlesList()}
                        >
                          Search
                        </button>
                        <button
                          className="reset_btn"
                          onClick={() => handleBlankFilter()}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="row ml-3 ">
                        <div className="col-sm-12 col-md-3">
                          <div
                            className="dataTables_length mb-0"
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              Entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 my-3">
                          <div
                            id="dataTable_filter "
                            className="dataTables_filter mb-0 mealSearch"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}

                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "250px", whiteSpace: "pre" }}
                                >
                                  Meal Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "250px" }}
                                >
                                  Description
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  style={{ width: "150", whiteSpace: "pre" }}
                                >
                                  Status
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {allRole.length == 0 ? (
                                // Render a loader while data is being fetched
                                <tr>
                                  <td
                                    colSpan="5"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3>Empty List</h3>
                                  </td>
                                </tr>
                              ) : (
                                allRole.map((item, index) => (
                                  <>
                                    <tr className="odd" key={item.index}>
                                      <td> {item.mealName}</td>
                                      <td>
                                        <div className="meal_list_desc">
                                          {item.description}
                                        </div>
                                      </td>

                                      <td
                                        className={
                                          item.isActive
                                            ? "text-success"
                                            : "text-danger"
                                        }
                                        onClick={() =>
                                          handleStatusUpdate(
                                            item.isActive,
                                            item._id
                                          )
                                        }
                                      >
                                        <span
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Click to change status"
                                        >
                                          {item?.isActive
                                            ? "Active"
                                            : "Inactive"}
                                        </span>
                                      </td>

                                      <td>
                                        <ul className="action-btns d-flex gap-2">
                                          {isVisibleViewIcon() && (
                                            <li>
                                              <button
                                                className="bg-blue delete_btn"
                                                onClick={() =>
                                                  navigate(
                                                    `/menu-management/meal/view/${item?._id}`
                                                  )
                                                }
                                              >
                                                <img src={whiteview} />
                                              </button>
                                            </li>
                                          )}
                                          {isVisibleEditIcon() && (
                                            <li>
                                              <button
                                                className="bg-blue delete_btn"
                                                onClick={() =>
                                                  navigate(
                                                    `/menu-management/meal/edit/${item?._id}`
                                                  )
                                                }
                                              >
                                                <img src={whiteedit} />
                                              </button>
                                            </li>
                                          )}
                                          {isVisibleDeleteIcon() && (
                                            <li>
                                              <button
                                                className="bg-orange delete_btn"
                                                onClick={() =>
                                                  handleDeleteBundle(item?._id)
                                                }
                                              >
                                                <img src={whitedelete} />
                                              </button>
                                            </li>
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  </>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row  align-items-center justify-content-between mx-3 ">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to {totalCounts} of{" "}
                            {allRole.length ? allRole.length : "0"} Entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MealList;
