import React, { useEffect, useState } from "react";
import "./topcart.css";
import boathouse from "../../../../../../img/newlog.png";
import cartimg from "../../../../../../img/cartimg.png";
import logout_img from "../../../../../../img/logout-icon.svg";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dashboard, mycart } from "../../../../../../Helper/Redux/API";
const Topheader = (props) => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allrestaurantlogo, setAllRestaurantLogo] = useState("");
  const [allrestaurantCount, setAllRestaurantCount] = useState(props.count);

  const getRestaurantListData = () => {
    // setIsLoading(true);

    dispatch(dashboard())
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurantLogo(res?.payload.restaurantLogos[0]);
          // console.log(res?.payload.restaurantLogos[0]);
          if (roletype === 2 && roletype === 1) {
            // setImg(res?.payload?.payload?.image);
          }
          // setAllRestaurantLength(res?.payload.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const getmycartListData = () => {
    // setIsLoading(true);

    dispatch(mycart())
      .then((res) => {
        if (res?.payload?.status === true) {
          setMyCartData(res?.payload?.totalCount);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    if (roletype === "2" || roletype === "1") {
      getRestaurantListData();
    }
    getmycartListData();
  }, []);

  const [mycartdata, setMyCartData] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  // const toggleallSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  const viewcart = () => {
    // console.log("id", id);
    navigate("/ordermanagement/mycart");
  };

  const logout = () => {
    if (roletype === "1" || roletype === "2") {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id");
      localStorage.removeItem("RoleName");
      localStorage.removeItem("roletype");
      localStorage.removeItem("restaurantType");
      localStorage.removeItem("isperfectmember");
      navigate("/restaurantslogin");
    } else {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id");
      localStorage.removeItem("RoleName");
      localStorage.removeItem("roletype");
      navigate("/");
    }
  };
  // console.log("props.count", props.count);
  return (
    <div>
      <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top">
        {/* <!-- Sidebar Toggle (Topbar) --> */}
        {/* <button className="toggle-button" onClick={toggleallSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button> */}

        {/* {isOpen ? "Close" : "Open"} */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        <div className="page-title">
          <h4>
            <img src={props.img_icon} alt="" className="img-fluid" />
            {props.heding_text}
          </h4>
        </div>

        {/* <!-- Topbar Navbar --> */}
        <ul className="navbar-nav ml-auto  align-items-center">
          {/* <!-- Nav Item - User Information --> */}
          <li>
            <button onClick={viewcart} className="cart_view">
              <img src={cartimg} alt="" className="" />
              <span>{props.count ? props.count : mycartdata}</span>
            </button>
          </li>
          <li className="nav-item">
            <a className="nav-link " href="/dashboard">
              {roletype === "1" || roletype === "2" ? (
                <>
                  {" "}
                  <img className="img-profile" src={allrestaurantlogo?.image} />
                </>
              ) : (
                <img className="img-profile imgpro" src={boathouse} />
              )}
            </a>
          </li>
          <li className="nav-item">
            {" "}
            <a className="logout-btn_menu" onClick={logout}>
              <span>LOG OUT</span>{" "}
              <img src={logout_img} alt="" className="img-fluid" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Topheader;
