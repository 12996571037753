import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/team.svg";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import * as yup from "yup";

import { toast } from "react-toastify";
import {
  branchrole,
  designationUpdate,
  designationView,
  restaurantList,
  Profilelist,
} from "../../../../../Helper/Redux/API";

const DesignationEdit = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [filddisble, setFilddisble] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = React.useState([]);
  const [selectedvaluesArray, setSelectedvaluesArray] = useState([]);
  const [selectedOptionsBranch, setSelectedOptionsBranch] = React.useState([]);
  const [selectedBrancArray, setSelectedBrancArray] = useState([]);
  const [brachList, setBrachList] = React.useState([]);

  const [Profiledata, setProfiledata] = React.useState();
  const [isperfect, setIsPerfect] = React.useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfect(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);
  const [initialValue, setInitialValue] = useState({
    // restaurant_name: "",
    // branch_name: "",
    designation: "",
  });

  const handleSubmit = (values, { resetForm }) => {
    const requestOptions = {
      id: id,
      data: {
        restaurantId: selectedvaluesArray,
        branchIds: selectedBrancArray,
        restaurantLabel: selectedRestaurant,
        branchLabel: selectedOptionsBranch,
        designationName: values.designation,
      },
    };

    console.log("requestOptions", requestOptions);
    try {
      dispatch(designationUpdate(requestOptions)).then((res) => {
        console.log(res);
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          navigation(-1);
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchemas = yup.object().shape({
    // restaurant_name: yup.string().required("Restaurant is required"),
    // branch_name: yup.string().required("Branch is required"),
    designation: yup.string().required("Designation is required"),
  });

  const getDesignationView = () => {
    setLoadingData(true);
    try {
      dispatch(designationView(id)).then((res) => {
        setLoadingData(false);

        if (res?.payload?.status === true) {
          setInitialValue({
            // restaurant_name: res?.payload?.payload[0]?.restaurantId[0],
            // branch_name: res?.payload?.payload[0]?.branchIds[0],
            designation: res?.payload?.payload[0]?.designationName,
          });

          setSelectedRestaurant(res?.payload?.payload[0]?.restaurantLabel);
          setSelectedOptionsBranch(res?.payload?.payload[0]?.branchLabel);
          setSelectedvaluesArray(res?.payload?.payload[0]?.restaurantId);
          setSelectedBrancArray(res?.payload?.payload[0]?.branchIds);
          getBraches(res?.payload?.payload[0]?.restaurantId);

          if (roletype === "2") {
            setSelectedRestaurant(res?.payload?.payload[0]?.restaurantLabel);
            setSelectedOptionsBranch(res?.payload?.payload[0]?.branchLabel);
            setSelectedvaluesArray(res?.payload?.payload[0]?.restaurantId);
            setSelectedBrancArray(res?.payload?.payload[0]?.branchIds);
            getBraches(res?.payload?.payload[0]?.restaurantId);
            setFilddisble(true);
            // getBraches(res?.payload?.payload[0]?.restaurantId);
          }
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
      });
  };

  const allrestaurantlist = Array.isArray(restaurant)
    ? restaurant.map((item) => ({
        label: item?.name,
        value: item?._id,
      }))
    : [];

  const handleChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
    // console.log("selectedOptions", selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    // console.log("valuesArray", valuesArray);
    setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    // setSelectedOptionsBranch([{ value: "", label: "" }]);
    setSelectedOptionsBranch([]);
    getBraches(valuesArray);
    console.log("selectedOptions", selectedOptions);
  };

  useEffect(() => {
    getRestaurantListData();
    getDesignationView();

    if (roletype === "1") {
      setFilddisble(true);
      setSelectedRestaurant({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("RoleName"),
      });

      // setInitialValue({
      //   restaurant_name: localStorage.getItem("id"),
      // });
      setSelectedvaluesArray(localStorage.getItem("id"));
      setFilddisble(true);
      getBraches(localStorage.getItem("id"));
    }
  }, []);

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBranches(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(branches)
    ? branches.map((item) => ({
        label: item?.name,
        value: item?._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    // setSelectedOptionsBranch(selectedOptions);
    setSelectedOptionsBranch(selectedOptions);
    const valuesArray = selectedOptions.map((option) => option.value);
    setSelectedBrancArray(valuesArray);
  };

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text=" Edit Designation" img_icon={Firedark} />
          <div className="container-fluid">
            <div className="card shadow-sm card-custom_box mb-4 adddesignation_card">
              <div className="card-body ">
                <div className=" designation_form_container">
                  <h6 class="text-navy-blue mb-5">Edit Designation</h6>
                  <hr />
                  {loadingData ? (
                    <p>Data is loading</p>
                  ) : (
                    <Formik
                      initialValues={initialValue}
                      validationSchema={validationSchemas}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <div
                            className="d-flex flex-column  "
                            style={{ gap: "10px" }}
                          >
                            <div className="row addrolemange">
                              <div className="col-md-6 d-flex flex-column">
                                <label htmlFor="Restaurant">
                                  Restaurant Name{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                {roletype === "1" || roletype === "2" ? (
                                  <>
                                    {" "}
                                    {isperfect && roletype === "2" ? (
                                      <>
                                        <MultiSelect
                                          options={allrestaurantlist}
                                          value={selectedRestaurant}
                                          onChange={handleChange}
                                          labelledBy="Select Restaurant..."
                                          overrideStrings={{
                                            selectSomeItems:
                                              "Select Restaurant...",
                                            allItemsAreSelected:
                                              "All Restaurant are selected.",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <Select
                                        // isMulti
                                        options={allrestaurantlist}
                                        value={selectedRestaurant}
                                        onChange={handleChange}
                                        isDisabled={filddisble}
                                        placeholder="Select Restaurant..."
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <MultiSelect
                                      options={allrestaurantlist}
                                      value={selectedRestaurant}
                                      onChange={handleChange}
                                      labelledBy="Select Restaurant..."
                                      overrideStrings={{
                                        selectSomeItems: "Select Restaurant...",
                                        allItemsAreSelected:
                                          "All Restaurant are selected.",
                                      }}
                                    />
                                  </>
                                )}

                                {/* <Field
                                  as="select"
                                  Placeholder="Enter Restaurant Name "
                                  id="Restaurant"
                                  name="restaurant_name"
                                  disabled={filddisble}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "restaurant_name",
                                      e.target.value
                                    );
                                    getBraches(e.target.value);
                                  }}
                                >
                                  <option value="">Select</option>
                                  {restaurant.map((res, i) => (
                                    <option value={res?._id} key={i}>
                                      {res?.name}
                                    </option>
                                  ))}
                                </Field> */}
                                {/* <ErrorMessage
                                  component="p"
                                  name="restaurant_name"
                                  className="text-danger"
                                /> */}
                              </div>
                              <div className="col-md-6 d-flex flex-column">
                                <label htmlFor="Branch">
                                  Branch Name
                                  <span className="text-danger">*</span>
                                </label>

                                <MultiSelect
                                  options={allbrachList}
                                  value={selectedOptionsBranch}
                                  onChange={handleChangeBranch}
                                  labelledBy="Select Branch..."
                                  overrideStrings={{
                                    selectSomeItems: "Select Branch...",
                                    // allItemsAreSelected:
                                    //   "All branches are selected.",
                                  }}
                                />
                                {/* <Field
                                  as="select"
                                  Placeholder="Enter Branch Name "
                                  id="Branch"
                                  name="branch_name"
                                >
                                  <option value="">Select</option>
                                  {branches.map((res, i) => (
                                    <option value={res?._id} key={i}>
                                      {res?.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  component="p"
                                  name="branch_name"
                                  className="text-danger"
                                /> */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 d-flex flex-column">
                                <label htmlFor="designation">
                                  Designation{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  Placeholder="Enter Designation "
                                  id="designation"
                                  name="designation"
                                />
                                <ErrorMessage
                                  component="p"
                                  name="designation"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="modal_btn_container role_btn">
                            {" "}
                            <div
                              class="modal_cancel_btn"
                              onClick={() => navigation(-1)}
                            >
                              Cancel
                            </div>
                            <button class="modal_cancel_btn" type="submit">
                              Save
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DesignationEdit;
