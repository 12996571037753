import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/team.svg";
import userimg from "../../../../../img/users-icon.svg";
import whitedelete from "../../../../../img/white-delete.svg";
import whiteeye from "../../../../../img/white-eye.svg";
import whiteedit from "../../../../../img/white-edit.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  restaurantList,
  branchrole,
  RoleAdd,
  Profilelist,
} from "../../../../../Helper/Redux/API";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
// import AddBrachModal from "../add/add";
// import EditBrachModal from "../edit/edit";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allrestaurant, setAllRestaurant] = useState("");
  const [brachList, setBrachList] = useState([]);
  const roletype = localStorage.getItem("roletype");
  const [sidebarmenulist, setSidebarmenulist] = useState(() => {
    const roletype = localStorage.getItem("roletype") || "1";
    if (roletype === "1") {
      // For roletype 1, exclude 'Hardware Information'
      return [
        {
          name: "Restaurant",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Branch",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        // {
        //   name: "Hardware Information",
        //   View: false,
        //   Create: false,
        //   Edit: false,
        //   Delete: false,
        //   roleType: 1,
        // },
        // {
        //   name: "Hardware Allotment",
        //   View: false,
        //   Create: false,
        //   Edit: false,
        //   Delete: false,
        // },
        {
          name: "Role Management",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Add Designations",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "User Management",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Categories",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Items",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Bundle creation",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Meal creation",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Order placement",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Order Tracking",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        // Add other items for roletype 1
      ];
    } else if (roletype === "2") {
      // For roletype 2, exclude 'Hardware Information'
      return [
        {
          name: "Restaurant",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Branch",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        // {
        //   name: "Hardware Information",
        //   View: false,
        //   Create: false,
        //   Edit: false,
        //   Delete: false,
        //   roleType: 1,
        // },
        {
          name: "Hardware Allotment",
          View: false,
          // Create: false,
          // Edit: false,
          // Delete: false,
        },
        {
          name: "Role Management",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Add Designations",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "User Management",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Categories",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Items",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Bundle creation",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Meal creation",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Order placement",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Order Tracking",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        // Add other items for roletype 2
      ];
    } else {
      // For other roletypes, include 'Hardware Information'
      return [
        {
          name: "Restaurant",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Branch",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Hardware Information",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
          roleType: 1,
        },
        {
          name: "Hardware Allotment",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Role Management",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        {
          name: "Add Designations",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "User Management",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Categories",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Items",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Bundle creation",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Meal creation",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },

        {
          name: "Order Tracking",
          View: false,
          Create: false,
          Edit: false,
          Delete: false,
        },
        // Add other items for other roletypes
      ];
    }
  });

  // const [sidebarmenulist, setSidebarmenulist] = useState([

  //   {
  //     name: "Restaurant",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },
  //   {
  //     name: "Branch",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },
  //   {
  //     name: "Hardware Information",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //     roleType: 1,
  //   },
  //   {
  //     name: "Hardware Allotment",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },
  //   {
  //     name: "Role Management",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },
  //   {
  //     name: "Add Designations",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },

  //   {
  //     name: "User Management",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },

  //   {
  //     name: "Categories",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },

  //   {
  //     name: "Items",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },

  //   {
  //     name: "Bundle creation",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },

  //   {
  //     name: "Meal creation",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },
  //   {
  //     name: "Order Placement",
  //     View: false,
  //     Create: false,
  //     Edit: false,
  //     Delete: false,
  //   },

  //   // Add more items as needed
  // ]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptionsBranch, setSelectedOptionsBranch] = useState([]);
  const [selectedvaluesArray, setSelectedvaluesArray] = useState([]);
  const [selectedBrancArray, setSelectedBrancArray] = useState([]);
  const [isPerfectUser, setIsPerfectUser] = useState(false);
  const [rolename, setRoleName] = useState("");
  const [filddisble, setFilddisble] = React.useState(false);

  const [Profiledata, setProfiledata] = useState("");
  // const userpermissions = Profiledata ? Profiledata : [];

  // const getProfilelogin = () => {
  //   // setIsLoading(true);

  //   dispatch(Profilelist())
  //     .then((res) => {
  //       if (res?.payload?.status === true) {
  //         // console.log("profiledata", res?.payload.payload);
  //         const permissiondata = res?.payload?.payload?.rolesId;
  //         setProfiledata(permissiondata.permission);
  //       } else {
  //         // toast.error(res?.payload?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching test data:", error);
  //       // Handle error if needed
  //     })
  //     .finally(() => {
  //       // Set loading to false after data fetching completes
  //     });
  // };
  // useEffect(() => {
  //   if (roletype === "2") {
  //     getProfilelogin();
  //   }
  // }, []);

  // let canViewRoleManagement = false;
  // let canEditRoleManagement = false;
  // let canDeleteRoleManagement = false;
  // let canCreactRoleManagement = false;

  // if (roletype !== "2") {
  //   canViewRoleManagement = true;
  //   canEditRoleManagement = true;
  //   canDeleteRoleManagement = true;
  //   canCreactRoleManagement = true;

  //   // Set to false if roletype is not "2"
  // }

  // if (roletype === "2") {
  //   const RoleManagementPermission = userpermissions?.find(
  //     (permission) => permission.name === "Role Management"
  //   );

  //   if (RoleManagementPermission) {
  //     canViewRoleManagement = RoleManagementPermission.View || false;
  //     canCreactRoleManagement = RoleManagementPermission.Create || false;
  //     canEditRoleManagement = RoleManagementPermission.Edit || false;
  //     canDeleteRoleManagement = RoleManagementPermission.Delete || false;
  //   }
  // }

  const [isperfect, setIsPerfect] = useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfect(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);
  const handleChange = (selectedOptions) => {
    setSelectedOptions2(selectedOptions);
    setSelectedOptions(selectedOptions);
    // console.log("selectedOptions", selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    // console.log("valuesArray", valuesArray);
    setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    getBraches(valuesArray);
    console.log("selectedOptions", selectedOptions);
  };

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);

          if (roletype === "2") {
            setSelectedOptions({
              value: res?.payload?.payload[0]?._id,
              label: res?.payload?.payload[0]?.name,
            });
            setSelectedvaluesArray(res?.payload?.payload[0]?._id);
            setFilddisble(true);
            getBraches(res?.payload?.payload[0]?._id);
          }
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };

  const getBraches = (valuesArray) => {
    const requestOptions = {
      restaurantId: valuesArray,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setBrachList(res?.payload?.payload);

          // console.log("totalCounts", res?.payload?.payload);
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  useEffect(() => {
    getRestaurantListData();

    if (roletype === "1") {
      setSelectedOptions({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("RoleName"),
      });
      setSelectedvaluesArray(localStorage.getItem("id"));
      setFilddisble(true);
      getBraches(localStorage.getItem("id"));
    }
  }, []);

  const allrestaurantlist = Array.isArray(allrestaurant)
    ? allrestaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    setSelectedOptionsBranch(selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    // console.log("valuesArray", valuesArray);
    setSelectedBrancArray(valuesArray);
    // console.log("selectedOptions", selectedOptions);
  };

  const handleCheckboxClick = (index, field) => {
    setSidebarmenulist((prevState) => {
      const updatedList = prevState.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: !item[field] };
        }
        return item;
      });
      return updatedList;
    });
  };

  const handlePerfectUserChange = (event) => {
    const isChecked = event.target.checked;
    setIsPerfectUser(isChecked);

    if (isChecked) {
      setSelectedOptions(allrestaurantlist);
      setSelectedOptionsBranch(allbrachList);

      const valuesArray = allrestaurantlist.map((option) => option.value);

      // console.log("valuesArray", valuesArray);
      setSelectedvaluesArray(valuesArray);

      // console.log("valuesArray", valuesArray);

      setTimeout(() => {
        const valuesArray2 = allbrachList.map((option) => option.value);
        getBraches(valuesArray);
        setSelectedBrancArray(valuesArray2);
        console.log("valuesArray2", valuesArray2);
      }, 2000);

      // console.log("sss", valuesArray);
    } else {
      setSelectedOptions([]);
      setSelectedOptionsBranch([]);
    }
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const creactrole = () => {
    const requestOptions = {
      roleName: rolename,
      restaurantId: selectedvaluesArray
        ? selectedvaluesArray
        : selectedOptions2,
      branchIds: selectedBrancArray,
      is_perfect_user: isPerfectUser,
      permission: sidebarmenulist,
      restaurantLabel: selectedOptions,
      branchLabel: selectedOptionsBranch,
    };
    console.log("dd", requestOptions);

    try {
      dispatch(RoleAdd(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          // setBrachList(res?.payload?.payload);
          toast.success(res?.payload?.message);
          navigate("/team-management");

          console.log("totalCounts", res?.payload);
          // setTotalCounts(res?.payload?.total);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  // console.log("sidebarmenulist", sidebarmenulist);
  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text=" Add Role Management" img_icon={Firedark} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="mb-3">
              <Link to="/team-management" className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </Link>
            </div>
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <h6 class="text-navy-blue mb-2 space_div">Add Roles</h6>
                      <div className="Line_div"></div>
                      {roletype === "1" || roletype === "2" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="Prefectuser space_div pb-0">
                            <input
                              type="checkbox"
                              checked={isPerfectUser}
                              onChange={handlePerfectUserChange}
                            />{" "}
                            <label>Is Prefect User</label>
                          </div>
                        </>
                      )}{" "}
                      {/* <div className="pageName">Roles</div> */}
                      <div className="row addrolemange space_div pt-1 pb-1">
                        <div className="col-sm-12 col-md-4">
                          <div
                            className="dataTables_length "
                            id="dataTable_length"
                          >
                            <label>Restaurant: </label>

                            {roletype === "1" || roletype === "2" ? (
                              <>
                                {isperfect && roletype === "2" ? (
                                  <>
                                    <MultiSelect
                                      options={allrestaurantlist}
                                      value={selectedOptions2}
                                      onChange={handleChange}
                                      labelledBy="Select Restaurant..."
                                      overrideStrings={{
                                        selectSomeItems: "Select Restaurant",
                                        allItemsAreSelected:
                                          "All Restaurant are selected.",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Select
                                    options={allrestaurantlist}
                                    value={selectedOptions}
                                    onChange={handleChange}
                                    placeholder="Select Restaurant"
                                    isDisabled={filddisble}
                                  />
                                )}{" "}
                              </>
                            ) : (
                              <>
                                {" "}
                                <MultiSelect
                                  options={allrestaurantlist}
                                  value={selectedOptions}
                                  onChange={handleChange}
                                  labelledBy="Select Restaurant..."
                                  overrideStrings={{
                                    selectSomeItems: "Select Restaurant",
                                    allItemsAreSelected:
                                      "All Restaurant are selected.",
                                  }}
                                />
                              </>
                            )}

                            <div>
                              {/* <ul>
                                {selectedOptions.map((option) => (
                                  <li key={option.value}>{option.label}</li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                          <div
                            className="dataTables_length"
                            id="dataTable_length"
                          >
                            <label>Branch: </label>
                            {/* <Select
                              isMulti
                              options={allbrachList}
                              value={selectedOptionsBranch}
                              onChange={handleChangeBranch}
                              placeholder="Select Branch..."
                            /> */}

                            <MultiSelect
                              options={allbrachList}
                              value={selectedOptionsBranch}
                              onChange={handleChangeBranch}
                              labelledBy="Select Branch"
                              overrideStrings={{
                                selectSomeItems: "Select Branch",
                                allItemsAreSelected: "All Branch are selected.",
                              }}
                            />
                            <div>
                              {/* <ul>
                                {selectedOptions.map((option) => (
                                  <li key={option.value}>{option.label}</li>
                                ))}
                              </ul> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter"
                          >
                            <label>Role Name:</label>

                            <input
                              type="search"
                              className="form-control"
                              placeholder="Enter Role Name"
                              onChange={handleRoleNameChange}
                              value={rolename}
                              // value={search}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer space_div pt-0"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "200px;", whiteSpace: "pre" }}
                                >
                                  Sr. No.
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "250px" }}
                                >
                                  Menu
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  // style="width: 204px;"
                                  style={{ width: "200px" }}
                                >
                                  View
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Create
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Edit
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "150px", whiteSpace: "pre" }}
                                >
                                  Delete
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {Array.isArray(sidebarmenulist) &&
                                sidebarmenulist
                                  // .filter((item) => {
                                  //   // Filter out items with roleType 1 if roletype from localStorage is 1
                                  //   if (roletype === "1") {
                                  //     return (
                                  //       item.name !== "Hardware Information"
                                  //     );
                                  //   }
                                  //   return true;
                                  // })
                                  .map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.name}</td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={item.View}
                                          onChange={() =>
                                            handleCheckboxClick(index, "View")
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={item.Create}
                                          onChange={() =>
                                            handleCheckboxClick(index, "Create")
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={item.Edit}
                                          onChange={() =>
                                            handleCheckboxClick(index, "Edit")
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={item.Delete}
                                          onChange={() =>
                                            handleCheckboxClick(index, "Delete")
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="Line_div"></div>
                      <div class="modal_btn_container role_btn space_div mt-0">
                        <Link to="/team-management">
                          {" "}
                          <div class="modal_cancel_btn">Cancel</div>
                        </Link>
                        <button
                          class="modal_cancel_btn"
                          type="submit"
                          onClick={creactrole}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add;
