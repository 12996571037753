import React from "react";
import "./OrderPopup.css";

const OrderPopup = ({ orderData, onClose }) => {
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour: "2-digit", minute: "2-digit" };
    return date.toLocaleTimeString("en-US", options);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options);
  };

  const calculateTotal = (items) => {
    return items.reduce(
      (total, item) =>
        total +
        calculateTotalCustomizationPrice(item.customization) *
          (item.mealbundle.length + 1),
      0
    );
  };

  const calculateTotalCustomizationPrice = (customizations) => {
    let totalPrice = 0;
    customizations.forEach((customization) => {
      totalPrice += parseFloat(customization.price);
    });
    return totalPrice.toFixed(2);
  };

  const calculateItemTotalPrice = (item) => {
    const customizationPrice = parseFloat(
      calculateTotalCustomizationPrice(item.customization)
    );
    const quantity = item.mealbundle.length + 1;
    const itemTotalPrice = customizationPrice * quantity;
    return itemTotalPrice.toFixed(2);
  };

  const subTotal = calculateTotal(orderData.completeOrder);
  const tax = subTotal * 0.05;
  const total = subTotal + tax + 1;

  return (
    <div className="popup">
      <div className="popup-inner">
        <button className="close-btn" onClick={onClose}>
          ×
        </button>
        <h2>Order Details</h2>
        <div className="order-summary">
          <div className="order-info">
            <p>
              <strong>Order ID:</strong> {orderData._id}
            </p>
            <p>
              <strong>Order Status:</strong> {orderData.orderStatus}
            </p>
            <p>
              <strong>Date:</strong> {formatDate(orderData.createdAt)}
            </p>
            <p>
              <strong>Time:</strong> {formatTime(orderData.createdAt)}
            </p>
            <p>
              <strong>Payment Mode:</strong> {orderData.paymentType}
            </p>
            <p>
              <strong>Payment Status:</strong> {orderData.paymentStatus}
            </p>
          </div>
          <div className="customer-info">
            <p>{orderData.name}</p>
            <p>{orderData.phone}</p>
            <p>{orderData.email}</p>
          </div>
        </div>
        <table className="order-table">
          <thead>
            <tr>
              <th>Qty.</th>
              <th>Product</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderData.completeOrder.map((item, index) => (
              <tr key={index}>
                <td>{item.mealbundle.length + 1}</td>
                <td>{item.item_name}</td>
                <td>£{calculateTotalCustomizationPrice(item.customization)}</td>
                <td>£{calculateItemTotalPrice(item)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="totals">
          <p>
            <strong>Sub Total:</strong> £{subTotal.toFixed(2)}
          </p>
          <p>
            <strong>Tax:</strong> £{tax.toFixed(2)}
          </p>
          <p>
            <strong>Total:</strong> £{total.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;
