import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import tip from "../../../../../img/tip2.svg";
import { useDispatch } from "react-redux";
import { designationList } from "../../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import Tippopup from "../TipDetailsPopup/Tippopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
const TippingView = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [updateAPI, setUpdateAPI] = useState(1);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState(null);
  const [totalCounts, setTotalCounts] = useState("");
  const [tippingview, setTippingView] = useState([]);
  const OrderId = location.state?.tippingdata;

  //   console.log("OrderId", OrderId);
  const getDesignationList = () => {
    setIsLoading(true);
    const requestOption = {
      page: page,
      search: "",
      limit: limit,
    };
    dispatch(designationList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setTippingView(res?.payload?.payload);
          setTotalCounts(res?.payload?.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getDesignationList();
  }, [page, limit]);
  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };
  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };
  const pageCount = Math.ceil(totalCounts / limit);

  return (
    <>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Tipping Details" img_icon={tip} />
          <div className="container-fluid">
            <div className="mb-3">
              <Link to="/TippingManagement/tippingDetails" className="Back_brn">
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </Link>
            </div>
            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4 ">
                <div className="card-body">
                  <h6 class="text-navy-blue mb-2">Tipping View</h6>
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <div
                            className="dataTables_length "
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="Date_section">
                            <label>
                              Date:&nbsp; &nbsp;
                              <div className="date-picker-wrapper">
                                <FaCalendarAlt className="calendar-icon" />
                                <DatePicker
                                  selected={date}
                                  onChange={(date) => setDate(date)}
                                  placeholderText="MM/DD/YYYY"
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control form-control-sm"
                                  aria-controls="dataTable"
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={15}
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            // style={{ width: "125%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "50px;" }}
                                >
                                  S.No
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "600px;" }}
                                >
                                  Order Id
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  style={{ width: "200px", whiteSpace: "pre" }}
                                >
                                  Tipping
                                </th>
                              </tr>
                            </thead>
                            {/* {canViewDesignations ? ( */}
                            <tbody>
                              {tippingview && tippingview?.length == 0 ? (
                                // Render a loader while data is being fetched
                                <tr>
                                  <td
                                    colSpan="5"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3>Empty List</h3>
                                  </td>
                                </tr>
                              ) : (
                                tippingview.map((item, index) => (
                                  <>
                                    <tr className="odd" key={item?.index}>
                                      <td className="sorting_1">{index + 1}</td>
                                      <td> {item?.designationName}</td>
                                      <td> {item?.designationName}</td>
                                    </tr>
                                  </>
                                ))
                              )}
                            </tbody>
                            {/* ) : (
                              "No View Permission"
                            )} */}
                          </table>
                        </div>
                      </div>
                      <div className="row  align-items-center justify-content-between">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to {totalCounts} of{" "}
                            {tippingview?.length ? tippingview?.length : "0"}{" "}
                            entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="MonthlyTipping">
                          <Tippopup
                            setUpdateAPI={setUpdateAPI}
                            // id={item?._id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TippingView;
