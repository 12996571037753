import React, { useEffect, useState } from "react";
import Sidebarpage from "../../Sidebarpage/sidebarpage";
import "./dashbord.css";
import Topheader from "../TopHeader/topheader";
import homeicon2 from "../../../img/Home-dark.svg";
import homeicon from "../../../img/od.png";
import userimg from "../../../img/res.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dashboard } from "../../../Helper/Redux/API";
const Dashboard = () => {
  const roletype = localStorage.getItem("roletype");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allorder, setAllOrder] = useState("");
  const [allrestaurantlength, setAllRestaurantLength] = useState("");
  const [totalCounts, setTotalCounts] = useState("");

  const getRestaurantListData = () => {
    // setIsLoading(true);

    dispatch(dashboard())
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllOrder(res?.payload?.totalOrder);
          setTotalCounts(res?.payload?.totalCount);
          if (roletype === 2 && roletype === 1) {
            // setImg(res?.payload?.payload?.image);
          }
          // setAllRestaurantLength(res?.payload.total);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  useEffect(() => {
    getRestaurantListData();
  }, []);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Dashbored allsection">
          <Topheader heding_text="Dashboard" img_icon={2} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="row">
            <div className="col-lg-6 col-md-12 mb-4">
  <div className="card shadow-sm card-custom_box h-100 py-3">
    <div className="card-body">
      <div className="row card-custom_box_row no-gutters align-items-center">
        <div className="col mr-2">
          <div className="h5 mb-0">Total Orders</div>
        </div>
        <div className="col-auto">
          <h4 className="count-text">
            <img className="img-fluid" src={homeicon} alt="Home Icon" />
            <span>{allorder}</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>


              <div className="col-lg-6 col-md-12 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <></>
                ) : (
                  <div className="card shadow-sm card-custom_box h-100 py-3">
                    <div className="card-body">
                      <div className="row card-custom_box_row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="h5 mb-0">Total Restaurants</div>
                        </div>
                        <div className="col-auto">
                          <h4 className="count-text">
                            <img className="img-fluid" src={userimg} />
                            <span>{totalCounts}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
