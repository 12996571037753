import React, { useEffect, useState } from "react";
import "./restaurantslogin.css";
import * as Yup from "yup";
import loginsvg from "../../img/newlog.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoginAPI, setpermissionsType } from "../../Helper/Redux/API";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Restaurantslogin = () => {
  const roletype = localStorage.getItem("roletype");

  const [checked, setChecked] = useState(false);
  const [labelText, setLabelText] = useState("Hardware Login");

  // Handle checkbox change

  console.log("isChecked", checked);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Use the dynamic initial state with useState
  const [initialValues, setInitialValues] = useState({
    password: "",
    email: "",
    restaurant: false,
  });

  // useEffect(() => {
  //   setInitialValues((prevValues) => ({
  //     ...prevValues,
  //     restaurant: checked ? "1" : "2",
  //   }));
  // }, [checked]);
  const validation = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const restaurantValue = values.restaurant ? "2" : "1";
    const updatedValues = { ...values, restaurant: restaurantValue };

    dispatch(isLoginAPI(updatedValues))
      .unwrap()
      .then((res) => {
        if (res?.status === true) {
          const { token, payload, role, permissions } = res;
          const {
            _id: userId,
            name: userName,
            is_perfect_member: isperfect,
            // role: roletype,
            restaurantType,
          } = payload;

          if (token) {
            localStorage.setItem("access_token", token);
            localStorage.setItem("id", userId);
            localStorage.setItem("RoleName", userName);
            localStorage.setItem("roletype", role);
            // localStorage.setItem("restaurantType", restaurantType);

            console.log(res?.role, "res?.payload?.role");

            // console.log(
            //   res?.payload?.payload?.is_perfect_member,
            //   "res?.payload?.role"
            // );

            if (
              res?.role == 2 &&
              res?.role == 1 &&
              res?.payload?.is_perfect_member == false
            ) {
              localStorage.setItem(
                "isperfectmember",
                res?.payload?.is_perfect_member
              );

              console.log(
                res?.payload?.is_perfect_member,
                "res?.payload?.role"
              );
            }

            dispatch(setpermissionsType(permissions));
            navigate("/dashboard");
            toast.success(res.message);
          }
        } else {
          toast.error(res.error.message);
        }
      })
      .catch((error) => {
        console.error("Error occurred during login:", error);
        toast.error(error.message);
      });

    navigate("/restaurantslogin");
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setLabelText(isChecked ? "Restaurants Login" : "Hardware Login");
    // setInitialValues({
    //   ...initialValues,
    //   restaurant: event.target.checked ? "1" : "2",
    // });
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="container">
          {/* Outer Row */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-6">
                <div className="card-body p-0">
                  {/* Nested Row within Card Body */}
                  <div className="row justify-content-center">
                    {/* <!-- <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> --> */}
                    <div className="col-lg-6">
                      <div className="p-5 login-inner">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">
                            Welcome Back to!
                          </h1>
                          <img className="img-fluid mb-4" src={loginsvg} />
                        </div>

                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validation}
                        >
                          <Form className="user">
                            <div className="form-group">
                              <Field
                                type="email"
                                name="email"
                                id="login_email"
                                className="form-control form-control-user"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address"
                              />

                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="form-group">
                              <div className="eys_input">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  Placeholder="Enter Password"
                                  id="password"
                                  name="password"
                                  className="form-control form-control-user"
                                />

                                <span
                                  className="password-toggle-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>

                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="form-group form-check">
                              <Field
                                type="checkbox"
                                id="restaurant"
                                name="restaurant"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="restaurant"
                              >
                                Team Login
                              </label>
                            </div>
                            <button
                              className="btn btn-primary btn-user btn-block"
                              type="submit"
                            >
                              Login
                            </button>
                          </Form>
                        </Formik>

                        <div className="text-center mt-4">
                          <Link className="small" to="/Repassword">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Restaurantslogin;
