import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../../Sidebarpage/sidebarpage";
import Topheader from "../../../TopHeader/topheader";
import Firedark from "../../../../../img/team.svg";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";
import Select from "react-select";
import "./add.css";
import {
  branchrole,
  designationAdd,
  restaurantList,
  Profilelist,
} from "../../../../../Helper/Redux/API";

const DesignationAdd = () => {
  const roletype = localStorage.getItem("roletype");
  const [selectedRestaurant, setSelectedRestaurant] = React.useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedvaluesArray, setSelectedvaluesArray] = useState([]);
  const [selectedOptionsBranch, setSelectedOptionsBranch] = React.useState([]);
  const [selectedBrancArray, setSelectedBrancArray] = useState([]);
  const [brachList, setBrachList] = React.useState([]);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [restaurant, setRestaurant] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filddisble, setFilddisble] = useState(false);
  const initialValue =
    roletype === "1" || roletype === "2"
      ? {
          restaurant_name: localStorage.getItem("id"),
          branch_name: "",
          designation: "",
        }
      : {
          // restaurant_name: "",
          // branch_name: "",
          designation: "",
        };

  const [Profiledata, setProfiledata] = useState();
  const [isperfectuser, setIsPerfectUser] = useState("");

  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
          setProfiledata(permissiondata.permission);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    console.log(values, "values");
    const requestOptions = {
      restaurantId: selectedvaluesArray,
      branchIds: selectedBrancArray,
      restaurantLabel: selectedRestaurant
        ? selectedRestaurant
        : selectedOptions2,
      branchLabel: selectedOptionsBranch,
      designationName: values.designation,
    };

    try {
      dispatch(designationAdd(requestOptions)).then((res) => {
        console.log(res);
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          resetForm();
          navigation(-1);
        } else {
          toast.success(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchemas = yup.object().shape({
    // restaurant_name: yup.string().required("Restaurant is required"),
    // branch_name: yup.string().required("Branch is required"),
    designation: yup.string().required("Designation is required"),
  });

  const allrestaurantlist = Array.isArray(restaurant)
    ? restaurant.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const getRestaurantListData = () => {
    const requestOption = {
      page: "",
      search: "",
      limit: "",
    };
    dispatch(restaurantList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setRestaurant(res?.payload?.payload);

          if (roletype === "2") {
            setSelectedRestaurant({
              value: res?.payload?.payload[0]?._id,
              label: res?.payload?.payload[0]?.name,
            });
            setSelectedvaluesArray(res?.payload?.payload[0]?._id);
            setFilddisble(true);
            getBraches(res?.payload?.payload[0]?._id);
          }
        } else {
          setRestaurant([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      });
  };
  useEffect(() => {
    getRestaurantListData();

    if (roletype === "1") {
      // setSelectedOptions({
      //   value: localStorage.getItem("id"),
      //   label: localStorage.getItem("RoleName"),
      // });
      setSelectedRestaurant({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("RoleName"),
      });

      // setInitialValue({
      //   restaurant_name: localStorage.getItem("id"),
      // });
      setSelectedvaluesArray(localStorage.getItem("id"));
      setFilddisble(true);
      getBraches(localStorage.getItem("id"));
    }
  }, []);

  const handleChange = (selectedOptions) => {
    setSelectedRestaurant(selectedOptions);
    setSelectedOptions2(selectedOptions);
    // console.log("selectedOptions", selectedOptions);

    const valuesArray = selectedOptions.map((option) => option.value);
    // console.log("valuesArray", valuesArray);
    setSelectedvaluesArray(valuesArray);
    // Store the values array in the state
    // setSelectedOptions(valuesArray);
    setSelectedOptionsBranch([]);
    getBraches(valuesArray);
    console.log("selectedOptions", selectedOptions);
  };

  const getBraches = (id) => {
    const requestOptions = {
      restaurantId: id,
    };

    try {
      dispatch(branchrole(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          console.log("res?.payload?.payload", res?.payload?.payload);
          setBrachList(res?.payload?.payload);
        } else {
        }
      });
    } catch (error) {
      console.error("Error fetching test data:", error);
    }
  };

  const allbrachList = Array.isArray(brachList)
    ? brachList.map((item) => ({
        label: item.name,
        value: item._id,
      }))
    : [];

  const handleChangeBranch = (selectedOptions) => {
    setSelectedOptionsBranch(selectedOptions);
    const valuesArray = selectedOptions.map((option) => option.value);
    setSelectedBrancArray(valuesArray);
  };

  console.log("allbrachList", allbrachList);

  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Add Designation" img_icon={Firedark} />
          <div className="container-fluid">
            <div className="card shadow-sm card-custom_box mb-4 adddesignation_card">
              <div className="card-body ">
                <div className=" designation_form_container">
                  <h6 class="text-navy-blue mb-5">Add Designation</h6>
                  <hr />
                  <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchemas}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form>
                        <div
                          className="d-flex flex-column  "
                          style={{ gap: "10px" }}
                        >
                          <div className="row addrolemange">
                            <div className="col-md-6 d-flex flex-column">
                              <label htmlFor="Restaurant">
                                Restaurant Name{" "}
                                <span className="text-danger">*</span>
                              </label>

                              {roletype === "1" || roletype === "2" ? (
                                <>
                                  {" "}
                                  {isperfectuser && roletype === "2" ? (
                                    <MultiSelect
                                      options={allrestaurantlist}
                                      value={selectedOptions2}
                                      onChange={handleChange}
                                      labelledBy="Select Restaurant..."
                                      overrideStrings={{
                                        selectSomeItems: "Select Restaurant...",
                                        allItemsAreSelected:
                                          "All Restaurant are selected.",
                                      }}
                                      // isDisabled={filddisble}
                                    />
                                  ) : (
                                    <Select
                                      options={allrestaurantlist}
                                      value={selectedRestaurant}
                                      onChange={handleChange}
                                      placeholder="Select Restaurant..."
                                      isDisabled={filddisble}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <MultiSelect
                                    options={allrestaurantlist}
                                    value={selectedRestaurant}
                                    onChange={handleChange}
                                    labelledBy="Select Restaurant..."
                                    overrideStrings={{
                                      selectSomeItems: "Select Restaurant...",
                                      allItemsAreSelected:
                                        "All Restaurant are selected.",
                                    }}
                                    // isDisabled={filddisble}
                                  />
                                </>
                              )}

                              {/* <Field
                                as="select"
                                Placeholder="Enter Restaurant Name "
                                id="Restaurant"
                                name="restaurant_name"
                                disabled={filddisble}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "restaurant_name",
                                    e.target.value
                                  );
                                  getBraches(e.target.value);
                                }}
                              >
                                <option value="">Select</option>
                                {restaurant.map((res, i) => (
                                  <option value={res?._id} key={i}>
                                    {res?.name}
                                  </option>
                                ))}
                              </Field> */}
                              {/* <ErrorMessage
                                component="p"
                                name="restaurant_name"
                                className="text-danger"
                              /> */}
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                              <label htmlFor="Branch">
                                Branch Name
                                {/* <span className="text-danger">*</span> */}
                              </label>

                              <MultiSelect
                                options={allbrachList}
                                value={selectedOptionsBranch}
                                onChange={handleChangeBranch}
                                labelledBy="Select Branch..."
                                overrideStrings={{
                                  selectSomeItems: "Select Branch...",
                                  allItemsAreSelected:
                                    "All branches are selected.",
                                }}
                              />
                              {/* <Field
                                as="select"
                                Placeholder="Enter Branch Name "
                                id="Branch"
                                name="branch_name"
                              >
                                <option value="">Select</option>
                                {branches.map((res, i) => (
                                  <option value={res?._id} key={i}>
                                    {res?.name}
                                  </option>
                                ))}
                              </Field> */}
                              {/* <ErrorMessage
                                component="p"
                                name="branch_name"
                                className="text-danger"
                              /> */}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 d-flex flex-column">
                              <label htmlFor="designation">
                                Designation{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                Placeholder="Enter Designation "
                                id="designation"
                                name="designation"
                              />
                              <ErrorMessage
                                component="p"
                                name="designation"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="modal_btn_container role_btn">
                          {" "}
                          <div
                            class="modal_cancel_btn"
                            onClick={() => navigation(-1)}
                          >
                            Cancel
                          </div>
                          <button class="modal_cancel_btn" type="submit">
                            Save
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DesignationAdd;
