import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 707,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  overflow: "hidden",
  // Added height constraint
};
const Tippopup = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  return (
    <div>
      <div className="" onClick={handleOpen}>
        <button className="modal_cancel_btn">Monthly Tipping</button>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="modal_body_container Team_modal MonthlyTip">
              <div className="modal_heading ">
                <h1>Monthly Tipping</h1>
                <p>Employee Name Ann Chovey</p>
              </div>
              <div className="containermenu">
                <div className="modal_form_container">
                  <div className="Date_pic">
                    {/* <label htmlFor="MobileNumber">From</label> */}
                    <div className="date-picker-wrapper">
                      <FaCalendarAlt className="calendar-icon" />
                      <DatePicker
                        selected={startDate}
                        onChange={(dates) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="MM/DD/YYYY - MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy"
                        className="form-control form-control-sm"
                        aria-controls="dataTable"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15} // Number of years to show in the dropdown
                      />
                    </div>
                  </div>

                  <div className="Table_data">
                    <table
                      className="table"
                      width="100%"
                      cellspacing="0"
                      // style={{ width: "125%" }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className=""
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Restaurant Name: activate to sort column descending"
                            style={{ fontWeight: 400 }}
                          >
                            Date
                          </th>

                          <th
                            className=""
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Location: activate to sort column ascending"
                            style={{
                              fontWeight: 400,
                              whiteSpace: "pre",
                              width: "100px",
                            }}
                          >
                            Tipping
                          </th>
                        </tr>
                      </thead>
                      {/* {canViewDesignations ? ( */}
                      <tbody>
                        {/* {tippingview && tippingview?.length == 0 ? (
                              
                                <tr>
                                  <td
                                    colSpan="5"
                                    style={{ textAlign: "center" }}
                                  >
                                    <h3>Empty List</h3>
                                  </td>
                                </tr>
                              ) : (
                                tippingview.map((item, index) => (
                                  <> */}
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">05-01-2024</td>
                          <td> £ 10.1</td>
                        </tr>
                        {/* </>
                                ))
                              )} */}
                      </tbody>
                      {/* ) : (
                              "No View Permission"
                            )} */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Tippopup;
