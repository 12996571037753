import React, { useEffect, useState } from "react";
import "./add.css";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./add.css";
import { customizationAdd } from "../../../../../Helper/Redux/API";
import cross_image from "../../../../../img/AddModal/Group 82624.png";
import preview_generic_image from "../../../../../img/AddModal/Group 839.png";
import upload_generic_image from "../../../../../img/AddModal/Group 82553.png";

const AddCategory = ({ setUpdateAPI }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const itemId = location.pathname.split("/")[3];
  const initialValue = {
    title: "",
    isRequired: false,
    isMultiselect: false,
    maximumNumber: "",
    minimumNumber: "",
    customization: [
      {
        name: "",
        price: "",
        image: "",
        imagePreview: "",
      },
    ],
  };

  const handleSubmit = async (values, { resetForm }) => {
    const customizationData = {
      title: values.title,
      isRequired: values.isRequired,
      isMultiselect: values.isMultiselect,
      maximumNumber: values?.isMultiselect ? values.maximumNumber : "",
      minimumNumber: values?.isMultiselect ? values?.minimumNumber : "",
      items: values.customization.map((res) => ({
        name: res?.name || "",
        price: res?.price || "",
      })),
    };

    try {
      const formData = new FormData();
      formData.append("menuItemId", itemId);
      formData.append("customization", JSON.stringify(customizationData));

      values.customization.forEach((res, i) => {
        if (res.image) {
          formData.append(`customizationImages[0][${i}]`, res.image);
        }
      });

      const requestOptions = {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      };

      const response = await dispatch(customizationAdd(requestOptions));

      if (response?.payload?.status) {
        toast.success(response.payload.message);
        resetForm();

        setUpdateAPI("added");
      } else {
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const validationSchemas = yup.object().shape({
    title: yup.string().required("Title is required"),
    customization: yup.array().of(
      yup.object({
        name: yup.string().required("Name is required"),
        price: yup
          .string()
          // .matches(/^\d+$/, "Price must contain only digits")
          .required("Price is required")
          .test(
            "is-decimal",
            "Must be a decimal with up to 2 digits after the decimal point",
            (value) => (value + "").match(/^\d+(\.\d{1,2})?$/)
          ),
      })
    ),
    maximumNumber: yup
      .number()
      .typeError("Maximum Number must be a number")
      .when("isMultiselect", {
        is: true,
        then: yup
          .number()
          .required("Maximum Number is required")
          .positive("Maximum Number must be a positive number"),
        otherwise: yup.number(),
      }),
    minimumNumber: yup
      .number()
      .typeError("Minimum Number must be a number")
      .when("isMultiselect", {
        is: true,
        then: yup
          .number()
          .required("Minimum Number is required")
          .positive("Minimum Number must be a positive number"),
        otherwise: yup.number(),
      }),
  });

  const backbtn = () => {
    navigate("/menu-management/items");
  };
  return (
    <section className="add_category ">
      <div className="add_category_inner">
        <h6 className="heading_name">Add Customization</h6>
        <hr />
        <div className="form_container">
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={validationSchemas}
          >
            {(formik) => (
              <Form>
                <div
                  className="field_container d-flex flex-column gap-5"
                  style={{ gap: "8px" }}
                >
                  <div className=" d-flex flex-column">
                    <label htmlFor="title">
                      Title <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter Title Name "
                      id="title"
                      name="title"
                    />
                    <ErrorMessage
                      component="p"
                      name="title"
                      className="text-danger"
                    />
                  </div>

                  <FieldArray name="customization">
                    {({ push, remove }) => (
                      <>
                        <div className="d-flex justify-content-between align-items-center my-2  ">
                          <p></p>
                          <p
                            className="add_more_field_btn"
                            onClick={() => push(initialValue.customization[0])}
                          >
                            Add Details
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="">Name</label>
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="">Price (£)</label>
                          </div>
                        </div>

                        {formik.values.customization.map(
                          (customization, index) => (
                            <>
                              <div className=" w-100 row  flex-wrap ">
                                <div className="col-md-4 d-flex flex-column">
                                  <Field
                                    type="text"
                                    Placeholder="Enter name "
                                    id="designation"
                                    name={`customization.${index}.name`}
                                  />
                                  <ErrorMessage
                                    component="p"
                                    name={`customization.${index}.name`}
                                    className="text-danger"
                                  />
                                </div>

                                <div className="col-md-4 pl-0 d-flex flex-column">
                                  <Field
                                    type="text"
                                    Placeholder="Enter price "
                                    step="0.01"
                                    id="designation"
                                    name={`customization.${index}.price`}
                                  />

                                  <ErrorMessage
                                    component="p"
                                    name={`customization.${index}.price`}
                                    className="text-danger"
                                  />
                                </div>
                                <div
                                  className=" col-4 p-0 d-flex align-items-center  "
                                  style={{ gap: "10px" }}
                                >
                                  <div className="customization_image_upload_btn">
                                    <img
                                      src={upload_generic_image}
                                      alt=""
                                      style={{ width: "45px" }}
                                    />
                                    <input
                                      type="file"
                                      // onChange={handleCustomizationImageChange(index)}

                                      onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                          try {
                                            formik.setFieldValue(
                                              `customization.${index}.image`,
                                              file
                                            );
                                            formik.setFieldValue(
                                              `customization.${index}.imagePreview`,
                                              URL.createObjectURL(file)
                                            );
                                          } catch (error) {
                                            console.error(
                                              "Failed to create object URL:",
                                              error
                                            );
                                          }
                                        } else {
                                          console.warn(
                                            "No file selected or file type is not supported."
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <img
                                    src={
                                      formik.values.customization[index]
                                        .imagePreview
                                        ? formik.values.customization[index]
                                            .imagePreview
                                        : preview_generic_image
                                    }
                                    alt=""
                                    style={{ width: "55px", height: "45px" }}
                                  />
                                  {formik.values?.customization.length > 1 && (
                                    <img
                                      src={cross_image}
                                      alt="cross image"
                                      style={{ width: "45px" }}
                                      onClick={() => {
                                        const updateCustomization =
                                          formik.values.customization.filter(
                                            (_, i) => i !== index
                                          );
                                        formik.setFieldValue(
                                          "customization",
                                          updateCustomization
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>

                  <div className="row align-items-end mt-5">
                    <div className="col-md-5">
                      <div
                        className="d-flex align-items-center my-1"
                        style={{ gap: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          checked={formik.values.isRequired == true}
                          onChange={(e) =>
                            formik.setFieldValue("isRequired", e.target.checked)
                          }
                        />

                        <label htmlFor="" className="m-0">
                          Is required?
                        </label>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          checked={formik.values.isMultiselect == true}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "isMultiselect",
                              e.target.checked
                            )
                          }
                        />

                        <label htmlFor="" className="m-0">
                          Is Multiselect?
                        </label>
                      </div>
                    </div>
                  </div>
                  {formik.values.isMultiselect && (
                    <div
                      className="row align-items-end "
                      style={{ gap: "10px" }}
                    >
                      <div className="col-md-12 ">
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <label htmlFor="" className="m-0 mr-1">
                            Enter Maximum Number
                          </label>
                          <Field
                            type="number"
                            name="maximumNumber"
                            id=""
                            className="maximum_number_field"
                          />
                        </div>
                        <ErrorMessage
                          component="p"
                          name="maximumNumber"
                          className="text-danger text-end"
                        />
                      </div>
                      <div className="col-md-12 ">
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <label htmlFor="" className="m-0 mr-1">
                            Enter Minimum Number
                          </label>
                          <Field
                            type="number"
                            name="minimumNumber"
                            id=""
                            className="maximum_number_field"
                          />
                        </div>
                        <ErrorMessage
                          component="p"
                          name="minimumNumber"
                          className="text-danger text-end"
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className="submit_btn_container mt-4 d-flex gap-3 justify-content-center align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div className="category_cancel" onClick={backbtn}>
                      Cancel
                    </div>
                    <button className="category_save">Save</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default AddCategory;
