import React, { useEffect, useState } from "react";
import Sidebarpage from "../../../Sidebarpage/sidebarpage";
import Topheader from "../../TopHeader/topheader";
import Firedark from "../../../../img/Fire-dark.svg";
import userimg from "../../../../img/users-icon.svg";
import whitedelete from "../../../../img/white-delete.svg";
// import whiteeye from "../../../../img/img/white-eye.svg";
import whiteedit from "../../../../img/white-edit.svg";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  HardwareList,
  HardwareDelete,
  EditHardware,
  Hardwarestatus,
  Profilelist,
} from "../../../../Helper/Redux/API";
import ReactPaginate from "react-paginate";
import AddHardwareModal from "../Hardware/add/add";
import seting from "../../../../img/seting.svg";
import { toast } from "react-toastify";
import EditModal from "./Edit/edit";
import SettingModal from "./seting /seting";
import swal from "sweetalert";
import "../restaurants.css";

const Hardware = () => {
  const dispatch = useDispatch();
  const roletype = localStorage.getItem("roletype");
  const navigate = useNavigate();
  const [allrestaurant, setAllRestaurant] = useState("");
  const [allrestaurantlength, setAllRestaurantLength] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalRestaurants, setTotalRestaurants] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [updateAPI, setUpdateAPI] = useState(1);
  const [totalCounts, setTotalCounts] = useState("");
  const [Profiledata, setProfiledata] = useState("");
  const [isperfectuser, setIsPerfectUser] = useState("");
  const userpermissions = Profiledata ? Profiledata : [];

  const getProfilelogin = () => {
    // setIsLoading(true);

    dispatch(Profilelist())
      .then((res) => {
        if (res?.payload?.status === true) {
          // console.log("profiledata", res?.payload.payload);
          const permissiondata = res?.payload?.payload?.rolesId;
          setProfiledata(permissiondata.permission);
          setIsPerfectUser(res?.payload?.payload?.is_perfect_member);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        // Set loading to false after data fetching completes
      });
  };
  useEffect(() => {
    if (roletype === "2") {
      getProfilelogin();
    }
  }, []);

  let canViewHardAllot = false;
  let canEditHardAllot = false;
  let canDeleteHardAllot = false;
  let canCreactHardAllot = false;

  if (roletype !== "2") {
    canViewHardAllot = true;
    canEditHardAllot = true;
    canDeleteHardAllot = true;
    canCreactHardAllot = true;

    // Set to false if roletype is not "2"
  }

  if (roletype === "2") {
    const HardAllotPermission = userpermissions?.find(
      (permission) => permission.name === "Hardware Allotment"
    );

    if (HardAllotPermission) {
      canViewHardAllot = HardAllotPermission.View || false;
      canCreactHardAllot = HardAllotPermission.Create || false;
      canEditHardAllot = HardAllotPermission.Edit || false;
      canDeleteHardAllot = HardAllotPermission.Delete || false;
    }
  }

  const gethardwareList = () => {
    setIsLoading(true);
    const requestOption = {
      page: page,
      search: search,
      limit: limit,
    };
    dispatch(HardwareList(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setAllRestaurantLength(res?.payload.payload.length);
          setTotalCounts(res?.payload?.total);
          setUpdateAPI(1);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
  };

  // const handleDeleteTeam = (id) => {
  //   try {
  //     dispatch(HardwareDelete(id)).then((res) => {
  //       if (res?.payload?.status === true) {
  //         toast.success(res?.payload?.message);
  //         // allrestaurant(allrestaurant.filter((team) => team._id !== id));
  //         // getTeam();
  //         gethardwareList();
  //       } else {
  //         toast.error(res?.payload?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeleteTeam = (id) => {
    swal({
      title: "Are you sure want to delete?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(HardwareDelete(id)).then((res) => {
          if (res?.payload?.status == true) {
            toast.success(res?.payload?.message);
            setAllRestaurant(allrestaurant.filter((team) => team._id !== id));
          } else {
            toast.error(res?.payload?.message);
          }
        });
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
    // Append image data if available
  };

  const handleStatusUpdate = (status, id) => {
    const requestOption = {
      isActive: !status,
    };

    const data = {
      id: id,
      requestOption: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(requestOption),
      },
    };
    try {
      dispatch(EditHardware(data)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          gethardwareList();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);

    gethardwarestatus(event.target.value);
    // You can perform any additional actions here based on the selected value
  };

  const gethardwarestatus = (status) => {
    const requestOption = {
      status: status,
    };
    console.log(requestOption);
    dispatch(Hardwarestatus(requestOption))
      .then((res) => {
        if (res?.payload?.status === true) {
          setAllRestaurant(res?.payload?.payload);
          setAllRestaurantLength(res?.payload.payload.length);
          setTotalCounts(res?.payload?.total);
          setUpdateAPI(1);
        } else {
          // toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching test data:", error);
        // Handle error if needed
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after data fetching completes
      });
  };

  const getresetstatus = () => {
    gethardwareList();
    setSearch("");
    setLimit("10");
  };

  useEffect(() => {
    gethardwareList();
  }, [page, limit, search, updateAPI]);
  const pageCount = Math.ceil(totalCounts / limit);
  return (
    <div>
      <div
        className="side_div_menu"
        style={{ display: "flex", height: "100%" }}
      >
        <Sidebarpage />
        <section className="Restaurants Dashbored allsection">
          <Topheader heding_text="Hardware" img_icon={Firedark} />
          <div className="container-fluid">
            {/* <!-- Content Row --> */}
            <div className="row justify-content-between">
              <div className="col-lg-6 mb-4">
                {roletype === "1" || roletype === "2" ? (
                  <></>
                ) : (
                  <>
                    <div className="card shadow-sm card-custom_box h-100 py-3">
                      <div className="card-body">
                        <div className="row card-custom_box_row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="h5 mb-0 ">Allot Hardware</div>
                          </div>
                          <div className="col-auto">
                            <h4 className="count-text">
                              <img className="img-fluid" src={userimg} />
                              <span> {totalCounts ? totalCounts : "0"} </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}{" "}
              </div>

              <div className="col-lg-4 col-md-12 mb-4">
                {roletype === "2" ? (
                  <>
                    {isperfectuser === true && canCreactHardAllot ? (
                      <>
                        {" "}
                        <AddHardwareModal setUpdateAPI={setUpdateAPI} />
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {" "}
                    {roletype === "1" ? (
                      <></>
                    ) : (
                      <AddHardwareModal setUpdateAPI={setUpdateAPI} />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="custom-table">
              <div className="card shadow-sm card-custom_box mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="dataTable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <h6 class="text-navy-blue mb-2">Hardware</h6>

                      {roletype === "1" || roletype === "2" ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <div className="status_div d-flex">
                            <div class="form-group mb-0">
                              <select
                                class="form-control"
                                id="selectstatis"
                                onChange={handleChange}
                                value={selectedStatus}
                              >
                                <option selected>Status</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                            </div>

                            <button
                              className="resetbutton"
                              onClick={getresetstatus}
                            >
                              Reset
                            </button>
                          </div>
                        </>
                      )}

                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="dataTable_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="dataTable_length"
                                aria-controls="dataTable"
                                value={limit}
                                onChange={handleLimitChange}
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                {/* <option value="100">100</option> */}
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="dataTable_filter"
                            className="dataTables_filter restroSearch"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="dataTable"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table dataTable no-footer"
                            id="dataTable"
                            width="100%"
                            cellspacing="0"
                            role="grid"
                            aria-describedby="dataTable_info"
                            // style={{ width: "130%" }}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="Restaurant Name: activate to sort column descending"
                                  style={{ width: "100px;", whiteSpace: "pre" }}
                                >
                                  Sr. No.
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Mobile Number: activate to sort column ascending"
                                  style={{ width: "200px" }}
                                >
                                  Product Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Email: activate to sort column ascending"
                                  // style="width: 204px;"
                                  style={{ width: "283px" }}
                                >
                                  Restaurant Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Location: activate to sort column ascending"
                                  // style="width: 130px;"
                                  style={{ width: "180px" }}
                                >
                                  Branch Name
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "150px" }}
                                >
                                  Product Serial No
                                </th>

                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  // style="width: 70px;"
                                  style={{ width: "80px" }}
                                >
                                  Status
                                </th>
                                <th
                                  className=""
                                  tabindex="0"
                                  aria-controls="dataTable"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action: activate to sort column ascending"
                                  // style="width: 132px;"
                                  style={{ width: "60px" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>

                            {canViewHardAllot ? (
                              <>
                                {" "}
                                <tbody>
                                  {isLoading ? (
                                    // Render a loader while data is being fetched
                                    <tr>
                                      <td
                                        colSpan="5"
                                        style={{ textAlign: "center" }}
                                      >
                                        {/* <img src={loder} className="img-fluid" alt="loder" /> */}
                                        <h3>Empty List</h3>
                                      </td>
                                    </tr>
                                  ) : (
                                    // Array.isArray(allrestaurant) &&
                                    // allrestaurant.map((item, index) => (

                                    Array.isArray(allrestaurant) &&
                                    allrestaurant.map((item, index) => (
                                      <>
                                        <tr className="odd" key={item._id}>
                                          <td className="sorting_1">
                                            {index + 1}
                                          </td>
                                          <td> {item?.productId?.name}</td>
                                          <td> {item?.restaurantId?.name}</td>
                                          <td>
                                            {" "}
                                            {item?.branchId?.name
                                              ? item?.branchId?.name
                                              : "NA"}
                                          </td>
                                          <td> {item?.serialNo}</td>
                                          {roletype === "2" ? (
                                            <>
                                              {" "}
                                              {isperfectuser === true &&
                                              canEditHardAllot ? (
                                                <td
                                                  className={
                                                    item.isActive
                                                      ? "text-success"
                                                      : "text-danger"
                                                  }
                                                  data-toggle="tooltip"
                                                  title="Change status"
                                                  onClick={() =>
                                                    handleStatusUpdate(
                                                      item.isActive,
                                                      item._id
                                                    )
                                                  }
                                                >
                                                  {item.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                                </td>
                                              ) : (
                                                <td
                                                  className={
                                                    item.isActive
                                                      ? "text-success"
                                                      : "text-danger"
                                                  }
                                                  data-toggle="tooltip"
                                                  title="Change status"
                                                >
                                                  {item.isActive
                                                    ? "Active"
                                                    : "Inactive"}
                                                </td>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <td
                                                className={
                                                  item.isActive
                                                    ? "text-success"
                                                    : "text-danger"
                                                }
                                                data-toggle="tooltip"
                                                title="Change status"
                                                onClick={() =>
                                                  handleStatusUpdate(
                                                    item.isActive,
                                                    item._id
                                                  )
                                                }
                                              >
                                                {item.isActive
                                                  ? "Active"
                                                  : "Inactive"}
                                              </td>
                                            </>
                                          )}

                                          <td>
                                            <ul className="action-btns">
                                              {roletype === "2" &&
                                              isperfectuser === true ? (
                                                <>
                                                  {canEditHardAllot ? (
                                                    <>
                                                      {" "}
                                                      <li>
                                                        <EditModal
                                                          setUpdateAPI={
                                                            setUpdateAPI
                                                          }
                                                          id={item._id}
                                                          SrNo={item.serialNo}
                                                        />
                                                      </li>
                                                    </>
                                                  ) : null}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  {roletype === "1" ||
                                                  roletype === "2" ? (
                                                    <></>
                                                  ) : (
                                                    <li>
                                                      <EditModal
                                                        setUpdateAPI={
                                                          setUpdateAPI
                                                        }
                                                        id={item._id}
                                                        SrNo={item.serialNo}
                                                      />
                                                    </li>
                                                  )}
                                                </>
                                              )}

                                              {roletype === "2" &&
                                              isperfectuser === true ? (
                                                <>
                                                  {" "}
                                                  {canDeleteHardAllot && (
                                                    <li>
                                                      <button
                                                        className="bg-orange delete_btn"
                                                        onClick={() =>
                                                          handleDeleteTeam(
                                                            item?._id
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={whitedelete}
                                                        />
                                                      </button>
                                                    </li>
                                                  )}{" "}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  {roletype == "1" ||
                                                  roletype == "2" ? (
                                                    <></>
                                                  ) : (
                                                    <li>
                                                      <button
                                                        className="bg-orange delete_btn"
                                                        onClick={() =>
                                                          handleDeleteTeam(
                                                            item?._id
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={whitedelete}
                                                        />
                                                      </button>
                                                    </li>
                                                  )}{" "}
                                                </>
                                              )}
                                              <li>
                                                <SettingModal
                                                  setUpdateAPI={setUpdateAPI}
                                                  id={item?.productId?._id}
                                                  resid={
                                                    item?.restaurantId?._id
                                                  }
                                                  menid={item?._id}
                                                  branchId={
                                                    item?.branchId?._id
                                                      ? item?.branchId?._id
                                                      : item?.productId?._id
                                                  }
                                                />
                                              </li>
                                            </ul>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                  )}
                                </tbody>
                              </>
                            ) : (
                              "No View Permission"
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="dataTable_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 10 of{" "}
                            {allrestaurantlength ? allrestaurantlength : "0"}{" "}
                            entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          {/* <Pagination
                            currentPage={page}
                            totalItems={totalRestaurants}
                            itemsPerPage={limit}
                            onPageChange={handlePageChange}
                          /> */}

                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={limit}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Hardware;
